<template>
    <div class="main grid_card">
        <b-field>
            <b-checkbox
                v-model="choice"
                aria-label="f_building_violations.toggle"
                :class="{ unchecked: !choice }"
            >
                {{ $t('valuer_tool.building_violations.question') }}
                <Tooltip :label="$t('valuer_tool.building_violations.tooltip')" />
            </b-checkbox>
        </b-field>
        <div v-if="choice" class="main__details" aria-label="f_building_violations">
            <textarea
                v-model="f_building_violations"
                cols="30"
                rows="10"
                :placeholder="$t('valuer_tool.building_violations.placeholder')"
            ></textarea>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'

import Tooltip from '@/apps/ERS/components/elements/Tooltip.vue'

export default {
    name: 'BuildingViolations',
    components: {
        Tooltip,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getDVMFeatures']),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES']),
        }
    },
    data: function() {
        return {
            choice: null,
            f_building_violations: 'null',
        }
    },
    watch: {
        f_building_violations(val) {
            this.choice && this.SET_DVM_FEATURES({ f_building_violations: val })
        },
    },
    mounted() {
        this.f_building_violations = this.getDVMFeatures.f_building_violations
        if (this.f_building_violations) {
            this.choice = true
        }
    },
}
</script>

<style scoped lang="scss">
::v-deep .field {
    .unchecked {
        .check {
            background: white !important;
        }
    }
}
::v-deep .control-label {
    display: flex;
    gap: 0.75rem;
    align-items: center;
}
.main {
    grid-column: 1/-1;
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid #0096ff;
    background: linear-gradient(
            0deg,
            rgba(0, 150, 255, 0.1) 0%,
            rgba(0, 150, 255, 0.1) 100%
        ),
        #fff;

    /* shadow floating */
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    color: #001837;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &__details {
        display: flex;
        height: 7.5rem;
        gap: 1.5rem;
        align-self: stretch;
        min-width: 100%;
    }
}

textarea {
    min-width: 100%;
    border-radius: 5px;
    border: 1px solid #d6e2e9;
    background: #fff;
    resize: none;
    padding: 12px;
}
</style>
