<template>
    <div class="container section is-fluid">
        <div class="faq-container">
            <h1
                class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"
            >
                {{ $t('section.helpcenter') }}
            </h1>

            <b-input v-model="searchField" placeholder="Search" class="mb-6"></b-input>
            <h2
                v-if="Object.keys(items.available_videos).length"
                class="title is-size-4 mgt-medium is-family-secondary has-text-weight-bold"
            >
                {{ $t('global.helpcenter.video_tutorials') }}
            </h2>
            <div
                v-for="category in Object.keys(items.available_videos)"
                :key="category + '_video'"
            >
                <h2
                    v-if="items.available_videos[category].length"
                    class="has-text-primary is-size-5 has-text-weight-medium mgb-small"
                >
                    {{ t(`section.${category}`) }}
                </h2>

                <div
                    v-if="items.available_videos[category].length"
                    class="questions faq-content mb-6"
                >
                    <question
                        v-for="item in items.available_videos[category]"
                        :id="item.name"
                        :key="item.name"
                        is-video
                        :open="item.shouldOpen"
                        :q-data="{
                            question: $t(`faq.${item.name}_question`),
                            answer: $t(`faq.${item.name}_answer`),
                            modules: item.modules.filter((m) =>
                                accessibleModules.includes(m)
                            ),
                        }"
                    />
                </div>
            </div>

            <h2
                class="title is-size-4 mgt-medium is-family-secondary has-text-weight-bold"
            >
                {{ $t('global.helpcenter.faq') }}
            </h2>

            <div v-for="category in Object.keys(items.available_faqs)" :key="category">
                <h2 class="has-text-primary is-size-5 has-text-weight-medium mgb-small">
                    {{ t(`section.${category}`) }}
                </h2>

                <div class="questions faq-content mb-6">
                    <question
                        v-for="item in items.available_faqs[category]"
                        :id="item.name"
                        :key="item.name"
                        ref="question"
                        :open="item.shouldOpen"
                        :q-data="{
                            question: $t(`faq.${item.name}_question`),
                            answer: $t(`faq.${item.name}_answer`),
                            modules: item.modules.filter((m) =>
                                accessibleModules.includes(m)
                            ),
                        }"
                    />
                </div>
            </div>
            <div v-if="shouldShowContact" class="contact-link faq-content mb-6">
                <h2
                    class="has-text-black is-size-5 has-text-weight-medium is-flex is-align-items-center"
                >
                    {{ $t('global.helpcenter.question') }}
                </h2>
                <b-button size="is-medium" type="is-primary" @click="showContactModal">
                    {{ $t('global.helpcenter.contact_cta') }}
                </b-button>
            </div>
        </div>
        <b-modal
            v-model="isContactModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="contact-modal"
        >
            <contact-form @hide-contact-modal="hideContactModal" />
        </b-modal>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import { localeMixin } from '@/components/mixins/locale.js'
import { config } from '@/shared/assets/config/config'
import ContactForm from './ContactForm.vue'
import Question from './Question.vue'
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
export default {
    name: 'FAQ',
    components: {
        Question,
        ContactForm,
    },
    mixins: [localeMixin],
    data() {
        return {
            searchField: '',
            isContactModalActive: false,
            isFeedbackSent: false,

            contactForm: {
                subject: 0,
                module: '-',
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            },

            userRequests: [],
        }
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Support',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    computed: {
        ...mapGetters('auth', ['accessibleModules']),
        shouldShowContact() {
            return this.$config.SUPPORT_CONTACT
        },
        items() {
            let available_items = config.filter(
                (item) =>
                    item.functionality === 'faq' &&
                    item.modules.some((mod) => this.accessibleModules.includes(mod))
            )

            let available_videos = {},
                available_faqs = {}

            available_items.forEach((item) => {
                const isSearchFilter =
                    this.searchField && !utils.isEmptyStr(this.searchField)
                const isSearchQueryInQuestion = this.$t(`faq.${item.name}_question`)
                    .toUpperCase()
                    .includes(this.searchField.toUpperCase())
                const isSearchQueryInAnswer = this.$t(`faq.${item.name}_answer`)
                    .toUpperCase()
                    .includes(this.searchField.toUpperCase())

                item.shouldOpen = false

                if (this.$route.hash.substring(1) === item.name) {
                    item.shouldOpen = true
                }

                if (this.isVideoTutorial(this.$t(`faq.${item.name + '_answer'}`))) {
                    if (isSearchFilter && !isSearchQueryInQuestion) {
                        return
                    }
                    if (!available_videos.hasOwnProperty(item.sections[0])) {
                        available_videos[item.sections[0]] = []
                    }
                    available_videos[item.sections[0]].push(item)
                } else {
                    if (
                        isSearchFilter &&
                        !isSearchQueryInQuestion &&
                        !isSearchQueryInAnswer
                    ) {
                        return
                    }

                    if (!available_faqs.hasOwnProperty(item.sections[0])) {
                        available_faqs[item.sections[0]] = []
                    }

                    if (isSearchQueryInAnswer && isSearchFilter) {
                        item.shouldOpen = true
                    }

                    available_faqs[item.sections[0]].push(item)
                }
            })

            return {
                available_faqs,
                available_videos,
            }
        },
    },
    mounted() {
        const { question } = this.$route.query
        if (question) {
            this.scrollToQuestion(question, true)
        }
    },
    methods: {
        isVideoTutorial(str) {
            return str.includes('https://share.synthesia.io/embeds/videos/')
        },
        hideContactModal() {
            this.isContactModalActive = false
            this.isFeedbackSent = false
        },

        showContactModal() {
            if (this.$route.query.valuation_request_ref) {
                this.contactForm.valuation_request_ref = this.$route.query.valuation_request_ref
            }
            this.isContactModalActive = true
            this.isFeedbackSent = false
        },
        async scrollToQuestion(id, shouldOpen) {
            const elementId = id
            const ref = this.$refs.question.find((q) => q.$el.id === elementId)
            if (ref) {
                if (shouldOpen) ref.toggleAnswer()
                await nextTick()
                ref.$el.scrollIntoView({ behavior: 'smooth' })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.container {
    background: url('../../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: 100%;
    min-height: 100%;

    width: 100%;
    overflow: scroll;
}

.faq-container {
    max-width: 800px;
    margin: auto auto;
}

.faq-content {
    border: 1px solid #d6e2e9;
    border-radius: 5px;
    box-shadow: 0px 2px 14px 0px #002f501a;
    background: white;
}

.contact-link {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact-modal {
}

.control .select {
    background: red;
}

.submit-btn {
    width: 100%;
}

.separator {
    width: calc(100% + 60px);
    height: 1px;
    background: #e5eaed;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
}
</style>

<style lang="scss">
.help-center-video {
    p {
        font-size: 1rem;
    }
}

.contact-modal {
    .control {
        .select,
        select {
            width: 100% !important;
        }
    }
}
</style>
