<template>
    <div class="multiple-choice" :class="{}">
        <div
            v-for="(answer, i) in answers"
            :key="`answer_${answer.value}`"
            class="answer"
            :class="{
                active: choice === answer.value,
                'answer--small': isSmall,
            }"
            :aria-label="answer.label"
            @click="updateValue(answer.value)"
        >
            <div class="icon-container" :class="[answer.value === 'idk' && 'idk']">
                <svg-icon
                    :icon="answer.icon"
                    height="42"
                    width="42"
                    scale=".8"
                    :color="choice === answer.value ? 'var(--color-primary)' : '#798DA6'"
                />
            </div>
            <div class="text">
                <h3>{{ answer.name }}</h3>
                <p v-if="details[i]">{{ details[i] }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'YesNo',
    model: {
        prop: 'choice',
        event: 'select',
    },
    props: {
        idkEnabled: {
            type: Boolean,
            default: false,
        },
        choice: {
            type: Boolean,
        },
        details: {
            type: Array,
            default: () => [],
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        answers() {
            let answers = [
                {
                    name: this.$t('common.yes'),
                    value: true,
                    icon: 'yes',
                    label: 'Yes',
                },
                {
                    name: this.$t('common.no'),
                    value: false,
                    icon: 'no',
                    label: 'No',
                },
            ]

            if (this.idkEnabled) {
                answers.push({
                    name: this.$t('common.i_dont_know'),
                    value: 'idk',
                    icon: 'idk',
                    label: "I don't know",
                })
            }

            return answers
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('select', value)
        },
    },
}
</script>
<style lang="scss" scoped>
.multiple-choice {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 40px;
    }
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .answer {
        min-height: 6.5rem;
        flex-grow: 1;
        width: 100%;
        padding: 1.5rem;
        @media screen and (min-width: 768px) {
            width: 28%;
            padding: 40px;
        }
        border-radius: 5px;
        background: #fff;
        border: 1px solid #d6e2e9;
        box-shadow: 0px 2px 14px rgba(0, 47, 80, 0.1);
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        flex-grow: auto;

        transition: all 0.3s ease-in-out;

        .icon-container {
            height: 3.5rem;
            width: 3.5rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(121, 141, 166, 0.1);
            border: 1px solid rgba(121, 141, 166, 0.2);
            aspect-ratio: 1;

            &.idk {
                svg {
                    position: relative;
                    left: 2px;
                }
            }
        }

        &:hover {
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);
        }

        h3 {
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;

            transition: all 0.3s ease-in-out;

            color: #001837;
        }

        &.stacked {
            flex-direction: column;
        }

        &.small {
            max-width: auto;
            width: fit-content;
            padding-top: 20px;
            padding-bottom: 20px;
            flex-grow: 1;

            &:not(:first-child) {
                margin-left: 20px;
            }
        }

        &.active {
            border: 1px solid var(--color-primary);
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);

            h3 {
                color: var(--color-primary);
            }

            .icon-container {
                background: rgba(0, 150, 255, 0.1);
                border: 1px solid rgba(0, 150, 255, 0.2);
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        p {
            color: #798da6;
            font-family: Satoshi;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3125rem; /* 150% */
        }
        &--small {
            display: flex;
            width: 12.5rem;
            flex-grow: 0;
            padding: 1rem;
            align-items: center;
            gap: 1rem;
            min-height: unset;
            .icon-container {
                display: flex;
                width: 2rem;
                height: 2rem;
                padding: 0.5rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
            }
            .text {
                h3 {
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}
</style>
