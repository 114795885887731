var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container section is-fluid"},[_c('h1',{staticClass:"title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.t('welcome_title'))+" ")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-8-desktop is-10-tablet is-12-mobile"},[_c('p',{staticClass:"has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.t('welcome_text_1'))+" ")]),_c('p',{staticClass:"has-text-weight-normal my-5"},[_vm._v(" "+_vm._s(_vm.t('welcome_text_2'))+" ")]),_c('p',{staticClass:"has-text-weight-normal my-5"},[_vm._v(" "+_vm._s(_vm.t('welcome_text_3'))+" ")])])]),_c('div',{staticClass:"column is-9-fullhd is-10-tablet is-12-mobile"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[(_vm.submitableValuations.length > 1)?_c('radio-selector',{attrs:{"value":_vm.$route.query.valuation_type,"label":_vm.$t('valuation.type'),"options":_vm.selectOptions,"type":{ 'is-primary': true },"size":"small"},on:{"input":(m) => {
                        _vm.$router.push({ query: { valuation_type: m } })
                    }}}):_c('b-button',{staticClass:"mt-1",attrs:{"size":"is-medium","type":"is-dark","icon-left":"view-dashboard","tag":"router-link","to":{
                    name: 'dashboard',
                    params: {
                        lang: _vm.$route.params.lang,
                    },
                }}},[_c('span',[_vm._v(_vm._s(_vm.t('dashboard')))])]),_c('b-button',{staticClass:"mt-1",attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right","aria-label":"start valuation"},on:{"click":_vm.nextStep}},[_c('span',[_vm._v(_vm._s(_vm.t('start_button_text')))])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }