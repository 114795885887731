<template>
    <div class="pb-5">
        <div v-if="displayList && appointments" class="main main--appointments">
            <h1>{{ $t('valuer_tool.borrower.valuer_appointments.title') }}</h1>
            <span v-if="appointments.length == 0">
                {{ $t('valuer_tool.borrower.valuer_appointments.title-no-appointments') }}
            </span>
            <div v-else class="cards" aria-label="appointment list">
                <div
                    v-for="appointment in appointments"
                    :key="appointment.valuation_request.valuation_request_ref"
                    class="card"
                >
                    <div class="card__title">
                        <div class="card__title-icon">
                            <img src="@/assets/icons/ic-calendar.svg" alt="" />
                        </div>
                        <div class="card__title-text" aria-label="date">
                            <div class="card__title-text-date">
                                {{
                                    new Date(appointment.start_time).toLocaleString([], {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    })
                                }}
                            </div>
                            <div class="card__title-text-time">
                                {{
                                    new Date(appointment.start_time).toLocaleString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })
                                }}
                                -
                                {{
                                    new Date(appointment.end_time).toLocaleString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="card-content__section">
                            <span aria-label="request ref">
                                {{ appointment.valuation_request.valuation_request_ref }}
                            </span>
                        </div>
                        <div class="card-content__section">
                            <span aria-label="status">
                                {{
                                    $t(
                                        'valuer_tool.valuer_summary.appointment_' +
                                            appointment.status
                                    )
                                }}
                            </span>
                        </div>
                        <div class="card-content__section card-content__section--button">
                            <b-button
                                mt="1"
                                size="is-small"
                                tag="router-link"
                                aria-label="view details"
                                :to="{
                                    name: 'valuation.ovm.summary',
                                    query: {
                                        valuation_request_ref:
                                            appointment.valuation_request
                                                .valuation_request_ref,
                                    },
                                }"
                            >
                                {{ $t('valuer_tool.borrower.valuer_appointments.edit') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="appointment-summary">
                <div class="main">
                    <div v-if="appointments == undefined">
                        <progress
                            class="progress is-small is-primary"
                            max="100"
                        ></progress>
                    </div>
                    <div
                        v-else-if="appointments.length == 0"
                        class="main__no-appointment"
                    >
                        <span>
                            {{ $t('valuer_tool.valuer_summary.appointment_not-yet') }}
                        </span>
                        <router-link
                            :to="{ name: 'dashboard' }"
                            class="main__no-appointment-button"
                        >
                            {{ $t('valuer_tool.borrower.submitter.dashboard_button') }}
                        </router-link>
                    </div>
                    <template v-else>
                        <h1
                            class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"
                        >
                            {{ $t('valuer_tool.valuer_summary.appointment_title') }}
                        </h1>
                        <add-to-calendar-button
                            v-if="
                                latestAppointment &&
                                    latestAppointment.status === 'confirmed'
                            "
                            class="calendar-button"
                            :data="{
                                address: getAddress,
                                appointment: latestAppointment,
                            }"
                            :title="$t('valuer_tool.borrower.calendar.title')"
                        />
                        <div class="columns">
                            <div class="column is-6">
                                <!-- ADDRESS -->
                                <p v-if="getAddress" class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <img
                                                src="@/assets/icons/ic-marker-pin.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span>
                                            <a :href="addressGoogleMaps" target="_blank">
                                                {{ getAddress.streetnumber }}
                                                {{ getAddress.streetname }},

                                                {{ getAddress.postalcode }}
                                                {{ getAddress.municipality }}
                                            </a>
                                        </span>
                                    </span>
                                </p>
                                <!-- APPOINTMENT DATE -->
                                <p v-if="latestAppointment" class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <img
                                                src="@/assets/icons/ic-calendar.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span
                                            aria-label="appointment start datetime"
                                            :data-ts="appointmentStartTime.getTime()"
                                        >
                                            {{ formatDate(appointmentStartTime) }}
                                        </span>
                                    </span>
                                </p>
                                <!-- APPOINTMENT TIME -->
                                <p v-if="latestAppointment" class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <img
                                                src="@/assets/icons/ic-clock.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span>
                                            {{ formatTime(latestAppointment.start_time) }}
                                            -
                                            {{ formatTime(latestAppointment.end_time) }}
                                        </span>
                                    </span>
                                </p>
                                <p v-if="paymentSummary" class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <img
                                                src="@/assets/icons/ic-euro-blue.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span>
                                            {{
                                                $t(
                                                    'valuer_tool.valuer_summary.payment_' +
                                                        paymentSummary.status
                                                )
                                            }}
                                        </span>
                                        <a
                                            v-if="hasRole('dispatcher', 'ovm')"
                                            href="#"
                                            class="ml-2"
                                            @click="
                                                () => (editPaymentInfo = !editPaymentInfo)
                                            "
                                        >
                                            <b-icon
                                                icon="pencil"
                                                type="is-secondary"
                                                size="is-small"
                                            />
                                        </a>
                                    </span>
                                </p>
                                <div v-if="editPaymentInfo" class="box dispatcher-box">
                                    <b-field horizontal label="Total fee">
                                        <b-input
                                            v-model="totalFeeEuro"
                                            :disabled="loadingPayment"
                                            type="number"
                                        ></b-input>
                                        <b-button
                                            type="is-primary"
                                            :disabled="
                                                totalFeeEuro ===
                                                    paymentSummary.total_fee_euro
                                            "
                                            @click="
                                                () =>
                                                    updateValReq({
                                                        total_fee_euro: totalFeeEuro,
                                                    })
                                            "
                                        >
                                            {{ $t('monolith.global.update') }}
                                        </b-button>
                                    </b-field>
                                    <b-field horizontal label="Total paid">
                                        {{ paymentSummary.total_paid_euro }}
                                    </b-field>
                                    <b-field horizontal label="Amount still due">
                                        {{ paymentSummary.amount_due_euro }}
                                    </b-field>
                                    <b-field
                                        v-if="paymentHistory !== null"
                                        horizontal
                                        label="Payment status"
                                    >
                                        <b-select
                                            :disabled="loadingPayment"
                                            :value="paymentSummary.status"
                                            @input="
                                                (val) =>
                                                    updatePayment(paymentHistory[0], {
                                                        status: val,
                                                    })
                                            "
                                        >
                                            <option
                                                v-for="status in [
                                                    'pending',
                                                    'succeeded',
                                                    'cancelled',
                                                    'refunded',
                                                ]"
                                                :key="status"
                                                :value="status"
                                            >
                                                {{
                                                    $t(
                                                        'valuer_tool.valuer_summary.payment_' +
                                                            status
                                                    )
                                                }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <b-field
                                        v-if="
                                            paymentSummary &&
                                                paymentSummary.is_payment_needed
                                        "
                                    >
                                        <b-button
                                            :disabled="loadingPayment"
                                            type="is-primary"
                                            @click="postPayment"
                                        >
                                            {{
                                                $t(
                                                    'valuer_tool.valuer_summary.payment_post-full'
                                                )
                                            }}
                                        </b-button>
                                    </b-field>
                                </div>
                                <p class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <b-icon
                                                type="is-primary"
                                                icon="account-tie"
                                            />
                                        </span>
                                        <span>
                                            {{ $t('valuer_tool.valuer_summary.status') }}
                                            {{
                                                $t(
                                                    'valuer_tool.valuer_summary.appointment_' +
                                                        latestAppointment.status
                                                )
                                            }}
                                        </span>
                                    </span>
                                </p>
                            </div>
                            <div v-if="showBorrowerDetails" class="column is-6">
                                <p class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <b-icon type="is-primary" icon="account" />
                                        </span>
                                        <span>
                                            {{ borrower.first_name }}
                                            {{ borrower.last_name }}
                                        </span>
                                    </span>
                                </p>
                                <p class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <b-icon type="is-primary" icon="email" />
                                        </span>
                                        <span>
                                            <a :href="'mailto:' + borrower.email">
                                                {{ borrower.email }}
                                            </a>
                                        </span>
                                    </span>
                                </p>
                                <p class="summary__item">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <b-icon type="is-primary" icon="phone" />
                                        </span>
                                        <span>
                                            <a :href="'tel:' + borrower.phone">
                                                {{ borrower.phone }}
                                            </a>
                                        </span>
                                    </span>
                                </p>
                                <p
                                    v-if="ovmInfo.access_instructions"
                                    class="summary__item"
                                >
                                    <span class="icon-text">
                                        <span class="icon">
                                            <b-icon
                                                type="is-primary"
                                                icon="comment-alert-outline"
                                            />
                                        </span>
                                        <span>
                                            {{ ovmInfo.access_instructions }}
                                        </span>
                                    </span>
                                </p>
                                <template v-if="representative">
                                    <h4 class="is-size-5 mt-5 summary__item">
                                        Representative ({{
                                            representative.role == 'other'
                                                ? representative.role_description
                                                : representative.role
                                        }}):
                                        <router-link
                                            v-if="hasRole('dispatcher', 'ovm')"
                                            :to="{
                                                name: 'valuation.ovm.borrower',
                                                query: {
                                                    step: 'confirm-appointment',
                                                    valuation_request_ref: getRequestRef,
                                                },
                                            }"
                                            class="ml-2"
                                        >
                                            <b-icon
                                                icon="pencil"
                                                type="is-secondary"
                                                size="is-small"
                                            />
                                        </router-link>
                                    </h4>
                                    <p class="summary__item">
                                        <span class="icon-text">
                                            <span class="icon">
                                                <b-icon
                                                    type="is-primary"
                                                    icon="account-key-outline"
                                                />
                                            </span>
                                            <span>
                                                {{ representative.full_name }}
                                            </span>
                                        </span>
                                    </p>
                                    <p class="summary__item">
                                        <span class="icon-text">
                                            <span class="icon">
                                                <b-icon type="is-primary" icon="phone" />
                                            </span>
                                            <span>
                                                <a :href="'tel:' + representative.phone">
                                                    {{ representative.phone }}
                                                </a>
                                            </span>
                                        </span>
                                    </p>
                                    <p class="summary__item">
                                        <span class="icon-text">
                                            <span class="icon">
                                                <b-icon type="is-primary" icon="email" />
                                            </span>
                                            <span>
                                                <a
                                                    :href="
                                                        'mail-to:' + representative.email
                                                    "
                                                >
                                                    {{ representative.email }}
                                                </a>
                                            </span>
                                        </span>
                                    </p>
                                </template>
                            </div>
                        </div>
                        <template
                            v-if="
                                latestAppointment &&
                                    latestAppointment.status === 'pending_confirmation' &&
                                    canConfirm
                            "
                        >
                            <nav-buttons
                                :next-step="nextStep"
                                :prev-step="prevStep"
                                class="mt-5"
                            >
                                <b-button
                                    type="is-success"
                                    :loading="loadingConfirmation"
                                    aria-label="confirm appointment"
                                    class="mr-5"
                                    @click="
                                        () =>
                                            updateLatestAppointment(
                                                { status: 'confirmed' },
                                                false
                                            )
                                    "
                                >
                                    {{
                                        $t(
                                            'valuer_tool.valuer_summary.appointment_confirm-btn'
                                        )
                                    }}
                                </b-button>
                                <b-button
                                    :loading="loadingConfirmation"
                                    type="is-danger"
                                    @click="cancelAppointmentAsValuer"
                                >
                                    {{
                                        $t(
                                            'valuer_tool.valuer_summary.appointment_cancel-btn'
                                        )
                                    }}
                                </b-button>
                            </nav-buttons>
                        </template>
                    </template>
                </div>
            </div>
            <div
                v-if="hasRole('dispatcher', 'ovm')"
                class="container mt-3 dispatcher-options pb-5 px-5"
            >
                <h3 class="title mt-3 py-5">Dispatcher Tools</h3>
                <form v-if="latestAppointment !== null">
                    <h4 class="title is-size-4 mt-3">Edit current appointment details</h4>
                    <p class="my-3">
                        <strong>Currently assigned:</strong>
                        {{
                            latestAppointment.valuer
                                ? getNameForUserId(latestAppointment.valuer.id)
                                : 'No valuer assigned'
                        }}
                    </p>

                    <b-field horizontal>
                        <b-checkbox v-model="skipNotifications">
                            {{ $t('valuer_tool.valuer_summary.skip-notification') }}
                        </b-checkbox>
                    </b-field>
                    <b-field horizontal label="Valuer">
                        <b-select
                            :value="
                                latestAppointment.valuer
                                    ? latestAppointment.valuer.id
                                    : null
                            "
                            @input="
                                (val) => {
                                    editLatestAppointment({ valuer_id: val })
                                }
                            "
                        >
                            <option
                                v-for="valuer in valuerUsers"
                                :key="valuer.id"
                                :value="valuer.id"
                            >
                                {{ getNameForUserId(valuer.id) }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field horizontal label="Appointment status">
                        <b-select
                            :value="latestAppointment.status"
                            :disabled="loadingConfirmation"
                            @input="
                                (val) => {
                                    editLatestAppointment({ status: val })
                                }
                            "
                        >
                            <option
                                v-for="status in [
                                    'new',
                                    'pending_confirmation',
                                    'confirmed',
                                    'cancelled',
                                    'cancelled_by_valuer',
                                ]"
                                :key="status"
                                :value="status"
                            >
                                {{
                                    $t('valuer_tool.valuer_summary.appointment_' + status)
                                }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field horizontal label="Start time">
                        <b-datetimepicker
                            icon="calendar-today"
                            :value="latestAppointment.start_time"
                            :disabled="loadingConfirmation"
                            rounded
                            placeholder="Click to select..."
                            @input="
                                (val) => {
                                    editLatestAppointment({ start_time: val })
                                }
                            "
                        ></b-datetimepicker>
                    </b-field>
                    <b-field horizontal label="End time">
                        <b-datetimepicker
                            icon="calendar-today"
                            :value="latestAppointment.end_time"
                            :disabled="loadingConfirmation"
                            rounded
                            placeholder="Click to select..."
                            @input="
                                (val) => {
                                    editLatestAppointment({ end_time: val })
                                }
                            "
                        ></b-datetimepicker>
                    </b-field>
                    <div class="is-flex is-justify-content-center">
                        <b-button
                            type="is-success"
                            :disabled="
                                !apptChanges || Object.keys(apptChanges).length < 1
                            "
                            :loading="loadingConfirmation"
                            aria-label="confirm changes"
                            class="mr-5"
                            @click="confirmAppointmentChanges"
                        >
                            Confirm changes
                        </b-button>
                    </div>
                    <b-notification
                        v-if="latestAppointment.end_time <= latestAppointment.start_time"
                        type="is-danger"
                    >
                        End time should be later than start time
                    </b-notification>
                </form>
                <div>
                    <h4 class="title is-size-4 mt-3">Create new appointment</h4>
                    <p class="mb-3 new-apt-notice">
                        The appointment detailed above will be cancelled and a new one
                        will be created. The assigned valuer will be notified of the
                        appointment's cancellation.
                    </p>
                    <b-button @click="createAppointment">
                        Create appointment
                    </b-button>
                </div>
                <div v-if="appointments && appointments.length > 1">
                    <h4
                        class="title is-size-4 mt-3"
                        @click="showAllAppointments = !showAllAppointments"
                    >
                        Previous appointment records
                        <b-icon
                            :icon="showAllAppointments ? 'menu-down' : 'menu-up'"
                        ></b-icon>
                    </h4>
                    <ul v-if="showAllAppointments">
                        <li
                            v-for="appointment in appointments.slice(1)"
                            :key="appointment.id"
                        >
                            {{ appointment.cronofy_event_id }} -
                            {{ appointment.status }} -
                            {{ appointment.start_time.toLocaleString() }} -
                            {{
                                appointment.valuer
                                    ? getNameForUserId(appointment.valuer.id)
                                    : 'No valuer assigned'
                            }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import deepmerge from 'deepmerge'
import AddToCalendarButton from './borrower/AddToCalendarButton'

export default {
    name: 'OvmSummary',
    components: {
        AddToCalendarButton,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
        displayList: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
    data: () => ({
        appointments: undefined,
        paymentSummary: null,
        loadingConfirmation: false,
        valuerUsers: null,
        skipNotifications: false,
        showAllAppointments: false,
        totalFeeEuro: null,
        loadingPayment: false,
        paymentHistory: null,
        editPaymentInfo: false,
        apptChanges: {},
    }),
    computed: {
        ...mapGetters('auth', ['userId', 'hasRole']),
        ...mapGetters('valuationStore', ['getRequestRef', 'ovmInfo', 'borrower']),
        ...mapGetters('valuationStore/Address', ['getAddress', 'getOVMFeatures']),
        representative() {
            if (!this.ovmInfo) return null
            return this.ovmInfo.representative
        },
        latestAppointment() {
            return this.appointments?.length ? this.appointments[0] : null
        },
        appointmentStartTime() {
            return new Date(this.latestAppointment?.start_time)
        },
        addressGoogleMaps() {
            const urlEncodedAddress = encodeURIComponent(this.getAddress.full_address)
            return 'https://www.google.com/maps?q=' + urlEncodedAddress
        },
        canConfirm() {
            return (
                this.hasRole('valuer', 'ovm') &&
                this.latestAppointment.valuer?.id === this.userId &&
                this.paymentSummary &&
                !this.paymentSummary.is_payment_needed
            )
        },
        showBorrowerDetails() {
            if (this.hasRole('dispatcher', 'ovm')) return true
            if (!this.borrower || this.latestAppointment.status != 'confirmed')
                return false
            // only show if appointmentStartTime is in less than 24h:
            return this.appointmentStartTime - new Date() < 24 * 60 * 60 * 1000
        },
    },
    mounted() {
        // TODO: replace this by proper Store action:
        if (!this.displayList && this.hasRole('dispatcher', 'ovm')) this.getValuerUsers()

        this.getAppointments()
    },
    methods: {
        formatDate(aDate) {
            return utils.formatDate(aDate, true, this.$route.params.lang)
        },
        formatTime(aDate) {
            return utils.formatTime(aDate, this.$route.params.lang)
        },
        getAppointments() {
            this.loadingConfirmation = true
            let url = ''
            if (!this.displayList && this.getRequestRef) {
                url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'request',
                    this.getRequestRef,
                ])
            } else {
                url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'user',
                    this.$route.query.id || this.userId,
                ])
            }
            return this.$axios
                .get(url)
                .then((res) => {
                    this.appointments = res.data
                    for (const appointment of this.appointments) {
                        appointment.start_time = new Date(appointment.start_time)
                        appointment.end_time = new Date(appointment.end_time)
                    }
                    if (this.getRequestRef) this.getPaymentSummary()

                    // TODO: replace this by proper Store action:
                    if (!this.displayList && this.hasRole('dispatcher', 'ovm')) {
                        this.getPaymentHistory()
                    }
                })
                .finally(() => {
                    this.loadingConfirmation = false
                })
        },
        // Move this to a Store action:
        getPaymentSummary() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
                'summary',
            ])

            this.loadingPayment = true
            this.$axios
                .get(url)
                .then((res) => {
                    this.paymentSummary = res.data
                    this.totalFeeEuro = this.paymentSummary.total_fee_euro
                })
                .finally(() => {
                    this.loadingPayment = false
                })
        },
        editLatestAppointment(data) {
            if (Array.isArray(this.appointments)) {
                this.appointments[0] = deepmerge(this.appointments[0], data)
            } else {
                this.appointments = [
                    {
                        ...data,
                    },
                ]
            }

            this.apptChanges = { ...this.apptChanges, ...data }
        },
        getPaymentHistory() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
            ])

            this.$axios.get(url).then((res) => {
                this.paymentHistory = res.data
            })
        },
        getValuerUsers() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, ['users'])

            this.$axios
                .get(url, {
                    params: {
                        roles: 'valuer',
                    },
                })
                .then((res) => {
                    this.valuerUsers = res.data
                })
        },
        getNameForUserId(id) {
            if (!this.valuerUsers) return null
            const user = this.valuerUsers.find((user) => user.id === id)
            if (!user) return `Unknown user <${id}>`
            return `${user.first_name || '-'} ${user.last_name || '-'} <${user.id}>`
        },
        confirmAppointmentChanges() {
            this.updateLatestAppointment(this.apptChanges, true)
            this.apptChanges = {}
        },
        updateLatestAppointment(data, skipValidation) {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'event',
                this.latestAppointment.cronofy_event_id,
            ])

            this.loadingConfirmation = true

            this.$axios
                .put(url, data, {
                    params: {
                        skip_validation: skipValidation,
                        skip_notifications: this.skipNotifications,
                    },
                })
                .finally(() => {
                    this.getAppointments()
                    // this.loadingConfirmation = false
                })
        },
        cancelAppointmentAsValuer() {
            if (
                confirm(this.$t('valuer_tool.valuer_summary.appointment_confirm-cancel'))
            ) {
                this.updateLatestAppointment({ status: 'cancelled_by_valuer' }, false)
            }
        },
        updatePayment(payment, data) {
            if (
                data.status === 'pending' &&
                payment.status === 'succeeded' &&
                payment.payment_intent_id
            ) {
                if (
                    !confirm(
                        'Attempting to set a payment status back to `pending` will likely be reversed if the Stripe transaction was successful.'
                    )
                )
                    return
            }

            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
                'payment',
                `${payment.payment_id}`,
            ])

            this.$axios.patch(url, data).then(() => {
                this.paymentHistory = null
                this.getPaymentSummary()
                this.getPaymentHistory()
            })
        },
        postPayment() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
            ])

            this.$axios
                .post(url, {
                    amount_euro: this.paymentSummary.amount_due_euro,
                    status: 'succeeded',
                })
                .then(() => {
                    this.getPaymentSummary()
                    this.getPaymentHistory()
                })
        },
        updateValReq(data) {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'ovm',
                'request',
                this.getRequestRef,
            ])

            this.paymentSummary = null
            this.$axios.put(url, data).then(() => {
                this.getPaymentSummary()
            })
        },
        createAppointment() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
            ])

            this.$axios
                .post(url, {
                    start_time: new Date(),
                    end_time: new Date(Date.now() + 90 * 60 * 1000),
                })
                .then(() => {
                    this.getAppointments()
                })
        },
    },
}
</script>

<style lang="scss">
.summary {
    &__item {
        margin-bottom: 1rem;
    }
}

.dispatcher-options,
.dispatcher-box {
    background-color: #d0efff !important;
}

.dispatcher-options > form,
.dispatcher-options > div {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #b3d7ff;
}
.dispatcher-options ul li {
    // display bullet points
    list-style-type: disc;
    list-style-position: inside;
}
</style>

<style scoped lang="scss">
.new-apt-notice {
    font-weight: 500;
    color: #001837;
}

.calendar-button {
    margin-block: 1.5rem;
    max-width: fit-content;
    min-width: 9rem;
    min-height: 3rem;
}

.main {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    gap: 3rem;
    &--appointments {
        padding-inline: 0;
    }
    &__no-appointment {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-top: 5rem;
        align-items: center;
        &-button {
            display: flex;
            padding: 0.75rem 1.25rem;
            align-items: flex-start;
            gap: 0.5rem;
            border-radius: 0.3125rem;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background-color: #001837;
            color: #fff;
            max-width: fit-content;
        }
        span {
            max-width: fit-content;
        }
    }
    h1 {
        color: #001837;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

::v-deep .b-skeleton > .b-skeleton-item {
    flex-grow: 1;
}

.cards {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    .card {
        display: flex;
        padding: 1.25rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        &__title {
            display: flex;
            gap: 1rem;
            &-icon {
                display: flex;
                width: 3rem;
                height: 3rem;
                padding: 0.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 5rem;
                background: rgba(0, 150, 255, 0.2);
                img {
                    width: 1.5rem;
                    height: 1.5rem;
                    flex-shrink: 0;
                }
            }
            &-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.25rem;
                &-date {
                    color: #001837;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                &-time {
                    color: #001837;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
        &-content {
            min-width: 100%;
            padding: 0;
            &__section {
                &--button {
                    margin-top: 1rem;
                    a {
                        min-width: 100%;
                    }
                }
                &-title {
                    color: #001837;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.25rem; /* 100% */
                    margin-bottom: 1rem;
                }
                h2 {
                    color: rgba(0, 24, 55, 0.6);
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem; /* 166.667% */
                    margin-bottom: 0.25rem;
                }
                span {
                    color: #001837;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem; /* 142.857% */
                }
            }
        }
    }
}
</style>
