<template>
    <div>
        <report-section
            v-for="section in sections"
            :key="section.name"
            :name="section.name"
            :columns="section.columns"
            :filter-building-type="section.filterBuildingType"
            :filter-null="section.filterNull"
            :orientation="section.orientation"
            :section-data="section.data"
        ></report-section>
    </div>
</template>

<script>
import ReportSection from './Section.vue'

export default {
    name: 'SectionsContent',
    components: {
        ReportSection,
    },
    props: {
        sections: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    computed: {},
    methods: {},
}
</script>
