/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'idk': {
    width: 18,
    height: 35,
    viewBox: '0 0 18 35',
    data: `<path d="M0.3,3.6C0.8,3.2,1.4,2.7,2,2.3c0.6-0.4,1.2-0.8,2-1.1c0.7-0.3,1.5-0.6,2.3-0.7C7,0.3,7.9,0.2,8.9,0.2c1.3,0,2.4,0.2,3.5,0.5c1.1,0.4,2,0.9,2.8,1.6C16,3,16.6,3.8,17,4.8c0.4,0.9,0.7,2,0.7,3.2c0,1.2-0.2,2.2-0.5,3.1c-0.4,0.9-0.8,1.6-1.3,2.3c-0.5,0.6-1.1,1.2-1.8,1.7c-0.6,0.5-1.2,0.9-1.8,1.4c-0.6,0.4-1,0.8-1.4,1.3c-0.4,0.4-0.6,0.9-0.7,1.4l-0.4,3.6H6.5l-0.3-3.9c-0.1-0.7,0-1.3,0.4-1.9c0.3-0.5,0.8-1,1.3-1.5C8.4,14.8,9,14.4,9.6,14c0.6-0.4,1.2-0.9,1.8-1.5c0.5-0.5,1-1.2,1.4-1.8c0.4-0.7,0.6-1.5,0.6-2.4c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-1-1-1.3c-0.4-0.4-1-0.7-1.6-0.8C9.8,4.1,9.2,4,8.5,4C7.6,4,6.8,4.1,6.1,4.3C5.4,4.6,4.9,4.8,4.4,5.1C3.9,5.4,3.6,5.6,3.3,5.8C3,6.1,2.7,6.2,2.5,6.2c-0.5,0-0.8-0.2-1-0.6L0.3,3.6z M4.9,31.7c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.2,1.2-0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.5,0.6,0.7,1c0.2,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.6-1C5,32.5,4.9,32.1,4.9,31.7z"/>`
  }
})
