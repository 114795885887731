<template>
    <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadScript, unloadScript } from 'vue-plugin-load-script'

export default {
    name: 'HubspotChat',
    data: () => ({
        scriptUrl: 'https://js-na1.hs-scripts.com/9112572.js',
        loaded: false,
    }),
    computed: {
        ...mapGetters('auth', ['roles', 'hasRole']),
    },
    watch: {
        roles: {
            handler(r) {
                if (
                    this.$config.ENABLE_HUBSPOT &&
                    !this.loaded &&
                    this.hasRole('valuer', 'ovm')
                ) {
                    this.load()
                } else if (this.loaded && !this.hasRole('valuer', 'ovm')) {
                    this.unload()
                }
            },
            immediate: true,
        },
    },
    methods: {
        load() {
            loadScript(this.scriptUrl).then(() => {
                this.loaded = true
            })
        },
        unload() {
            unloadScript(this.scriptUrl).then(() => {
                this.loaded = false
            })
        },
    },
}
</script>
