<template>
    <div class="documents_modal">
        <div class="documents_modal-header">
            <h4 class="documents_modal-header-title">
                {{
                    purpose
                        ? $tc('document.' + purpose, 2)
                        : $t('monolith.global.documents')
                }}
            </h4>
            <clear-button>
                <svg-icon icon="close" />
            </clear-button>
        </div>
        <documents :purpose="purpose" :room-id="roomId" :type="type" />
    </div>
</template>
<script>
import Documents from '@/apps/Valuation/components/ValuerTool/elements/DocMgmt/Documents.vue'
export default {
    name: 'DocumentsModal',
    components: {
        Documents,
    },
    props: {
        purpose: {
            type: String,
            default: undefined,
        },
        roomId: {
            type: String,
            default: null,
        },
        type: {
            validator(value) {
                return ['picture', 'document', '*'].includes(value)
            },
            default: '*',
        },
    },
}
</script>
<style lang="scss" scoped>
.documents_modal {
    height: fit-content;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;

    & > * {
        padding: 24px;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        border-bottom: 1px solid #d6e2e9;

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    @media screen and (max-width: 999px) {
        width: 100vw;

        & > * {
            padding: 16px;
        }
    }
}
</style>
