<template>
    <grid-card :title="$t(`${configLocation}.${feature}`)" :is-full="full">
        <multi-choice
            :wide-options="!full && !small"
            :options="values"
            :vertical="small"
            :feature="feature"
            :multiple="multiple"
            @select="selectValue"
        />
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import utils from '@/shared/plugins/utils'
import GridCard from '..'
import MultiChoice from '../../MultiChoice.vue'
export default {
    name: 'MultiChoiceCard',
    components: {
        GridCard,
        MultiChoice,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
        configLocation: {
            type: String,
            default: 'dvm_features',
        },
        small: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFullBuildingData']),
            ...mapMutations(requestObjects, ['SET_UNIVERSAL']),
        }
    },
    computed: {
        values() {
            return utils
                .generateValues(this.feature, this.value)
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
        value: {
            get: function() {
                return (
                    this.getFullBuildingData[this.configLocation]?.[this.feature] ?? null
                )
            },
            set: function(val) {
                this.SET_UNIVERSAL({
                    key: this.configLocation,
                    values: { [this.feature]: val },
                })
            },
        },
    },
    methods: {
        selectValue(value) {
            this.value = value
        },
    },
}
</script>
