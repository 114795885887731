<template>
    <grid-card is-full :title="$t('features.f_garden_access')" no-mobile-toggle>
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t('valuer_tool.exterior.garden.question') }}</h5>
                <b-checkbox v-model="has_garden" />
            </label>
        </template>
        <template v-if="has_garden" slot="default">
            <div class="grid_card-content_grid three-cols">
                <div class="content_grid-item content_grid-item--full">
                    <label>{{ $t('dvm_features.garden_orientation') }}</label>
                    <multi-choice
                        is-small-mobile
                        feature="garden_orientation"
                        :options="orientationValues"
                        @select="selectOrientation"
                    />
                </div>
                <div class="content_grid-item content_grid-item--full">
                    <label>{{ $t('dvm_features.garden_arrangement') }}</label>
                    <multi-choice
                        feature="garden_arrangement"
                        :options="arrangementValues"
                        @select="selectArrangement"
                    />
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import MultiChoice from '@/apps/Valuation/components/ValuerTool/elements/MultiChoice.vue'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Garden',
    components: {
        GridCard,
        MultiChoice,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures', 'getDVMFeatures']),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES', 'SET_FEATURES']),
        }
    },
    data: () => ({
        has_garden: false,
        garden_orientation: null,
        garden_arrangement: null,
    }),
    computed: {
        orientationValues() {
            return utils
                .generateValues('garden_orientation', this.garden_orientation)
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
        arrangementValues() {
            return utils
                .generateValues('garden_arrangement', this.garden_arrangement)
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        has_garden(val) {
            let f_garden_area
            if (val) {
                f_garden_area =
                    this.getFeatures.f_garden_area ||
                    this.getFeatures.f_parcel_area - this.getFeatures.f_building_area
            } else {
                f_garden_area = 0
            }
            this.SET_FEATURES({
                f_garden_private: val,
                f_garden_area,
            })
        },
        garden_orientation(val) {
            this.SET_DVM_FEATURES({
                garden_orientation: val,
            })
        },
        garden_arrangement(val) {
            this.SET_DVM_FEATURES({
                garden_arrangement: val,
            })
        },
    },
    mounted() {
        this.has_garden =
            this.getFeatures?.f_garden_private ||
            this.getFeatures?.f_garden_area > 0 ||
            false
        this.garden_orientation = this.getDVMFeatures?.garden_orientation || null
        this.garden_arrangement = this.getDVMFeatures?.garden_arrangement || null
    },
    methods: {
        selectOrientation(val) {
            this.garden_orientation = val
        },
        selectArrangement(val) {
            this.garden_arrangement = val
        },
    },
}
</script>
