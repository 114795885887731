<template>
    <grid-card is-full :title="$t(`ovm_features.${feature}`)" no-mobile-toggle>
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t(`valuer_tool.exterior.${feature}.question`) }}</h5>
                <b-checkbox v-model="has_annex" />
            </label>
        </template>
        <template v-if="has_annex" slot="default">
            <div class="grid_card-content_grid three-cols">
                <div class="content_grid-item">
                    <label>{{ $t(`ovm_features.${feature}_area`) }}</label>
                    <b-input v-model.number="annex_area" type="number" />
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'

export default {
    name: 'Annex',
    components: {
        GridCard,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getOVMFeatures']),
            ...mapMutations(requestObjects, ['SET_OVM_FEATURES']),
        }
    },
    data: () => ({
        has_annex: false,
        annex_area: null,
    }),
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        has_annex(val) {
            let newFeatures = {}
            newFeatures[this.feature] = val
            this.SET_OVM_FEATURES(newFeatures)
        },
        annex_area(val) {
            let newFeatures = {}
            newFeatures[`${this.feature}_area`] = val
            this.SET_OVM_FEATURES(newFeatures)
        },
    },
    mounted() {
        this.has_annex = this.getOVMFeatures?.[this.feature] || false
        this.annex_area = this.getOVMFeatures?.[`${this.feature}_area`] || null
    },
}
</script>
