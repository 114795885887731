var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isDispatchDashboard)?_c('b-tabs',{staticClass:"block",attrs:{"position":"is-centered"},model:{value:(_vm.workloadTab),callback:function ($$v) {_vm.workloadTab=$$v},expression:"workloadTab"}},[_c('b-tab-item',{attrs:{"value":"0","label":_vm.$t('all')}}),_vm._l((_vm.dispatchedBanks),function(bank){return _c('b-tab-item',{key:`tab_${bank}`,attrs:{"value":bank,"label":bank}})})],2):_vm._e(),_c('div',{staticClass:"workload is-flex is-flex-direction-column is-align-items-flex-end"},[_c('div',{class:`workload-grid-${_vm.workloadPerValuer.maxBD}`},[_c('p',{staticClass:"mr-4 has-text-weight-medium has-text-right"},[_vm._v(" "+_vm._s(_vm.$t('specialist'))+" ")]),_vm._l((_vm.workloadPerValuer.maxBD + 1),function(BD){return _c('p',{key:`workload_header_${BD - 1}bd`,staticClass:"ml-1 mr-1 has-text-centered has-text-weight-medium"},[_vm._v(" D"+_vm._s(BD - 1)+_vm._s(BD - 1 == 4 ? '+' : '')+" ")])}),_c('p',{staticClass:"ml-1 mr-1 has-text-centered has-text-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('total'))+" ")])],2),_vm._l((Object.keys(_vm.workloadPerValuer.wl)),function(specialist){return _c('div',{key:`workload_${specialist}`,class:`workload-grid-${
                    _vm.workloadPerValuer.maxBD
                } ${specialist.toLowerCase() === 'unassigned' && 'first-row'}`},[_c('p',{staticClass:"mr-4 has-text-right"},[_vm._v(" "+_vm._s(specialist.toLowerCase() === 'unassigned' ? _vm.$t('unassigned') : specialist)+" ")]),_vm._l((_vm.workloadPerValuer.maxBD + 1),function(BD){return _c('p',{key:`workload_${specialist}_${BD - 1}bd`,staticClass:"ml-1 mr-1 has-text-centered",class:{
                    'has-text-weight-bold has-text-primary': _vm.workloadPerValuer.wl[
                        specialist
                    ].hasOwnProperty(`D${BD - 1}`),
                }},[_vm._v(" "+_vm._s(_vm.workloadPerValuer.wl[specialist][`D${BD - 1}`] || '-')+" ")])}),_c('p',{staticClass:"ml-1 mr-1 has-text-centered",class:{
                    'has-text-weight-bold has-text-danger':
                        _vm.workloadPerValuer.wl[specialist].total > 0,
                }},[_vm._v(" "+_vm._s(_vm.workloadPerValuer.wl[specialist].total)+" ")])],2)}),_vm._l((Object.keys(_vm.workloadPerValuer.totalPerBank)),function(bank,index){return _c('div',{key:`workload_${bank}`,class:`workload-grid-${_vm.workloadPerValuer.maxBD} ${!index &&
                    'first-bank-row'}`},[_c('p',{staticClass:"mr-4 has-text-right"},[_vm._v(" "+_vm._s(bank)+" ")]),_vm._l((_vm.workloadPerValuer.maxBD + 1),function(BD){return _c('p',{key:`workload_${_vm.specialist}_${BD - 1}bd`,staticClass:"ml-1 mr-1 has-text-centered",class:{
                    'has-text-weight-bold has-text-primary': _vm.workloadPerValuer.totalPerBank[
                        bank
                    ].hasOwnProperty(`D${BD - 1}`),
                }},[_vm._v(" "+_vm._s(_vm.workloadPerValuer.totalPerBank[bank][`D${BD - 1}`] || '-')+" ")])}),_c('p',{staticClass:"ml-1 mr-1 has-text-centered",class:{
                    'has-text-weight-bold has-text-danger':
                        _vm.workloadPerValuer.totalPerBank[bank].total > 0,
                }},[_vm._v(" "+_vm._s(_vm.workloadPerValuer.totalPerBank[bank].total)+" ")])],2)}),_c('div',{class:`workload-grid-${_vm.workloadPerValuer.maxBD} last-row`},[_c('p',{staticClass:"mr-4 has-text-weight-medium has-text-right"},[_vm._v(" "+_vm._s(_vm.$t('total'))+" ")]),_vm._l((_vm.workloadPerValuer.maxBD + 1),function(BD){return _c('p',{key:`workload_totals_${BD - 1}bd`,staticClass:"ml-1 mr-1 has-text-centered",class:{
                    'has-text-weight-bold':
                        _vm.workloadPerValuer.totalPerBD[`D${BD - 1}`],
                }},[_vm._v(" "+_vm._s(_vm.workloadPerValuer.totalPerBD[`D${BD - 1}`] || '-')+" ")])}),_c('p',{staticClass:"ml-1 mr-1 has-text-centered has-text-weight-bold has-text-danger"},[_vm._v(" "+_vm._s(_vm.workloadPerValuer.totalSubmitted)+" ")])],2),_c('b-button',{staticClass:"is-align-self-center mt-3",attrs:{"type":"is-primary"},on:{"click":_vm.toggleFullList}},[_vm._v(" "+_vm._s(_vm.seeFullList ? _vm.$t('hideFullList') : _vm.$t('showFullList'))+" ")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }