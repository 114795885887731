/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heating-fuel-gas': {
    width: 27,
    height: 42,
    viewBox: '0 0 27 42',
    data: '<path d="M17.9775 16.0545C17.9775 8.23725 10.775 0 10.775 0C11.4206 4.3575 9.68484 11.6655 6.05447 15.6188C2.4241 19.572 0.0214844 25.3208 0.0214844 29.2688C0.0214844 32.8066 2.39353 37.8038 6.3427 40.3558C5.22331 38.9631 4.0957 36.9091 4.0957 34.2852C4.0957 29.0877 8.54635 24.9927 8.54635 24.9927C8.54635 24.9927 5.91089 31.6602 11.4729 34.8102C11.4729 34.8102 13.7537 34.6894 17.7969 30.3949C18.4526 31.6328 18.8146 33.0029 18.8553 34.4007C18.8553 36.2193 18.4757 38.5161 16.5462 41.0584C21.5824 39.1422 26.0215 34.5 26.0215 29.1533C26.0215 23.0002 20.0467 17.0783 20.0467 17.0783C19.1471 23.4098 15.2838 24.864 15.2838 24.864C15.2838 24.864 17.9775 20.853 17.9775 16.0545Z"/>'
  }
})
