<template>
    <button
        class="comment_button"
        :class="{ 'comment_button--big': big, 'comment_button--active': active }"
    >
        <img src="@/assets/img/comment.svg" class="comment-icon" />
    </button>
</template>
<script>
export default {
    name: 'CommentButton',
    props: {
        big: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss" scoped>
.comment_button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    height: 32px;
    width: 32px;
    padding: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e2e9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    .comment-icon {
        height: 16px;
        width: 16px;
    }

    &--big {
        height: 44px;
        width: 44px;
        padding: 10px;

        .comment-icon {
            height: 24px;
            width: 24px;
        }
    }

    &--active {
        background: #001837;
        border: 1px solid #001837;
    }
}
</style>
