/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floors': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path d="M18.3,18.3H1.7c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8V5.2c0-1,0-1.6,0.3-2.1C2.2,2.6,2.6,2.2,3,1.9 c0.5-0.3,1.1-0.3,2.1-0.3h3c1,0,1.6,0,2.1,0.3c0.5,0.2,0.9,0.6,1.1,1.1c0.3,0.5,0.3,1.1,0.3,2.1v3.2h3.2c1,0,1.6,0,2.1,0.3 c0.5,0.2,0.9,0.6,1.1,1.1c0.3,0.5,0.3,1.1,0.3,2.1v4.8c0.5,0,0.8,0.4,0.8,0.8S18.8,18.3,18.3,18.3z M11.7,16.7h5v-4.8 c0-0.7,0-1.2-0.1-1.4c-0.1-0.2-0.2-0.3-0.4-0.4C16,10,15.6,10,14.8,10h-3.2V16.7z M3.3,16.7H10V5.2c0-0.7,0-1.2-0.1-1.4 C9.8,3.6,9.7,3.5,9.5,3.4C9.4,3.3,8.9,3.3,8.2,3.3h-3c-0.7,0-1.2,0-1.4,0.1C3.6,3.5,3.5,3.6,3.4,3.8C3.3,4,3.3,4.4,3.3,5.2V16.7z  M7.9,13.3H5.4c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h2.5c0.5,0,0.8,0.4,0.8,0.8S8.4,13.3,7.9,13.3z M7.9,10H5.4 C5,10,4.6,9.6,4.6,9.2S5,8.3,5.4,8.3h2.5c0.5,0,0.8,0.4,0.8,0.8S8.4,10,7.9,10z M7.9,6.7H5.4C5,6.7,4.6,6.3,4.6,5.8S5,5,5.4,5h2.5 c0.5,0,0.8,0.4,0.8,0.8S8.4,6.7,7.9,6.7z"/>'
  }
})
