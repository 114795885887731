<template>
    <b-field custom-class="is-small-tooltip" :message="message" :type="type">
        <template v-if="!isEmptyStr(label)" slot="label">
            {{ label }}
            <b-tooltip
                v-if="!isEmptyStr(tooltip)"
                class="ml-2 mb-1"
                type="is-primary"
                :label="tooltip"
                multilined
            >
                <div
                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                >
                    ?
                </div>
            </b-tooltip>
        </template>
        <div class="is-radio-selector">
            <b-radio-button
                v-for="option in options"
                :key="`${label}_option_${option.value.toString()}`"
                :value="value"
                :size="size"
                :native-value="option.value"
                :disabled="disabled"
                @input="$emit('input', $event)"
            >
                <span class="has-text-black">
                    {{ option.label }}
                </span>
            </b-radio-button>
        </div>
    </b-field>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        message: {
            type: Array,
            default: null,
        },
        type: {
            type: Object,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number, Boolean],
            required: false,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: 'is-medium',
        },
    },
    computed: {
        cssProps: () => ({
            '--right-border-color': value === null ? '#dbdbdb' : 'transparent',
        }),
    },
    methods: {
        isEmptyStr: (str) => utils.isEmptyStr(str),
    },
}
</script>

<style lang="scss">
.is-radio-selector {
    display: flex;

    .control {
        label {
            border-radius: 0;

            &:not(:hover) {
                border-left-color: transparent;
                border-right-color: --right-border-color;
            }

            &.is-primary {
                span {
                    color: white !important;
                }
            }
        }

        &:first-of-type {
            label {
                border-radius: 4px 0 0 4px;
                &:not(:hover):not(.is-primary) {
                    border-left-color: #dbdbdb;
                }
            }
        }

        &:last-of-type {
            label {
                border-radius: 0 4px 4px 0;
                &:not(:hover):not(.is-primary) {
                    border-right-color: #dbdbdb;
                }
            }
        }
    }
}
</style>
