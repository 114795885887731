var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main__wrapper"},[(_vm.choice === false)?_c('div',{staticClass:"main__unhappy"},[_vm._m(0),_c('h1',{staticClass:"main__unhappy-title"},[_vm._v(" "+_vm._s(_vm.$t('valuer_tool.borrower.confirm-address_unhappy-title'))+" ")]),_c('p',{staticClass:"main__unhappy-description"},[_vm._v(" "+_vm._s(_vm.$t('valuer_tool.borrower.confirm-address_unhappy-description'))+" ")]),_c('div',{staticClass:"main__buttons"},[_c('clear-button',{staticClass:"main__unhappy-button",attrs:{"aria-label":"Go back"},on:{"click":function($event){_vm.choice = null}}},[_c('img',{staticClass:"chevron",attrs:{"src":_vm.chevron}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.back')))])])],1)]):_c('div',{staticClass:"main__content"},[_c('div',{staticClass:"main__text"},[_c('h1',{staticClass:"main__title"},[_vm._v(_vm._s(_vm.$t('monolith.apps.welcome')))]),_c('p',{staticClass:"main__description"},[_vm._v(" "+_vm._s(_vm.$t('valuer_tool.borrower.confirm-address_question'))+" ")])]),_c('div',{staticClass:"main__map"},[_c('re-map',{staticClass:"address-map",attrs:{"building-id":_vm.getAddress.building_id,"parcel-ids":_vm.get_map_scene2d.parcel_ids,"level":"2","center":{
                        lat: _vm.get_map_scene2d.center?.lat,
                        lng: _vm.get_map_scene2d.center?.lng,
                    },"map-scene2d":_vm.get_map_scene2d,"flood-visible":false,"focus-layer":'building',"read-only":"","marker":"","size":"small","layers-control-visible":false,"options":{
                        zoomControl: false,
                        attributionControl: false,
                        scrollWheelZoom: false,
                        attributionControl: false,
                        scrollWheelZoom: false,
                        doubleClickZoom: false,
                        boxZoom: false,
                        dragging: false,
                    }}}),_c('div',{staticClass:"address-text"},[_vm._m(1),_c('div',{staticClass:"address-text__content"},[_c('p',[_vm._v(" "+_vm._s(_vm.formatAddress.firstLine)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.formatAddress.secondLine)+" ")])])])],1),_c('yes-no',{staticClass:"main__buttons",attrs:{"is-small":""},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main__unhappy-icon"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-question-mark-orange.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address-text__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-marker-pin.svg"),"alt":""}})])
}]

export { render, staticRenderFns }