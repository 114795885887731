<template>
    <div id="sidebar-summary">
        <sidebar-section
            :title="$t('valuer_tool.sidebar.summary.title')"
            is-toggle
            :toggled="showSummary"
            @click="toggleSummary"
        />
        <div
            v-if="showSummary && isMobile && getFeatures && getFeatures.building_id"
            class="sidebar-summary-content-map_container"
        >
            <re-map
                :default-zoom-modifier="-2"
                :building-id="getFeatures.building_id"
                :parcel-ids="getFeatures.parcel_ids"
                :level="getFeatures.level"
                :region="getFeatures.region"
                :center="{
                    lat: getFeatures.f_lat,
                    lng: getFeatures.f_lng,
                }"
                :map-scene2d="get_map_scene2d"
                :read-only="true"
                :focus-layer="'building'"
                :layers-control-visible="false"
                :options="{
                    zoomControl: true,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    doubleClickZoom: true,
                    boxZoom: true,
                    dragging: true,
                }"
                :aerial-tile-visible="false"
                :cadastral-tile-visible="false"
                class="has-ratio"
            ></re-map>
        </div>
        <div v-if="showSummary" class="sidebar-summary-content">
            <h5>
                {{ getAddress.full_address.split(',')[0] }}
                <br />
                {{ getAddress.full_address.split(',')[1] }}
            </h5>
            <div class="sidebar-summary-content-right_col">
                <clear-button @click="copyAddress">
                    <svg-icon
                        icon="copy"
                        color="#0096FF"
                        type="is-primary"
                        height="16"
                        scale="1"
                    />
                </clear-button>
            </div>

            <div class="grid-separator" />

            <h4>{{ $t('features.level') }}</h4>
            <h5 class="sidebar-summary-content-right_col">{{ getFeatures.level }}</h5>

            <h4>{{ $t('valuer_tool.sidebar.summary.lang') }}</h4>
            <h5 class="sidebar-summary-content-right_col">
                {{ $t('valuer_tool.global.' + getRequestLanguage) }}
            </h5>

            <div class="grid-separator" />

            <template v-if="is_house">
                <h4>{{ $t('features.f_parcel_area') }}</h4>
                <h5 class="sidebar-summary-content-right_col">
                    {{ Math.round(getFeatures.f_parcel_area) }} {{ $t('unit.m2') }}
                </h5>

                <h4>{{ $t('features.f_garden_area') }}</h4>
                <h5 class="sidebar-summary-content-right_col">
                    {{ Math.round(getFeatures.f_garden_area) }} {{ $t('unit.m2') }}
                </h5>

                <h4>{{ $t('features.f_building_area') }}</h4>
                <h5 class="sidebar-summary-content-right_col">
                    {{ Math.round(getFeatures.f_building_area) }} {{ $t('unit.m2') }}
                </h5>

                <div class="grid-separator" />
            </template>

            <template v-if="!is_plot">
                <h4>{{ $t('valuer_tool.general.state.epc_label') }}</h4>
                <div
                    class="sidebar-summary-content-epc_label sidebar-summary-content-right_col"
                >
                    <epc-badge :color="epc_color" />
                    <h5>{{ epc_label }}</h5>
                </div>

                <h4>{{ $t('valuer_tool.sidebar.summary.epc_score') }}</h4>
                <h5 class="sidebar-summary-content-right_col">
                    {{ getFeatures.f_epc_numeric }}
                </h5>

                <h4>{{ $t('features.f_construction_year') }}</h4>
                <h5 class="sidebar-summary-content-right_col">
                    {{ getFeatures.f_construction_year }}
                </h5>

                <h4>{{ $t('features.f_living_area') }}</h4>
                <h5 class="sidebar-summary-content-right_col">
                    {{ Math.round(getFeatures.f_living_area) }} {{ $t('unit.m2') }}
                </h5>
            </template>

            <h4>{{ $t('valuer_tool.reference_listing.price_sqm') }}:</h4>
            <h5>€ {{ withPoints(pricePerSqm) }}</h5>

            <template v-if="getFeatures.f_flood_risk">
                <div class="grid-separator" />

                <h4>{{ $t('features.f_flood_risk') }}</h4>
                <h5
                    :class="{
                        'sidebar-summary-content-right_col': true,
                        'has-text-danger has-text-weight-bold':
                            getFeatures.f_flood_risk.toLowerCase() !== 'none',
                    }"
                >
                    {{ $t(`value.${getFeatures.f_flood_risk.toLowerCase()}`) }}
                    <b-icon
                        v-if="getFeatures.f_flood_risk.toLowerCase() !== 'none'"
                        type="is-danger"
                        icon="alert-circle"
                    />
                </h5>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters as vuexGetters } from 'vuex'
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import utils from '@/shared/plugins/utils'
import ReMap from '@/components/common/Map.vue'
import SidebarSection from './Section.vue'
import EpcBadge from './EpcBadge.vue'
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'SidebarSummary',
    components: {
        ReMap,
        SidebarSection,
        EpcBadge,
        ClearButton,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'get_map_scene2d',
                'getAddress',
                'getFeatures',
                'getAvmTransactionEstimations',
                'getRequestLanguage',
            ]),
        }
    },
    data: () => ({
        showSummary: false,
    }),
    computed: {
        ...vuexGetters(['isMobile']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        pricePerSqm() {
            return Math.round(
                this.getAvmTransactionEstimations?.asking_price_q50 /
                    (this.is_building
                        ? this.getFeatures.f_living_area
                        : this.getFeatures.f_parcel_area)
            )
        },
        epc_label() {
            if (this.getFeatures.f_epc_label) return this.getFeatures.f_epc_label

            let region = this.getFeatures.region
            let epc_value = this.getFeatures.f_epc_numeric

            return utils.epc_label(region, epc_value)
        },
        epc_color() {
            let region = this.getFeatures.region
            return utils.epc_color(region, this.epc_label)
        },
    },
    methods: {
        toggleSummary() {
            this.showSummary = !this.showSummary
        },
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
        withPoints(number) {
            return utils.numberWithPoints(number)
        },
        copyAddress() {
            this.$copyText(this.getAddress.full_address)
                .then(() => {
                    this.onCopy()
                })
                .catch(() => {
                    this.onCopyError()
                })
        },
        onCopy() {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('monolith.global.copy.success'),
                position: this.isMobile ? 'is-top' : 'is-bottom',
                type: 'is-success',
            })
        },
        onCopyError() {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('monolith.global.copy.error'),
                position: this.isMobile ? 'is-top' : 'is-bottom',
                type: 'is-danger',
            })
        },
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },
    },
}
</script>
<style lang="scss" scoped>
.sidebar-summary-content {
    border-bottom: 1px solid rgba(214, 226, 233, 1);
    padding: 16px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
    row-gap: 8px;
    align-items: center;
    justify-content: space-between;
    justify-items: start;
    transform-origin: top center;

    * {
        font-weight: 500;
    }

    &-map_container {
        grid-column: 1 / 3;
        height: 150px;
        width: 100%;
    }

    .grid-separator {
        height: 8px;
        width: 100%;
        display: block;
        grid-column: 1 / 3;
    }

    &-right_col {
        justify-self: end;
    }

    &-epc_label {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 33px;
        height: 24px;
        height: fit-content;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        h5 {
            z-index: 2;
        }
    }

    h4 {
        color: #798da6;
    }

    h5 {
        color: #001837;
    }
}
</style>
