<template>
    <div class="year-selector">
        <p>{{ label }}</p>
        <b-field class="ers-input-field">
            <b-input
                size="is-medium"
                class="year-selector-input"
                :value="year"
                lazy
                @input="updateValue"
            />
        </b-field>
        <b-slider
            size="is-medium"
            class="year-selector-slider"
            :min="minimum"
            :max="maximum"
            :custom-formatter="(val) => val.toString()"
            :value="year"
            rounded
            lazy
            @change="updateValue"
        >
            <template v-for="val in ticks">
                <b-slider-tick :key="val" :value="val">{{ val }}</b-slider-tick>
            </template>
        </b-slider>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    name: 'YearSelector',
    model: {
        prop: 'year',
        event: 'input',
    },
    props: {
        year: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        minimum: {
            type: Number,
            default: 1800,
        },
        maximum: {
            type: Number,
            default: new Date().getFullYear(),
        },
    },
    computed: {
        ticks() {
            let delta = this.maximum - this.minimum
            let interval =
                delta > 100
                    ? this.$vssWidth < 768
                        ? 50
                        : 20
                    : delta > 40
                    ? this.$vssWidth < 768
                        ? 20
                        : 10
                    : this.$vssWidth < 768
                    ? 10
                    : 2
            return utils.generateIntervalsOf(interval, this.minimum, this.maximum)
        },
    },
    methods: {
        updateValue(value) {
            const parsedValue = Number(value)
            if (!isNaN(parsedValue)) {
                this.$emit('input', parsedValue)
            }
        },
    },
}
</script>

<style lang="scss">
.year-selector-slider .b-slider-track .b-slider-tick {
    background: transparent !important;
}
</style>

<style scoped lang="scss">
.year-selector {
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
    &-input {
        max-width: 15rem;
    }
    &-slider {
    }
}
</style>
