<template>
    <div class="renovation_page">
        <h1 class="renovation_page-title">
            <span class="renovation_page-title-number">{{ number }}</span>
            {{ $t(`energy.result.renovation.${getName}`) }}
        </h1>
        <div class="renovation_page-info_grid">
            <div class="renovation_page-info_grid-icon">
                <img :src="icon" />
            </div>
            <div class="renovation_page-info_grid-content">
                <h2 class="renovation_page-info_grid-content-name">
                    {{ $t('ers_report.renovation_price') }}
                </h2>
                <h3 class="renovation_page-info_grid-content-value">
                    {{ int_value_formatter(price) }}
                </h3>
            </div>
            <div v-if="surface" class="renovation_page-info_grid-content">
                <h2 class="renovation_page-info_grid-content-name">
                    {{ $t('ers_report.renovation_area') }}
                </h2>
                <h3 class="renovation_page-info_grid-content-value">
                    {{ Math.round(surface) }}
                    <span class="renovation_page-info_grid-content-value-unit">
                        {{ $t('unit.m2') }}
                    </span>
                </h3>
            </div>
            <div v-if="subsidyAmt" class="renovation_page-info_grid-content">
                <h2 class="renovation_page-info_grid-content-name">
                    {{ $t('ers_report.renovation_subsidy') }}
                </h2>
                <h3 class="renovation_page-info_grid-content-value">
                    {{ int_value_formatter(subsidyAmt) }}
                </h3>
            </div>
            <div
                v-for="feature in Object.entries(extraFeatures)"
                :key="feature[0]"
                class="renovation_page-info_grid-content"
            >
                <h2 class="renovation_page-info_grid-content-name">
                    {{ $t(`features.${feature[0]}`) }}
                </h2>
                <h3 class="renovation_page-info_grid-content-value">
                    {{ $t(`value.${feature[1]}`) }}
                </h3>
            </div>
            <!-- <div
                v-for="(n, m) in Array(num_empty_tiles).fill(0)"
                :key="m"
                class="renovation_page-info_grid-content"
            >
                <h2 class="renovation_page-info_grid-content-name"></h2>
                <h3 class="renovation_page-info_grid-content-value"></h3>
            </div> -->
        </div>
        <h2 class="renovation_page-subtitle">
            {{ $t('ers_report.renovation.title.gtk') }}
        </h2>
        <vue-markdown
            :source="$t(`ers_report.renovation.${getName}.gtk`)"
            class="ers-report-markdown"
        />
        <h2 class="renovation_page-subtitle">
            {{ $t('ers_report.renovation.title.works') }}
        </h2>
        <p class="renovation_page-paragraph">
            {{ $t('ers_report.renovation.works.lead_in') }}
            {{ $t(`ers_report.renovation.${getName}.works`) }}
        </p>
        <h2 v-if="subsidy_urls.length > 0" class="renovation_page-subtitle">
            {{ $t('ers_report.renovation.title.subsidies') }}
        </h2>
        <p v-if="subsidy_urls.length > 0" class="renovation_page-paragraph subsidy-links">
            {{ $t('ers_report.renovation.subsidies.lead_in') }}:
            <a v-for="subsidy_url in subsidy_urls" :key="subsidy_url" :href="subsidy_url">
                {{ subsidy_url }}
            </a>
        </p>
        <!--<h2 class="renovation_page-subtitle">Subsidy conditions</h2>
        <div class="renovation_page-subsidy_grid">
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    Where can I apply?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.where }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    Who is eligible for subsidies?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.eligibility }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    Who does the work?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.worker }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    When can I apply for a subsidy?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.when }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    Energetic conditions?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.conditions.energy }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    Other conditions?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.conditions.other }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    Cumulative with other subsidies?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.cumulative }}
                </p>
            </div>
            <div class="renovation_page-subsidy_grid-content">
                <h3 class="renovation_page-subsidy_grid-content-name">
                    What works are excluded from subsidies?
                </h3>
                <p class="renovation_page-subsidy_grid-content-value">
                    {{ subsidy.excluded }}
                </p>
            </div>
        </div>-->
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueMarkdown from 'vue-markdown-render'

export default {
    name: 'RenovationPage',
    components: {
        VueMarkdown,
    },
    props: {
        number: {
            type: Number,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: null,
        },
        price: {
            type: Number,
            required: true,
        },
        region: {
            type: String,
            required: true,
        },
        surface: {
            type: Number,
            default: null,
        },
        subsidyAmt: {
            type: Number,
            default: null,
        },
        extraFeatures: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters('ersStore', ['getFeatures']),
        num_empty_tiles() {
            let n = 3
            if (this.surface) {
                n -= 1
            }
            if (this.subsidyAmt) {
                n -= 1
            }
            n -= Object.keys(this.extraFeatures).length
            return n
        },
        subsidy_urls() {
            const key = `ers_report.renovation.${this.name}.${this.region}.subsidies`
            return this.$te(key) ? this.$t(key).split(',') : []
        },
        insulation_material() {
            const key = `ers_report.renovation.${this.name}.insmat`
            return this.$te(key) ? this.$t(key) : null
        },
        getName() {
            if (this.name === 'roofIn') {
                const { f_percent_of_roof_flat } = this.getFeatures
                if (f_percent_of_roof_flat === 0) {
                    return 'roofIn'
                } else if (f_percent_of_roof_flat === 1) {
                    return 'ceiling'
                } else {
                    return 'mixed'
                }
            } else {
                return this.name
            }
        },
        subsidy() {
            return {
                where:
                    'You can apply for attic floor insulation under the category ‘Floor’.',
                eligibility: `- Owner living in the home
- Owner renting the home
- Renter
- Other`,
                worker:
                    'You or a contractor. If you work with a contractor, you’re eligible for the highest subsidy amount.',
                when:
                    'You can apply once every 2 years, maximum 2 years after the invoice date.',
                conditions: {
                    energy: 'R>6m2K/W',
                    other: '-',
                },
                cumulative:
                    'Within 24 months, you can apply for a maximum of 5 renovation works and 5 energetic improvement works.',
                excluded: 'Test test',
            }
        },
    },
    methods: {
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
    },
}
</script>

<style lang="scss">
.ers-report-markdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 3rem;
    h1,
    h3 {
        color: #001837;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    h2 {
        color: #001837;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 142.857% */
    }
    p,
    li {
        color: #001837;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }
    li:before {
        content: '- ';
    }
}
</style>
<style lang="scss" scoped>
.renovation_page {
    &-title {
        font-size: 2rem;
        color: #001837;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 2rem;

        &-number {
            height: 1.5rem;
            width: 1.5rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 24, 55, 0.2);
            color: rgba(0, 24, 55, 0.6);
            font-size: 0.75rem;
            line-height: 0.75rem;
            margin-right: 0.5rem;
            border-radius: 50%;
            position: relative;
            bottom: 0.5rem;
        }
    }

    &-subtitle {
        font-size: 1.6rem;
        color: #001837;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    &-paragraph {
        color: #001837;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }

    &-info_grid {
        display: flex;
        flex-direction: row;
        background: #d6e2e9;
        border-radius: 0.25rem;
        border: 1px solid #d6e2e9;
        gap: 1px;
        overflow: hidden;
        margin-bottom: 3rem;

        & > * {
            background: white;
        }

        &-icon {
            padding: 1rem 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6cm;
            height: 2.8cm;

            img {
                height: 100%;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 1rem;
            padding: 1.5rem;
            flex-grow: 1;

            &-name {
                color: rgba(0, 24, 55, 0.5);
                font-size: 1rem;
                font-weight: 500;
            }

            &-value {
                font-size: 1.5rem;
                color: #001837;
                font-weight: 700;

                &-unit {
                    font-weight: 400;
                }
            }
        }
    }

    &-subsidy_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;

        &-content {
            border-radius: 0.25rem;
            color: #001837;
            border: 1px solid #d6e2e9;
            padding: 1rem;

            &-name {
                font-size: 1.25rem;
                font-weight: 700;
            }

            &-value {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}

.subsidy-links {
    display: flex;
    flex-direction: column;
}
</style>
