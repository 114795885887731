import L from 'leaflet'

const scale = 1.5

const map_pin = () => {
    return L.divIcon({
        html: `<svg viewBox="0 0 28 38">
    <style type="text/css">
       .st0{stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
    </style>
    <path class="st0" d="M23.5,6.1c-1.7-2.1-4.2-3.6-7-4.2C9.7,0.5,3.1,4.7,1.7,11.3c-0.2,1-0.3,2.1-0.3,3.1C1.1,21,6.3,27,11.1,33.4
       c1.2,1.5,2.9,3.2,2.9,3.2l2.6-3.1C22.6,25.7,31.3,14.4,23.5,6.1z"/>
    </svg>`,
        iconSize: [28 * scale, 38 * scale],
        iconAnchor: [14 * scale, 38 * scale],
    })
}

export default map_pin
