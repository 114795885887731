/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'yes': {
    width: 31,
    height: 24,
    viewBox: '0 0 31 24',
    data: '<path d="M0.767767 11.2322C-0.208544 12.2085 -0.208544 13.7915 0.767767 14.7678L8.54594 22.5459C9.52225 23.5223 11.1052 23.5223 12.0815 22.5459L29.7591 4.86827C30.7355 3.89196 30.7355 2.30905 29.7591 1.33274C28.7828 0.356428 27.1999 0.356427 26.2236 1.33274L10.3137 17.2426L4.3033 11.2322C3.32699 10.2559 1.74408 10.2559 0.767767 11.2322Z"/>'
  }
})
