<i18n>
{
  "nl-BE": {
    "title": "SSO Login",
    "welcome": "Click on the button to log in",
    "login": "Log In"
    
  },
  "en-BE": {
    "title": "SSO Login",
    "welcome": "Click on the button to log in",
    "login": "Log In"
  },
  "fr-BE": {
    "title": "SSO Login",
    "welcome": "Click on the button to log in",
    "login": "S'identifier"
  }
}
</i18n>

<template>
    <div class="login is-relative">
        <div class="form-wrapper is-absolute">
            <div class="columns is-centered">
                <div
                    class="column is-5-fullhd is-6-desktop is-12-mobile is-9-tablet mb-6"
                >
                    <h3
                        class="is-centered has-text-centered has-text-weight-bold title is-size-2"
                    >
                        {{ $t('title') }}
                        <span></span>
                    </h3>
                    <div
                        v-if="$route.query.msg !== 'email-sent'"
                        class="has-text-centered"
                    >
                        {{ $t('welcome') }}
                    </div>
                </div>
            </div>
            <div class="columns is-centered is-multiline is-mobile">
                <b-button
                    tag="a"
                    :href="login_url"
                    size="is-medium "
                    :label="$t('login')"
                    native-type="submit"
                    type="is-primary"
                />
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'

export default {
    name: 'LogIn',
    data() {
        return {
            email: '',
        }
    },
    computed: {
        login_url: function() {
            let org_hint = utils.getCookie('re-org-hint')
            let redirect_query_param = this.$route.query.r
                ? `&r=${this.$route.query.r}`
                : ''
            if (org_hint === 'rockestate') {
                return this.$config.ROCKESTATE_SSO_URL + redirect_query_param
            } else {
                return this.$config.CUSTOMER_SSO_URL + redirect_query_param
            }
        },
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Log In',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
}
</script>

<style lang="scss" scoped>
.login {
    background: url('../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: 100%;
    min-height: 100%;

    width: 100%;
    .form-wrapper {
        left: 50%;
        top: 30%;
        width: 100%;
        transform: translate(-50%, -30%);
        padding: 0 1.5rem;
        section {
            min-width: 500px;
        }
    }
    .check-card {
        width: 450px;
        .icon-box {
            border-radius: 100%;
            background: rgba(82, 216, 96, 0.2);
        }
    }
}
</style>
