<template>
    <div>
        <b-button
            class="action-button is-success"
            :class="{ inactive: !valid }"
            rounded
            @click="setValidStatus(true)"
        >
            <b-icon class="button-icon" icon="check"></b-icon>
        </b-button>
        <b-button
            class="action-button is-danger"
            :class="{ inactive: valid || valid == null }"
            rounded
            @click="setValidStatus(false)"
        >
            <b-icon class="button-icon" icon="close"></b-icon>
        </b-button>
    </div>
</template>
<script>
export default {
    name: 'ValidationToggle',
    model: {
        prop: 'valid',
        event: 'validate',
    },
    props: {
        valid: {
            type: Boolean,
            default: null,
            validator: (v) => {
                return ['boolean'].includes(typeof v) || v === null
            },
        },
    },
    methods: {
        setValidStatus(status) {
            this.$emit('validate', status !== this.valid ? status : null)
        },
    },
}
</script>
<style lang="scss" scoped>
.action-button {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;

    &:not(:first-child) {
        margin-left: 1rem;
    }

    .button-icon {
        height: 37px;
        width: auto;
    }

    & > span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.inactive {
        background: #99a7cc33;
        color: #99a7cc99;

        &:hover {
            background: #99a7cc66;
            color: #99a7ccff;
        }
    }
}
</style>
