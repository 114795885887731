<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="info-bubble">
        <img
            class="info-bubble__icon"
            src="@/assets/icons/ic-lightbulb.svg"
            alt=""
            srcset=""
        />
        <p v-if="text.length" class="info-bubble__text" v-html="text" />
        <slot v-else />
    </div>
</template>

<script>
export default {
    name: 'InfoBubble',
    props: {
        text: {
            type: String,
            default: '',
        },
    },
}
</script>

<style scoped lang="scss">
.info-bubble {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-radius: 0.3125rem;
    background: rgba(0, 156, 255, 0.1);
    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 3.125rem;
        background: rgba(0, 156, 255, 0.2);
    }
    &__text {
        color: #6583a8;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
</style>
