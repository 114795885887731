<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t(`consumption.${getHeatingFuel}_consumption_title`) }}
                <!-- <b-tooltip
                    :label="$t('steps.f_construction_year_tooltip')"
                    multilined
                    position="is-right"
                    size="is-large"
                >
                    <img src="@/assets/icons/ic-tooltip2.svg" alt="" srcset="" />
                </b-tooltip> -->
            </h1>
        </div>

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="$router.go(-1)"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <b-button
                    v-if="consumption_known !== null"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextPage"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>

    <!-- <div class="ers-content is-fluid is-max-desktop">
        <div class="columns is-mobile is-multiline is-variable is-6">
            <div class="column is-12 pt-0">
                <h2 class="title is-size-4 has-text-weight-bold mgt-medium pb-4">
                    {{ $t(`consumption.${getHeatingFuel}_consumption_title`) }}
                </h2>
            </div>

            <div class="column is-12">
                <h3 class="has-text-weight-medium has-text-black input-instructions">
                    {{ $t(`features_question.${getHeatingFuel}_consumption_known`) }}
                </h3>

                <h3
                    v-if="getHeatingFuel === 'electricity'"
                    class="has-text-weight-medium has-text-black input-instructions mb-4"
                >
                    {{ $t('features_question.yearly_electricity_consumption_subtitle') }}
                </h3>
                <yes-no v-model="consumption_known" />
            </div>

            <div v-if="consumption_known" class="column is-12 mt-5 mb-0 pb-0">
                <h3 class="has-text-weight-medium has-text-black input-instructions">
                    {{ $t('features_question.enter_value') }}
                </h3>
            </div>
            <div v-if="consumption_known" class="input-row">
                <div class="is-w100 ">
                    <b-field class="input-field">
                        <b-input
                            v-model="amount"
                            size="is-medium"
                            type="number"
                            class="input-field__input"
                            :min="0"
                        />
                        <span
                            v-if="canonical_consumption_unit == 'kwh'"
                            class="is-align-self-flex-start is-size-5 is-unit"
                        >
                            {{ $t('unit.kwh/year') }}
                        </span>
                    </b-field>
                    <b-field
                        v-if="canonical_consumption_unit != 'kwh'"
                        class="input-field"
                    >
                        <b-radio-button
                            v-model="unit"
                            :native-value="canonical_consumption_unit"
                            size="is-medium"
                        >
                            <span>{{ $t(`unit.${canonical_consumption_unit}/year`) }}</span>
                        </b-radio-button>
                        <b-radio-button
                            v-model="unit"
                            native-value="kwh"
                            size="is-medium"
                        >
                            <span>{{ $t('unit.kwh/year') }}</span>
                        </b-radio-button>
                    </b-field>
                </div>
            </div>

            <div
                v-else-if="getConsumptionKnown === false"
                class="column is-12 mt-5 mb-0 pb-0"
            >
                <h3 class="has-text-weight-medium has-text-black input-instructions">
                    {{ $t(`features_question.${getHeatingFuel}_consumption_approx`) }}
                </h3>
                <multiple-choice v-model="estimate" :answers="levels" />
            </div>

            <div class="column is-12 mt-2">
                <div class="is-flex is-justify-content-space-between">
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        icon-left="arrow-left"
                        @click="$router.go(-1)"
                    >
                        {{ $t('common.previous') }}
                    </b-button>
                    <b-button
                        v-if="showNextButton"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        @click="nextPage"
                    >
                        <span>{{ $t('common.next') }}</span>
                    </b-button>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import MultipleChoice from '../../elements/MultipleChoice.vue'
import YesNo from '../../elements/YesNo.vue'

export default {
    name: 'Consumption',
    components: {
        // YesNo,
        // MultipleChoice,
        // TextInput,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        estimate: null,
        amount: null,
        unit: null,
        isFirstPass: true,
        consumption_known: null,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getHeatingFuel',
            'getConsumptionKnown',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        levels() {
            return [
                {
                    name: this.$t('value.low'),
                    value: 'low',
                },
                {
                    name: this.$t('value.medium'),
                    value: 'medium',
                },
                {
                    name: this.$t('value.high'),
                    value: 'high',
                },
            ]
        },
        canonical_consumption_unit() {
            switch (this.fuel) {
                case 'gas':
                    return 'm3'
                case 'oil':
                    return 'l'
                case 'wood':
                case 'pellets':
                case 'coal':
                    return 'kg'
                case 'district_heating':
                case 'electricity':
                    return 'kwh'
                default:
                    return ''
            }
        },
        showNextButton() {
            if (getConsumptionKnown === null) return false
            if (getConsumptionKnown && !this.amount) return false
            if (!getConsumptionKnown && this.estimate === null) return false
            return true
        },
    },
    watch: {
        fuel() {
            this.load_consumption()
        },
        // estimate() {
        //     if (this.isFirstPass) {
        //         this.nextPage()
        //     } else {
        //         this.isFirstPass = false
        //     }
        // },
    },
    mounted() {
        this.load_consumption()
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_CONSUMPTION']),
        load_consumption() {
            let previous_consumption = {}
            let known_consumptions = this.getFeatures.known_consumptions.filter(
                (item) => item.consumption_fuel === this.fuel
            )
            let estimated_consumptions = this.getFeatures.estimated_consumptions.filter(
                (item) => item.consumption_fuel === this.fuel
            )
            if (known_consumptions.length) {
                previous_consumption = {
                    ...known_consumptions[0],
                    consumption_known: true,
                    estimate: null,
                }
            } else if (estimated_consumptions.length) {
                previous_consumption = {
                    ...estimated_consumptions[0],
                    consumption_known: false,
                    amount: null,
                    unit: this.canonical_consumption_unit,
                }
            } else {
                previous_consumption = {
                    consumption_known: null,
                    amount: null,
                    unit: 'kwh',
                    estimate: null,
                }
            }

            this.isFirstPass =
                previous_consumption.consumption_known === null ||
                !(
                    previous_consumption.consumption_known === false &&
                    previous_consumption.estimate !== null
                )
            this.consumption_known = previous_consumption.consumption_known
            this.unit = previous_consumption.unit
            this.amount = previous_consumption.amount
            this.estimate = previous_consumption.estimate
        },
        nextPage() {
            if (this.consumption_known) {
                this.SET_CONSUMPTION({
                    consumption: {
                        consumption_fuel: this.fuel,
                        amount: +this.amount,
                        unit: this.unit,
                    },
                    consumption_known: true,
                })
            } else {
                this.SET_CONSUMPTION({
                    consumption: {
                        consumption_fuel: this.fuel,
                        estimate: this.estimate,
                    },
                    consumption_known: false,
                })
            }
            let next_route = {}
            if (this.fuel === 'electricity' && this.getHeatingFuel !== 'electricity') {
                next_route = {
                    name: 'ers.fuel-consumption',
                    params: {
                        lang: this.$route.params.lang,
                        fuel: this.getHeatingFuel,
                    },
                    query: this.$route.query,
                }
            } else {
                next_route = {
                    name: 'ers.electric-vehicles',
                    params: {
                        lang: this.$route.params.lang,
                    },
                    query: this.$route.query,
                }
            }
            this.$router.push(next_route)
        },
    },
}
</script>

<style lang="scss">
.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.grid-layout {
    display: grid;
    align-items: center;
    justify-items: start;
    column-gap: 1rem;
    row-gap: 0.5rem;
}

.heating-grid {
    grid-template-columns: repeat(3, auto);
}

.ventilation-grid {
    grid-template-columns: repeat(1, auto);
}

.renewables-grid {
    grid-template-columns: repeat(4, auto);
}

.additional-grid {
    grid-template-columns: repeat(3, auto);
}

.consumers-grid {
    grid-template-columns: repeat(2, auto);
}

.consumption-grid {
    grid-template-columns: repeat(2, auto);
}
</style>
