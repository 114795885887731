<template>
    <div class="ers-content section is-fluid out-of-bounds">
        <img src="@/assets/img/ers/orange-exclamation.svg" class="mb-6 mt-5" />
        <h3 class="has-text-black has-text-weight-medium is-size-5 mb-2">
            {{ $t('out_of_bounds_page.title') }}
        </h3>
        <div class="mt-6">
            <b-button type="is-primary" class="mr-2" @click="restartRequest">
                {{ $t('out_of_bounds_page.button') }}
            </b-button>
            <b-button
                tag="a"
                :href="ing_redirect_link"
                target="_blank"
                type="is-primary"
                class="ing-button ml-2"
            >
                {{ $t('out_of_bounds_page.ing_button') }}
            </b-button>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    name: 'OutOfBounds',
    computed: {
        ing_redirect_link() {
            return `/api/valuation/go/epc-simulator/${this.$route.params.lang}`
        },
    },
    methods: {
        ...mapMutations('ersStore', ['RESET_FEATURES', 'RESET_MAP']),
        restartRequest() {
            this.RESET_FEATURES()
            this.RESET_MAP()
            this.$router.push({
                name: 'ers.address',
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.out-of-bounds {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.building-not-found {
    background: rgba(241, 70, 104, 0.2);
    border: 1px solid #f14668;
    box-shadow: 0px 2px 14px rgba(0, 47, 80, 0.1);
    border-radius: 5px;
}
.ing-button {
    padding-left: 2rem;
    padding-right: 2rem;
    background: #d85f32;

    &:hover {
        background: lighten(#d85f32, 10%);
    }
}
</style>
