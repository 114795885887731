/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heating-fuel-electric': {
    width: 26,
    height: 42,
    viewBox: '0 0 26 42',
    data: '<path d="M10.3793 40.3419V26.759H1.62792C1.34733 26.7567 1.07199 26.6827 0.828224 26.5438C0.584453 26.4049 0.380392 26.2059 0.23554 25.9657C0.0906878 25.7256 0.00988475 25.4523 0.000850777 25.172C-0.00818319 24.8918 0.0548543 24.6139 0.18394 24.3649L12.2664 0.861264C12.4388 0.541823 12.7124 0.288581 13.0443 0.141224C13.3763 -0.00613242 13.7477 -0.0392763 14.1005 0.0469874C14.4533 0.133251 14.7675 0.334046 14.9938 0.617907C15.2202 0.901767 15.3459 1.25265 15.3512 1.61556V15.3406H23.7143C23.9932 15.34 24.2676 15.4105 24.5116 15.5453C24.7557 15.6802 24.9613 15.875 25.1091 16.1113C25.2569 16.3476 25.342 16.6176 25.3563 16.896C25.3707 17.1743 25.3138 17.4516 25.1911 17.7019L13.5025 41.0634C13.3421 41.4011 13.071 41.6739 12.7342 41.8366C12.3974 41.9993 12.015 42.0421 11.6505 41.9579C11.2861 41.8737 10.9613 41.6675 10.7301 41.3736C10.4989 41.0797 10.3752 40.7157 10.3793 40.3419Z"/>'
  }
})
