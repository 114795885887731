<!-- eslint-disable vue/no-v-html -->
<i18n>
{
    "fr-BE":{
        "sidebar4": {
            "title1": "La maison et ses propriétés",
            "title2": "Que voyons-nous exactement sur les graphiques?",
            "subtitle1": "Avec ces graphiques, vous pouvez voir rapidement comment les propriétés de la maison se rapportent aux propriétés des 200 maisons sélectionnées dans la région.",
            "subtitle2": "Les propriétés de la maison à estimer <span style='color: #ff6f00'>(orange)</span> ne doivent pas présenter de trop grands écarts par rapport aux habitations présentes dans les environs <span style='color: var(--color-primary)'>(bleu)</span>.",
            "map_marker1": "Point de référence",
            "map_marker2": "La maison à estimer",
            "submit": "Voir le prix cible"
        }
    },
    "nl-BE":{
        "sidebar4": {
            "title1": "De woning en haar eigenschappen",
            "title2": "Wat zien we juist op de grafieken?",
            "subtitle1": "Met deze grafieken zie je snel hoe de eigenschappen van de woning zich verhouden tot de eigenschappen van de 200 geselecteerde woningen in de buurt.",
            "subtitle2": "De eigenschappen van de te schatten woning <span style='color: #ff6f00'>(oranje)</span> mogen niet te veel verschillen van de huizen in de omgeving <span style='color: var(--color-primary)'>(blauw)</span>.",
            "map_marker1": "Referentiepunt",
            "map_marker2": "De te schatten woning",
            "submit": "Bekijk de richtprijs"
        }
    },
    "en-BE":{  
        "sidebar4": {
            "title1": "The house and its properties",
            "title2": "What exactly do we see on the graphs?",
            "subtitle1": "With these graphs you can quickly see how the properties of the home relate to the properties of the 200 selected homes in the area.",
            "subtitle2": "The properties of the house to be estimated <span style='color: #ff6f00'>(orange)</span> must not show too great deviations from the homes that are compared <span style='color: var(--color-primary)'>(blue)</span>.",
            "map_marker1": "Reference point",
            "map_marker2": "The property to be estimated",
            "submit": "View the target price"
        }
    }
}
</i18n>

<template>
    <div id="sidebar4" class="sidebar-inner">
        <h1>{{ $t('sidebar4.title1') }}</h1>
        <div class="subtitle mt-4">
            {{ $t('sidebar4.title1') }}
        </div>
        <div class="separator"></div>
        <h2>{{ $t('sidebar4.title2') }}</h2>
        <div class="my-4">
            <div class="legend-box">
                <div class="ball blue"></div>
                {{ $t('sidebar4.map_marker1') }}
            </div>
            <div class="legend-box">
                <div class="ball orange"></div>
                {{ $t('sidebar4.map_marker2') }}
            </div>
        </div>
        <div class="subtitle" v-html="$t('sidebar4.subtitle2')"></div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar2',
}
</script>

<style lang="scss" scoped>
#sidebar4 {
    min-height: calc(100% - 151px);
    transition: 0.3s all;
    position: relative;
    .subtitle {
        font-size: 15px;
    }
    .ball {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 15px;
        &.blue {
            border: solid 2px #0080e3;
            background-color: var(--color-primary);
        }
        &.orange {
            border: solid 2px #ff3100;
            background-color: #ff6f00;
        }
    }
    .legend {
        margin: 30px 0;
        .legend-box {
            margin-bottom: 15px;
        }
    }
}
</style>
