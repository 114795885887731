/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'new-construction': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<path pid="0" d="M37 53v-9.176h6V53h7.5V40.765H55L40 27 25 40.765h4.5V53H37z" _stroke="#798DA6" stroke-width="2" stroke-dasharray="2 2"/>'
  }
})
