const filterKeys = function(obj, keys) {
    const filtered = {}
    for (const key of keys) {
        filtered[key] = obj[key]
    }
    return filtered
}
const mapGetters = filterKeys
const mapMutations = filterKeys
const mapActions = filterKeys

export { mapGetters, mapMutations, mapActions, filterKeys }
