<template>
    <grid-card :title="title" no-mobile-toggle>
        <documents
            :purpose="purpose"
            :room-id="roomId"
            :thumbnails="thumbnails"
            in-grid-card
        />
    </grid-card>
</template>
<script>
import { mapGetters } from 'vuex'
import GridCard from '..'
import Documents from '../../DocMgmt/Documents.vue'
export default {
    name: 'UploadCard',
    components: {
        GridCard,
        Documents,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        purpose: {
            type: String,
            default: undefined,
        },
        roomId: {
            type: String,
            default: null,
        },
        thumbnails: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isMobile']),
    },
}
</script>
<style lang="scss" scoped>
.input_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    div {
        flex-grow: 1;
    }

    h2 {
        color: #001837;
        font-size: 18px;
        font-weight: 700;
        flex-grow: 2;
    }
}
</style>
