/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: `<path d="M14.7,5.2v3.6c0,1.2,0,1.8-0.3,2.4c-0.3,0.5-0.7,0.9-1.2,1.2c-0.6,0.3-1.2,0.3-2.4,0.3H9.1c-0.4,0-0.5,0-0.7,0 c-0.1,0-0.2,0.1-0.3,0.1C8,12.9,7.8,13,7.5,13.2L6,14.5C5.6,14.8,5.3,15,5,15c-0.3,0-0.6-0.1-0.8-0.4C4,14.3,4,14,4,13.6v-0.9 c-0.3,0-0.5,0-0.7-0.1c-0.9-0.2-1.6-1-1.9-1.9C1.3,10.3,1.3,10,1.3,9.3V5.2c0-1.2,0-1.8,0.3-2.4c0.3-0.5,0.7-0.9,1.2-1.2 C3.4,1.3,4,1.3,5.2,1.3h5.6c1.2,0,1.8,0,2.4,0.3c0.5,0.3,0.9,0.7,1.2,1.2C14.7,3.4,14.7,4,14.7,5.2z"/>`
  }
})
