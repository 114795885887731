var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-diagram-container py-6 px-0",class:{ shown: _vm.showDiagram, hidden: !_vm.showDiagram, 'is-small': _vm.topFloor >= 10 }},[_c('span',{staticClass:"close-diagram-button"},[_c('b-button',{attrs:{"type":"is-ghost","size":_vm.topFloor < 10 && 'is-small'},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center mb-5 ml-6"},[_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.topFloor <= 0},on:{"click":_vm.reduceTopFloor}},[_vm._v(" - ")]),_c('span',{staticClass:"floor-diagram-number is-size-4"},[_vm._v(" "+_vm._s(_vm.topFloor)+" ")]),_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.topFloor >= 40},on:{"click":_vm.increaseTopFloor}},[_vm._v(" + ")])],1),_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-center floor-diagram-building ml-2"},[_vm._m(0),_vm._l((_vm.buildingFloors),function(floor){return _c('div',{key:'floor_diagram_' + floor,class:`is-flex is-align-items-center is-justify-content-center is-flex-shrink-0 is-flex-grow-0 floor-diagram-floor mr-4 ${floor ==
                    0 && 'ground-floor'} ${floor == -1 && 'first-negative'}`},[(floor == 0)?_c('img',{staticClass:"tree-pic",attrs:{"src":require("@/assets/img/tree.svg")}}):_vm._e(),_c('span',{class:`is-size-5 ${floor.toString().includes('x') && 'hidden-number'}`,on:{"click":function($event){return _vm.toggleFloor(floor)}}},[_vm._v(" "+_vm._s(floor.toString().includes('x') ? floor[0] : floor)+" ")]),(!floor.toString().includes('x'))?_c('div',{class:`floor-pic ml-2 px-3 ${_vm.activatedFloors.includes(floor) &&
                        (_vm.isError ? 'error' : 'selected')} ${
                        floor >= 0 ? 'has-windows' : 'has-small-windows'
                    }`,on:{"click":function($event){return _vm.toggleFloor(floor)}}},[_c('div',{staticClass:"window"}),(floor > 0)?_c('div',{staticClass:"window"}):_vm._e(),(floor == 0)?_c('div',{staticClass:"door"}):_vm._e(),_c('div',{staticClass:"window"})]):_c('div',{class:`floor-pic mid-floor ml-2 px-3 ${floor === 'xx' &&
                        'top-floor'} ${_vm.activatedFloors.includes(+floor[0]) &&
                        (_vm.isError ? 'error' : 'selected')}`}),(floor == 0)?_c('img',{staticClass:"tree-pic",attrs:{"src":require("@/assets/img/tree.svg")}}):_vm._e()])})],2),_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center mt-5 ml-6"},[_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.bottomFloor <= -5},on:{"click":_vm.reduceBottomFloor}},[_vm._v(" - ")]),_c('span',{staticClass:"floor-diagram-number is-size-4"},[_vm._v(" "+_vm._s(_vm.bottomFloor)+" ")]),_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.bottomFloor >= 0},on:{"click":_vm.increaseBottomFloor}},[_vm._v(" + ")])],1),(_vm.isError)?_c('div',{staticClass:"error-message p-2 has-text-centered"},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-floor-border is-flex is-align-items-center is-justify-content-center is-flex-shrink-0 is-flex-grow-0 floor-diagram-floor"},[_c('span'),_c('div',{staticClass:"top-border"})])
}]

export { render, staticRenderFns }