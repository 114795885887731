<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.valid_epc.question') }}
                <Tooltip :label="$t('energy.valid_epc.tooltip')" />
            </h1>
        </div>

        <yes-no
            v-model="has_epc_certificate"
            :details="[
                $t('energy.valid_epc.answer_yes'),
                $t('energy.valid_epc.answer_no'),
            ]"
        />

        <div class="column is-12 mt-6">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    tag="router-link"
                    :to="{
                        name: getLivingAreaChanged
                            ? 'ers.building-dimensions'
                            : 'ers.living-area',
                        params: {
                            lang: $route.params.lang,
                        },
                        query: $route.query,
                    }"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        nextPage()
                    "
                />
                <b-button
                    v-if="showNextButton"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextPage"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import MultipleChoice from '../../elements/MultipleChoice.vue'
import YesNo from '../../elements/YesNo.vue'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton.vue'

export default {
    name: 'EPC',
    components: { SubmitButton, Tooltip, YesNo },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        has_epc_certificate: null,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getEpcVars',
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
            'getLivingAreaChanged',
        ]),
        showNextButton() {
            return this.has_epc_certificate !== null
        },
    },
    watch: {
        epc_type(val, prevVal) {
            if (prevVal != val) {
                if (val !== 'units') {
                    this.f_epc_numeric = null
                }
                if (val !== 'label') {
                    this.f_epc_label = null
                }

                if (val === 'idk' && this.isFirstPass) {
                    if (this.isFirstPass) {
                        this.nextPage()
                    } else {
                        this.isFirstPass = true
                    }
                }
            }
        },
        has_epc_certificate(value) {
            if (value !== this.getFeatures.has_epc_certificate) {
                this.nextPage()
            }
        },
        getFeatures: {
            handler(newVal, oldVal) {
                this.has_epc_certificate = newVal.has_epc_certificate
            },
            deep: true,
        },
    },
    mounted() {
        const { has_epc_certificate } = this.getFeatures
        if (has_epc_certificate !== null) {
            this.has_epc_certificate = has_epc_certificate
        }
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS', 'SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        nextPage() {
            let feature = {}

            if (this.has_epc_certificate) {
                feature.has_epc_certificate = this.has_epc_certificate
            } else {
                feature.has_epc_certificate = this.has_epc_certificate
                feature.is_epc_number = null
                feature.is_epc_label = null
                feature.f_epc_label = null
                feature.f_epc_numeric = null
            }
            const nextPage = this.has_epc_certificate
                ? 'ers.epc-score'
                : 'ers.construction-year'

            this.set_and_submit_feature({
                feature,
                nextPage,
            })

            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
.epc-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 1;
    }
}

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
