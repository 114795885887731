<template>
    <grid-card no-mobile-toggle class="mobile_exterior_card">
        <b-field :label="$t('features.f_touching_sides')">
            <b-input v-model="f_touching_sides" type="number" expanded />
        </b-field>
        <label>
            <h5>{{ $t('dvm_features.f_building_listed') }}</h5>
            <b-checkbox v-model="f_building_listed" />
        </label>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

export default {
    name: 'MobileExteriorCard',
    components: {
        GridCard,
    },
    data: () => ({
        f_touching_sides: 0,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures']),
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_touching_sides: debounce(function(val) {
            this.SET_FEATURES({
                f_touching_sides: val,
            })
        }, 250),
    },
    mounted() {
        this.f_touching_sides = this.getFeatures.f_touching_sides
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_FEATURES']),
    },
}
</script>
<style lang="scss" scoped>
.mobile_exterior_card {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & ::v-deep .checkbox {
            width: 20px;
        }
    }
}
</style>
