var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t('energy.attic.question')))])]),_c('div',{staticClass:"ers-page-attic"},[_c('yes-no',{model:{value:(_vm.has_attic),callback:function ($$v) {_vm.has_attic=$$v},expression:"has_attic"}})],1),(_vm.has_attic)?_c('div',{staticClass:"ers-page-heated"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.attic.question_heated'))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('energy.attic.tooltip')}})],1),_c('yes-no',{model:{value:(_vm.is_attic_heated),callback:function ($$v) {_vm.is_attic_heated=$$v},expression:"is_attic_heated"}})],1):_vm._e(),_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left","disabled":"","tag":"router-link","to":{
                name: 'ers.address',
                params: {
                    lang: _vm.$route.params.lang,
                },
                query: _vm.$route.query,
            }}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                    unsubmit: false,
                })
                _vm.nextPage}}}),(_vm.showNextButton)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.nextPage}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }