<template>
    <div
        class="valuer_tool-default-layout-grid valuer_tool-default-layout-grid--has_tabs"
    >
        <b-tabs
            v-if="isMobile"
            v-model="activeTab"
            class="valuer_tool-mobile_tabs"
            destroy-on-hide
        >
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('valuer_tool.surroundings.title')"
                value="0"
            />
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('valuer_tool.surroundings.mobility')"
                value="1"
            />
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('features.f_others')"
                value="2"
            />
        </b-tabs>
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.surroundings.title') }}
        </h1>
        <template v-if="!isMobile || activeTab === '0'">
            <flood-map />
            <multi-choice-card key="card_environment" feature="environment" />
            <multi-choice-card
                key="card_direct_traffic_level"
                feature="direct_traffic_level"
            />
            <multi-choice-card
                key="card_indirect_traffic_level"
                feature="indirect_traffic_level"
            />
        </template>
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.surroundings.mobility') }}
        </h1>
        <template v-if="!isMobile || activeTab === '1'">
            <multi-choice-card key="card_public_transport" feature="public_transport" />
            <multi-choice-card key="card_education" feature="education" />
            <multi-choice-card key="card_recreation" feature="recreation" />
            <multi-choice-card key="card_shops" feature="shops" />
            <multi-choice-card key="card_healthcare" feature="healthcare" />
        </template>
        <h1 v-if="!isMobile" class="valuer_tool-title">{{ $t('features.f_others') }}</h1>
        <template v-if="!isMobile || activeTab === '2'">
            <other-impacting-card type="positive" feature="other_positive" />
            <other-impacting-card type="negative" feature="other_negative" />
        </template>
    </div>
</template>
<script>
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import FloodMap from './cards/FloodMap.vue'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import OtherImpactingCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/OtherImpactingCard.vue'
import { mapGetters as vuexGetters } from 'vuex'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Surroundings',
    components: {
        FloodMap,
        MultiChoiceCard,
        OtherImpactingCard,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
        }
    },
    data: () => ({
        activeTab: '0',
    }),
    computed: {
        ...vuexGetters(['isMobile']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
    },
}
</script>
