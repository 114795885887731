<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="main">
        <b-loading :is-full-page="true" :active="loading" />
        <div class="main__content">
            <h2 class="main__title">
                {{ $t('valuer_tool.borrower.appointment-making.title') }}
            </h2>
            <b-message v-if="noValuersInRegion" type="is-warning" :has-icon="true">
                {{ $t('global.errors.no_valuers_in_region') }}
            </b-message>
            <div class="columns is-centered">
                <div v-if="borrowerData?.appointment" class="column">
                    <div>
                        Date: {{ borrowerData?.appointment.start_time.toLocaleString() }}
                    </div>
                    <div>
                        Status: {{ $t('status.' + borrowerData.appointment.status) }}
                    </div>
                </div>
                <b-message v-else-if="noSlotsFound" type="is-warning">
                    {{ $t('errors.no_valuers_in_region') }}
                </b-message>
                <div v-else class="column">
                    <div id="cronofy-slot-picker"></div>
                </div>
            </div>
        </div>
        <div class="main__buttons">
            <nav-buttons
                :show-next="showNext"
                :next-step="nextStep"
                :prev-step="prevStep"
                :is-submit="false"
            />
        </div>
        <b-modal
            v-model="isModalVisible"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
        >
            <div class="warning-modal">
                <div class="warning-modal__icon">
                    <img src="@/assets/icons/ic-warning.svg" alt="" />
                </div>
                <p v-html="$t('valuer_tool.borrower.appointment.warning_modal')"></p>
                <div class="warning-modal__buttons">
                    <button class="warning-modal__button" @click="isModalVisible = false">
                        {{ $t('monolith.global.i_understand') }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '@/shared/plugins/utils'
import { loadScript } from 'vue-plugin-load-script'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'AppointmentMaking',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        appointments: [],
        loading: true,
        loadingAvailabilityData: false,
        noSlotsFound: false,
        noValuersInRegion: false,
        isModalVisible: false,
        cronofy: null,
        appointmentCreated: false,
    }),
    computed: {
        ...mapGetters('valuationStore', ['getRequestRef']),
        showNext() {
            return this.borrowerData?.appointment?.start_time !== undefined
        },
        getLang() {
            return this.$route.params.lang
        },
    },
    watch: {
        getLang(lang) {
            if (!this.cronofy) return

            this.cronofy.update({
                locale: lang,
            })
        },
    },
    mounted() {
        if (this.borrowerData?.appointment) {
            this.loading = false
        } else {
            this.showAppointmentPicker()
            this.isModalVisible = true
        }
    },
    methods: {
        ...mapActions('valuationStore', ['load_valuation_request']),
        handleCronofyCallback({ notification }) {
            if (notification.type == 'slot_selected') {
                // NOTE: sanity check to ensure this is not called twice
                if (this.appointmentCreated) return

                this.loading = true
                this.appointmentCreated = true

                const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'request',
                    this.getRequestRef,
                ])
                this.$axios
                    .post(url, {
                        start_time: notification.slot.start,
                        end_time: notification.slot.end,
                        cronofy_sub: notification.slot.participants[0].sub,
                    })
                    .then(() => {
                        // TODO: figure out a way to hide the Cronofy date picker once we are done
                        this.load_valuation_request().then(() => {
                            this.$router.push({
                                query: { ...this.$route.query, step: 'borrower-details' },
                            })
                        })
                        this.$emit('update:appointment')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else if (notification.type == 'no_slots_found') {
                this.noSlotsFound = true
            } else if (notification.type == 'error') {
                this.$store.dispatch('log/logError', {
                    error: {
                        error_code: 'cronofy_error',
                        debug: {
                            message: notification.message,
                        },
                    },
                    errorType: 'error',
                })
            } else {
                console.log('Unknown cronofy notification:', notification.type)
            }
        },
        showAppointmentPicker() {
            this.loading = true
            const now = Date.now()
            const inTwoDays = new Date(now + 2 * 24 * 60 * 60 * 1000)
            const inThreeWeeks = new Date(now + 21 * 24 * 60 * 60 * 1000)

            // NOTE: calling availability_query on a VR an existing appointment will return an error:
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
                'availability_query',
            ])
            this.loadingAvailabilityData = true

            this.$axios
                .get(url, {
                    params: {
                        start_time: inTwoDays.toISOString(),
                        end_time: inThreeWeeks.toISOString(),
                        origin_uri: window.location.href,
                        duration: 90,
                    },
                })
                .then((res) => {
                    if (res.status == 204) {
                        this.noValuersInRegion = true
                        return
                    }
                    loadScript(
                        'https://elements.cronofy.com/js/CronofyElements.v1.56.5.js'
                    ).then(() => {
                        // eslint-disable-next-line no-undef
                        this.cronofy = CronofyElements.DateTimePicker({
                            element_token: res.data.cronofy_element_token,
                            target_id: 'cronofy-slot-picker',
                            locale: this.$route.params.lang,
                            availability_query: res.data.query,
                            config: {
                                tz_list: ['Europe/Brussels'],
                            },
                            styles: {
                                prefix: 're-cronofy',
                                colors: {
                                    buttonHover: 'rgba(0, 150, 255, 0.2)',
                                    buttonConfirm: 'var(--color-primary)',
                                    buttonActive: 'var(--color-primary)',
                                    buttonActiveText: 'white',
                                },
                            },
                            tzid: 'Europe/Brussels',
                            data_center: res.data.data_center || 'de',
                            callback: this.handleCronofyCallback,
                        })
                    })
                })
                .finally(() => {
                    this.loadingAvailabilityData = false
                    this.loading = false
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    border: 1px solid #d6e2e9;
    background: #fff;

    &__title {
        color: #001837;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        margin-inline: 1.5rem;
        margin-top: 1.5rem;
    }

    &__buttons {
        border-top: 1px solid #d6e2e9;
        min-width: 100%;
        padding-block: 1rem;
        padding-inline: 1.5rem;
    }
}
.button-flex {
    display: flex;
    justify-content: space-between;
}
.error-message {
    background-color: #fdd;
    border: 1px solid #f00;
    border-radius: 4px;
    margin: 0.5rem;
}

.warning-modal {
    max-width: 32rem;
    width: fit-content;
    margin: 2.5rem 2rem;
    position: relative;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
    background: #fff;
    border-radius: 0.5rem;

    &__icon {
        display: flex;
        margin-top: 2.5rem;
        width: 5rem;
        height: 5rem;
        padding: 1.04169rem;
        justify-content: center;
        align-items: center;
        gap: 1.04169rem;
        border-radius: 8.33331rem;
        background: rgba(255, 170, 0, 0.2);
        img {
            width: 2.28569rem;
            height: 2rem;
            flex-shrink: 0;
            fill: #fa0;
        }
    }
    p {
        padding-inline: 2rem;
        color: #001837;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
    }

    &__buttons {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        align-self: stretch;
        border-top: 1px solid #d6e2e9;
        background: #fff;
        button {
            all: unset;
            display: flex;
            padding: 0.75rem 1.25rem;
            justify-content: center;
            gap: 0.5rem;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 0.3125rem;
            background: #001837;

            /* Shadow */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
                0px 1px 3px 0px rgba(16, 24, 40, 0.1);
            color: #fff;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
        }
    }
}
</style>

<style>
.re-cronofy__details {
    display: none !important;
}
</style>
