<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="question-container">
        <div class="question" @click="toggleAnswer">
            <h2
                class="has-text-black is-size-5 has-text-weight-medium is-flex is-align-items-center"
            >
                {{ qData.question }}
            </h2>
            <span class="question-icons is-align-items-right">
                <svg-icon v-for="m of qData.modules" :key="m" :icon="m" scale="1" />
                <b-icon
                    :icon="answer_shown ? 'minus' : 'plus'"
                    class="is-medium"
                    type="is-primary"
                />
            </span>
        </div>
        <vue-markdown
            v-if="answer_shown && !isVideo"
            :source="qData.answer"
            class="answer"
        />
        <div
            v-if="answer_shown && isVideo"
            class="help-center-video"
            v-html="qData.answer"
        ></div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown-render'

export default {
    components: {
        VueMarkdown,
    },
    expose: ['toggleAnswer'],
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        qData: {
            type: Object,
            required: true,
        },
        isVideo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return { answer_shown: this.open }
    },
    watch: {
        open(val) {
            this.answer_shown = val
        },
    },
    methods: {
        toggleAnswer() {
            this.answer_shown = !this.answer_shown
        },
    },
}
</script>
<style lang="scss">
.help-center-video {
    p {
        font-size: 1rem;
        padding: 15px 0 30px 30px;
        color: black;
    }
}

.question-container {
    &:not(:last-child) {
        border-bottom: 1px solid #d6e2e9;
    }
}

.question {
    cursor: pointer;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
        max-width: 18px;
    }
}

.question-icons {
    width: 64px;
    flex-shrink: 0;
    .svg-icon {
        vertical-align: inherit;
    }
}

.answer {
    background: #d6e2e933;
    border-top: 1px solid #d6e2e9;
    padding: 15px 30px;
    color: #001837;

    & > ul {
        list-style: disc inside !important;
        margin-block: 1rem;
    }
}
</style>
