<template>
    <div>
        <h5 v-if="question">{{ question }}</h5>
        <div
            class="multichoice"
            :class="{
                'multichoice--vertical': vertical,
                'multichoice--small_mobile': isSmallMobile,
                'multichoice--wide_options': wideOptions,
            }"
        >
            <clear-button
                v-for="(option, i) in options"
                :key="`${feature}-answer_${i}`"
                class="multichoice-button"
                :class="{ selected: option.selected }"
                @click="() => select(option.value)"
            >
                {{ option.name }}
            </clear-button>
        </div>
    </div>
</template>
<script>
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'MultiChoice',
    components: {
        ClearButton,
    },
    props: {
        question: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        wideOptions: {
            type: Boolean,
            default: false,
        },
        feature: {
            type: String,
            required: true,
        },
        isSmallMobile: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        selectedOption() {
            if (!this.multiple) {
                return this.options.find((option) => option.selected)?.value
            } else {
                return this.options
                    .filter((option) => option.selected)
                    .map((option) => option.value)
            }
        },
    },
    methods: {
        select(value) {
            if (!this.multiple) {
                if (this.selectedOption === value) {
                    this.$emit('select', null)
                } else {
                    this.$emit('select', value)
                }
            } else {
                const copy = [...this.selectedOption]
                const index = copy.indexOf(value)
                if (index > -1) {
                    copy.splice(index, 1)
                } else {
                    copy.push(value)
                }
                this.$emit('select', copy)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.multichoice {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    justify-items: stretch;
    align-items: center;

    &--vertical {
        display: flex;
        flex-direction: column;
    }

    &--wide_options {
        display: flex;
        flex-direction: row;

        & > * {
            flex-grow: 1;
        }
    }

    @media screen and (max-width: 999px) {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &.multichoice--small_mobile {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 12px;
        }
    }

    &-button {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #d6e2e9;
        background: #fff;
        color: rgba(0, 24, 55, 0.7);
        font-size: 14px;
        font-weight: 500;

        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);

        &.selected {
            background: var(--color-primary);
            color: white;
        }
    }
}
</style>
