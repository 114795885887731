<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('features_question.solar_panels') }}
                <!-- <b-tooltip
                    :label="$t('steps.f_construction_year_tooltip')"
                    multilined
                    position="is-right"
                    size="is-large"
                >
                    <img src="@/assets/icons/ic-tooltip2.svg" alt="" srcset="" />
                </b-tooltip> -->
            </h1>
        </div>

        <yes-no v-model="photovoltaic_available" />

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    tag="router-link"
                    :to="{
                        name: 'ers.heating',
                        params: {
                            lang: $route.params.lang,
                        },
                        query: $route.query,
                    }"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        nextPage()
                    "
                />
                <b-button
                    v-if="photovoltaic_available !== null"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextPage"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import YesNo from '../../elements/YesNo.vue'
import SubmitButton from '../../elements/SubmitButton.vue'

export default {
    name: 'SolarPanels',
    components: {
        SubmitButton,
        YesNo,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        photovoltaic_available: null,
        isFirstPass: true,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
    },
    watch: {
        photovoltaic_available() {
            if (this.isFirstPass) {
                this.nextPage()
            } else {
                this.isFirstPass = true
            }
        },
    },
    mounted() {
        const { photovoltaic_available } = this.getFeatures
        if (photovoltaic_available !== null) {
            this.isFirstPass = false
            this.photovoltaic_available = photovoltaic_available
        }
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature']),

        nextPage() {
            const nextPage = 'ers.end-section'
            const feature = {
                photovoltaic_available: this.photovoltaic_available,
            }

            this.set_and_submit_feature({
                feature,
                nextPage,
            })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.grid-layout {
    display: grid;
    align-items: center;
    justify-items: start;
    column-gap: 1rem;
    row-gap: 0.5rem;
}

.heating-grid {
    grid-template-columns: repeat(3, auto);
}

.ventilation-grid {
    grid-template-columns: repeat(1, auto);
}

.renewables-grid {
    grid-template-columns: repeat(4, auto);
}

.additional-grid {
    grid-template-columns: repeat(3, auto);
}

.consumers-grid {
    grid-template-columns: repeat(2, auto);
}

.consumption-grid {
    grid-template-columns: repeat(2, auto);
}
</style>
