<template>
    <div class="container">
        <section>
            <h1
                class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"
            >
                Dispatcher dashboard
            </h1>
            <div class="container is-max-desktop">
                <div v-if="loading">
                    <progress class="progress is-small" max="100"></progress>
                </div>
                <div v-else>
                    <section>
                        <h2
                            class="title is-size-3 mgt-medium is-family-secondary has-text-weight-bold"
                        >
                            Availability
                        </h2>
                        <div class="columns">
                            <div class="box column is-4">
                                <template v-if="availabilityQueryError">
                                    <div class="notification is-danger">
                                        {{ availabilityQueryError }}
                                    </div>
                                </template>
                                <b-select
                                    v-model="selectedNuts"
                                    placeholder="Select a NUTS"
                                    :loading="nuts3List === null"
                                    @input="selectedValuer = null"
                                >
                                    <!-- group nuts by province -->
                                    <optgroup
                                        v-for="province in nuts3List"
                                        :key="province.prov_en"
                                        :label="province.prov_en"
                                    >
                                        <option
                                            v-for="nuts3 in province.nuts3"
                                            :key="nuts3.NUTS_ID"
                                            :value="nuts3.NUTS_ID"
                                        >
                                            {{ nuts3.arr_en }} &lt;{{ nuts3.NUTS_ID }}&gt;
                                        </option>
                                    </optgroup>
                                </b-select>
                                <div v-if="nuts3Valuers">
                                    Number of available valuers in this NUTS:
                                    {{ nuts3Valuers.length }}
                                </div>
                                - or -
                                <b-select
                                    v-model="selectedValuer"
                                    placeholder="Select a valuer"
                                    :loading="valuerUsers === null"
                                    @input="
                                        () => {
                                            selectedNuts = null
                                        }
                                    "
                                >
                                    <option
                                        v-for="user in valuerUsers"
                                        :key="user.id"
                                        :value="user.id"
                                    >
                                        {{ user.first_name }} {{ user.last_name }} &lt;{{
                                            user.id
                                        }}&gt;
                                    </option>
                                </b-select>
                            </div>
                            <div class="column is-8">
                                <div id="cronofy-availability-viewer"></div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import { loadScript } from 'vue-plugin-load-script'

export default {
    name: 'DispatcherSettings',
    components: {},
    data: () => ({
        valuerUsers: null,
        selectedValuer: null,
        selectedNuts: null,
        nuts3List: null,
        nuts3Valuers: null,
        loading: true,
        reloadingAvailability: false,
        cronofyViewer: null,
        availabilityQueryError: null,
    }),
    computed: {
        ...mapGetters('auth', ['userId']),
    },
    watch: {
        selectedValuer(val) {
            if (!val) return
            this.reloadingAvailability = true
            this.loadAvailabilityViewer().finally(() => {
                this.reloadingAvailability = false
            })
        },
        selectedNuts(val) {
            if (!val) return
            this.reloadingAvailability = true
            this.loadAvailabilityViewer().finally(() => {
                this.reloadingAvailability = false
            })
        },
    },
    mounted() {
        this.loading = true

        Promise.all([
            loadScript('https://elements.cronofy.com/js/CronofyElements.v1.56.3.js'),
            this.getValuerUsers(),
            this.getNuts3List(),
        ]).then(() => {
            this.loading = false
        })
    },
    methods: {
        getValuerUsers() {
            // TODO: replace this by proper Store action:

            const url = utils.urlJoin(this.$config.VALUATION_API_URL, ['users'])

            this.$axios
                .get(url, {
                    params: { roles: 'valuer', modules: 'ovm' },
                })
                .then((res) => {
                    this.valuerUsers = res.data
                })
                .catch(() => {})
        },
        getNuts3List() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'availability',
                'nuts3',
            ])

            this.$axios
                .get(url)
                .then((res) => {
                    this.nuts3List = this.processNuts3(res.data)
                })
                .catch(() => {})
        },
        processNuts3(nuts3) {
            // Move this to Store and remove redundant code from other components
            const provinceAwareNuts3 = {}

            for (const n of nuts3) {
                if (!(n.prov_en in provinceAwareNuts3)) {
                    provinceAwareNuts3[n.prov_en] = {
                        prov_en: n.prov_en,
                        prov_fr: n.prov_fr,
                        prov_nl: n.prov_nl,
                        nuts3: [],
                    }
                }
                provinceAwareNuts3[n.prov_en].nuts3.push(n)
            }

            // Manual fill for Brussels
            provinceAwareNuts3['null'].prov_en = 'Brussels Capital Region'
            provinceAwareNuts3['null'].prov_fr = 'Région de Bruxelles-Capitale'
            provinceAwareNuts3['null'].prov_nl = 'Brussels Hoofdstedelijk Gewest'

            return Object.values(provinceAwareNuts3)
        },
        loadAvailabilityViewer() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'dispatcher',
                'availability_query',
            ])
            const now = Date.now()

            this.availabilityQueryError = null
            this.nuts3Valuers = null

            return this.$axios
                .get(url, {
                    params: {
                        start_time: new Date(now).toISOString(),
                        end_time: new Date(now + 21 * 24 * 60 * 60 * 1000).toISOString(),
                        origin_uri: window.location.href,
                        valuer_id: this.selectedValuer,
                        nuts3_id: this.selectedNuts,
                    },
                })
                .then(({ data }) => {
                    if (data.query.participants[0].members.length === 0) {
                        this.availabilityQueryError =
                            'No availability info found for this selection.'
                        return
                    } else if (this.selectedNuts) {
                        this.nuts3Valuers = data.query.participants[0].members
                    }

                    const options = {
                        element_token: data.cronofy_element_token,
                        target_id: 'cronofy-availability-viewer',
                        availability_query: data.query,
                        styles: {
                            prefix: 're-cronofy',
                            colors: {
                                buttonHover: 'rgba(0, 150, 255, 0.2)',
                                buttonConfirm: 'var(--color-primary)',
                                buttonActive: 'var(--color-primary)',
                                buttonActiveText: 'white',
                            },
                        },
                        tzid: 'Europe/Brussels',
                        data_center: data.data_center || 'de',
                        callback: this.handleCronofyCallback,
                        config: {
                            tz_list: ['Europe/Brussels'],
                            start_time: '08:00',
                            end_time: '18:00',
                            interval: 30,
                            bounds_control: true,
                            allow_expansion: true,
                        },
                    }

                    if (this.cronofyViewer) {
                        this.cronofyViewer.update(options)
                    } else {
                        // eslint-disable-next-line no-undef
                        this.cronofyViewer = CronofyElements.AvailabilityViewer(options)
                    }
                })
                .catch((err) => {})
        },
        handleCronofyCallback(notification) {
            console.log('handleCronofyCallback', notification)
        },
    },
}
</script>
