<template>
    <div class="empty">
        <span class="empty-icon">
            <svg-icon :icon="type" color="#009CFF" height="20" />
        </span>
        <h4>{{ $t(`valuer_tool.interior.empty_${type}.title`) }}</h4>
        <p>{{ $t(`valuer_tool.interior.empty_${type}.content`) }}</p>
    </div>
</template>
<script>
export default {
    name: 'EmptyList',
    props: {
        type: {
            validator(value) {
                return ['floors', 'rooms'].includes(value)
            },
            required: true,
        },
    },
}
</script>
<style lang="scss" scoped>
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    @media screen and (max-width: 999px) {
        flex-grow: 1;
        justify-content: center;
        margin-bottom: 70px;
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #009cff33;
        border-radius: 50%;
        margin-bottom: 8px;
    }

    h4 {
        color: #001837;
        font-size: 16px;
        font-weight: 700;
    }

    p {
        color: #798da6;
        font-size: 14px;
        font-weight: 400;
    }
}
</style>
