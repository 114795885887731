import { ref, computed } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export function useUser() {
    const { isLoading, isFetching, data: user } = useQuery({
        queryKey: ['auth', 'user'],
        placeholderData: {},
    })

    const queryClient = useQueryClient()
    const hasRole = computed(() => {
        return (role, module) => {
            if (!user.value.roles) {
                return false
            }
            if (!module) {
                for (const roles of Object.values(user.value.roles)) {
                    if (roles.includes(role)) {
                        return true
                    }
                }
                return false
            }
            return user.value.roles[module]?.includes(role)
        }
    })

    const accessibleModules = computed(() => {
        if (!user.value || !user.value.roles) {
            return []
        }
        const modules = Object.entries(user.value.roles)
            .filter(([k, v]) => v.length && !['-', '*'].includes(k))
            .map(([k, v]) => k)

        // dmv:submitter can view and edit borrower info on ovm requests:
        if (!modules.includes('ovm') && user.value.roles['dvm']?.includes('submitter')) {
            modules.push('ovm')
        }
        return modules
    })

    const submitableModules = computed(() => {
        return Object.entries(user.value.roles)
            .filter(([k, v]) => v.length && !['-', '*'].includes(k))
            .filter(([k, v]) => v.includes('submitter'))
            .map(([k, v]) => k)
    })

    const submitableValuations = computed(() => {
        return submitableModules.value.filter((m) => ['avm', 'dvm', 'ovm'].includes(m))
    })

    const { mutateAsync } = useMutation({
        onMutate: async ({ verb, queryKey, data }) => {
            const previous = queryClient.getQueryData(['auth', 'user'])
            const optimistic_next = { ...previous, ...data }
            queryClient.setQueryData(['auth', 'user'], optimistic_next)
            // Return context with the optimistic update
            return { previous, optimistic_next }
        },
        onSuccess: (result, { verb, queryKey, data }, context) => {},
        onError: (error, variables, context) => {
            // Remove optimistic update
            queryClient.setQueryData(['auth', 'user'], context.previous)
        },
    })

    const patchUser = async (data) => {
        await mutateAsync({
            verb: 'PATCH',
            queryKey: ['user', user.value.username],
            data,
        })
    }

    return {
        user,
        isLoading,
        isFetching,
        hasRole,
        accessibleModules,
        submitableModules,
        submitableValuations,
        patchUser,
    }
}
