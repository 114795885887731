<template>
    <div class="button-flex">
        <b-button
            v-if="prevStep"
            icon-left="arrow-left"
            class="mr-auto"
            tag="router-link"
            aria-label="previous step"
            :to="{ query: { ...$route.query, step: prevStep } }"
        >
            {{ $t('common.previous') }}
        </b-button>
        <slot></slot>
        <b-button
            v-if="nextStep && nextStep != 'valuation-pending'"
            :icon-right="loading ? 'loading' : 'arrow-right'"
            :disabled="!showNext"
            :native-type="isSubmit ? 'submit' : 'button'"
            class="ml-auto"
            :tag="isSubmit ? 'button' : 'router-link'"
            aria-label="next step"
            :to="{ query: { ...$route.query, step: nextStep } }"
        >
            {{ $t('common.next') }}
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'NavButtons',
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
        showNext: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isSubmit: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style>
.button-flex {
    display: flex;
    justify-content: space-between;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
</style>
