<i18n lang="json">
{
  "en-BE": {
    "epc_unavailable_disclaimer": "I confirm that there is no valid EPC certificate, and that this EPC estimate is for this property",
    "refinancing_disclaimer": "I confirm the purpose of this request is refinancing or reinstatement",
    "title": "We're almost there. These are the final questions.",
    "apt_floors": "On which floor(s) is the apartment located?",
    "field_required": "This field is required",
    "required": "Required",
    "number_only": "Please enter only numeric values",
    "wrong_epc": "Please enter a correct EPC label",
    "documents": "Documents",
    "upload_documents_tooltip": "document(s) uploaded",
    "value_too_large": "The number is too large",
    "valid_years": "Between {min} and {max}",
    "floors_not_consecutive": "The selected floors are not consecutive",
    "missing_features": "The following features are missing and will prevent automatic valuation: ",
    "incoherent_feature": "Please review the value in this field",
    "renovation_cost_zero_invalid": "Please enter a renovation cost higher than 0",
    "renovation_cost_minValue": "Please enter a renovation cost higher than 10000",
    "construction_cost_minValue": "Please enter a construction cost higher than 10000",
    "parcel_cost_minValue": "Please enter a land cost higher than 10000",
    "save": "Save",
    "saved": "Data saved!",
    "sumbit_incomplete": "Do you want to submit this incomplete form?",
    "warning": "Warning",
    "send": "Send",
    "yes": "Yes",
    "no": "No",
    "previous": "Previous",
    "next": "Next",
    "select_message": "Select one",
    "ground_floor": "0 (Ground floor)",
    "documents_modal": {
        "title": "Upload documents",
        "label": "Add documents (.jpg, .jpeg, .png, .pdf)",
        "warning": "Any shared document that contains information other than EPC or pictures of the exterior of the property will be disregarded and deleted.",
        "steps": {
            "upload": "Upload",
            "uploading": "Uploading",
            "online": "Uploaded"
        }
    },
    "leave_modal": {
        "title": "You have unsaved changes.",
        "content": "Do you want to save before you leave?",
        "confirm": "Exit",
        "cancel": "Stay on the page",
        "save": "Save and exit"
    }
  },
  "nl-BE": {
    "epc_unavailable_disclaimer": "Ik bevestig dat er geen geldig EPC certificaat beschikbaar is, en dat deze schatting bij dit pand hoort",
    "refinancing_disclaimer": "Ik bevestig dat het doel van deze aanvraag in het kader van een herfinanciering of wederopname is",
    "title": "We zijn er bijna. Nog enkele vraagjes om af te ronden.",
    "apt_floors": "Op welke verdieping(en) bevindt het appartement zich?",
    "field_required": "Dit veld is verplicht",
    "required": "Verplicht",
    "number_only": "Gelieve enkel cijfers in te vullen",
    "wrong_epc": "Gelieve een correct EPC label in te geven",
    "documents": "Documenten",
    "upload_documents_tooltip": "document(en) verzonden",
    "value_too_large": "Het aantal is te groot",
    "valid_years": "Tussen {min} en {max}",
    "floors_not_consecutive": "De geselecteerde verdiepingen zijn niet opeenvolgend",
    "incoherent_feature": "Gelieve de waarde in dit veld te controleren",
    "renovation_cost_zero_invalid": "Gelieve renovatiekosten hoger dan 0 in te voeren",
    "renovation_cost_minValue": "Gelieve renovatiekosten hoger dan 10000 in te voeren",
    "construction_cost_minValue": "Gelieve bouwkosten hoger dan 10000 in te voeren",
    "parcel_cost_minValue": "Gelieve een waarde van de bouwgrond hoger dan 10000 in te voeren",
    "save": "Opslaan",
    "saved": "Data opgeslagen!",
    "wrong_value": "Sommige informatie ontbreekt nog of is onverwacht. Wilt u dit onvolledige formulier toch indienen?",
    "warning": "Opgelet",
    "send": "Verzenden",
    "yes": "Ja",
    "no": "Nee",
    "previous": "Vorige",
    "next": "Volgende",
    "select_message": "Selecteer",
    "ground_floor": "0 (Gelijkvloers)",
    "documents_modal": {
        "title": "Documenten verzenden",
        "label": "Documenten toevoegen (.jpg, .jpeg, .png, .pdf)",
        "warning": "Alle gedeelde documenten die andere informatie bevatten dan het EPC document of een exterieur foto van het gebouw zullen worden genegeerd en verwijderd.",
        "steps": {
            "upload": "Toevoegen",
            "uploading": "Laden",
            "online": "Verzonden"
        }
    },
    "leave_modal": {
        "title": "Er zijn niet opgeslagen wijzigingen.",
        "content": "Wil je opslaan voor je vertrekt?",
        "confirm": "Afsluiten",
        "cancel": "Blijf op de pagina",
        "save": "Opslaan en afsluiten"
    }
  },
  "fr-BE": {
    "epc_unavailable_disclaimer": "Je confirme qu'il n'y a pas de certificat PEB, et que cette estimation du PEB concerne ce bien",
    "refinancing_disclaimer": "Je confirme que l'objectif de cette demande est un refinancement ou une rémise en vigueur",
    "title": "Nous y sommes presque. Quelques dernières questions pour conclure.",
    "apt_floors": "A quel(s) étage(s) se trouve l'appartement ?",
    "field_required": "Ce champ est obligatoire",
    "required": "Obligatoire",
    "number_only": "Veuillez entrer uniquement des chiffres",
    "wrong_epc": "Veuillez fournir un label PEB correct",
    "documents": "Documents",
    "upload_documents_tooltip": "document(s) envoyé(s)",
    "value_too_large": "Ce nombre est trop grand",
    "valid_years": "Entre {min} et {max}",
    "floors_not_consecutive": "Les étages sélectionnés ne sont pas consécutifs",
    "incoherent_feature": "Veuillez vérifier la valeur de ce champ",
    "renovation_cost_zero_invalid": "Veuillez entrer un coût de renovation supérieur à 0",
    "renovation_cost_minValue": "Veuillez entrer un coût de renovation supérieur à 10000",
    "construction_cost_minValue": "Veuillez entrer un coût de construction supérieur à 10000",
    "parcel_cost_minValue": "Veuillez entrer un coût du terrain supérieur à 10000",
    "save": "Enregistrer",
    "saved": "Données enregistrées!",
    "wrong_value": "Certaines informations sont manquantes ou ont une valeur inattendue. Voulez-vous soumettre ces valeurs ?",
    "warning": "Attention",
    "send": "Envoyer",
    "yes": "Oui",
    "no": "Non",
    "previous": "Précédent",
    "next": "Suivant",
    "select_message": "Sélectionnez",
    "ground_floor": "0 (Rez-de-chaussée)",
    "documents_modal": {
        "title": "Envoyer des documents",
        "label": "Ajouter des documents (.jpg, .jpeg, .png, .pdf)",
        "warning": "Tout document partagé qui contient des informations autres que le PEB ou des photos de l'extérieur du bien ne sera pas pris en compte et supprimé.",
        "steps": {
            "upload": "Ajouter",
            "uploading": "Chargement",
            "online": "Envoyé"
        }
    },
    "leave_modal": {
        "title": "Vous avez des modifications non enregistrées.",
        "content": "Voulez-vous les enregistrer avant de quitter?",
        "confirm": "Quitter",
        "cancel": "Rester sur cette page",
        "save": "Enregistrer et quitter"
    }
  }
}
</i18n>

<template>
    <div class="container is-fluid section is-max-desktop">
        <div class="columns is-mobile is-multiline is-variable is-6">
            <div class="column is-12">
                <h2
                    class="title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('title') }}
                </h2>
            </div>
        </div>

        <div>
            <div
                v-if="show_parcel_area || show_touching_sides"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="columns is-multiline is-mobile is-variable is-6">
                    <div v-if="show_parcel_area" class="column is-6-desktop is-12-mobile">
                        <text-input
                            v-model="structural.f_parcel_area"
                            :disabled="!isDraft"
                            :label="$t('features.f_parcel_area')"
                            :tooltip="$t('features.f_parcel_area_tooltip')"
                            :type="{
                                'is-danger':
                                    !$v.structural.f_parcel_area.decimal ||
                                    !$v.structural.f_parcel_area.required ||
                                    ((!$v.structural.f_parcel_area.minValue ||
                                        !$v.structural.f_parcel_area.maxValue) &&
                                        $v.structural.f_parcel_area.decimal) ||
                                    (is_incoherent('f_living_area') &&
                                        !$v.structural.f_parcel_area.$dirty),
                            }"
                            :message="[
                                (!$v.structural.f_parcel_area.minValue ||
                                    !$v.structural.f_parcel_area.maxValue) &&
                                $v.structural.f_parcel_area.decimal
                                    ? $t('valid_area', { min: 25, max: 99999 })
                                    : null,
                                !$v.structural.f_parcel_area.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_parcel_area.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_parcel_area') &&
                                !$v.structural.f_parcel_area.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.structural.f_parcel_area.$touch()"
                            @focus="toggleFloorDiagram(false)"
                        />
                    </div>
                    <div
                        v-if="show_building_area"
                        class="column is-6-desktop is-12-mobile"
                    >
                        <text-input
                            v-model="structural.f_building_area"
                            :disabled="!isDraft"
                            :label="$t('features.f_building_area')"
                            :tooltip="$t('features.f_building_area_tooltip')"
                            :type="{
                                'is-danger':
                                    !$v.structural.f_building_area.decimal ||
                                    !$v.structural.f_building_area.required ||
                                    ((!$v.structural.f_building_area.minValue ||
                                        !$v.structural.f_building_area.maxValue) &&
                                        $v.structural.f_building_area.decimal) ||
                                    (is_incoherent('f_building_area') &&
                                        !$v.structural.f_building_area.$dirty),
                            }"
                            :message="[
                                (!$v.structural.f_building_area.minValue ||
                                    !$v.structural.f_building_area.maxValue) &&
                                $v.structural.f_building_area.decimal
                                    ? $t('valid_area', { min: 25, max: 99999 })
                                    : null,
                                !$v.structural.f_building_area.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_building_area.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_building_area') &&
                                !$v.structural.f_building_area.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.structural.f_building_area.$touch()"
                            @focus="toggleFloorDiagram(false)"
                        />
                    </div>
                    <div
                        v-if="show_touching_sides"
                        class="column is-6-desktop is-12-mobile"
                    >
                        <b-field custom-class="is-small-tooltip">
                            <template slot="label">
                                {{ $t('features.f_number_of_facades_or_type') }}
                                <b-tooltip
                                    class="ml-2 mb-1"
                                    type="is-primary"
                                    :label="
                                        $t('features.f_number_of_facades_or_type_tooltip')
                                    "
                                    multilined
                                >
                                    <div
                                        class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                    >
                                        ?
                                    </div>
                                </b-tooltip>
                            </template>
                            <b-select
                                v-model="structural.touching_sides"
                                :placeholder="$t('common.select_one')"
                                :disabled="!isDraft"
                                size="is-medium"
                                expanded
                            >
                                <option :value="2">
                                    {{ $t('value.attached') }}
                                </option>
                                <option :value="3">
                                    {{ $t('value.semi') }}
                                </option>
                                <option :value="4">
                                    {{ $t('value.detached') }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
            </div>
            <div
                v-if="is_building"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="is-flex is-align-content-flex-end">
                    <b-field custom-class="is-small-tooltip" class="is-w100">
                        <template slot="label">
                            {{
                                is_new
                                    ? $t('features.f_expected_epc')
                                    : $t('features.f_epc')
                            }}
                            <b-tooltip
                                type="is-primary"
                                :label="
                                    is_new
                                        ? $t('features.f_expected_epc_tooltip')
                                        : $t('features.f_epc_tooltip')
                                "
                                multilined
                            >
                                <div
                                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>
                        </template>

                        <template v-if="allowEPCProxy">
                            <b-radio-button
                                v-model="structural.epc_unavailable"
                                :native-value="false"
                                size="is-medium"
                                type="is-primary"
                                :disabled="!isDraft"
                                @input="() => toggleFloorDiagram(false)"
                            >
                                <span>{{ $t('value.certificate') }}</span>
                            </b-radio-button>

                            <b-radio-button
                                v-model="structural.epc_unavailable"
                                :native-value="true"
                                size="is-medium"
                                type="is-primary"
                                class="mr-5"
                                @input="() => toggleFloorDiagram(false)"
                            >
                                <span>{{ $t('value.user_estimate') }}</span>
                            </b-radio-button>
                        </template>

                        <b-field
                            v-if="structural.is_epc_number"
                            class="is-w100"
                            :type="{
                                'is-danger':
                                    !$v.structural.f_epc_numeric.decimal ||
                                    !$v.structural.f_epc_numeric.required ||
                                    !$v.structural.f_epc_numeric.maxValue ||
                                    (is_incoherent('f_epc') &&
                                        !$v.structural.f_epc_numeric.$dirty),
                            }"
                            :message="[
                                !$v.structural.f_epc_numeric.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_epc_numeric.maxValue
                                    ? $t('value_too_large')
                                    : null,
                                !$v.structural.f_epc_numeric.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_epc') &&
                                !$v.structural.f_epc_numeric.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="structural.f_epc_numeric"
                                size="is-medium"
                                :disabled="!isDraft"
                                expanded
                                aria-label="EPC"
                                @input="$v.structural.f_epc_numeric.$touch()"
                                @focus="toggleFloorDiagram(false)"
                            ></b-input>
                        </b-field>
                        <span
                            class="is-align-self-flex-start pt-3 ml-2 is-size-5 is-unit"
                        >
                            {{ $t('unit.kwh/m2year') }}
                        </span>
                    </b-field>
                </div>

                <template v-if="allowEPCProxy">
                    <b-checkbox
                        v-if="structural.epc_unavailable && isDraft"
                        v-model="structural.epc_unavailable_disclaimer"
                        class="is-w100 my-2"
                    >
                        {{ $t('epc_unavailable_disclaimer') }}
                        <b-icon
                            v-if="!$v.structural.epc_unavailable_disclaimer.sameAs"
                            type="is-danger"
                            icon="alert-circle"
                        />
                    </b-checkbox>
                    <b-checkbox
                        v-model="structural.refinancing_disclaimer"
                        :disabled="!isDraft"
                        class="is-w100 my-2"
                    >
                        {{ $t('refinancing_disclaimer') }}
                        <b-icon
                            v-if="!$v.structural.refinancing_disclaimer.sameAs"
                            type="is-danger"
                            icon="alert-circle"
                        />
                    </b-checkbox>
                </template>
            </div>

            <div
                v-if="is_building"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="columns is-multiline is-mobile is-variable is-6">
                    <div class="column is-6-desktop is-12-mobile">
                        <text-input
                            v-model="structural.f_construction_year"
                            :disabled="!isDraft"
                            :label="
                                is_new
                                    ? $t('features.f_expected_construction_year')
                                    : $t('features.f_construction_year')
                            "
                            :tooltip="
                                is_new
                                    ? $t('features.f_expected_construction_year_tooltip')
                                    : $t('features.f_construction_year_tooltip')
                            "
                            :type="{
                                'is-danger':
                                    !$v.structural.f_construction_year.decimal ||
                                    !$v.structural.f_construction_year.required ||
                                    ((!$v.structural.f_construction_year.minValue ||
                                        !$v.structural.f_construction_year.maxValue) &&
                                        $v.structural.f_construction_year.decimal) ||
                                    (is_incoherent('f_construction_year') &&
                                        !$v.structural.f_construction_year.$dirty),
                            }"
                            :message="[
                                (!$v.structural.f_construction_year.minValue ||
                                    !$v.structural.f_construction_year.maxValue) &&
                                $v.structural.f_construction_year.decimal
                                    ? $t('valid_years', { min: minYear, max: maxYear })
                                    : null,
                                !$v.structural.f_construction_year.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_construction_year.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_construction_year') &&
                                !$v.structural.f_construction_year.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="construction year"
                            @input="$v.structural.f_construction_year.$touch()"
                            @focus="toggleFloorDiagram(false)"
                        />
                    </div>
                    <div
                        class="column is-6-desktop is-12-tablet is-flex is-align-content-flex-end"
                    >
                        <text-input
                            v-model="structural.f_living_area"
                            class="is-flex-grow-2"
                            :disabled="!isDraft"
                            :label="
                                is_new
                                    ? $t('features.f_expected_living_area')
                                    : $t('features.f_living_area')
                            "
                            :tooltip="
                                is_new
                                    ? $t('features.f_expected_living_area_tooltip')
                                    : $t('features.f_living_area_tooltip')
                            "
                            :type="{
                                'is-danger':
                                    !$v.structural.f_living_area.required ||
                                    !$v.structural.f_living_area.maxValue ||
                                    !$v.structural.f_living_area.decimal ||
                                    (is_incoherent('f_living_area') &&
                                        !$v.structural.f_living_area.$dirty),
                            }"
                            :message="[
                                !$v.structural.f_living_area.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_living_area.maxValue
                                    ? $t('value_too_large')
                                    : null,
                                !$v.structural.f_living_area.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_living_area') &&
                                !$v.structural.f_living_area.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="living area"
                            @focus="toggleFloorDiagram(false)"
                            @input="$v.structural.f_living_area.$touch()"
                        />
                        <span
                            class="is-align-self-flex-start mt-5 pt-5 ml-2 is-size-5 is-unit"
                        >
                            {{ $t('unit.m2') }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- apartment -->
            <div
                v-if="is_apartment"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="columns is-multiline is-mobile is-variable is-6">
                    <!-- Number of bedrooms -->
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_bedrooms"
                            :disabled="!isDraft"
                            :label="$t('features.f_bedrooms')"
                            :tooltip="$t('features.f_bedrooms_tooltip')"
                            :min="0"
                            :max="20"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_bedrooms') &&
                                    !$v.apartment.f_bedrooms.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_bedrooms') &&
                                !$v.apartment.f_bedrooms.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="number of bedrooms"
                            @focus="toggleFloorDiagram(false)"
                            @input="
                                () => {
                                    toggleFloorDiagram(false)
                                    $v.apartment.f_bedrooms.$touch()
                                }
                            "
                        />
                    </div>
                    <!-- Balcony -->
                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_balcony_present"
                            :label="$t('features.f_balcony_present')"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_balcony_present_tooltip')"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_balcony_present') &&
                                    !$v.apartment.f_balcony_present.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_balcony_present') &&
                                !$v.apartment.f_balcony_present.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="balcony present"
                            @input="
                                () => {
                                    toggleFloorDiagram(false)
                                    $v.apartment.f_balcony_present.$touch()
                                }
                            "
                        />
                    </div>

                    <div
                        :class="
                            `columns column is-mobile is-multiline is-12 is-floors-section ${showFloorDiagram &&
                                isFloorDiagramEnabled &&
                                'diagram-is-shown'}`
                        "
                    >
                        <div class="column is-6-desktop is-12-tablet">
                            <int-selector
                                v-model="apartment.f_bottom_floor"
                                :disabled="!isDraft"
                                :label="$t('features.f_bottom_floor')"
                                :tooltip="$t('features.f_bottom_floor_tooltip')"
                                :min="-5"
                                :max="0"
                                :type="{
                                    'is-danger':
                                        is_incoherent('f_bottom_floor') &&
                                        !$v.apartment.f_bottom_floor.$dirty,
                                }"
                                :message="[
                                    is_incoherent('f_bottom_floor') &&
                                    !$v.apartment.f_bottom_floor.$dirty
                                        ? $t('incoherent_feature')
                                        : null,
                                ]"
                                aria-label="bottom floor"
                                @focus="toggleFloorDiagram(true)"
                                @input="
                                    () => {
                                        $v.apartment.f_bottom_floor.$touch()
                                        toggleFloorDiagram(true)
                                    }
                                "
                            />
                        </div>
                        <div class="column is-6-desktop is-12-tablet">
                            <int-selector
                                v-model="apartment.f_top_floor"
                                :disabled="!isDraft"
                                :label="$t('features.f_top_floor')"
                                :tooltip="$t('features.f_top_floor_tooltip')"
                                :max="40"
                                :type="{
                                    'is-danger':
                                        is_incoherent('f_top_floor') &&
                                        !$v.apartment.f_top_floor.$dirty,
                                }"
                                :message="[
                                    is_incoherent('f_top_floor') &&
                                    !$v.apartment.f_top_floor.$dirty
                                        ? $t('incoherent_feature')
                                        : null,
                                ]"
                                aria-label="top floor"
                                @focus="toggleFloorDiagram(true)"
                                @input="
                                    () => {
                                        $v.apartment.f_top_floor.$touch()
                                        toggleFloorDiagram(true)
                                    }
                                "
                            />
                        </div>
                        <range-selector
                            v-model="apartment.f_floors"
                            :label="$t('apt_floors')"
                            :tooltip="$t('features.f_floors_tooltip')"
                            :disabled="!isDraft"
                            :message="[
                                !$v.apartment.f_floors.consecutive
                                    ? $t('floors_not_consecutive')
                                    : null,
                                is_incoherent('f_floors') && !$v.apartment.f_floors.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            :type="
                                !$v.apartment.f_floors.consecutive ||
                                (is_incoherent('f_floors') &&
                                    !$v.apartment.f_floors.$dirty)
                                    ? 'is-danger'
                                    : 'is-primary'
                            "
                            :range-list="floor_list"
                            :button-label-renderer="
                                (floor) => (floor === 0 ? $t('ground_floor') : floor)
                            "
                            aria-label="floors"
                            @input="
                                () => {
                                    $v.apartment.f_floors.$touch()
                                    toggleFloorDiagram(true)
                                }
                            "
                        />
                    </div>
                    <!-- Garden -->
                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_garden_common"
                            :label="$t('features.f_garden_common')"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_garden_common_tooltip')"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_garden_common') &&
                                    !$v.apartment.f_garden_common.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_garden_common') &&
                                !$v.apartment.f_garden_common.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="access to common garden"
                            @input="
                                () => {
                                    $v.apartment.f_garden_common.$touch()
                                    toggleFloorDiagram(false)
                                }
                            "
                        />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_garden_private"
                            :label="$t('features.f_garden_private')"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_garden_private_tooltip')"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_garden_private') &&
                                    !$v.apartment.f_garden_private.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_garden_private') &&
                                !$v.apartment.f_garden_private.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="access to private garden"
                            @input="
                                () => {
                                    $v.apartment.f_garden_private.$touch()
                                    toggleFloorDiagram(false)
                                }
                            "
                        />
                    </div>

                    <!-- Garage -->
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_n_closed_garage"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_n_closed_garage_tooltip')"
                            :is-big="true"
                            aria-label="closed garages"
                            :label="$t('features.f_n_closed_garage')"
                            :min="0"
                            :max="20"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_n_closed_garage') &&
                                    !$v.apartment.f_n_closed_garage.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_n_closed_garage') &&
                                !$v.apartment.f_n_closed_garage.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="
                                () => {
                                    $v.apartment.f_n_closed_garage.$touch()
                                    toggleFloorDiagram(false)
                                }
                            "
                            @focus="toggleFloorDiagram(false)"
                        />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_n_parking_spot"
                            :disabled="!isDraft"
                            :is-big="true"
                            :label="$t('features.f_n_parking_spot')"
                            aria-label="parking spots"
                            :tooltip="$t('features.f_n_parking_spot_tooltip')"
                            :min="0"
                            :max="40"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_n_parking_spot') &&
                                    !$v.apartment.f_n_parking_spot.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_n_parking_spot') &&
                                !$v.apartment.f_n_parking_spot.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="
                                () => {
                                    $v.apartment.f_n_parking_spot.$touch()
                                    toggleFloorDiagram(false)
                                }
                            "
                            @focus="toggleFloorDiagram(false)"
                        />
                    </div>

                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_cellar_attic"
                            :label="$t('features.f_cellar_attic')"
                            :disabled="!isDraft"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_cellar_attic') &&
                                    !$v.apartment.f_cellar_attic.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_cellar_attic') &&
                                !$v.apartment.f_cellar_attic.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            aria-label="cellar or attic"
                            @input="
                                () => {
                                    $v.apartment.f_cellar_attic.$touch()
                                    toggleFloorDiagram(false)
                                }
                            "
                        />
                    </div>
                </div>
            </div>
            <!-- Renovations -->
            <div
                v-if="allow_renovation"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="is-flex is-align-content-flex-end">
                    <b-field
                        custom-class="is-small-tooltip"
                        aria-label="planned renovation"
                    >
                        <template slot="label">
                            {{ $t('section.renovation') }}
                            <!--<b-tooltip
                                type="is-primary"
                                :label="$t('features.renovation_tooltip')"
                                multilined
                            >
                                <div
                                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>-->
                        </template>
                        <b-radio-button
                            v-model="structural.has_renovation"
                            :native-value="false"
                            size="is-medium"
                            type="is-primary"
                            :disabled="!isDraft"
                            aria-label="no renovation"
                            @click="$v.structural.renovation_cost.$touch()"
                            @input="toggleFloorDiagram(false)"
                        >
                            <span>{{ $t('no') }}</span>
                        </b-radio-button>

                        <b-radio-button
                            v-model="structural.has_renovation"
                            :disabled="!isDraft"
                            :native-value="true"
                            size="is-medium"
                            type="is-primary"
                            aria-label="renovation planned"
                            @click="$v.structural.renovation_cost.$touch()"
                            @input="toggleFloorDiagram(false)"
                        >
                            <span>{{ $t('yes') }}</span>
                        </b-radio-button>
                    </b-field>
                    <b-field
                        v-if="structural.has_renovation"
                        class="is-w100 ml-5"
                        :type="{
                            'is-danger':
                                !$v.structural.renovation_cost.decimal ||
                                !$v.structural.renovation_cost.required ||
                                // !$v.structural.renovation_cost.minValue ||
                                renovation_cost_zero_invalid ||
                                (is_incoherent('renovation_cost') &&
                                    !$v.structural.renovation_cost.$dirty),
                        }"
                        :message="[
                            !$v.structural.renovation_cost.decimal
                                ? $t('number_only')
                                : null,

                            !$v.structural.renovation_cost.required
                                ? $t('field_required')
                                : null,
                            // !$v.structural.renovation_cost.minValue
                            //     ? $t('renovation_cost_minValue')
                            //     : null,
                            renovation_cost_zero_invalid
                                ? $t('renovation_cost_zero_invalid')
                                : null,
                            is_incoherent('renovation_cost') &&
                            !$v.structural.renovation_cost.$dirty
                                ? $t('incoherent_feature')
                                : null,
                        ]"
                    >
                        <template slot="label">
                            {{ $t('features.renovation_cost') }}
                        </template>
                        <b-input
                            v-model="structural.renovation_cost"
                            size="is-medium"
                            :disabled="!isDraft"
                            expanded
                            @input="$v.structural.renovation_cost.$touch()"
                            @focus="toggleFloorDiagram(false)"
                        ></b-input>
                    </b-field>
                    <span
                        v-if="structural.has_renovation"
                        class="is-align-self-flex-start mt-5 pt-5 ml-2 mr-4 is-size-5 is-unit"
                    >
                        {{ $t('unit.euro') }}
                    </span>

                    <b-field
                        v-if="structural.has_renovation && allow_full_renovation"
                        :message="[
                            $tc('renovation_info.items', renovation_items.length) +
                                ', ' +
                                $tc(
                                    'renovation_info.linked_items',
                                    renovation_items.filter(
                                        (item) => item.linked_document_ref !== null
                                    ).length
                                ),
                            `${$t('common.total')}: ${exact_value_formatter(
                                renovation_items
                                    .map((item) => item.amount)
                                    .reduce((a, b) => a + b, 0)
                            )}`,
                        ]"
                    >
                        <template slot="label">
                            {{ $t('renovation_info.title') }}
                        </template>
                        <b-button
                            tag="router-link"
                            :to="{
                                params: {
                                    lang: $route.params.lang,
                                    action: 'renovation-info',
                                },
                                query: $route.query,
                            }"
                            size="is-medium"
                            type="is-primary"
                            icon-left="plus"
                        >
                            {{ $t('renovation_info.title') }}
                        </b-button>
                    </b-field>
                </div>
            </div>
            <div
                v-if="is_new || is_plot"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="is-flex is-align-content-flex-end">
                    <b-field
                        v-if="is_new"
                        class="is-w100"
                        custom-class="is-small-tooltip"
                        :type="{
                            'is-danger':
                                !$v.structural.construction_cost.decimal ||
                                !$v.structural.construction_cost.required ||
                                !$v.structural.construction_cost.minValue,
                        }"
                        :message="[
                            !$v.structural.construction_cost.decimal
                                ? $t('number_only')
                                : null,
                            !$v.structural.construction_cost.required
                                ? $t('field_required')
                                : null,
                            !$v.structural.construction_cost.minValue
                                ? $t('construction_cost_minValue')
                                : null,
                        ]"
                    >
                        <template slot="label">
                            {{ $t('features.construction_cost') }}
                            <b-tooltip
                                type="is-primary"
                                :label="$t('features.construction_cost_tooltip')"
                                multilined
                            >
                                <div
                                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>
                        </template>
                        <b-input
                            v-model="structural.construction_cost"
                            size="is-medium"
                            :disabled="!isDraft"
                            expanded
                            @input="$v.structural.construction_cost.$touch()"
                            @focus="toggleFloorDiagram(false)"
                        ></b-input>
                    </b-field>
                    <span
                        v-if="is_new"
                        class="is-align-self-flex-start mt-5 pt-5 ml-2 mr-4 is-size-5 is-unit"
                    >
                        {{ $t('unit.euro') }}
                    </span>

                    <b-field
                        v-if="is_new || is_plot"
                        class="is-w100"
                        :class="{ 'ml-5': is_new }"
                        custom-class="is-small-tooltip"
                        :type="{
                            'is-danger':
                                !$v.structural.parcel_cost.decimal ||
                                !$v.structural.parcel_cost.required ||
                                !$v.structural.parcel_cost.minValue,
                        }"
                        :message="[
                            !$v.structural.parcel_cost.decimal ? $t('number_only') : null,
                            !$v.structural.parcel_cost.required
                                ? $t('field_required')
                                : null,
                            !$v.structural.parcel_cost.minValue
                                ? $t('parcel_cost_minValue')
                                : null,
                        ]"
                    >
                        <template slot="label">
                            {{ $t('features.parcel_cost') }}
                            <b-tooltip
                                type="is-primary"
                                :label="$t('features.parcel_cost_tooltip')"
                                multilined
                            >
                                <div
                                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>
                        </template>
                        <b-input
                            v-model="structural.parcel_cost"
                            size="is-medium"
                            :disabled="!isDraft"
                            expanded
                            @input="$v.structural.parcel_cost.$touch()"
                            @focus="toggleFloorDiagram(false)"
                        ></b-input>
                    </b-field>
                    <span
                        v-if="(is_house && is_new) || is_plot"
                        class="is-align-self-flex-start mt-5 pt-5 ml-2 mr-4 is-size-5 is-unit"
                    >
                        {{ $t('unit.euro') }}
                    </span>
                </div>
            </div>
        </div>
        <div
            v-if="
                isDraft ? document_purposes.length > 0 : Object.keys(documents).length > 0
            "
        >
            <div class="column is-12">
                <h2
                    class="title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('documents') }}
                </h2>
            </div>
            <div
                v-if="isDraft"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet is-flex upload-row"
            >
                <b-field
                    v-for="purpose in document_purposes"
                    :key="'upload_field_' + purpose"
                    :type="{
                        'is-danger':
                            required_documents.includes(purpose) &&
                            documents_per_purpose[purpose].length == 0,
                    }"
                    :message="[
                        required_documents.includes(purpose) &&
                        documents_per_purpose[purpose].length == 0
                            ? $t('required')
                            : null,
                    ]"
                    class="is-flex-grow-1"
                >
                    <b-upload
                        v-model="pending_document"
                        class="is-w100"
                        type="is-primary"
                        rounded
                        outlined
                        accept=".jpg,.jpeg,.png,.pdf,image/png,image/jpeg,application/pdf"
                        :disabled="!isDraft"
                        @input="upload_document(purpose)"
                    >
                        <a
                            :class="
                                `button is-fullwidth is-outlined ${
                                    required_documents.includes(purpose) &&
                                    documents_per_purpose[purpose].length == 0
                                        ? 'is-danger'
                                        : 'is-primary'
                                }`
                            "
                        >
                            <b-icon class="file-icon" icon="plus" />
                            <span class="file-label mr-1">
                                {{ $tc(`document.${purpose}`, 1) }}
                            </span>
                        </a>
                    </b-upload>
                </b-field>
            </div>

            <div
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet mt-0 py-0"
            >
                <div v-for="purpose in document_purposes" :key="`documents_${purpose}`">
                    <div
                        v-for="document in documents_per_purpose[purpose]"
                        :key="`doc_${document.document_ref}`"
                        class="upload-item"
                    >
                        <img src="@/assets/icons/ic-file.svg" alt="" class="file-icon" />
                        <a
                            :href="documentURL(document.document_ref)"
                            class="upload-name"
                            target="_blank"
                        >
                            <span class="has-text-primary">
                                {{ document.filename }}
                            </span>
                        </a>
                        <span>
                            {{ $tc(`document.${purpose}`, 1) }}
                        </span>
                        <b-button
                            v-if="isDraft"
                            class="action-button is-light"
                            rounded
                            @click="remove_document(document)"
                        >
                            <b-icon class="file-icon" icon="delete"></b-icon>
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet mt-6">
            <div class="button-group">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="prev_page"
                >
                    {{ $t('previous') }}
                </b-button>
                <b-button
                    v-if="(isDraft || sending) && $v.$anyDirty"
                    size="is-medium"
                    type="is-dark"
                    icon-left="content-save"
                    :disabled="form_any_error"
                    :loading="sending"
                    @click="submitForm('save-draft', true)"
                >
                    {{ $t('save') }}
                </b-button>
                <b-button
                    v-if="isDraft || sending"
                    size="is-medium"
                    type="is-primary"
                    icon-right="send"
                    :disabled="!form_complete"
                    :loading="sending"
                    aria-label="submit valuation request"
                    @click="submitForm('submit')"
                >
                    {{ $t('send') }}
                </b-button>
                <div v-else>
                    <b-button
                        v-if="getValuationType !== 'ovm'"
                        size="is-medium"
                        type="is-dark"
                        icon-left="view-dashboard"
                        tag="router-link"
                        :to="{ name: 'dashboard' }"
                    >
                        {{ $t('monolith.apps.dashboard') }}
                    </b-button>
                    <b-button
                        v-if="isBankOVM"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :disabled="!form_complete"
                        :loading="sending"
                        tag="router-link"
                        :to="{ params: { action: 'ovm' }, query: $route.query }"
                    >
                        {{ $t('next') }}
                    </b-button>
                    <b-button
                        v-if="isWhitelabelOVM"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :disabled="!form_complete"
                        :loading="sending"
                        tag="router-link"
                        :to="{
                            name: 'valuation.valuer-app.general',
                            query: $route.query,
                        }"
                    >
                        {{ $t('next') }}
                    </b-button>
                </div>
            </div>
        </div>

        <b-modal
            v-model="isExitingModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="isExitingModalActive" class="exit-modal">
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('leave_modal.title') }}
                </h2>
                <p>{{ $t('leave_modal.content') }}</p>
                <div>
                    <b-button
                        v-if="cancelFn"
                        size="is-medium"
                        type="is-dark"
                        @click="cancelFn"
                    >
                        {{ $t('leave_modal.cancel') }}
                    </b-button>
                    <b-button
                        v-if="proceedFn"
                        size="is-medium"
                        type="is-primary"
                        @click="
                            () => {
                                submitForm('save-draft')
                                proceedFn()
                            }
                        "
                    >
                        {{ $t('leave_modal.save') }}
                    </b-button>
                    <b-button
                        v-if="proceedFn"
                        size="is-medium"
                        type="is-dark"
                        @click="proceedFn"
                    >
                        {{ $t('leave_modal.confirm') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
        <floors-diagram
            v-if="isFloorDiagramEnabled"
            :bottom-floor="apartment.f_bottom_floor"
            :top-floor="apartment.f_top_floor"
            :increase-bottom-floor="() => apartment.f_bottom_floor++"
            :reduce-bottom-floor="() => apartment.f_bottom_floor--"
            :increase-top-floor="() => apartment.f_top_floor++"
            :reduce-top-floor="() => apartment.f_top_floor--"
            :toggle-floor="toggleFloor"
            :activated-floors="apartment.f_floors"
            :building-floors="diagram_floor_list"
            :is-error="!$v.apartment.f_floors.consecutive"
            :show-diagram="showFloorDiagram"
            :error-message="$t('floors_not_consecutive')"
            @close="toggleFloorDiagram(false)"
        />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
    required,
    requiredIf,
    decimal,
    minValue,
    maxValue,
    sameAs,
} from 'vuelidate/lib/validators'
import IntSelector from '@/components/elements/IntSelector.vue'
import RadioSelector from '@/components/elements/RadioSelector.vue'
import TextInput from '@/components/elements/TextInput.vue'
import RangeSelector from '@/components/elements/RangeSelector.vue'
import FloorsDiagram from './05.3-FloorsDiagram.vue'
import utils from '@/shared/plugins/utils'
import enums from '@/shared/plugins/enums'

const currentYear = new Date().getFullYear()

function default_data() {
    return {
        leaving: false,
        cancelFn: () => {},
        proceedFn: () => {},
        isUploadModalActive: false,
        isExitingModalActive: false,
        showFloorDiagram: false,
        sending: false,
        notFoundValid: false,
        validAll: false,
        structural: {
            f_construction_year: null,
            f_epc_numeric: null,
            f_epc_label: null,
            is_epc_number: true,
            f_living_area: null,
            has_renovation: false,
            renovation_cost: 0,
            construction_cost: null,
            parcel_cost: null,
            f_parcel_area: null,
            f_building_area: null,
            touching_sides: null,
            epc_unavailable: false,
            epc_unavailable_disclaimer: false,
            refinancing_disclaimer: false,
        },
        apartment: {
            f_bedrooms: 0,
            f_balcony_present: null,
            f_bottom_floor: 0,
            f_top_floor: 0,
            f_floors: [],
            f_garden_common: null,
            f_garden_private: null,
            f_n_closed_garage: 0,
            f_n_parking_spot: 0,
            f_cellar_attic: null,
        },
        /*documents: {
            uploading_docs: {
                exterior_picture: [],
                epc_certificate: [],
            },
            pending: {
                exterior_picture: [],
                epc_certificate: [],
            },
            uploaded: [],
        },*/
        documents: {},
        pending_document: null,
        renovation_items: [],
        missing_fields: [],
    }
}
export default {
    components: {
        IntSelector,
        RadioSelector,
        TextInput,
        RangeSelector,
        FloorsDiagram,
    },
    props: {
        buildingType: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        ...default_data(),
    }),
    validations: {
        structural: {
            epc_unavailable_disclaimer: {
                sameAs: sameAs(function(structural) {
                    return (
                        structural.epc_unavailable ||
                        structural.epc_unavailable_disclaimer
                    )
                }),
            },
            refinancing_disclaimer: {
                sameAs: sameAs(function(structural) {
                    return structural.epc_unavailable || structural.refinancing_disclaimer
                }),
            },
            f_epc_numeric: {
                decimal,
                required: requiredIf(function(structural) {
                    return structural.is_epc_number && !this.is_new && !this.is_plot
                }),
                maxValue: maxValue(2000),
            },
            f_epc_label: {
                oneOf: function(value) {
                    return (
                        this.structural.is_epc_number ||
                        this.epc_labels.includes(value) ||
                        this.is_new ||
                        this.is_plot
                    )
                },
            },
            touching_sides: {
                required: requiredIf(function() {
                    return this.show_touching_sides
                }),
            },
            f_construction_year: {
                // min - max year
                decimal,
                required: requiredIf(function() {
                    return this.is_building
                }),
                minValue: function(val) {
                    return !val || val >= this.minYear
                },
                maxValue: function(val) {
                    return !val || val <= this.maxYear
                },
            },
            f_living_area: {
                decimal,
                required: requiredIf(function() {
                    return this.is_building
                }),
                maxValue: maxValue(99999),
            },
            f_parcel_area: {
                decimal,
                required: requiredIf(function() {
                    return this.show_parcel_area
                }),
                minValue: minValue(10),
                maxValue: maxValue(99999),
            },
            f_building_area: {
                decimal,
                required: requiredIf(function() {
                    return this.show_building_area
                }),
                minValue: minValue(10),
                maxValue: maxValue(99999),
            },
            renovation_cost: {
                decimal,
                required: requiredIf(function() {
                    return this.structural.has_renovation
                }),
                // minValue: function(val) {
                //     if (!this.structural.has_renovation) {
                //         return true
                //     }
                //     return val >= 10000
                // },
            },
            construction_cost: {
                decimal,
                required: requiredIf(function() {
                    return this.is_new
                }),
                minValue: minValue(10000),
            },
            parcel_cost: {
                decimal,
                required: requiredIf(function() {
                    return this.is_new || this.is_plot
                }),
                minValue: minValue(10000),
            },
        },
        apartment: {
            f_bedrooms: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_balcony_present: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_floors: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
                consecutive: function(floors) {
                    floors.sort((a, b) => a - b)
                    let is_consecutive = true
                    for (let i = 1; i < floors.length; i++) {
                        is_consecutive = is_consecutive && floors[i - 1] === floors[i] - 1
                    }

                    return is_consecutive
                },
            },
            f_garden_common: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_garden_private: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_n_closed_garage: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_n_parking_spot: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_cellar_attic: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_bottom_floor: {
                minValue: minValue(-5),
                maxValue: maxValue(0),
            },
            f_top_floor: {
                maxValue: maxValue(40),
                minValue: minValue(0),
            },
        },
    },

    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getFullBuildingData',
            'isExtraInfoEdited',
        ]),
        ...mapGetters(['flow_type', 'isMobile']),
        ...mapGetters('valuationStore', [
            'getFullValuationStore',
            'isDraft',
            'isWhitelabelOVM',
            'isBankOVM',
            'getRequestRef',
            'getValuationType',
            'status',
            'purpose',
        ]),
        ...mapGetters('auth', ['hasRole', 'getUsername', 'accessibleModules']),
        isDocumentUploadEnabled() {
            return this.$config.ENABLE_DOCUMENTS_UPLOAD
        },
        isErsReportEnabled() {
            return this.$config.ALLOW_ERS_REPORT_UPLOAD
        },
        document_purposes() {
            if (!this.isDocumentUploadEnabled) {
                return []
            }
            switch (this.buildingType) {
                case 'house': {
                    const document_purposes = ['exterior_picture']
                    if (!this.structural.epc_unavailable) {
                        document_purposes.push('epc_certificate')
                    }
                    if (
                        this.accessibleModules.includes('ers') &&
                        this.isErsReportEnabled
                    ) {
                        document_purposes.push('ers_report')
                    }
                    return document_purposes
                }
                case 'apartment': {
                    const document_purposes = ['exterior_picture']
                    if (!this.structural.epc_unavailable) {
                        document_purposes.push('epc_certificate')
                    }
                    return document_purposes
                }
                case 'new_house':
                case 'new_apartment':
                    return [
                        'specifications',
                        'building_blueprint',
                        'cadastral_plan',
                        'expected_epc',
                        'sales_agreement',
                    ]
                case 'construction_plot':
                    return ['cadastral_plan', 'sales_agreement']
                default:
                    return []
            }
        },
        required_documents() {
            let candidates = ['specifications']
            if (this.$config.NEW_MANDATORY_DOCS) {
                if (this.is_new) {
                    candidates.push('building_blueprint')
                    candidates.push('specifications')
                }

                if (this.is_new || this.is_plot) {
                    candidates.push('sales_agreement')
                }

                if ((this.is_new && this.is_house) || this.is_plot) {
                    candidates.push('cadastral_plan')
                }
            } else {
                if (this.getFeatures.f_building_type == 'new_house') {
                    candidates.push('building_blueprint')
                }
                if (
                    (this.getFeatures.parcelnotfound ||
                        this.getFeatures.main_parcel_not_found ||
                        this.getFeatures.extra_parcels_not_found) &&
                    (this.is_new || this.is_plot)
                ) {
                    candidates.push('cadastral_plan')
                }
            }

            return candidates.filter(
                (purpose) => this.document_purposes.indexOf(purpose) !== -1
            )
        },
        documents_per_purpose() {
            let d = {}
            for (let purpose of this.document_purposes) {
                d[purpose] = Object.values(this.documents).filter(
                    (document) => document.purpose == purpose
                )
            }
            return d
        },
        isFloorDiagramEnabled() {
            return this.$config.SHOW_FLOOR_DIAGRAM
        },
        is_apartment() {
            return utils.is_apartment(this.buildingType)
        },
        allow_renovation() {
            return (
                this.$config.ALLOW_RENOVATION &&
                ['house', 'apartment'].includes(this.buildingType)
            )
        },
        renovation_cost_zero_invalid() {
            return (
                this.structural.has_renovation &&
                !this.$v.structural.renovation_cost.$invalid &&
                this.getFeatures.renovation_cost <= 0
            )
        },
        epc_labels() {
            return Object.keys(
                enums.epc_mapping[
                    this.getFeatures.region ? this.getFeatures.region : 'BE-VLG'
                ]
            )
        },
        form_complete() {
            let missing_document = this.required_documents.some(
                (purpose) => this.documents_per_purpose[purpose].length === 0
            )
            return (
                !this.$v.$invalid &&
                !this.renovation_cost_zero_invalid &&
                !missing_document
            )
        },
        form_any_error() {
            return (
                !this.$v.structural.f_living_area.decimal ||
                !this.$v.structural.f_construction_year.decimal ||
                !this.$v.structural.f_epc_numeric.decimal ||
                this.renovation_cost_zero_invalid ||
                !this.$v.structural.renovation_cost.decimal
                // !this.$v.structural.renovation_cost.minValue
            )
        },
        floor_list() {
            let l = []
            for (
                let i = this.apartment.f_bottom_floor;
                i <= this.apartment.f_top_floor;
                i += 1
            ) {
                l.push(i)
            }
            return l
        },
        diagram_floor_list() {
            let l = []
            for (
                let i = this.apartment.f_bottom_floor;
                i <= this.apartment.f_top_floor;
                i += 1
            ) {
                l.push(i)
                if (i == this.apartment.f_top_floor) l.push(i + 'x')
            }
            return l.reverse()
        },
        isDocWrongFormat() {
            return (
                this.documents.pending.exterior_picture.length > 0 &&
                !this.documents.pending.exterior_picture.every((x) =>
                    ['image/png', 'image/jpeg', 'application/pdf'].includes(x.type)
                ) &&
                this.documents.pending.epc_certificate.length > 0 &&
                !this.documents.pending.epc_certificate.every((x) =>
                    ['image/png', 'image/jpeg', 'application/pdf'].includes(x.type)
                )
            )
        },

        /*displayDocuments() {
            let pending_epcs = this.documents.pending.epc_certificate.map(
                (file, index) => {
                    return {
                        filename: file.name,
                        document_ref: null,
                        purpose: 'epc_certificate',
                        content_type: file.content_type,
                        status: this.documents.uploading_docs.epc_certificate.includes(
                            index
                        )
                            ? 'uploading'
                            : 'pending',
                        original_file: file,
                        indexInPendings: index,
                    }
                }
            )

            let pending_pictures = this.documents.pending.exterior_picture.map(
                (file, index) => {
                    return {
                        filename: file.name,
                        document_ref: null,
                        purpose: 'exterior_picture',
                        content_type: file.content_type,
                        status: this.documents.uploading_docs.exterior_picture.includes(
                            index
                        )
                            ? 'uploading'
                            : 'pending',
                        original_file: file,
                        indexInPendings: index,
                    }
                }
            )
            let uploaded_documents = this.documents.uploaded.map((data) => {
                return {
                    ...data,
                    status: 'uploaded',
                }
            })
            return [...pending_epcs, ...pending_pictures, ...uploaded_documents]
        },*/
        is_building() {
            return ['house', 'apartment', 'new_house', 'new_apartment'].includes(
                this.buildingType
            )
        },
        is_new() {
            return utils.is_new(this.buildingType)
        },
        is_house() {
            return utils.is_house(this.buildingType)
        },
        is_plot() {
            return utils.is_plot(this.buildingType)
        },
        allow_full_renovation() {
            return this.$config.FULL_RENOVATION_ENABLED
        },
        minYear() {
            if (this.is_new) {
                return currentYear - 1
            } else {
                return 1000
            }
        },
        maxYear() {
            if (this.is_new) {
                return currentYear + 5
            } else {
                return currentYear + 1
            }
        },
        is_epc_numeric_required() {
            return this.is_epc_numeric || this.is_new
        },
        show_touching_sides() {
            return (
                this.is_building &&
                (this.missing_fields.includes('f_touching_sides') ||
                    this.getFeatures.buildingnotfound ||
                    this.is_new)
            )
        },
        show_parcel_area() {
            return (
                (this.getFeatures.buildingnotfound ||
                    this.getFeatures.parcelnotfound ||
                    this.getFeatures.main_parcel_not_found ||
                    this.getFeatures.extra_parcels_not_found) &&
                !this.is_apartment
            )
        },
        show_building_area() {
            return this.show_parcel_area && !this.is_plot
        },
        allowEPCProxy() {
            return !this.is_new && !this.is_plot && this.$config.ALLOW_EPC_PROXY
        },
    },
    watch: {
        'structural.f_construction_year'(val) {
            this.SET_FEATURES({ f_construction_year: val === '' ? null : parseInt(val) })
        },
        'structural.f_parcel_area'(val) {
            this.SET_FEATURES({ f_parcel_area: val === '' ? null : parseFloat(val) })
        },
        'structural.f_building_area'(val) {
            this.SET_FEATURES({ f_building_area: val === '' ? null : parseFloat(val) })
        },
        'structural.touching_sides'(val) {
            this.SET_FEATURES({ f_touching_sides: 4 - val })
        },
        'structural.f_epc_label'(val) {
            this.SET_FEATURES({ f_epc_label: val === '' ? null : val })
        },
        'structural.f_epc_numeric'(val) {
            this.SET_FEATURES({ f_epc_numeric: val === '' ? null : parseFloat(val) })
        },
        'structural.is_epc_number'(val) {
            // Make sure to erase the content of a textbox when changing the epc type
            if (val) {
                this.structural.f_epc_label = null
            } else {
                this.structural.f_epc_numeric = null
            }
            this.SET_FEATURES({
                is_epc_number: val,
            })
        },
        'structural.f_living_area'(val) {
            this.SET_FEATURES({
                f_living_area: val === '' ? null : parseFloat(val),
            })
        },
        'structural.renovation_cost'(val) {
            this.SET_FEATURES({
                renovation_cost: val === '' ? null : parseFloat(val),
            })
        },
        'structural.construction_cost'(val) {
            this.SET_FEATURES({
                construction_cost: val === '' ? null : parseFloat(val),
            })
        },
        'structural.parcel_cost'(val) {
            this.SET_FEATURES({
                parcel_cost: val === '' ? null : parseFloat(val),
            })
        },
        'structural.has_renovation'(val) {
            if (!val) {
                this.structural.renovation_cost = '0'
            }
        },
        'apartment.f_bedrooms'(val) {
            this.SET_FEATURES({
                f_bedrooms: val,
            })
        },
        'apartment.f_balcony_present'(val) {
            this.SET_FEATURES({
                f_balcony_present: val,
            })
        },
        'apartment.f_bottom_floor'(val) {
            if (val < -5) this.apartment.f_bottom_floor = -5
            if (val > 0) this.apartment.f_bottom_floor = 0
            this.SET_FEATURES({
                f_bottom_floor: val,
            })
            const new_floors = this.apartment.f_floors.filter((x) => {
                return x >= this.apartment.f_bottom_floor
            })
            this.apartment.f_floors = new_floors
        },
        'apartment.f_top_floor'(val) {
            if (val > 40) this.apartment.f_top_floor = 40
            if (val < 0) this.apartment.f_top_floor = 0
            this.SET_FEATURES({
                f_top_floor: val,
            })
            const new_floors = this.apartment.f_floors.filter((x) => {
                return x <= this.apartment.f_top_floor
            })
            this.apartment.f_floors = new_floors
        },
        'apartment.f_floors'(val) {
            this.SET_FEATURES({
                f_floors: val,
            })
        },
        'apartment.f_garden_common'(val) {
            this.SET_FEATURES({
                f_garden_common: val,
            })
        },
        'apartment.f_garden_private'(val) {
            this.SET_FEATURES({
                f_garden_private: val,
            })
        },
        'apartment.f_n_closed_garage'(val) {
            this.SET_FEATURES({
                f_n_closed_garage: val,
            })
        },
        'apartment.f_n_parking_spot'(val) {
            this.SET_FEATURES({
                f_n_parking_spot: val,
            })
        },
        'apartment.f_cellar_attic'(val) {
            this.SET_FEATURES({
                f_cellar_attic: val,
            })
        },
        'structural.epc_unavailable'(val) {
            this.SET_FEATURES({
                epc_unavailable: val,
            })
        },
    },
    mounted() {
        // updated separate key data from store
        for (const key in this.apartment) {
            this.apartment[key] =
                typeof this.getFeatures[key] !== 'undefined'
                    ? this.getFeatures[key]
                    : default_data().apartment[key]
        }
        for (const key in this.structural) {
            this.structural[key] =
                typeof this.getFeatures[key] !== 'undefined'
                    ? this.getFeatures[key]
                    : default_data().structural[key]
        }
        if (this.getFeatures.f_touching_sides !== null) {
            this.structural.touching_sides = Math.max(
                4 - this.getFeatures.f_touching_sides
            )
        } else {
            this.missing_fields.push('f_touching_sides')
        }
        if (this.getFeatures.is_epc_number === null) {
            this.structural.is_epc_number = true
        }
        if (
            this.getFeatures.f_bedrooms < 1 ||
            typeof this.getFeatures.f_bedrooms !== 'number'
        ) {
            this.apartment.f_bedrooms = 0
        }
        if (this.getFeatures.renovation_cost) {
            this.structural.has_renovation = true
        }
        if (this.purpose == 'refinancing') {
            this.structural.refinancing_disclaimer = true
        }

        this.$axios
            .get(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    '/request',
                    this.getRequestRef,
                    '/documents',
                ])
            )
            .then((res) => {
                for (let document of res.data) {
                    this.$set(this.documents, document.document_ref, document)
                }
            })
        this.$axios
            .get(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    '/request',
                    this.getRequestRef,
                    '/renovation-items',
                ])
            )
            .then((res) => {
                this.renovation_items = res.data
            })
    },
    methods: {
        onLeaving(next) {
            if (this.$v.$anyDirty && !this.isExitingModalActive) {
                this.cancelFn = () => next(false)
                this.proceedFn = () => next()
                this.isExitingModalActive = true
            } else {
                next()
            }
        },
        ...mapMutations('valuationStore', ['UPDATE_STATUS']),
        ...mapMutations('valuationStore/Address', ['SET_FEATURES', 'SET_OTHER_DATA']),
        ...mapActions('valuationStore', ['load_valuation_request']),
        prev_page() {
            if (!this.isDraft || this.isExtraInfoEdited) {
                this.$router.push({
                    params: {
                        lang: this.$route.params.lang,
                        action: 'view-building-parcels',
                    },
                    query: this.$route.query,
                })
            } else if (
                this.buildingType === 'house' &&
                !this.getFeatures.buildingnotfound
            ) {
                this.$router.push({
                    params: {
                        lang: this.$route.params.lang,
                        action: 'select-parcels',
                    },
                    query: this.$route.query,
                })
            } else if (
                this.buildingType === 'apartment' ||
                (this.buildingType === 'house' && this.getFeatures.buildingnotfound)
            ) {
                this.$router.push({
                    params: {
                        lang: this.$route.params.lang,
                        action: 'select-main-building',
                    },
                    query: this.$route.query,
                })
            } else {
                this.$router.push({
                    params: {
                        lang: this.$route.params.lang,
                        action: 'select-extra-parcels',
                    },
                    query: this.$route.query,
                })
            }
        },
        next_page() {
            let next_route = {}
            if (this.isWhitelabelOVM) {
                next_route = {
                    name: 'valuation.valuer-app.general',
                    query: this.$route.query,
                    params: this.$route.params,
                }
            } else if (this.isBankOVM) {
                next_route = {
                    name: 'valuation.request',
                    params: { action: 'ovm' },
                    query: this.$route.query,
                }
            } else if (this.$config.FEEDBACK_FORM === 'always') {
                next_route = {
                    params: { action: 'feedback' },
                    query: {
                        valuation_request_ref: this.getRequestRef,
                    },
                }
            } else if (this.getValuationType === 'avm') {
                next_route = { name: 'valuation.report', query: this.$route.query }
            } else if (this.getValuationType === 'dvm') {
                next_route = { name: 'dashboard' }
            }
            this.$router.push(next_route)
        },
        increment() {
            this.form.number += 1
        },
        decrement() {
            if (this.form.number) {
                this.form.number -= 1
            }
        },
        is_incoherent(feature) {
            return (
                this.getFullBuildingData?.dvm_rejections.length &&
                this.getFullBuildingData.dvm_rejections[
                    this.getFullBuildingData.dvm_rejections.length - 1
                ].incoherent_features &&
                this.getFullBuildingData.dvm_rejections[
                    this.getFullBuildingData.dvm_rejections.length - 1
                ].incoherent_features.hasOwnProperty(feature) &&
                this.isDraft
            )
        },
        async submitForm(action, notify = false) {
            if (this.isDraft) {
                this.sending = true
                let features = {}
                for (let feature of [
                    'f_construction_year',
                    'f_epc_numeric',
                    'f_epc_label',
                    'is_epc_number',
                    'epc_unavailable',
                    'f_living_area',
                    'renovation_cost',
                    'construction_cost',
                    'parcel_cost',
                ]) {
                    features[feature] = this.$store.state.valuationStore.Address.features[
                        feature
                    ]
                }
                if (this.is_apartment) {
                    for (let feature of [
                        'f_bedrooms',
                        'f_balcony_present',
                        'f_bottom_floor',
                        'f_top_floor',
                        'f_floors',
                        'f_garden_common',
                        'f_garden_private',
                        'f_n_closed_garage',
                        'f_n_parking_spot',
                        'f_cellar_attic',
                    ]) {
                        features[
                            feature
                        ] = this.$store.state.valuationStore.Address.features[feature]
                    }
                }
                if (this.show_touching_sides) {
                    features.f_touching_sides = this.$store.state.valuationStore.Address.features.f_touching_sides
                }
                if (this.show_parcel_area) {
                    features.f_parcel_area = this.$store.state.valuationStore.Address.features.f_parcel_area
                }
                if (this.show_building_area) {
                    features.f_building_area = this.$store.state.valuationStore.Address.features.f_building_area
                    features.f_garden_area =
                        this.$store.state.valuationStore.Address.features.f_parcel_area -
                        this.$store.state.valuationStore.Address.features.f_building_area
                }
                if (this.is_building && !this.structural.is_epc_number) {
                    let epc =
                        enums.epc_mapping[this.getFeatures.region][
                            features.f_epc_label
                        ] ?? null
                    features.f_epc_numeric = epc
                    features.f_epc = epc
                }
                if (this.is_new && !features.f_epc) {
                    features.f_epc_numeric = 99
                    features.f_epc = 99
                }
                let other_data = {
                    extra_info_edited: true,
                }
                await this.$axios.patch(
                    utils.val_urls(this.$config).request_ref(this.getRequestRef),
                    { features, other_data }
                )
                await this.$axios.put(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'v2',
                        'request',
                        this.getRequestRef,
                        'purpose',
                    ]),
                    null,
                    {
                        params: {
                            purpose: this.structural.refinancing_disclaimer
                                ? 'refinancing'
                                : 'new_loan',
                        },
                    }
                )
                this.$v.$reset()
                this.SET_OTHER_DATA(other_data)
                if (action === 'submit') {
                    await this.$axios.put(
                        utils
                            .val_urls(this.$config)
                            .request_ref_status(this.getRequestRef),
                        null,
                        { params: { lang: this.$i18n.locale, action: 'submit' } }
                    )
                    this.UPDATE_STATUS({
                        status: 'submitted',
                    })
                    if (this.isWhitelabelOVM) {
                        await this.$axios.put(
                            utils
                                .val_urls(this.$config)
                                .request_ref_valuer(this.getRequestRef),
                            null,
                            { params: { username: this.getUsername } }
                        )
                        this.load_valuation_request()
                    } else if (this.getValuationType === 'avm') {
                        this.load_valuation_request()
                    }
                    this.next_page()
                }
                this.sending = false
                if (notify) {
                    this.$buefy.snackbar.open({
                        message: this.$t('saved'),
                        position: this.isMobile ? 'is-top' : 'is-bottom',
                        type: 'is-primary',
                    })
                }
            }
        },
        toggleFloorDiagram(shouldShow = null) {
            if (shouldShow != null) this.showFloorDiagram = shouldShow
            else this.showFloorDiagram = !this.showFloorDiagram
        },
        toggleFloor(floor) {
            if (floor === 'xx') return
            if (floor.toString().includes('x')) {
                utils.arrayToggle(this.apartment.f_floors, +floor[0])
                return
            }
            utils.arrayToggle(this.apartment.f_floors, floor)
        },
        closeUploadModal() {
            this.isUploadModalActive = false
            this.documents.pending_docs = []
        },
        showUploadModal() {
            this.isUploadModalActive = true
        },
        remove_document(document) {
            this.$axios
                .delete(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'document',
                        document.document_ref,
                    ])
                )
                .then((_) => {
                    this.$delete(this.documents, document.document_ref)
                })
        },
        upload_document(purpose) {
            let formData = new FormData()
            formData.append('attachment', this.pending_document)
            this.uploading = true
            this.$axios
                .post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        '/request',
                        this.getRequestRef,
                        '/documents',
                    ]),
                    formData,
                    { params: { purpose: purpose } }
                )
                .then((response) => {
                    for (let document of response.data) {
                        this.$set(this.documents, document.document_ref, document)
                    }
                    this.uploading = false
                    this.pending_document = null
                })
        },
        documentURL(document_ref) {
            return (
                utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                    this.$config.VALUATION_API_URL,
                    'document',
                    document_ref,
                ]) + '?view=true'
            )
        },
        exact_value_formatter: utils.exact_value_formatter,
    },
}
</script>

<style lang="scss">
@import '~bulma/sass/utilities/functions';

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.required-checkbox {
    color: red;
}

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}
.is-modal-message {
    max-width: 400px;
    display: inline-block;
}
.media {
    align-items: center !important;
}
.animation-content {
    z-index: 98;
}
.exit-modal {
    padding: 3rem;
    background: white;
    z-index: 99;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 14px 0px #002f501a;

    h2.title {
        margin-top: 0 !important;
        padding-bottom: 0 !important;
    }

    p {
        margin-bottom: 24px;
        font-size: 16px;
    }

    div {
        display: flex;
        justify-content: center;

        button {
            margin: 0 5px;
        }
    }
}

.is-floors-section {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background: transparent;
    transition: background-color 0.2s ease-in-out;

    &.diagram-is-shown {
        background: #0096ff19;
        border-radius: 10px;
    }

    .column:first-child,
    .column:nth-child(3) {
        padding-left: 0.75rem !important;
    }
    .column:nth-of-type(2) {
        padding-left: 2.4rem !important;
    }
}

.is-uploading-icon {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.is-unit {
    height: auto;
    position: relative;
    bottom: 0.4rem;
}

.upload-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .file-icon {
        height: 37px;
        width: auto;
    }

    .upload-name {
        flex-grow: 1;
        color: var(--black);
    }

    .action-button {
        height: 2.5rem;
        width: 2.5rem;
        background: #99a7cc33;
        color: #99a7cc99;
        padding: 0;
        margin-left: 1rem;

        & > span {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            background: #99a7cc66;
            color: #99a7ccff;
        }
    }
}
.document-upload-modal {
    border-radius: 0 0 6px 6px;

    .file-upload {
        display: flex;
        flex-direction: column;
        width: 100%;

        .file-cta {
            color: var(--primary) !important;
            border-color: var(--primary) !important;
            background: white !important;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                color: white !important;
                background: var(--primary) !important;
            }

            .icon {
                margin-left: 10px;
            }
        }
    }
}

.is-w100 {
    width: 100%;
}

.upload-row {
    gap: 0.5rem;

    .file-upload .file-cta {
        border-color: var(--primary) !important;
        background: var(--primary) !important;
        color: var(--white) !important;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;

        &:hover {
            background: var(--white) !important;
            color: var(--primary) !important;
        }

        &.disabled {
            background: var(--primary-light);
        }
    }
}

.upload-dropdown {
    & > .dropdown-trigger {
        width: 100%;
    }
    & > .dropdown-menu {
        width: 100%;

        .dropdown-content {
            box-shadow: 0 2px 14px 0 rgba(var(--primary), 0.4);
            background-color: var(--primary) !important;
        }

        .is-flex {
            gap: 1rem;
        }
    }
}

.separator {
    height: 1px !important;
    background-color: lightgrey;
    padding: 0 !important;
}

.button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.button-group .button {
    margin: 0.5rem;
}
</style>
