<template>
    <div
        :class="{
            'sidebar-section': true,
            'is-toggle': isToggle,
            interactive: hasClickListener,
            active: active,
        }"
        @click="$emit('click')"
    >
        <h2 class="sidebar-section-title">
            {{ title }}
        </h2>
        <div
            v-if="isMobile"
            :class="{
                'chevron-badge': true,
                'chevron-toggle': isToggle,
            }"
        >
            <img :src="chevron" :class="{ toggled: toggled, chevron: true }" />
        </div>
        <img
            v-else-if="isToggle"
            :src="chevron"
            :class="{ toggled: toggled, chevron: true }"
        />
    </div>
</template>
<script>
import chevron from '@/shared/assets/icons/chevron.svg'
import { mapGetters } from 'vuex'

export default {
    name: 'SidebarSection',
    props: {
        title: {
            type: String,
            required: true,
        },
        isToggle: {
            type: Boolean,
            default: false,
        },
        toggled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        chevron,
    }),
    computed: {
        ...mapGetters(['isMobile']),
        hasClickListener() {
            return this.$listeners && this.$listeners.click
        },
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.sidebar-section {
    border-bottom: 1px solid rgba(214, 226, 233, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 0 16px;
    background: #fff;

    @media screen and (max-width: 999px) {
        flex-shrink: 0;
    }

    .sidebar-section-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        color: #001837b2;
        transition: all ease-out 0.2s;
    }

    &.interactive {
        &:hover {
            cursor: pointer;
        }

        &:not(.is-toggle) {
            border-right: 0px solid var(--color-primary);
            transition: all ease-out 0.2s;

            &:hover {
                border-right: 4px solid var(--color-primary);
            }

            @media screen and (max-width: 999px) {
                .sidebar-section-title {
                    color: #001837;
                }
            }
        }
    }

    &.active {
        border-right: 4px solid var(--color-primary) !important;
        background: #f4f6f9;

        .sidebar-section-title {
            color: var(--color-primary);
        }
    }
}

.chevron-badge {
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: rgba(121, 141, 166, 0.15);
    align-items: center;
    justify-content: center;

    &:not(.chevron-toggle) {
        img.chevron {
            transform: rotate(-90deg);
        }
    }
}

img.chevron {
    width: 8px;
    transform-origin: center;
    &.toggled {
        transform: scaleY(-1);
    }
}
</style>
