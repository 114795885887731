<template>
    <svg
        width="100%"
        viewBox="0 0 1141 35"
        :class="{ interactive: interactive }"
        class="epc_array"
    >
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('F') }"
            @click="$emit('select-epc', 'F')"
        >
            <path
                d="M0 0H562.425L571.894 17.0432L562.425 34.0864H0L9.46844 17.0432L0 0Z"
                fill="#d62015"
                class="epc_arrow-bg"
            />
            <path
                d="M283.728 16.84H289.104V18.92H283.728V16.84ZM283.92 23H281.328V11.8H289.792V13.88H283.92V23Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('E') }"
            @click="$emit('select-epc', 'E')"
        >
            <path
                d="M568 0H657.003L666.472 17.0432L657.003 34.0864H568L577.468 17.0432L568 0Z"
                fill="#fa9101"
                class="epc_arrow-bg"
            />
            <path
                d="M615.712 16.312H621.104V18.328H615.712V16.312ZM615.904 20.92H622V23H613.328V11.8H621.792V13.88H615.904V20.92Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('D') }"
            @click="$emit('select-epc', 'D')"
        >
            <path
                d="M663 0H752.003L761.472 17.0432L752.003 34.0864H663L672.468 17.0432L663 0Z"
                fill="#fdd400"
                class="epc_arrow-bg"
            />
            <path
                d="M706.719 23V11.8H711.807C713.023 11.8 714.095 12.0347 715.023 12.504C715.951 12.9627 716.676 13.608 717.199 14.44C717.721 15.272 717.983 16.2587 717.983 17.4C717.983 18.5307 717.721 19.5173 717.199 20.36C716.676 21.192 715.951 21.8427 715.023 22.312C714.095 22.7707 713.023 23 711.807 23H706.719ZM709.311 20.872H711.679C712.425 20.872 713.071 20.7333 713.615 20.456C714.169 20.168 714.596 19.7627 714.895 19.24C715.204 18.7173 715.359 18.104 715.359 17.4C715.359 16.6853 715.204 16.072 714.895 15.56C714.596 15.0373 714.169 14.6373 713.615 14.36C713.071 14.072 712.425 13.928 711.679 13.928H709.311V20.872Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('C') }"
            @click="$emit('select-epc', 'C')"
        >
            <path
                d="M757 0H846.003L855.472 17.0432L846.003 34.0864H757L766.468 17.0432L757 0Z"
                fill="#fdf300"
                class="epc_arrow-bg"
            />
            <path
                d="M807.821 23.192C806.957 23.192 806.151 23.0533 805.405 22.776C804.669 22.488 804.029 22.0827 803.485 21.56C802.941 21.0373 802.514 20.424 802.205 19.72C801.906 19.016 801.757 18.2427 801.757 17.4C801.757 16.5573 801.906 15.784 802.205 15.08C802.514 14.376 802.941 13.7627 803.485 13.24C804.039 12.7173 804.685 12.3173 805.421 12.04C806.157 11.752 806.962 11.608 807.837 11.608C808.807 11.608 809.682 11.7787 810.461 12.12C811.25 12.4507 811.911 12.9413 812.445 13.592L810.781 15.128C810.397 14.6907 809.97 14.3653 809.501 14.152C809.031 13.928 808.519 13.816 807.965 13.816C807.442 13.816 806.962 13.9013 806.525 14.072C806.087 14.2427 805.709 14.488 805.389 14.808C805.069 15.128 804.818 15.5067 804.637 15.944C804.466 16.3813 804.381 16.8667 804.381 17.4C804.381 17.9333 804.466 18.4187 804.637 18.856C804.818 19.2933 805.069 19.672 805.389 19.992C805.709 20.312 806.087 20.5573 806.525 20.728C806.962 20.8987 807.442 20.984 807.965 20.984C808.519 20.984 809.031 20.8773 809.501 20.664C809.97 20.44 810.397 20.104 810.781 19.656L812.445 21.192C811.911 21.8427 811.25 22.3387 810.461 22.68C809.682 23.0213 808.802 23.192 807.821 23.192Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('B') }"
            @click="$emit('select-epc', 'B')"
        >
            <path
                d="M852 0H941.003L950.472 17.0432L941.003 34.0864H852L861.468 17.0432L852 0Z"
                fill="#d8db1a"
                class="epc_arrow-bg"
            />
            <path
                d="M896.711 23V11.8H902.183C903.591 11.8 904.647 12.0667 905.351 12.6C906.065 13.1333 906.423 13.8373 906.423 14.712C906.423 15.2987 906.279 15.8107 905.991 16.248C905.703 16.6747 905.308 17.0053 904.807 17.24C904.305 17.4747 903.729 17.592 903.079 17.592L903.383 16.936C904.087 16.936 904.711 17.0533 905.255 17.288C905.799 17.512 906.22 17.848 906.519 18.296C906.828 18.744 906.983 19.2933 906.983 19.944C906.983 20.904 906.604 21.656 905.847 22.2C905.089 22.7333 903.975 23 902.503 23H896.711ZM899.287 21.048H902.311C902.983 21.048 903.489 20.9413 903.831 20.728C904.183 20.504 904.359 20.152 904.359 19.672C904.359 19.2027 904.183 18.856 903.831 18.632C903.489 18.3973 902.983 18.28 902.311 18.28H899.095V16.392H901.863C902.492 16.392 902.972 16.2853 903.303 16.072C903.644 15.848 903.815 15.512 903.815 15.064C903.815 14.6267 903.644 14.3013 903.303 14.088C902.972 13.864 902.492 13.752 901.863 13.752H899.287V21.048Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('A') }"
            @click="$emit('select-epc', 'A')"
        >
            <path
                d="M947 0H1036L1045.47 17.0432L1036 34.0864H947L956.468 17.0432L947 0Z"
                fill="#9ade27"
                class="epc_arrow-bg"
            />
            <path
                d="M990.231 23L995.223 11.8H997.783L1002.79 23H1000.07L995.975 13.112H996.999L992.887 23H990.231ZM992.727 20.6L993.415 18.632H999.175L999.879 20.6H992.727Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('A+') }"
            @click="$emit('select-epc', 'A+')"
        >
            <path
                d="M1042 0H1131L1140.47 17.0432L1131 34.0864H1042L1051.47 17.0432L1042 0Z"
                fill="#00bd00"
                class="epc_arrow-bg"
            />
            <path
                d="M1080.13 23L1085.12 11.8H1087.68L1092.69 23H1089.97L1085.87 13.112H1086.9L1082.79 23H1080.13ZM1082.63 20.6L1083.31 18.632H1089.07L1089.78 20.6H1082.63ZM1095.91 21.096V13.704H1097.97V21.096H1095.91ZM1093.14 18.376V16.424H1100.76V18.376H1093.14Z"
                class="epc_arrow-label"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'EpcArrayFlanders',
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        currentLabels: {
            type: Array,
            default: () => [],
        },
    },
}
</script>
<style lang="scss" scoped>
@import './epcArrayStyle.scss';
</style>
