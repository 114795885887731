<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.living_area.question') }}
                <Tooltip
                    :label="
                        +getFeatures.effective_level > 2
                            ? $t('energy.living_area.lvl_3.tooltip')
                            : $t('energy.living_area.lvl_2.tooltip')
                    "
                />
            </h1>
        </div>

        <info-bubble
            v-if="+getFeatures.effective_level >= 2"
            :text="
                $t('energy.living_area.bubble_info', {
                    estimated_living_area: getApproxLivingArea,
                })
            "
        />

        <b-field :message="messages" :type="{ 'is-danger': !isValid }">
            <b-input v-model="f_living_area" size="is-medium" class="ers-input-field" />
            <p class="unit-m2" :class="{ 'unit-m2--not-valid': !isValid }">
                {{ $t('unit.m2') }}
            </p>
        </b-field>

        <div class="is-flex is-justify-content-space-between">
            <b-button
                size="is-medium"
                type="is-dark"
                icon-left="arrow-left"
                @click="changePage(false)"
            >
                {{ $t('common.previous') }}
            </b-button>
            <submit-button
                @on-click="
                    $emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    changePage(true)
                "
            />
            <b-button
                v-if="showNextButton"
                :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                size="is-medium"
                type="is-primary"
                icon-right="arrow-right"
                @click="changePage(true)"
            >
                <span>{{ $t('common.next') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { decimal } from 'vuelidate/lib/validators'
import utils from '@/shared/plugins/utils'
import enums from '@/shared/plugins/enums'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton.vue'
import InfoBubble from '@/components/elements/InfoBubble.vue'

export default {
    name: 'LivingArea',
    components: {
        Tooltip,
        SubmitButton,
        InfoBubble,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        f_living_area: null,
        isValid: true,
        messages: [],
    }),

    computed: {
        ...mapGetters('ersStore', [
            'getFloorArea',
            'getBuildingId',
            'getApproxLivingArea',
            'getFeatures',
            'getLivingAreaChanged',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
            'computeProtectedVolume',
        ]),
        showNextButton() {
            return (
                this.f_living_area === null ||
                this.f_living_area.length ||
                this.getFeatures.f_living_area ||
                +this.getFeatures.effective_level > 1
            )
        },
    },
    watch: {
        getFeatures: {
            handler(features) {
                if (+features.effective_level === 3 || +features.effective_level === 2) {
                    this.f_living_area =
                        features.f_living_area ?? this.getApproxLivingArea
                } else {
                    this.f_living_area = 0
                }
            },
            deep: true,
        },
        f_living_area(newVal, oldVal) {
            if (!this.isValid) {
                if (newVal >= 50) {
                    this.messages = this.messages.filter(
                        (mess) => mess !== this.$t('validation.only_numbers')
                    )
                }
                if (decimal(newVal)) {
                    this.messages = this.messages.filter(
                        (mess) => mess !== this.$t('validation.value_too_small')
                    )
                }
            }
            if (!this.messages.length) {
                this.isValid = true
            }
        },
    },
    mounted() {
        if (+this.getFeatures.effective_level >= 2) {
            this.f_living_area = this.getFeatures.f_living_area
                ? this.getFeatures.f_living_area
                : this.getApproxLivingArea
        } else {
            this.f_living_area = this.getFeatures.f_living_area
                ? this.getFeatures.f_living_area
                : 0
        }
    },
    methods: {
        ...mapMutations('ersStore', [
            'UPDATE_STATUS',
            'SET_FEATURES',
            'SET_LIVING_AREA_CHANGED',
            'SET_LIVING_AREA',
        ]),
        ...mapActions('ersStore', ['submitFeatures', 'fetch_features', 'track_event']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        changePage(isNextPage) {
            this.isValid = true
            this.messages = []
            if (!decimal(this.f_living_area)) {
                this.isValid = false
                this.messages.push(this.$t('validation.only_numbers'))
            }
            if (+this.f_living_area < 50) {
                this.isValid = false
                this.messages.push(this.$t('validation.value_too_small'))
            }

            if (!this.isValid) return

            let feature = {
                f_living_area: +this.f_living_area,
            }
            this.SET_LIVING_AREA(+this.f_living_area)

            feature.protected_volume = this.computeProtectedVolume

            if (
                this.getFeatures.effective_level >= 2 &&
                this.getApproxLivingArea !== +this.f_living_area &&
                this.$config.TRACK
            ) {
                this.track_event({
                    event: 'change_living_area',
                    route: this.$route,
                    data: {
                        old_value: this.getApproxLivingArea,
                        new_value: +this.f_living_area,
                    },
                })
            }

            if (+this.getFeatures.effective_level === 3) {
                const percentDiff = utils.percentChange(
                    this.getApproxLivingArea,
                    +this.f_living_area
                )
                if (percentDiff > 20) {
                    feature.protected_volume =
                        +this.f_living_area * enums.const.floor_height
                    feature.effective_level = '2'

                    if (!this.getLivingAreaChanged) {
                        feature.initial_living_area = +this.getApproxLivingArea
                        feature.initial_features = {
                            f_living_area: +this.getApproxLivingArea,
                            f_top_floor: this.getFeatures.f_top_floor,
                            f_percent_of_roof_flat: this.getFeatures
                                .f_percent_of_roof_flat,
                            f_neighbour_type: this.getFeatures.f_neighbour_type,
                        }
                    }

                    this.SET_LIVING_AREA_CHANGED(true)
                }
            } else {
                if (this.getLivingAreaChanged) {
                    const initialLivingArea = this.getFeatures.initial_features
                        .f_living_area

                    const percentDiff = utils.percentChange(
                        initialLivingArea,
                        +this.f_living_area
                    )
                    if (percentDiff < 20) {
                        feature.effective_level = '3'
                        feature.f_top_floor = this.getFeatures.initial_features.f_top_floor
                        feature.f_percent_of_roof_flat = this.getFeatures.initial_features.f_percent_of_roof_flat
                        feature.f_neighbour_type = this.getFeatures.initial_features.f_neighbour_type
                        this.SET_LIVING_AREA_CHANGED(false)
                    }
                }
            }

            let nextPage

            if (isNextPage) {
                nextPage = `ers.${
                    this.getLivingAreaChanged ? 'building-dimensions' : 'epc'
                }`
            } else {
                nextPage =
                    +this.getFeatures.effective_level === 3 || this.getLivingAreaChanged
                        ? `ers.basement`
                        : 'ers.building-dimensions'
            }

            this.set_and_submit_feature({
                feature,
                nextPage,
            })

            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
.ers-input-field {
    min-width: 100% !important;
}
</style>

<style scoped lang="scss">
.ers-page {
    background: #f4f6f9;
    max-width: 100vw;
    flex-grow: 1;

    &-content {
        padding: 1.25rem 3.75rem;
        display: flex;
        flex-direction: column;
        gap: 2.38rem;
    }

    &-header {
        width: 100%;
        padding: 60px;
        min-height: 180px;
        background: linear-gradient(180deg, rgba(249, 250, 252, 0) 0%, #f4f6f9 100%),
            url('../../../../../assets/img/city.jpg') no-repeat,
            lightgray 0px -250px / 100% 356.25% no-repeat;
        background-size: cover;
        background-position: center right;
    }

    &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        p {
            color: #798da6;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &-info {
        display: flex;
        padding: 1rem;
        align-items: center;
        gap: 1rem;
        border-radius: 0.3125rem;
        background: rgba(0, 156, 255, 0.1);
        max-width: fit-content;
        color: #6583a8;

        img {
            display: flex;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.625rem;
            justify-content: center;
            margin-inline-start: 0.75rem;
            align-items: center;
            gap: 0.625rem;
            border-radius: 3.125rem;
            background: rgba(0, 156, 255, 0.2);
        }
    }

    h1 {
        color: #001837;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.unit-m2 {
    display: grid;
    align-items: center;
    transform: translateX(-150%);
    z-index: 4;
    &--not-valid {
        transform: translateX(-300%);
    }
}
</style>
