<template>
    <svg viewBox="0 0 33 23.71">
        <path d="m0,0h27.75l5.25,11.85-5.25,11.85H0l5.25-11.85L0,0Z" :fill="color" />
    </svg>
</template>
<script>
export default {
    name: 'EpcBadge',
    props: {
        color: {
            type: String,
            required: true,
        },
    },
}
</script>
