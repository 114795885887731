var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card",attrs:{"id":"toc"}},[_c('div',{staticClass:"card-content content is-small has-text-centered"},[_c('div',{staticClass:"has-text-left"},[_c('b-button',{staticClass:"mt-1",attrs:{"size":"is-normal","type":"is-primary","icon-left":"close","tag":"router-link","to":{
                        name: 'ers.action',
                        params: {
                            action: '2d',
                            lang: _vm.$route.params.lang,
                        },
                        query: _vm.$route.query,
                    }}}),_c('a',{attrs:{"href":_vm.reportURL}},[_c('b-button',{staticClass:"mt-1",attrs:{"size":"is-normal","type":"is-primary","icon-left":"download"}})],1)],1)])]),_c('div',{staticClass:"has-text-centered is-size-7",attrs:{"id":"report-vue"}},[_c('div',{staticClass:"page is-flex is-flex-direction-column",attrs:{"id":"page-general"}},[_c('div',{staticClass:"section content pt-5 px-5 pb-2"},[_c('div',{staticClass:"columns is-multiline has-text-left"},[_vm._m(0),_c('div',{staticClass:"column is-6 has-text-right is-size-6 pb-0"},[_c('div',{staticClass:"has-text-weight-bold has-text-black"},[_vm._v(" "+_vm._s(_vm.$t('front.core_title'))+" ")])]),_c('div',{staticClass:"column is-12 py-2"},[_c('p',{staticClass:"is-size-7 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('address_text.full_address'))+" ")]),_c('p',{staticClass:"has-text-weight-bold has-text-black is-size-7"},[_vm._v(" "+_vm._s(_vm.getAddress.full_address)+" ")])])]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('report-section',{attrs:{"name":"property_type"}}),_c('report-section',{attrs:{"name":'house_info'}}),_c('report-section',{attrs:{"columns":2,"name":'insulation'}}),_c('report-section',{attrs:{"name":"consumption"}})],1)])]),_c('report-footer')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-6 has-text-left pb-0"},[_c('img',{attrs:{"width":"150px","src":require("@/shared/assets/icons/rockestate-logo-horizontal-dark.svg")}})])
}]

export { render, staticRenderFns }