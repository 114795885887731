var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.living_area.question'))+" "),_c('Tooltip',{attrs:{"label":+_vm.getFeatures.effective_level > 2
                        ? _vm.$t('energy.living_area.lvl_3.tooltip')
                        : _vm.$t('energy.living_area.lvl_2.tooltip')}})],1)]),(+_vm.getFeatures.effective_level >= 2)?_c('info-bubble',{attrs:{"text":_vm.$t('energy.living_area.bubble_info', {
                estimated_living_area: _vm.getApproxLivingArea,
            })}}):_vm._e(),_c('b-field',{attrs:{"message":_vm.messages,"type":{ 'is-danger': !_vm.isValid }}},[_c('b-input',{staticClass:"ers-input-field",attrs:{"size":"is-medium"},model:{value:(_vm.f_living_area),callback:function ($$v) {_vm.f_living_area=$$v},expression:"f_living_area"}}),_c('p',{staticClass:"unit-m2",class:{ 'unit-m2--not-valid': !_vm.isValid }},[_vm._v(" "+_vm._s(_vm.$t('unit.m2'))+" ")])],1),_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":function($event){return _vm.changePage(false)}}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                    unsubmit: false,
                })
                _vm.changePage(true)}}}),(_vm.showNextButton)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":function($event){return _vm.changePage(true)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }