<template>
    <b-button
        v-if="hasRequestBeenUnsubmitted || getFeatures.has_big_energy_consumers !== null"
        class="ers-bttn-submit"
        size="is-medium"
        type="is-primary"
        @click="$emit('on-click')"
    >
        <span class="ers-bttn-submit-text">
            <svg-icon icon="submit" color="#fff" height="20" />
            {{ $t('ers_report.submit_button_budget') }}
        </span>
    </b-button>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
export default {
    name: 'SubmitButton',
    computed: {
        ...mapGetters('ersStore', [
            'getOtherData',
            'hasRequestBeenUnsubmitted',
            'getFeatures',
        ]),
    },
}
</script>

<style lang="scss">
.ers-bttn-submit {
    margin-inline: auto 1rem;
}
</style>

<style lang="scss" scoped>
.ers-bttn-submit {
    &-text {
        display: flex;
        align-items: center;
        gap: 8px;

        &-icon {
            margin-inline-end: 0.75rem;
        }
    }
}
</style>
