/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ers': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.5 1.332H5.663c-.12 0-.18 0-.233.018a.333.333 0 00-.124.077c-.04.04-.067.093-.12.2l-2.8 5.6c-.128.255-.192.383-.177.487.014.09.064.172.139.225.086.06.229.06.515.06H7l-2 6.666 8.128-8.43c.275-.284.412-.426.42-.548a.333.333 0 00-.118-.276c-.093-.079-.29-.079-.685-.079H8l1.5-4z" _fill="#FFB800" _stroke="#FFB800" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
