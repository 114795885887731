/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rooms': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path d="M11 3V18H0V16H2V0H11V1H16V16H18V18H14V3H11ZM7 8V10H9V8H7Z"/>'
  }
})
