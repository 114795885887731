/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'monitor': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path d="M21.3,29.3H10.7c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h4V24H9.1c-2.5,0-3.7,0-4.8-0.6 c-1-0.5-1.8-1.3-2.3-2.3c-0.6-1.1-0.6-2.4-0.6-4.8v-5.9c0-2.5,0-3.7,0.6-4.8c0.5-1,1.3-1.8,2.3-2.3c1.1-0.6,2.4-0.6,4.8-0.6h13.9 c2.5,0,3.7,0,4.8,0.6c1,0.5,1.8,1.3,2.3,2.3c0.6,1.1,0.6,2.4,0.6,4.8v5.9c0,2.5,0,3.7-0.6,4.8c-0.5,1-1.3,1.8-2.3,2.3 C26.6,24,25.4,24,22.9,24h-5.6v2.7h4c0.7,0,1.3,0.6,1.3,1.3S22.1,29.3,21.3,29.3z M9.1,5.3c-2,0-3,0-3.6,0.3 C5,5.9,4.5,6.3,4.3,6.8C4,7.4,4,8.4,4,10.4v5.9c0,2,0,3,0.3,3.6C4.5,20.4,5,20.8,5.5,21c0.6,0.3,1.7,0.3,3.6,0.3h13.9 c2,0,3,0,3.6-0.3c0.5-0.3,0.9-0.7,1.2-1.2c0.3-0.6,0.3-1.7,0.3-3.6v-5.9c0-2,0-3-0.3-3.6c-0.3-0.5-0.7-0.9-1.2-1.2 c-0.6-0.3-1.7-0.3-3.6-0.3H9.1z"/>'
  }
})
