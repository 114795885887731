<template>
    <svg
        width="100%"
        viewBox="0 0 1076.8 35"
        :class="{ interactive: interactive }"
        class="epc_array"
    >
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('G') }"
            @click="$emit('select-epc', 'G')"
        >
            <path
                fill="#d62015"
                class="epc_arrow-bg"
                d="M0,0h481.8l9.8,17.5L481.8,35H0l9.8-17.5L0,0z"
            />
            <path
                class="epc_arrow-label"
                d="M246.3,23.9c-0.9,0-1.8-0.1-2.5-0.4c-0.7-0.3-1.4-0.7-2-1.2c-0.5-0.5-1-1.1-1.3-1.9c-0.3-0.7-0.4-1.5-0.4-2.4
		s0.1-1.6,0.4-2.4c0.3-0.7,0.7-1.3,1.3-1.9c0.6-0.5,1.2-0.9,2.1-1.2c0.7-0.3,1.6-0.4,2.6-0.4c1,0,2,0.2,2.8,0.5
		c0.8,0.3,1.5,0.8,2.1,1.4l-1.8,1.5c-0.4-0.4-0.8-0.7-1.3-0.9s-1-0.3-1.6-0.3c-0.5,0-1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.2,0.7
		c-0.3,0.3-0.6,0.7-0.8,1.1c-0.2,0.4-0.3,0.9-0.3,1.5c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.4,0.8,0.8,1.2c0.3,0.3,0.7,0.6,1.2,0.7
		c0.4,0.2,0.9,0.3,1.5,0.3c0.5,0,1-0.1,1.5-0.2c0.5-0.2,1-0.5,1.4-0.8l1.5,2c-0.6,0.5-1.3,0.8-2.2,1.1
		C248,23.8,247.2,23.9,246.3,23.9z M248.6,22v-4.3h2.5v4.6L248.6,22z"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('F') }"
            @click="$emit('select-epc', 'F')"
        >
            <path
                fill="#fa9101"
                class="epc_arrow-bg"
                d="M487.4,0H579l9.8,17.5L579,35h-91.6l9.8-17.5L487.4,0z"
            />
            <path
                class="epc_arrow-label"
                d="M536.6,17.3h5.6v2.2h-5.6V17.3z M536.7,23.7h-2.7V12.1h8.8v2.2h-6.1V23.7z"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('E') }"
            @click="$emit('select-epc', 'E')"
        >
            <path
                fill="#fdd400"
                class="epc_arrow-bg"
                d="M585.2,0h91.6l9.8,17.5L676.8,35h-91.6l9.8-17.5L585.2,0z"
            />
            <path
                class="epc_arrow-label"
                d="M634.5,16.8h5.6v2.1h-5.6V16.8z M634.7,21.5h6.3v2.2h-9V12.1h8.8v2.2h-6.1V21.5z"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('D') }"
            @click="$emit('select-epc', 'D')"
        >
            <path
                fill="#fdf300"
                class="epc_arrow-bg"
                d="M682,0h91.6l9.8,17.5L773.6,35H682l9.8-17.5L682,0z"
            />
            <path
                class="epc_arrow-label"
                d="M728,23.7V12.1h5.2c1.2,0,2.4,0.2,3.3,0.7s1.8,1.1,2.3,2s0.8,1.9,0.8,3.1c0,1.1-0.3,2.2-0.8,3.1c-0.5,0.8-1.2,1.5-2.3,2.1
		c-0.9,0.5-2.1,0.7-3.3,0.7H728V23.7z M730.7,21.5h2.5c0.7,0,1.4-0.1,2-0.4c0.6-0.3,1-0.7,1.3-1.2c0.3-0.5,0.5-1.1,0.5-1.9
		c0-0.7-0.2-1.3-0.5-1.9c-0.3-0.5-0.7-0.9-1.3-1.2c-0.5-0.3-1.2-0.4-2-0.4h-2.5V21.5z"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('C') }"
            @click="$emit('select-epc', 'C')"
        >
            <path
                fill="#d8db1a"
                class="epc_arrow-bg"
                d="M779.8,0h91.6l9.8,17.5L871.4,35h-91.6l9.8-17.5L779.8,0z"
            />
            <path
                class="epc_arrow-label"
                d="M831,23.9c-0.9,0-1.8-0.1-2.5-0.4s-1.4-0.7-2-1.2c-0.5-0.5-1-1.1-1.3-1.9c-0.3-0.7-0.4-1.5-0.4-2.4s0.1-1.6,0.4-2.4
		c0.3-0.7,0.7-1.3,1.3-1.9c0.6-0.5,1.2-0.9,2-1.2s1.5-0.4,2.5-0.4c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.5l-1.8,1.5
		c-0.4-0.4-0.8-0.8-1.3-1s-1-0.3-1.5-0.3s-1,0.1-1.4,0.3s-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.8,1.1c-0.2,0.4-0.3,0.9-0.3,1.5
		s0.1,1,0.3,1.5c0.2,0.4,0.4,0.8,0.8,1.1s0.7,0.6,1.1,0.7s0.9,0.3,1.4,0.3c0.6,0,1.1-0.1,1.5-0.3c0.5-0.2,0.9-0.6,1.3-1l1.8,1.5
		c-0.5,0.7-1.2,1.1-2.1,1.5C833,23.7,832.1,23.9,831,23.9z"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('B') }"
            @click="$emit('select-epc', 'B')"
        >
            <path
                fill="#9ade27"
                class="epc_arrow-bg"
                d="M877.6,0h91.6l9.8,17.5L969.2,35h-91.6l9.8-17.5L877.6,0z"
            />
            <path
                class="epc_arrow-label"
                d="M923.6,23.7V12.1h5.7c1.4,0,2.6,0.3,3.3,0.8c0.7,0.5,1.1,1.2,1.1,2.2c0,0.6-0.1,1.1-0.4,1.5s-0.7,0.8-1.2,1
		s-1.1,0.4-1.8,0.4l0.3-0.7c0.7,0,1.3,0.1,2,0.4c0.5,0.2,1,0.6,1.3,1c0.3,0.4,0.5,1,0.5,1.6c0,1-0.4,1.8-1.1,2.4
		c-0.8,0.5-2,0.8-3.4,0.8C929.9,23.7,923.6,23.7,923.6,23.7z M926.3,21.6h3.1c0.7,0,1.2-0.1,1.5-0.3c0.4-0.2,0.5-0.6,0.5-1.1
		s-0.2-0.8-0.5-1c-0.3-0.2-0.8-0.4-1.5-0.4h-3.3v-2h2.9c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.5-0.6,0.5-1s-0.2-0.8-0.5-1
		c-0.3-0.2-0.8-0.3-1.4-0.3h-2.7V21.6z"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('A') }"
            @click="$emit('select-epc', 'A')"
        >
            <path
                fill="#00bd00"
                class="epc_arrow-bg"
                d="M975.4,0h91.6l9.8,17.5L1067,35h-91.6l9.8-17.5L975.4,0z"
            />
            <path
                class="epc_arrow-label"
                d="M1019.9,23.7l5.1-11.5h2.7l5.1,11.5h-2.8l-4.2-10.2h1l-4.2,10.2H1019.9z M1022.5,21.2l0.7-2.1h6l0.7,2.1H1022.5z"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'EpcArrayBru',
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        currentLabels: {
            type: Array,
            default: () => [],
        },
    },
}
</script>
<style lang="scss" scoped>
@import './epcArrayStyle.scss';
</style>
