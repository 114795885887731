<i18n>
{
    "fr-BE":{
        "errors": {
            "too_few_reference_listings": "Cette localité a trop peu de points de référence dans les environs. Une évaluation a distance est conseillée dans ce cas.",
            "default": "Une erreur inconnue s'est produite. Une évaluation desktop est conseillée dans ce cas."
        }
    },
    "nl-BE":{
        "errors": {
            "too_few_reference_listings": "Deze locatie heeft te weinig referentie punten in de omgeving. Een desktop waardering wordt aanbevolen in dit geval.",
            "default": "Er is een onbekende fout opgetreden. Een desktop waardering wordt aanbevolen in dit geval."
        }
    },
    "en-BE":{
        "errors": {
            "too_few_reference_listings": "This location has too few reference listings in the neighbourhood. A desktop valuation is advised in this case.",
            "default": "An unknown error occured. A desktop valuation is advised in this case."
        }
    }
}
</i18n>

<template>
    <div id="step2" class="map is-relative">
        <div v-if="errorMessage" class="is-absolute message_box">
            <div class="is-relative wrapper">
                <b-message type="is-warning is-size-4">
                    {{
                        $te(`errors.${errorMessage}`)
                            ? $t(`errors.${errorMessage}`)
                            : $t(`errors.default`)
                    }}
                </b-message>
            </div>
        </div>
        <section id="reference_points" class="section">
            <div class="container has-text-centered">
                <div id="map_reference_points"></div>
            </div>
        </section>
    </div>
</template>

<script>
//import { LMap, LTileLayer, LMarker, LPolyline, LLayerGroup, LTooltip, LPopup, LControlZoom,
//        LControlAttribution, LControlScale, LControlLayers } from 'vue2-leaflet';
import L from 'leaflet'
import { mapGetters } from 'vuex'
export default {
    /*components: {
      LMap,
      LTileLayer
    },*/
    data: function() {
        return {
            centre_map: {
                lat: 0,
                lon: 0,
            },
            zoom_level: 0,
            radius_factor: 0,
            //start_render_map:false
        }
    },
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getAddress',
            'errorMessage',
        ]),
    },
    created() {
        //this.get_estimate()
        var imageUrl =
            'https://rockestate.github.io/heatmap/heatmap_png/houses_belgium_vs2.png'
        var imageBounds = [
            [49.500474070569204, 2.5487001241039065],
            [51.50555219550086, 6.402472692976653],
        ]
        // Parse the center lat/lon
        this.centre_map.lat = this.getFeatures.f_lat
        this.centre_map.lon = this.getFeatures.f_lng
        // Set the zoom and the radius factor
        if (this.getAddress.estimationResultsData['max_distance_nn'] > 5000) {
            this.zoom_level = 10
            this.radius_factor = 400
        } else if (
            this.getAddress.estimationResultsData['max_distance_nn'] > 2000 &&
            this.getAddress.estimationResultsData['max_distance_nn'] < 5000
        ) {
            this.zoom_level = 11
            this.radius_factor = 200
        } else if (
            this.getAddress.estimationResultsData['max_distance_nn'] > 500 &&
            this.getAddress.estimationResultsData['max_distance_nn'] < 2000
        ) {
            this.zoom_level = 12
            this.radius_factor = 100
        } else {
            this.zoom_level = 14
            this.radius_factor = 25
        }
        this.$nextTick(function() {
            var map_surrounding_listings = L.map('map_reference_points', {
                touchZoom: true,
                dragging: true,
                doubleClickZoom: true,
                scrollWheelZoom: false,
            }).setView([this.getFeatures.f_lat, this.getFeatures.f_lng], this.zoom_level)
            L.tileLayer(
                `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=${this.$config.MAPBOX_TOKEN}`,
                {
                    attribution:
                        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    maxZoom: 18,
                    id: 'mapbox.light',
                }
            ).addTo(map_surrounding_listings)
            // L.control.scale().addTo(map_surrounding_listings);
            if (this.getAddress.estimationResultsData.reference_listings)
                for (
                    var i = 0;
                    i < this.getAddress.estimationResultsData.reference_listings.length;
                    i++
                ) {
                    var temp_point = this.getAddress.estimationResultsData
                        .reference_listings[i]
                    var circle = L.circle(
                        [parseFloat(temp_point['lat']), parseFloat(temp_point['lng'])],
                        {
                            color: '#1E90FF',
                            opacity: 0,
                            fillColor: '#1E90FF',
                            fillOpacity: 0.5,
                            radius: temp_point['weight'] * this.radius_factor,
                        }
                    ).addTo(map_surrounding_listings)
                }
            // Plot house that we are estimating
            L.marker([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                icon: new L.Icon({
                    iconUrl: require('@/assets/icons/ic-location.svg'),
                    iconOrigin: { x: 0, y: 0 },
                    iconAnchor: {
                        x: 21,
                        y: 57,
                    },
                }),
            }).addTo(map_surrounding_listings)
            // One kilometer circles
            L.circle([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                color: 'darkgrey',
                radius: 1000,
                fillOpacity: 0,
                dashArray: '2 10',
                weight: 2,
            }).addTo(map_surrounding_listings)
            L.circle([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                color: 'darkgrey',
                radius: 2000,
                fillOpacity: 0,
                dashArray: '2 10',
                weight: 2,
            }).addTo(map_surrounding_listings)
            L.circle([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                color: 'darkgrey',
                radius: 3000,
                fillOpacity: 0,
                dashArray: '2 10',
                weight: 2,
            }).addTo(map_surrounding_listings)
            L.circle([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                color: 'darkgrey',
                radius: 4000,
                fillOpacity: 0,
                dashArray: '2 10',
                weight: 2,
            }).addTo(map_surrounding_listings)
            L.circle([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                color: 'darkgrey',
                radius: 5000,
                fillOpacity: 0,
                dashArray: '10 10',
                weight: 2,
            }).addTo(map_surrounding_listings)
            L.circle([this.getFeatures.f_lat, this.getFeatures.f_lng], {
                color: 'darkgrey',
                radius: 10000,
                fillOpacity: 0,
                dashArray: '30 10',
                weight: 2,
            }).addTo(map_surrounding_listings)

            L.layerGroup([L.imageOverlay(imageUrl, imageBounds, { opacity: 0.5 })]).addTo(
                map_surrounding_listings
            )
            // Add legend
            // L.Control.Watermark = L.Control.extend({
            //     onAdd: function(map) {
            //         var img = L.DomUtil.create('img');
            //         img.src = this.$i18n.locale == 'be_nl' ? '/legend_reference_points_nl.png': '/legend_reference_points_fr.png';
            //         img.style.width = '300px';
            //         return img;
            //     }.bind(this),
            //     onRemove: function(map) {
            //         // Nothing to do here
            //     }
            // });
            // L.control.watermark = function(opts) {
            //     return new L.Control.Watermark(opts);
            // }
            // L.control.watermark({ position: 'bottomright' }).addTo(map_surrounding_listings);
        })
    },
}
</script>

<style scoped>
/*#map_surrounding_listings{
  height:500px;
}*/
.map {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
#reference_points {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
#reference_points .container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    height: 100%;
}
#map_reference_points {
    height: 100%;
    width: 100%;
}
.leaflet-image-layer {
    pointer-events: none;
}
.message_box {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999;
    width: 680px;
}
</style>
