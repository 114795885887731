/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M8,15.3C4,15.3,0.7,12,0.7,8c0-3.8,2.8-6.9,6.6-7.3C7.6,0.7,8,0.9,8,1.3C8,1.7,7.8,2,7.4,2 C4.3,2.3,2,4.9,2,8c0,0.1,0,0.1,0,0.2C4.5,8.6,7.2,7.8,9,6H8C7.6,6,7.3,5.7,7.3,5.3S7.6,4.7,8,4.7h2.4c0,0,0,0,0,0h0.2 c0.4,0,0.7,0.3,0.7,0.7V8c0,0.4-0.3,0.7-0.7,0.7S10,8.4,10,8V6.9c-2,2-4.9,3.1-7.8,2.7C2.9,12.1,5.2,14,8,14c3.1,0,5.7-2.3,6-5.4 C14,8.2,14.3,8,14.7,8c0.4,0,0.6,0.4,0.6,0.7C14.9,12.5,11.8,15.3,8,15.3z M14.7,6.3h-1C13.3,6.3,13,6,13,5.7S13.3,5,13.7,5h1 c0.4,0,0.7,0.3,0.7,0.7S15,6.3,14.7,6.3z M13,3.7c-0.2,0-0.3-0.1-0.5-0.2c-0.3-0.3-0.3-0.7,0-0.9l0.7-0.7c0.3-0.3,0.7-0.3,0.9,0 c0.3,0.3,0.3,0.7,0,0.9l-0.7,0.7C13.3,3.6,13.1,3.7,13,3.7z M10.3,3C10,3,9.7,2.7,9.7,2.3v-1c0-0.4,0.3-0.7,0.7-0.7S11,1,11,1.3 v1C11,2.7,10.7,3,10.3,3z"/>'
  }
})
