/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'click-choose': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path class="st1" d="M10.5,19.5c-0.2,0-0.5-0.1-0.7-0.2C9.4,19,9.3,18.7,9.1,18L6.2,8.7C6.1,8.2,6,7.8,6.1,7.4 c0.1-0.3,0.4-0.6,0.7-0.7c0.4-0.1,0.8,0,1.3,0.1l9.4,2.9c0.6,0.2,1,0.3,1.3,0.7c0.2,0.3,0.3,0.8,0.1,1.1c-0.2,0.4-0.5,0.6-1.1,0.9 l-3.8,2.1l-2.1,3.8c-0.3,0.6-0.5,0.9-0.9,1.1C10.8,19.4,10.6,19.5,10.5,19.5z M7.9,8.5l2.7,8.7l1.9-3.5c0.1-0.1,0.1-0.2,0.2-0.2 c0,0,0.2-0.2,0.2-0.2c0.1,0,0.1-0.1,0.3-0.2l3.5-1.9L7.9,8.5z M13.2,14l0.7,0.4c0,0,0,0,0,0L13.2,14z M13.2,14L13.2,14L13.2,14z  M7.2,8.3C7.2,8.3,7.2,8.3,7.2,8.3S7.2,8.3,7.2,8.3z M7.7,7.8L7.7,7.8L7.7,7.8z M3.3,13.1c-0.2,0-0.4-0.1-0.6-0.2 c-0.3-0.3-0.3-0.9,0-1.2l0.9-0.9c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2l-0.9,0.9C3.8,13,3.5,13.1,3.3,13.1z M2.9,8.9H1.7 c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h1.2c0.5,0,0.8,0.4,0.8,0.8S3.4,8.9,2.9,8.9z M10.8,5.6c-0.2,0-0.4-0.1-0.6-0.2 c-0.3-0.3-0.3-0.9,0-1.2l0.9-0.9C11.5,3,12,3,12.3,3.3c0.3,0.3,0.3,0.9,0,1.2l-0.9,0.9C11.3,5.5,11,5.6,10.8,5.6z M4.2,5.6 C4,5.6,3.8,5.5,3.6,5.4L2.7,4.5c-0.3-0.3-0.3-0.9,0-1.2C3.1,3,3.6,3,3.9,3.3l0.9,0.9c0.3,0.3,0.3,0.9,0,1.2 C4.6,5.5,4.4,5.6,4.2,5.6z M7.5,4.3C7,4.3,6.7,3.9,6.7,3.5V2.2c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.2C8.3,3.9,8,4.3,7.5,4.3z "/>'
  }
})
