var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.valid_epc.question'))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('energy.valid_epc.tooltip')}})],1)]),_c('yes-no',{attrs:{"details":[
            _vm.$t('energy.valid_epc.answer_yes'),
            _vm.$t('energy.valid_epc.answer_no'),
        ]},model:{value:(_vm.has_epc_certificate),callback:function ($$v) {_vm.has_epc_certificate=$$v},expression:"has_epc_certificate"}}),_c('div',{staticClass:"column is-12 mt-6"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left","tag":"router-link","to":{
                    name: _vm.getLivingAreaChanged
                        ? 'ers.building-dimensions'
                        : 'ers.living-area',
                    params: {
                        lang: _vm.$route.params.lang,
                    },
                    query: _vm.$route.query,
                }}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    _vm.nextPage()}}}),(_vm.showNextButton)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.nextPage}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }