export const localeMixin = {
    methods: {
        t(locale) {
            const root = this.$config.LOCALE_OVERWRITE.toLowerCase()
            return this.$te(`${root}.${locale}`)
                ? this.$t(`${root}.${locale}`)
                : this.$t(locale)
        },
    },
}
