<template>
    <div class="floorplan_layout">
        <div v-if="!isMobile" class="floorplan_layout-header">
            <h1 class="floorplan_layout-header-breadcrumbs">
                <span>{{ $t('valuer_tool.interior.floors.title') }}</span>
            </h1>
            <b-button
                v-if="!isApartment"
                type="is-primary"
                :icon-right="is_floors_empty ? 'plus' : 'pencil'"
                @click="openFloorsModal"
            >
                {{
                    $t(
                        `valuer_tool.interior.floors.${
                            is_floors_empty ? 'add_floors' : 'edit_floors'
                        }`
                    )
                }}
            </b-button>
        </div>
        <template v-if="is_floors_empty">
            <empty-list type="floors" />
            <b-button
                v-if="!isMobile && showEasyAptButton"
                type="is-primary"
                class="add-apt--buttn"
                icon-right="plus"
                @click="initAptFloors"
            >
                {{ $t('valuer_tool.interior.floors.add_apt_floors') }}
            </b-button>
        </template>
        <div v-else class="floors">
            <div
                v-for="(floor, i) in floors"
                :key="`floor_${i}`"
                class="floor"
                @click.self="
                    () => {
                        if (!isMobile) {
                            goToFloor(floor.level)
                        } else return
                    }
                "
            >
                <div class="floor-left_side">
                    <h2>
                        {{ $t('valuer_tool.interior.floors.floor') + ' ' + floor.level }}
                    </h2>
                    <div class="floor-rooms_badge">
                        <h3>{{ floor.rooms.length }}</h3>
                    </div>
                    <template v-if="isMobile">
                        <div class="floor-mobile_spacer" />
                        <icon-button icon="delete" @click="openDeleteModal(i)" />
                        <!-- <div v-if="isMobile" class="floor-mobile_actions">
                            <img :src="chevron" class="chevron" />
                        </div> -->
                    </template>
                </div>
                <div class="floor-right_side">
                    <span class="floor-area_field">
                        <label>{{ $t('features.f_living_area') }}</label>
                        <b-input v-model.number="floors[i].living_area" type="number" />
                    </span>
                    <template v-if="!isMobile">
                        <icon-button icon="delete" @click="openDeleteModal(i)" />
                        <img :src="chevron" class="chevron" />
                    </template>
                </div>
                <b-button
                    v-if="isMobile"
                    type="is-primary"
                    class="go_floor--buttn"
                    @click="goToFloor(floor.level)"
                >
                    {{ $t('valuer_tool.interior.floors.go_to_floor') }}
                </b-button>
            </div>
        </div>
        <div v-if="isMobile" class="mobile_actions_container">
            <b-button
                v-if="showEasyAptButton"
                type="is-primary"
                class="add-apt--buttn"
                icon-right="plus"
                @click="initAptFloors"
            >
                {{ $t('valuer_tool.interior.floors.add_apt_floors') }}
            </b-button>
            <b-button
                v-else
                type="is-primary"
                :icon-right="is_floors_empty ? 'plus' : 'pencil'"
                class="add_floors-buttn"
                @click="openFloorsModal"
            >
                {{
                    $t(
                        `valuer_tool.interior.floors.${
                            is_floors_empty ? 'add_floors' : 'edit_floors'
                        }`
                    )
                }}
            </b-button>
        </div>
        <b-modal
            v-if="is_delete_modal_open"
            v-model="is_delete_modal_open"
            destroy-on-hide
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            @close="closeDeleteModal"
        >
            <div class="delete_modal">
                <div class="delete_modal-header">
                    <h4 class="delete_modal-header-title">
                        {{
                            $t('valuer_tool.interior.floors.delete_floor.title') +
                                ' ' +
                                floors[deletingFloor].level
                        }}
                    </h4>
                </div>
                <div v-if="deletingRooms.length" class="delete_modal-content">
                    <h3>
                        {{
                            $t('valuer_tool.interior.floors.delete_floor.select_transfer')
                        }}
                    </h3>
                    <div class="floors">
                        <div
                            class="floor"
                            :class="{ 'floor--active': targetFloorIndex === null }"
                            @click="setTargetFloor(null)"
                        >
                            <div class="floor-left_side">
                                <h2>
                                    {{
                                        $t(
                                            'valuer_tool.interior.floors.delete_floor.no_transfer'
                                        )
                                    }}
                                </h2>
                            </div>
                        </div>
                        <template v-for="(floor, i) in floors">
                            <div
                                v-if="i !== deletingFloor"
                                :key="`floor_${i}`"
                                class="floor"
                                :class="{ 'floor--active': targetFloorIndex === i }"
                                @click="setTargetFloor(i)"
                            >
                                <div class="floor-left_side">
                                    <h2>
                                        {{ newFloorLevel(floor) }}
                                    </h2>
                                    <div class="floor-rooms_badge">
                                        <h3>{{ floor.rooms.length }}</h3>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="delete_modal-footer">
                    <b-button @click="closeDeleteModal">
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button type="is-primary" @click="deleteFloor">
                        {{ $t('valuer_tool.interior.floors.delete_floor.title') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-if="is_floors_modal_open"
            v-model="is_floors_modal_open"
            destroy-on-hide
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="modal"
            @close="closeFloorsModal"
        >
            <div class="floors_modal">
                <div class="floors_modal-header">
                    <h4 class="floors_modal-header-title">
                        {{
                            $t(
                                `valuer_tool.interior.floors.${
                                    is_floors_empty ? 'add_floors' : 'edit_floors'
                                }`
                            )
                        }}
                    </h4>
                </div>
                <div class="floors_modal-content">
                    <b-field :label="$t('features.f_bottom_floor')">
                        <b-input v-model="f_bottom_floor" type="number" />
                    </b-field>
                    <b-field :label="$t('features.f_top_floor')">
                        <b-input v-model="f_top_floor" type="number" />
                    </b-field>
                </div>
                <div class="floors_modal-footer">
                    <b-button @click="closeFloorsModal">
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button type="is-primary" @click="confirmFloors">
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { mapGetters as vuexGetters } from 'vuex'
import { useRequest } from '@/composables/request'
import IconButton from '@/components/elements/IconButton.vue'
import EmptyList from './EmptyList.vue'
import utils from '@/shared/plugins/utils'
import chevron from '@/shared/assets/icons/chevron.svg'

export default {
    name: 'Floors',
    components: {
        EmptyList,
        IconButton,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getOVMFeatures', 'getFeatures']),
            ...mapMutations(requestObjects, ['SET_OVM_FEATURES', 'SET_FEATURES']),
        }
    },
    data: () => ({
        is_floors_modal_open: false,
        deletingFloor: null,
        targetFloorIndex: null,
        deletingRooms: [],
        is_delete_modal_open: false,
        chevron,
    }),
    computed: {
        ...vuexGetters(['isMobile']),
        is_floors_empty() {
            return Array.isArray(this.floors) ? !this.floors.length : true
        },
        showEasyAptButton() {
            return this.isApartment && this.getFeatures.f_floors.length
        },
        floors: {
            get: function() {
                return this.getOVMFeatures.floors || []
            },
            set: function(floors) {
                this.SET_OVM_FEATURES({
                    floors,
                })
            },
        },
        f_top_floor: {
            get: function() {
                return +this.getFeatures.f_top_floor
            },
            set: function(val) {
                this.SET_FEATURES({
                    f_top_floor: +val,
                })
            },
        },
        f_bottom_floor: {
            get: function() {
                return +this.getFeatures.f_bottom_floor
            },
            set: function(val) {
                this.SET_FEATURES({
                    f_bottom_floor: +val,
                })
            },
        },
        building_type() {
            return this.getFeatures.f_building_type
        },
        isApartment() {
            return utils.is_apartment(this.building_type)
        },
    },
    methods: {
        newFloorLevel(floor) {
            let deletingLevel = this.floors[this.deletingFloor].level
            let newLevel = null
            if (deletingLevel < 0 && floor.level < deletingLevel) {
                newLevel = floor.level + 1
            } else if (deletingLevel >= 0 && floor.level > deletingLevel) {
                newLevel = floor.level - 1
            }

            let returnStr = 'Floor '
            if (typeof newLevel === 'number') {
                returnStr += `${newLevel} (previously Floor ${floor.level})`
            } else {
                returnStr += floor.level
            }
            return returnStr
        },
        openFloorsModal() {
            this.is_floors_modal_open = true
        },
        closeFloorsModal() {
            this.is_floors_modal_open = false
        },
        initAptFloors() {
            let floors = []

            for (let i = +this.f_bottom_floor; i <= +this.f_top_floor; i++) {
                floors.push({
                    level: i,
                    rooms: [],
                    living_area: 0,
                })
            }

            floors = floors.filter((f) => this.getFeatures.f_floors.includes(f.level))

            this.floors = floors
        },
        confirmFloors() {
            let floors
            if (this.floors.length) {
                floors = this.floors
                floors.sort((a, b) => a.level - b.level)
                floors = floors.filter(
                    (floor) =>
                        floor.level >= +this.f_bottom_floor &&
                        floor.level <= +this.f_top_floor
                )
                let previousFloors = []

                for (let i = +this.f_bottom_floor; i < floors[0].level; i++) {
                    previousFloors.push({
                        level: i,
                        rooms: [],
                        living_area: 0,
                    })
                }

                floors = [...previousFloors, ...floors]

                for (
                    let i = floors[floors.length - 1].level + 1;
                    i <= +this.f_top_floor;
                    i++
                ) {
                    floors.push({
                        level: i,
                        rooms: [],
                        living_area: 0,
                    })
                }
            } else {
                floors = []

                for (let i = +this.f_bottom_floor; i <= +this.f_top_floor; i++) {
                    floors.push({
                        level: i,
                        rooms: [],
                        living_area: 0,
                    })
                }
            }

            this.floors = floors
            this.is_floors_modal_open = false
        },
        goToFloor(floor) {
            this.$router.push({
                name: 'valuation.valuer-app.interior.floorplan.rooms',
                query: {
                    ...this.$route.query,
                    floor,
                },
                params: {
                    ...this.$route.params,
                },
            })
        },
        openDeleteModal(index) {
            this.deletingFloor = index
            let rooms = []

            if (Array.isArray(this.floors[index].rooms)) {
                rooms.push(...this.floors[index].rooms)
            }

            this.deletingRooms = rooms
            this.is_delete_modal_open = true
        },
        closeDeleteModal() {
            this.is_delete_modal_open = false
            this.deletingFloor = null
            this.deletingRooms = []
            this.targetFloorIndex = null
        },
        setTargetFloor(index) {
            this.targetFloorIndex = index
        },
        deleteFloor() {
            let level = this.floors[this.deletingFloor].level
            let floors = this.floors

            if (typeof this.targetFloorIndex === 'number') {
                floors[this.targetFloorIndex].rooms.push(...this.deletingRooms)
            }

            floors.splice(this.deletingFloor, 1)

            let min, max

            if (floors.length) {
                min = max = floors[0].level
                floors.forEach((floor) => {
                    if (level < 0 && floor.level < level) {
                        floor.level++
                    } else if (level >= 0 && floor.level > level) {
                        floor.level--
                    }
                    min = Math.min(floor.level, min)
                    max = Math.max(floor.level, max)
                })
            } else {
                min = max = null
            }

            if (!this.isApartment) {
                this.f_bottom_floor = min
                this.f_top_floor = max
            }

            this.floors = floors

            this.closeDeleteModal()
        },
    },
}
</script>
<style lang="scss" scoped>
.floor-mobile_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.floor-mobile_spacer {
    flex-grow: 1;
}

.add-apt--buttn {
    justify-self: center;
}

.floors {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 999px) {
        margin-bottom: 97px;
        gap: 16px;
    }

    .floor {
        display: flex;
        flex-direction: row;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #d6e2e9;
        background: #fff;
        cursor: pointer;
        box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
            0px 4px 6px 0px rgba(0, 24, 55, 0.06);
        transition: all 0.2s ease-out;

        @media screen and (max-width: 999px) {
            flex-direction: column;
            align-items: stretch;
            gap: 16px;
            border-radius: 0;
        }

        &:hover,
        &--active {
            box-shadow: 0px 1px 2px 0px rgba(0, 156, 255, 0.2),
                0px 4px 6px 0px rgba(0, 156, 255, 0.2);
            border-color: var(--color-primary);

            h2 {
                color: var(--color-primary) !important;
            }
        }

        & > * {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &-left_side {
            gap: 12px;

            @media screen and (max-width: 999px) {
                gap: 8px;
                justify-content: space-between;
            }

            h2 {
                color: #001837;
                font-size: 18px;
                font-weight: 700;
                transition: all 0.2s ease-out;
            }
        }

        &-rooms_badge {
            display: flex;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            background: rgba(0, 156, 255, 0.2);

            h3 {
                color: var(--color-primary);
                font-size: 12px;
                font-weight: 700;
            }
        }

        &-area_field {
            display: flex;
            gap: 12px;
            align-items: center;
            flex-grow: 1;

            label {
                color: #798da6;
                font-size: 12px;
                font-weight: 500;
            }

            & > *:not(label) {
                flex-grow: 1;
            }
        }

        .chevron {
            transform-origin: center;
            transform: rotate(-90deg);
            height: 8px;
        }
    }
}
.floors_modal,
.delete_modal {
    height: fit-content;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);

    & > * {
        padding: 24px;

        @media screen and (max-width: 999px) {
            padding: 16px;
        }
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 24px;

        @media screen and (max-width: 999px) {
            padding: 16px;
        }

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    &-content {
        border-top: 1px solid #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        flex-shrink: 1;
        overflow: scroll;

        & ::v-deep .field {
            margin-bottom: 0;
        }

        @media screen and (max-width: 999px) {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    &-footer {
        border-top: 1px solid #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        @media screen and (max-width: 999px) {
            padding: 16px;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
}

.delete_modal {
    &-content {
        display: flex;
        flex-direction: column;
    }

    .floors {
        gap: 12px;

        .floor {
            padding: 12px;
            gap: 40px;

            & > * {
                gap: 8px;
            }

            &-left_side {
                gap: 6px;

                h2 {
                    font-size: 12px;
                }
            }

            &-rooms_badge {
                padding: 2px 8px;
                border-radius: 24px;

                h3 {
                    font-size: 8px;
                }
            }

            &-area_field {
                gap: 8px;

                label {
                    font-size: 8px;
                }
            }
        }
    }
}
</style>
