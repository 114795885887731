/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile-menu-close': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path d="M18,19.5c-0.3,0-0.5-0.1-0.7-0.3L12,14l-5.3,5.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l5.3-5.3L5.3,7.3 c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l5.3,5.3l5.3-5.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5.3,5.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4 C18.5,19.5,18.3,19.5,18,19.5z"/>'
  }
})
