<template>
    <grid-card no-padding is-full no-mobile-toggle class="flood_map_card">
        <re-map
            :default-zoom-modifier="isMobile ? -2 : 0"
            :building-id="getFeatures.building_id"
            :parcel-ids="getFeatures.parcel_ids"
            :level="getFeatures.level"
            :region="getFeatures.region"
            :center="{
                lat: getFeatures.f_lat,
                lng: getFeatures.f_lng,
            }"
            :map-scene2d="get_map_scene2d"
            :read-only="true"
            :focus-layer="'building'"
            :layers-control-visible="true"
            :options="{
                zoomControl: true,
                attributionControl: false,
                scrollWheelZoom: false,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: true,
                boxZoom: true,
                dragging: true,
            }"
            :aerial-tile-visible="true"
            :cadastral-tile-visible="false"
            class="has-ratio"
        ></re-map>
    </grid-card>
</template>
<script>
import { mapGetters as vuexGetters } from 'vuex'
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import ReMap from '@/components/common/Map.vue'

export default {
    name: 'FloodMap',
    components: {
        GridCard,
        ReMap,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures', 'get_map_scene2d']),
        }
    },
    computed: {
        ...vuexGetters(['isMobile']),
    },
}
</script>
<style lang="scss" scoped>
.flood_map_card {
    height: 350px;
    padding-top: 0;
}
</style>
