<template>
    <div class="interior_page" :class="{ no_tabs: !showTabs }">
        <div v-if="showTabs" class="interior_page-tabs">
            <clear-button
                :class="{ active: $route.name === 'valuation.valuer-app.interior.info' }"
                @click="goTo('valuation.valuer-app.interior.info')"
            >
                {{ $t('valuer_tool.interior.tabs.interior') }}
            </clear-button>
            <clear-button
                :class="{ active: $route.name.includes('floorplan') }"
                @click="goTo('valuation.valuer-app.interior.floorplan')"
            >
                {{ $t('valuer_tool.interior.tabs.floors') }}
            </clear-button>
        </div>
        <div class="interior_page-content">
            <router-view />
        </div>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'InteriorPage',
    components: {
        ClearButton,
    },
    computed: {
        activeRoute() {
            return this.$route.name.toLowerCase()
        },
        showTabs() {
            return !utils.isMobile() || !this.activeRoute.endsWith('floorplan.rooms')
        },
    },
    methods: {
        goTo(target) {
            if (target.toLowerCase() !== this.activeRoute) {
                let { params, query } = this.$route
                this.$router.push({ name: target, params, query })
            }

            scrollTo(0, 0)
        },
    },
}
</script>
<style lang="scss" scoped>
.interior_page {
    display: grid;
    grid-template-rows: 50px auto;
    height: 100%;
    gap: 1px;
    background: #f4f6f9;

    &.no_tabs {
        display: flex;
        flex-direction: column;
    }

    &-tabs {
        background: white;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        border-bottom: 1px solid #d6e2e9;

        button {
            border-bottom: 0px solid var(--primary);
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 3px;

            transition: all 0.3s ease-out;

            color: rgba(0, 24, 55, 0.6);
            font-size: 14px;
            font-weight: 500;

            &.active,
            &:hover {
                padding-bottom: 0px;
                border-bottom: 3px solid var(--primary);

                color: #001837;
                font-weight: 700;
            }
        }
    }

    &-content {
        height: 100%;
        overflow-y: scroll;
        padding: 60px;

        @media screen and (max-width: 999px) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            padding: 0;
            padding-top: 16px;
        }
    }
}
</style>
