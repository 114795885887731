<template>
    <grid-card :title="$t('valuer_tool.general.basic_infos.title')">
        <template slot="header">
            <white-button class="docs_buttn" @click="openDocuments">
                <span>{{ $t('monolith.global.documents') }}</span>
                <svg-icon icon="documents" height="16" scale="1" color="#001837" />
                <div v-if="getDocuments && getDocuments.length" class="docs_buttn-badge">
                    <h5>{{ getDocuments.length }}</h5>
                </div>
            </white-button>
        </template>
        <template slot="default">
            <div class="grid_card-content_grid">
                <div class="content_grid-item content_grid-item--full">
                    <b-field :label="$t('features.f_building_type')">
                        <b-select :value="getFeatures.f_building_type" expanded disabled>
                            <option value="apartment">
                                {{ $t('value.apartment') }}
                            </option>
                            <option value="house">
                                {{ $t('value.house') }}
                            </option>
                            <option value="new_apartment">
                                {{ $t('value.new_apartment') }}
                            </option>
                            <option value="new_house">
                                {{ $t('value.new_house') }}
                            </option>
                            <option value="construction_plot">
                                {{ $t('value.construction_plot') }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="content_grid-item">
                    <b-field
                        :label="$t('valuer_tool.general.basic_infos.building_found')"
                    >
                        <b-input :value="getFeatures.notfound ? 'No' : 'Yes'" disabled />
                    </b-field>
                </div>
                <div class="content_grid-item content_grid-item--is_full">
                    <b-field :label="$t('features.level')">
                        <b-input :value="getFeatures.level" disabled />
                    </b-field>
                </div>
            </div>
        </template>
        <template slot="modal">
            <b-modal v-model="documentsShown" width="fit-content">
                <documents-modal />
            </b-modal>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'
import DocumentsModal from '@/apps/Valuation/components/ValuerTool/elements/DocMgmt/DocumentsModal.vue'
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import { useDocuments } from '@/composables/documents'

export default {
    name: 'BasicInfo',
    components: {
        GridCard,
        WhiteButton,
        DocumentsModal,
    },
    data: () => ({
        documentsShown: false,
    }),
    setup() {
        const requestObjects = useRequest()
        const documentsObjects = useDocuments()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
            ...mapGetters(documentsObjects, ['getDocuments']),
        }
    },
    methods: {
        openDocuments() {
            this.documentsShown = true
        },
        closeDocuments() {
            this.documentsShown = false
        },
    },
}
</script>
