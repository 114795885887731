import { render, staticRenderFns } from "./SideSteps-v2.vue?vue&type=template&id=cefea2ae&scoped=true"
import script from "./SideSteps-v2.vue?vue&type=script&lang=js"
export * from "./SideSteps-v2.vue?vue&type=script&lang=js"
import style0 from "./SideSteps-v2.vue?vue&type=style&index=0&id=cefea2ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cefea2ae",
  null
  
)

export default component.exports