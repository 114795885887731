<template>
    <div v-if="!hideErrors && errors && errors.length" class="errors-block">
        <div :class="`${errorType}s`">
            <b-message
                v-for="error in errors.filter((e) => !e.hidden && e.type === errorType)"
                :key="error.id"
                :type="errorType == 'warning' ? 'is-warning' : 'is-danger'"
                :class="[`re-${errorType}`, `re-${errorType}-${error.message}`, 'mt-3']"
                :title="errorTitle(error)"
                aria-close-label="Close"
                @close="closeError(error)"
            >
                <p v-if="$te(errorCode(error))">
                    {{ $t(errorCode(error)) }}
                </p>
                <p v-else>
                    <strong>[{{ errorCode(error) }}]</strong>
                    {{ error.debug?.message || '' }}
                </p>
                <details v-if="error.debug && isDev">
                    <summary>Debug info</summary>
                    <p>{{ error.debug.message }}</p>
                    <ul v-if="error.debug.context">
                        <li v-for="(value, key) in error.debug.context" :key="key">
                            <strong>{{ key }}:</strong>
                            {{ value }}
                        </li>
                    </ul>
                    <pre v-if="error.debug.traceback">{{ error.debug.traceback }}</pre>
                </details>
            </b-message>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Errors',
    props: {
        errorType: {
            type: String,
            default: 'error',
        },
    },
    data() {
        return {}
    },
    mounted() {
        // TODO: add (silent) exception handling for this template, to prevent infinite loops
    },
    computed: {
        ...mapGetters('log', ['errors', 'warnings']),
        isDev: () => process.env.NODE_ENV === 'development',
        hideErrors: function() {
            return !!this.$config.HIDE_FRONTEND_ERRORS
        },
    },
    methods: {
        ...mapActions('log', ['logError', 'hideError']),
        closeError(error) {
            this.hideError(error.id)
        },
        errorTitle(error) {
            if (error.title) {
                return this.$t(`${this.title}`)
            }
            // use capitalised errorType (try to localise)
            const title = `${this.errorType.charAt(0).toUpperCase() +
                this.errorType.slice(1)}`
            if (this.$te(title)) {
                return this.$t(title)
            } else {
                return title
            }
        },
        errorCode(error) {
            return error.error_code !== undefined ? error.error_code : error.message
        },
    },
}
</script>

<style lang="scss" scoped>
.errors-block {
    display: flex;
    flex-direction: column;

    & > div {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &:empty {
            display: none;
        }
    }

    & ::v-deep .message {
        margin: 0 !important;
    }

    &:empty {
        display: none;
    }
}
pre {
    white-space: pre-wrap;
}
</style>
