var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"valuation"}},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column pb-0 pr-0",class:[
                _vm.$route.params.action == 'faq'
                    ? 'is-3 is-2-desktop '
                    : 'is-4 is-3-desktop ',
            ]},[_c('app-side-steps')],1),_c('div',{staticClass:"column pl-0",class:[
                _vm.$route.params.action == 'faq'
                    ? 'is-10-desktop is-9'
                    : 'is-9-desktop is-8',
                { skyline: _vm.$route.params.action === 'start' },
                { 'p-0': _vm.isOnMapSelect },
            ]},[(_vm.$route.params.action == 'start')?_c('app-start'):_vm._e(),(_vm.$route.params.action == 'enter' && _vm.flow_type !== 'avm_demo')?_c('app-enter'):_vm._e(),(_vm.$route.params.action == 'building-type')?_c('app-building-type'):_vm._e(),(_vm.$route.params.action == 'address')?_c('app-address',{attrs:{"building-type":_vm.buildingType}}):_vm._e(),(_vm.isOnMapSelect)?_c('app-map-select'):_vm._e(),(_vm.$route.params.action == 'extra-info')?_c('app-extra-info',{ref:"extra-info",attrs:{"building-type":_vm.buildingType}}):_vm._e(),(_vm.$route.params.action == 'renovation-info')?_c('app-renovation-info'):_vm._e(),(_vm.$route.params.action == 'feedback')?_c('app-feed-back'):_vm._e(),(_vm.$route.params.action == 'ovm')?_c('OVMSubmitter'):_vm._e(),(_vm.getRouteForInfo)?_c('app-info'):_vm._e(),(_vm.$route.params.action == 'faq')?_c('app-faq'):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }