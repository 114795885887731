<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="ers-page-content">
        <h1>
            {{ $t('energy.building_dimensions.title') }}
        </h1>
        <div class="ers-page-form">
            <div class="ers-page-panel">
                <div class="ers-page-floors">
                    <h1 class="building-question">
                        {{ $t('energy.building_dimensions.floors.question') }}
                    </h1>
                    <info-bubble
                        :text="$t('energy.building_dimensions.floors.infobubble')"
                    />
                    <int-selector
                        v-model="f_top_floor"
                        :label="$t('energy.building_dimensions.floors.label')"
                        :type="{
                            'is-danger':
                                !$v.f_top_floor.required ||
                                !$v.f_top_floor.numeric ||
                                !$v.f_top_floor.min ||
                                !$v.f_top_floor.max,
                        }"
                        :min="1"
                        :max="5"
                        btn-type="is-normal"
                        class="building-dimensions-int-selector"
                        :message="[
                            !$v.f_top_floor.required
                                ? $t('validation.field_required')
                                : null,
                            !$v.f_top_floor.numeric
                                ? $t('validation.only_numbers')
                                : null,
                            !$v.f_top_floor.min ? $t('validation.value_too_small') : null,
                            !$v.f_top_floor.max ? $t('validation.value_too_large') : null,
                        ]"
                    />
                </div>

                <div class="ers-page-roof">
                    <h1 class="building-question">
                        {{ $t('energy.building_dimensions.roof.question') }}
                        <Tooltip :label="$t('energy.building_dimensions.roof.tooltip')" />
                    </h1>

                    <div class="roof-types">
                        <div
                            :class="{
                                'roof-card-selected':
                                    getSelectedRoofTypeCard === 'pitched',
                            }"
                            class="roof-card"
                            @click="f_percent_of_roof_flat = 0"
                        >
                            <img src="@/assets/icons/roof-pitched.svg" alt="" />
                            <p>
                                {{ $t('energy.building_dimensions.roof_type_pitched') }}
                            </p>
                        </div>

                        <div
                            :class="{
                                'roof-card-selected': getSelectedRoofTypeCard === 'flat',
                            }"
                            class="roof-card"
                            @click="f_percent_of_roof_flat = 1"
                        >
                            <img src="@/assets/icons/roof-flat.svg" alt="" />
                            <p>{{ $t('energy.building_dimensions.roof_type_flat') }}</p>
                        </div>

                        <div
                            :class="{
                                'roof-card-selected': getSelectedRoofTypeCard === 'mixed',
                            }"
                            class="roof-card"
                            @click="f_percent_of_roof_flat = 0.5"
                        >
                            <img src="@/assets/icons/roof-mixed.svg" alt="" />
                            <p>{{ $t('energy.building_dimensions.roof_type_mixed') }}</p>
                        </div>
                    </div>
                    <div
                        v-if="f_percent_of_roof_flat > 0 && f_percent_of_roof_flat < 1"
                        class="percent-selector"
                    >
                        <b-slider
                            v-if="
                                f_percent_of_roof_flat > 0 && f_percent_of_roof_flat < 1
                            "
                            size="is-medium"
                            format="raw"
                            :min="0"
                            :max="100"
                            :custom-formatter="(val) => parseInt(val).toString() + '%'"
                            :value="f_percent_of_roof_flat * 100"
                            @change="updatePercentOfRoofFlat"
                        ></b-slider>

                        <div class="percent-selector-input">
                            <b-input
                                class="input-percent"
                                type="number"
                                :value="parseInt(f_percent_of_roof_flat * 100)"
                                :min="0"
                                :max="100"
                                @input="updatePercentOfRoofFlat"
                            />
                            <span>% {{ $t('building_dimensions_page.is_flat') }}</span>
                        </div>
                    </div>
                </div>

                <div v-if="+getFeatures.effective_level < 2" class="ers-page-facades">
                    <h1 class="building-question">
                        {{ $t('energy.building_dimensions.facades.question') }}
                        <Tooltip
                            :label="$t('energy.building_dimensions.facades.tooltip')"
                        />
                    </h1>

                    <b-dropdown
                        v-model="f_neighbour_type"
                        class="building-dimension-dropdown"
                        aria-role="list"
                    >
                        <template v-if="f_neighbour_type === 'isolated'" #trigger>
                            <b-button
                                :label="
                                    $t('energy.building_dimensions.facade_type_detached')
                                "
                                icon-right="menu-down"
                            />
                        </template>

                        <template v-else-if="f_neighbour_type === 'row_end'" #trigger>
                            <b-button
                                :label="
                                    $t(
                                        'energy.building_dimensions.facade_type_semi-detached'
                                    )
                                "
                                icon-right="menu-down"
                            />
                        </template>

                        <template v-else #trigger>
                            <b-button
                                :label="
                                    $t('energy.building_dimensions.facade_type_attached')
                                "
                                icon-right="menu-down"
                            />
                        </template>

                        <b-dropdown-item value="isolated" aria-role="listitem">
                            {{ $t('energy.building_dimensions.facade_type_detached') }}
                        </b-dropdown-item>

                        <b-dropdown-item value="row_end" aria-role="listitem">
                            {{
                                $t('energy.building_dimensions.facade_type_semi-detached')
                            }}
                        </b-dropdown-item>

                        <b-dropdown-item value="row" aria-role="listitem">
                            {{ $t('energy.building_dimensions.facade_type_attached') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="ers-page-panel">
                <div class="ers-page-widget">
                    <img
                        v-if="f_percent_of_roof_flat === 0"
                        src="@/assets/img/building-widget-roof-pitched.svg"
                        alt=""
                    />
                    <img
                        v-else-if="f_percent_of_roof_flat === 1"
                        src="@/assets/img/building-widget-roof-flat.svg"
                        alt=""
                    />
                    <img
                        v-else
                        src="@/assets/img/building-widget-roof-mixed.svg"
                        alt=""
                    />
                    <img
                        v-for="index in (+f_top_floor || 1) - 1"
                        :key="index"
                        src="@/assets/img/building-widget-floor.svg"
                        alt=""
                    />
                    <img
                        v-if="getFeatures.has_basement"
                        src="@/assets/img/building-widget-basement.svg"
                        alt=""
                    />
                    <img v-else src="@/assets/img/building-widget.svg" alt="" />
                </div>
            </div>
        </div>

        <div class="is-flex is-justify-content-space-between ers-page-buttons">
            <b-button
                size="is-medium"
                type="is-dark"
                icon-left="arrow-left"
                @click="changePage(false)"
            >
                {{ $t('common.previous') }}
            </b-button>
            <submit-button
                @on-click="
                    $emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    changePage(true)
                "
            />
            <b-button
                v-if="showNextButton"
                :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                size="is-medium"
                type="is-primary"
                icon-right="arrow-right"
                @click="changePage(true)"
            >
                <span>{{ $t('common.next') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import utils from '@/shared/plugins/utils'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton.vue'
import IntSelector from '@/components/elements/IntSelector.vue'
import InfoBubble from '@/components/elements/InfoBubble.vue'

export default {
    name: 'BuildingDimensions',
    components: {
        Tooltip,
        SubmitButton,
        IntSelector,
        InfoBubble,
    },
    data: () => ({
        f_top_floor: 1,
        f_percent_of_roof_flat: 1,
        f_neighbour_type: 'isolated',
    }),
    validations: {
        f_top_floor: {
            required,
            numeric,
            min: minValue(1),
            max: maxValue(5),
        },
    },
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getRequestRef',
            'getAddress',
            'getLivingAreaChanged',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        getSelectedRoofTypeCard() {
            if (this.f_percent_of_roof_flat === 1) return 'flat'
            if (this.f_percent_of_roof_flat === 0) return 'pitched'

            return 'mixed'
        },
        showNextButton() {
            return !(
                !this.$v.f_top_floor.required ||
                !this.$v.f_top_floor.numeric ||
                !this.$v.f_top_floor.min ||
                !this.$v.f_top_floor.max
            )
        },
    },
    watch: {
        getFeatures: {
            handler(newValue) {
                this.f_top_floor = newValue.f_top_floor
                this.f_percent_of_roof_flat = newValue.f_percent_of_roof_flat
                this.f_neighbour_type = newValue.f_neighbour_type
            },
            deep: true,
        },
    },
    mounted() {
        const prevFeatures = this.getFeatures

        if (prevFeatures.f_top_floor !== null) {
            this.f_top_floor = prevFeatures.f_top_floor
        }
        if (prevFeatures.f_percent_of_roof_flat !== null) {
            this.f_percent_of_roof_flat = prevFeatures.f_percent_of_roof_flat
        }
        if (+prevFeatures.effective_level === 1 && this.f_neighbour_type === null) {
            this.f_neighbour_type = 'isolated'
        }
        if (prevFeatures.f_neighbour_type) {
            this.f_neighbour_type = prevFeatures.f_neighbour_type
        }
    },
    methods: {
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        updatePercentOfRoofFlat(value) {
            this.f_percent_of_roof_flat = value / 100
        },
        changePage(isNextPage) {
            let feature
            if (+this.getFeatures.effective_level >= 2) {
                feature = {
                    f_top_floor: parseInt(+this.f_top_floor),
                    f_percent_of_roof_flat: this.f_percent_of_roof_flat,
                }
            } else {
                feature = {
                    f_top_floor: parseInt(+this.f_top_floor),
                    f_percent_of_roof_flat: this.f_percent_of_roof_flat,
                    f_neighbour_type: this.f_neighbour_type,
                }
            }

            let nextPage

            if (isNextPage) {
                nextPage = this.getLivingAreaChanged ? 'ers.epc' : 'ers.living-area'
            } else {
                nextPage = this.getLivingAreaChanged ? 'ers.living-area' : 'ers.basement'
            }

            this.set_and_submit_feature({
                feature,
                nextPage,
            })

            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
.building-dimension-dropdown {
    min-width: 100% !important;
    .dropdown-trigger {
        min-width: 100% !important;
        button {
            min-width: 100% !important;
            min-height: 2.75rem;
            display: flex;
            justify-content: space-between;
            color: #001837;
            font-size: 0.875rem;
            align-self: stretch;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            padding: 0.75rem;
        }
    }
    .dropdown-menu {
        min-width: 100%;
    }
}
</style>

<style lang="scss" scoped>
.building-dimensions-int-selector {
    max-width: 15rem;
}

@media screen and (max-width: 1200px) {
    .ers-page-widget {
        display: none !important;
    }
}

.building-question {
    color: #001837;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    gap: 0.5rem;
    align-content: center;
}
.ers-page {
    &-form {
        display: flex;
        gap: 2.38rem;
    }
    &-floors {
        display: flex;
        flex-direction: column;
        gap: 2.38rem;
        margin-bottom: 2.5rem;
    }

    &-widget {
        border-radius: 0.25rem;
        border: 1px solid #d6e2e9;
        background: #fff;
        display: flex;
        min-height: 32.5rem;
        max-width: 30rem;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-block: 3rem;
    }

    &-roof {
        display: flex;
        flex-direction: column;
        gap: 2.38rem;
        margin-bottom: 2.5rem;
    }

    &-facades {
        display: flex;
        flex-direction: column;
        gap: 2.38rem;
    }

    &-living-area {
        display: flex;
        flex-direction: column;
        gap: 2.38rem;
    }

    &-buttons {
        margin-block-end: 12rem;
    }
}

.roof-types {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.roof-card {
    display: flex;
    padding: 1rem;
    gap: 0.75rem;
    flex-direction: column;
    min-height: 7.5rem;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    transition: all 0.25s;
    cursor: pointer;

    &:hover {
        border-radius: 0.3125rem;
        border: 1px solid var(--color-primary);
        background: #fff;
        box-shadow: 0px 2px 14px 0px rgba(0, 156, 255, 0.2);

        p {
            color: var(--color-primary);
        }
    }

    p {
        color: #001837;
        text-align: center;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: all 0.25s;
        margin-block: auto;
    }

    &-selected {
        border-radius: 0.3125rem;
        border: 1px solid var(--color-primary);
        background: #fff;
        box-shadow: 0px 2px 14px 0px rgba(0, 156, 255, 0.2);

        p {
            color: var(--color-primary);
            font-weight: 500;
        }
    }
}

.living-area-unit {
    margin-block: auto;
    padding-inline: 1rem;
    transform: translateY(4px);
}

.percent-selector-input {
    display: flex;
    span {
        display: flex;
        padding-inline-start: 0.75rem;
        padding-block-start: 8px;
    }
}

.input-percent {
    max-width: 100px;
}

.slider-labels {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-block-end: 1rem;
}

.floors-input {
    display: flex;
    flex-direction: column;
}
</style>
