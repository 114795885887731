/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 15,
    height: 21,
    viewBox: '0 0 15 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 11.766c-1.81 0-3.276-1.601-3.276-3.577S5.69 4.612 7.5 4.612c1.81 0 3.276 1.601 3.276 3.577S9.31 11.766 7.5 11.766zM7.5 0C3.358 0 0 3.666 0 8.189 0 16.154 7.5 21 7.5 21S15 16.154 15 8.189C15 3.666 11.642 0 7.5 0z" _fill="#fff"/>'
  }
})
