<template>
    <sidebar />
</template>
<script>
import Sidebar from '../elements/Sidebar'
import { mapGetters } from 'vuex'

export default {
    name: 'Overview',
    components: {
        Sidebar,
    },
    computed: {
        ...mapGetters(['isMobile']),
    },
    mounted() {
        if (!this.isMobile) {
            this.$router.push({
                ...this.$route,
                name: 'valuation.valuer-app.general',
            })
        }
    },
}
</script>
