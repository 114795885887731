<template>
    <div class="valuer_tool-default-layout-grid">
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.commons.title') }}
        </h1>
        <upload-card
            :title="$t('valuer_tool.commons.certificates')"
            purpose="common_parts_epc"
        />
        <multi-choice-card
            feature="common_parts_state"
            config-location="ovm_features"
            small
        />
        <single-input-card feature="apartment_quotity" config-location="ovm_features" />
        <single-input-card feature="reserve_savings" config-location="ovm_features" />
        <common-garden />
        <common-solar-panels />
        <checkboxes-card feature="common_comfort" config-location="ovm_features" />
        <costs />
    </div>
</template>
<script>
import { mapGetters as vuexGetters } from 'vuex'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import UploadCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/UploadCard.vue'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import CheckboxesCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/CheckboxesCard.vue'
import SingleInputCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/SingleInputCard.vue'
import CommonSolarPanels from './cards/CommonSolarPanels.vue'
import CommonGarden from './cards/CommonGarden.vue'
import Costs from './cards/Costs.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Commons',
    components: {
        CheckboxesCard,
        UploadCard,
        MultiChoiceCard,
        SingleInputCard,
        CommonSolarPanels,
        CommonGarden,
        Costs,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures', 'get_map_scene2d']),
            ...mapMutations(requestObjects, ['SET_MAP_SCENE2D']),
        }
    },
    computed: {
        ...vuexGetters(['isMobile']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
    },
    mounted() {
        if (!this.is_apartment) {
            this.$router.push({
                ...this.$route,
                name: 'valuation.valuer-app.general',
            })
        }
    },
}
</script>
