var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container section is-fluid"},[_c('div',{staticClass:"faq-container"},[_c('h1',{staticClass:"title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('section.helpcenter'))+" ")]),_c('b-input',{staticClass:"mb-6",attrs:{"placeholder":"Search"},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}}),(Object.keys(_vm.items.available_videos).length)?_c('h2',{staticClass:"title is-size-4 mgt-medium is-family-secondary has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('global.helpcenter.video_tutorials'))+" ")]):_vm._e(),_vm._l((Object.keys(_vm.items.available_videos)),function(category){return _c('div',{key:category + '_video'},[(_vm.items.available_videos[category].length)?_c('h2',{staticClass:"has-text-primary is-size-5 has-text-weight-medium mgb-small"},[_vm._v(" "+_vm._s(_vm.t(`section.${category}`))+" ")]):_vm._e(),(_vm.items.available_videos[category].length)?_c('div',{staticClass:"questions faq-content mb-6"},_vm._l((_vm.items.available_videos[category]),function(item){return _c('question',{key:item.name,attrs:{"id":item.name,"is-video":"","open":item.shouldOpen,"q-data":{
                        question: _vm.$t(`faq.${item.name}_question`),
                        answer: _vm.$t(`faq.${item.name}_answer`),
                        modules: item.modules.filter((m) =>
                            _vm.accessibleModules.includes(m)
                        ),
                    }}})}),1):_vm._e()])}),_c('h2',{staticClass:"title is-size-4 mgt-medium is-family-secondary has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('global.helpcenter.faq'))+" ")]),_vm._l((Object.keys(_vm.items.available_faqs)),function(category){return _c('div',{key:category},[_c('h2',{staticClass:"has-text-primary is-size-5 has-text-weight-medium mgb-small"},[_vm._v(" "+_vm._s(_vm.t(`section.${category}`))+" ")]),_c('div',{staticClass:"questions faq-content mb-6"},_vm._l((_vm.items.available_faqs[category]),function(item){return _c('question',{key:item.name,ref:"question",refInFor:true,attrs:{"id":item.name,"open":item.shouldOpen,"q-data":{
                        question: _vm.$t(`faq.${item.name}_question`),
                        answer: _vm.$t(`faq.${item.name}_answer`),
                        modules: item.modules.filter((m) =>
                            _vm.accessibleModules.includes(m)
                        ),
                    }}})}),1)])}),(_vm.shouldShowContact)?_c('div',{staticClass:"contact-link faq-content mb-6"},[_c('h2',{staticClass:"has-text-black is-size-5 has-text-weight-medium is-flex is-align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('global.helpcenter.question'))+" ")]),_c('b-button',{attrs:{"size":"is-medium","type":"is-primary"},on:{"click":_vm.showContactModal}},[_vm._v(" "+_vm._s(_vm.$t('global.helpcenter.contact_cta'))+" ")])],1):_vm._e()],2),_c('b-modal',{staticClass:"contact-modal",attrs:{"has-modal-card":"","trap-focus":"","can-cancel":['escape'],"aria-role":"dialog","aria-modal":""},model:{value:(_vm.isContactModalActive),callback:function ($$v) {_vm.isContactModalActive=$$v},expression:"isContactModalActive"}},[_c('contact-form',{on:{"hide-contact-modal":_vm.hideContactModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }