<template>
    <svg
        width="100%"
        viewBox="0 0 1282.9 35"
        :class="{ interactive: interactive }"
        class="epc_array"
    >
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('G') }"
            @click="$emit('select-epc', 'G')"
        >
            <path
                class="epc_arrow-bg"
                fill="#d62015"
                d="M0,0h486.5l9.9,17.5L486.5,35H0l9.9-17.5L0,0z"
            />
            <path
                d="M248.8,24.1c-0.9,0-1.7,0-2.6-0.5c-0.7-0.2-1.4-0.7-1.9-1.2c-0.5-0.5-0.9-1.2-1.4-1.9c-0.2-0.7-0.5-1.7-0.5-2.4
		c0-0.9,0-1.7,0.5-2.4c0.2-0.7,0.7-1.4,1.4-1.9s1.2-0.9,2.1-1.2c0.7-0.2,1.7-0.5,2.6-0.5c0.9,0,1.9,0.2,2.8,0.5
		c0.9,0.2,1.7,0.9,2.1,1.4l-1.7,1.7c-0.5-0.5-0.9-0.7-1.4-0.9s-0.9-0.2-1.7-0.2c-0.5,0-0.9,0-1.7,0.2c-0.5,0.2-0.9,0.5-1.2,0.7
		c-0.2,0.2-0.7,0.7-0.9,1.2s-0.2,0.9-0.2,1.7c0,0.5,0,0.9,0.2,1.4s0.5,0.9,0.9,1.2c0.2,0.2,0.7,0.7,1.2,0.7c0.5,0.2,0.9,0.2,1.7,0.2
		c0.5,0,0.9,0,1.7-0.2c0.5-0.2,0.9-0.5,1.4-0.9l1.7,1.9c-0.7,0.5-1.4,0.9-2.1,1.2C250.4,24.1,249.5,24.1,248.8,24.1z M250.9,22.2V18
		h2.6v4.7L250.9,22.2z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('F') }"
            @click="$emit('select-epc', 'F')"
        >
            <path
                class="epc_arrow-bg"
                fill="#fa9101"
                d="M492.4,0h92.2l9.9,17.5L584.6,35h-92.2l9.9-17.5L492.4,0z"
            />
            <path
                d="M541.8,17.5h5.7v2.1h-5.7V17.5z M541.8,23.9h-2.6V12.3h8.7v2.1h-6.1V23.9L541.8,23.9z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('E') }"
            @click="$emit('select-epc', 'E')"
        >
            <path
                class="epc_arrow-bg"
                fill="#fdd400"
                d="M590.7,0H683l9.9,17.5L683,35h-92.2l9.9-17.5L590.7,0z"
            />
            <path
                d="M640.2,17h5.7v2.1h-5.7V17z M640.4,21.8h6.4v2.1h-9V12.3h8.8v2.1h-6.1L640.4,21.8L640.4,21.8z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('D') }"
            @click="$emit('select-epc', 'D')"
        >
            <path
                class="epc_arrow-bg"
                fill="#fdf300"
                d="M688.2,0h92.2l9.9,17.5L780.4,35h-92.2l9.9-17.5L688.2,0z"
            />
            <path
                d="M734.5,23.9V12.3h5.2c1.2,0,2.4,0.2,3.3,0.7c0.9,0.5,1.7,1.2,2.4,1.9c0.5,0.9,0.9,1.9,0.9,3.1s-0.2,2.1-0.9,3.1
		c-0.5,0.9-1.2,1.7-2.4,2.1c-0.9,0.5-2.1,0.7-3.3,0.7L734.5,23.9L734.5,23.9z M737.1,21.8h2.6c0.7,0,1.4,0,1.9-0.5
		c0.7-0.2,0.9-0.7,1.4-1.2c0.2-0.5,0.5-1.2,0.5-1.9s-0.2-1.4-0.5-1.9c-0.2-0.5-0.7-0.9-1.4-1.2c-0.5-0.2-1.2-0.5-1.9-0.5h-2.6V21.8z
		"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('C') }"
            @click="$emit('select-epc', 'C')"
        >
            <path
                class="epc_arrow-bg"
                fill="#d8db1a"
                d="M786.6,0h92.2l9.9,17.5L878.8,35h-92.2l9.9-17.5L786.6,0z"
            />
            <path
                d="M838.1,24.1c-0.9,0-1.7,0-2.6-0.5c-0.7-0.2-1.4-0.7-1.9-1.2c-0.5-0.5-0.9-1.2-1.4-1.9c-0.2-0.7-0.5-1.7-0.5-2.4
		c0-0.9,0-1.7,0.5-2.4c0.2-0.7,0.7-1.4,1.4-1.9c0.7-0.5,1.2-0.9,1.9-1.2s1.7-0.5,2.6-0.5s1.9,0.2,2.6,0.5c0.7,0.2,1.7,0.9,2.1,1.7
		l-1.7,1.7c-0.5-0.5-0.9-0.9-1.4-0.9c-0.5-0.2-0.9-0.2-1.7-0.2c-0.5,0-0.9,0-1.4,0.2s-0.9,0.5-1.2,0.7c-0.2,0.2-0.7,0.7-0.9,1.2
		s-0.2,0.9-0.2,1.7c0,0.7,0,0.9,0.2,1.7c0.2,0.5,0.5,0.9,0.9,1.2c0.5,0.2,0.7,0.7,1.2,0.7s0.9,0.2,1.4,0.2c0.7,0,1.2,0,1.7-0.2
		c0.5-0.2,0.9-0.7,1.4-0.9l1.7,1.7c-0.5,0.7-1.2,1.2-2.1,1.7C840.2,23.9,839.3,24.1,838.1,24.1z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('B') }"
            @click="$emit('select-epc', 'B')"
        >
            <path
                class="epc_arrow-bg"
                fill="#9ade27"
                d="M884.9,0h92.2l9.9,17.5L977.2,35h-92.2l9.9-17.5L884.9,0z"
            />
            <path
                d="M931.3,23.9V12.3h5.7c1.4,0,2.6,0.2,3.3,0.9c0.7,0.5,1.2,1.2,1.2,2.1c0,0.7,0,1.2-0.5,1.7c-0.5,0.5-0.7,0.9-1.2,0.9
		c-0.5,0.2-1.2,0.5-1.7,0.5l0.2-0.7c0.7,0,1.4,0,1.9,0.5c0.5,0.2,0.9,0.7,1.4,0.9c0.2,0.5,0.5,0.9,0.5,1.7c0,0.9-0.5,1.7-1.2,2.4
		c-0.9,0.5-1.9,0.9-3.3,0.9C937.7,23.9,931.3,23.9,931.3,23.9z M934.1,21.8h3.1c0.7,0,1.2,0,1.7-0.2c0.5-0.2,0.5-0.7,0.5-1.2
		s-0.2-0.9-0.5-0.9c-0.2-0.2-0.9-0.5-1.7-0.5h-3.3V17h2.8c0.7,0,1.2,0,1.4-0.2c0.2-0.2,0.5-0.7,0.5-0.9s-0.2-0.9-0.5-0.9
		c-0.2-0.2-0.9-0.2-1.4-0.2h-2.6V21.8z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('A') }"
            @click="$emit('select-epc', 'A')"
        >
            <path
                class="epc_arrow-bg"
                fill="#00bd00"
                d="M983.5,0h92.2l9.9,17.5l-9.9,17.5h-92.2l9.9-17.5L983.5,0z"
            />
            <path
                d="M1028.2,23.9l5.2-11.6h2.6l5.2,11.6h-2.8l-4.3-10.2h0.9l-4.3,10.2H1028.2z M1030.8,21.5l0.7-2.1h5.9l0.7,2.1H1030.8z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('A+') }"
            @click="$emit('select-epc', 'A+')"
        >
            <path
                class="epc_arrow-bg"
                fill="#00bd00"
                d="M1081.9,0h92.2l9.9,17.5l-9.9,17.5h-92.2l9.9-17.5L1081.9,0z"
            />
            <path
                d="M1121.4,23.9l5.2-11.6h2.6l5.2,11.6h-2.8l-4.3-10.2h0.9l-4.3,10.2H1121.4z M1124,21.5l0.7-2.1h5.9l0.7,2.1H1124z
		 M1137.7,22v-7.6h2.1V22H1137.7z M1134.9,19.2V17h7.8v2.1H1134.9z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabels.includes('A++') }"
            @click="$emit('select-epc', 'A++')"
        >
            <path
                class="epc_arrow-bg"
                fill="#00bd00"
                d="M1180.8,0h92.2l9.9,17.5L1273,35h-92.2l9.9-17.5L1180.8,0z"
            />
            <path
                d="M1216.5,23.9l5.2-11.6h2.6l5.2,11.6h-2.8l-4.3-10.2h0.9l-4.3,10.2H1216.5z M1219.1,21.5l0.7-2.1h5.9l0.7,2.1H1219.1z
		 M1232.8,22v-7.6h2.1V22H1232.8z M1230,19.2V17h7.8v2.1H1230z M1242.3,22v-7.6h2.1V22H1242.3z M1239.4,19.2V17h7.8v2.1H1239.4z"
                class="epc_arrow-label"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'EpcArrayWal',
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        currentLabels: {
            type: Array,
            default: () => [],
        },
    },
}
</script>
<style lang="scss" scoped>
@import './epcArrayStyle.scss';
</style>
