<template>
    <Card class="summary">
        <div class="summary__title title is-4">
            {{ $t('energy.result.summary.title') }}
        </div>
        <div class="summary__notice">
            <img class="summary__icon" src="@/assets/icons/warning-icon.svg" alt="" />
            <div class="summary__text">
                {{ $t('energy.result.summary.subtitle') }}
                <router-link
                    v-if="!onReport"
                    :to="{
                        name: 'support',
                        params: {
                            lang: $route.params.lang,
                        },
                        query: {
                            question: 'how-total-investment-cost-calculated',
                        },
                    }"
                    target="_blank"
                >
                    {{ $t('energy.shared.tooltip_more_info') }}
                </router-link>
            </div>
        </div>
        <div class="summary__results results">
            <div class="results__line">
                <div class="line__header">{{ $t('energy.result.summary.item') }}</div>
                <div class="line__header line__header--end">
                    {{ $t('energy.result.summary.price_incl_vat') }}
                </div>
                <div class="line__header line__header--end">
                    {{ $t('energy.result.summary.price_excl_vat') }}
                </div>
            </div>
            <div
                v-for="(renovation, i) in getRenovationsSummary.renovations"
                :key="i"
                class="results__line line"
            >
                <div class="line__title">
                    {{ $t(`energy.result.renovation.${renovation.name}`) }}
                </div>
                <div class="line__price">
                    {{ int_value_formatter(renovation.price) }}
                </div>
                <div class="line__subsidy">
                    {{
                        int_value_formatter(
                            getRenovationVatExcl(renovation.name, renovation.price)
                        )
                    }}
                </div>
            </div>
            <div class="results__line line line--total">
                <div class="line__title">
                    {{ $t('monolith.global.total') }}
                </div>
                <div class="line__price">
                    {{ int_value_formatter(getRenovationsSummary.total.price) }}
                </div>
                <div class="line__subsidy">
                    {{ int_value_formatter(totalExclVat) }}
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from './Card.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Summary',
    components: { Card },
    props: {
        onReport: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('ersStore', [
            'getRenovationsSummary',
            'getSelectedScenario',
            'getFeatures',
        ]),
        totalExclVat() {
            let price = 0

            this.getRenovationsSummary.renovations.forEach((renovation) => {
                price += this.getRenovationVatExcl(renovation.name, renovation.price)
            })

            return price
        },
    },
    methods: {
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
        getRenovationVatExcl(name, price) {
            // https://financien.belgium.be/nl/particulieren/woning/zonnepanelen-zonneboilers-warmtepompen-tijdelijke-btw-verlaging
            const isOlderThanTenYo =
                new Date().getFullYear() - this.getFeatures.f_construction_year > 10
            const isEligibleRenovation = ['heatPump'].includes(name)
            const priceVat =
                isOlderThanTenYo || isEligibleRenovation
                    ? Math.round(price / 1.06)
                    : Math.round(price / 1.21)
            return priceVat
        },
    },
}
</script>

<style lang="scss" scoped>
.summary {
    max-width: 550px;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.summary__notice {
    margin-block-end: 30px;
    background: rgba(255, 184, 0, 0.2);
    border: 1px solid #ffb800;
    border-radius: 0.3125rem;
    padding: 1rem;
    color: black;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.summary__icon {
    max-width: 20px;
}

.results__line {
    display: flex;
    font-size: 12px;
    line-height: 16px;
    min-height: 36px;
}
.line__title,
.line__price,
.line__subsidy,
.line__after-subsidy,
.line__total,
.line__header {
    flex: 1 1 0px;
    margin-block: auto;
    padding-inline: 10px;
}

.line__header--end {
    text-align: end;
}

.line__header {
    color: rgba(0, 24, 55, 0.5);
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.line__price,
.line__subsidy,
.line__after-subsidy {
    text-align: end;
    color: rgba(0, 24, 55, 0.6);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.line__price:after,
.line__subsidy:after,
.line__after-subsidy:after {
}

.line__title {
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #001837;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.line--total {
    background: #52d86033;
    border-radius: 5px;

    & > *:not(.line__title) {
        color: #001837;
    }
}

.summary__text {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
}

.notification {
    padding: 15px 5px;
    background: rgba(255, 184, 0, 0.2) !important;
}

.media-content {
    background: red !important;
}

/* .is-danger {
    color: black !important;
}

.is-danger .media-left {
}

i {
    color: red !important;
} */

@media print {
    .summary {
        margin-block-start: 0;
        .summary__notice {
            gap: 1rem;
            margin-block-end: 1rem;

            .summary__icon {
                height: 1rem;
            }
        }

        .results__line {
            min-height: 2rem;
            line-height: normal;
        }
    }
}
</style>
