<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="bubble py-1 px-2 mb-2">
        <div class="message-meta">
            <h4 class="chat-time">
                <b-tooltip :label="detailedTime" position="is-right" type="is-black">
                    {{ timespan }}
                </b-tooltip>
            </h4>
            <h4 class="chat-sender">
                {{ username }}
            </h4>
        </div>
        <p class="content">{{ content }}</p>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'SystemBubble',
    props: {
        time: {
            type: Date,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        username: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters('auth', ['hasRole']),
        isValuer() {
            return hasRole('valuer', 'dvm')
        },
        detailedTime() {
            let month = this.time.getMonth() + 1
            let day = this.time.getDate()
            let hours = this.time.getHours()
            let minutes = this.time.getMinutes() + 1

            let returnStr = ''
            returnStr += day < 10 ? '0' : ''
            returnStr += day
            returnStr += '/'
            returnStr += month < 10 ? '0' : ''
            returnStr += month
            returnStr += '/'
            returnStr += this.time.getFullYear()
            returnStr += ' '
            returnStr += hours < 10 ? '0' : ''
            returnStr += hours
            returnStr += ':'
            returnStr += minutes < 10 ? '0' : ''
            returnStr += minutes

            return returnStr
        },
        timespan() {
            const now = new Date()
            const diffInWeeks = Math.floor(
                (now.getTime() - this.time.getTime()) / (7 * 24 * 60 * 60 * 1000)
            )
            if (diffInWeeks > 0) {
                return diffInWeeks + ' week' + (diffInWeeks > 1 ? 's ago' : ' ago')
            }

            const diffInDays = Math.floor(
                (now.getTime() - this.time.getTime()) / (24 * 60 * 60 * 1000)
            )
            if (diffInDays > 0) {
                return diffInDays + ' day' + (diffInDays > 1 ? 's ago' : ' ago')
            }

            const diffInHours = Math.floor(
                (now.getTime() - this.time.getTime()) / (60 * 60 * 1000)
            )
            if (diffInHours > 0) {
                return diffInHours + ' hour' + (diffInHours > 1 ? 's ago' : ' ago')
            }

            const diffInMins = Math.floor(
                (now.getTime() - this.time.getTime()) / (60 * 1000)
            )
            if (diffInMins > 0) {
                return diffInMins + ' minute' + (diffInMins > 1 ? 's ago' : ' ago')
            }

            return 'Just now'
        },
    },
}
</script>
<style lang="scss" scoped>
.bubble {
    width: 100%;
    display: flex;
    flex-direction: column;

    border-radius: var(--bubble-radius);
    color: var(--white);
    background: #4e4e4e;
    align-self: flex-start;
    align-items: flex-start;

    .content {
        font-size: 0.8rem;
    }

    .message-meta {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 0.6rem;
        width: 100%;
        gap: 1rem;

        flex-direction: row;
    }
}
</style>
