<template>
    <grid-card>
        <label class="historical_card single_line_card">
            <h5>{{ $t('dvm_features.f_building_listed') }}</h5>
            <b-checkbox v-model="f_building_listed" />
        </label>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
export default {
    name: 'Historical',
    components: {
        GridCard,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getDVMFeatures']),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES']),
        }
    },
    data: () => ({
        f_building_listed: false,
    }),
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_building_listed(val) {
            this.SET_DVM_FEATURES({
                f_building_listed: val,
            })
        },
    },
    mounted() {
        this.f_building_listed = this.getDVMFeatures?.f_building_listed || false
    },
}
</script>
