var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-card',{staticClass:"flood_map_card",attrs:{"no-padding":"","is-full":"","no-mobile-toggle":""}},[_c('re-map',{staticClass:"has-ratio",attrs:{"default-zoom-modifier":_vm.isMobile ? -2 : 0,"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"center":{
            lat: _vm.getFeatures.f_lat,
            lng: _vm.getFeatures.f_lng,
        },"map-scene2d":_vm.get_map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":true,"options":{
            zoomControl: true,
            attributionControl: false,
            scrollWheelZoom: false,
            attributionControl: false,
            scrollWheelZoom: false,
            doubleClickZoom: true,
            boxZoom: true,
            dragging: true,
        },"aerial-tile-visible":true,"cadastral-tile-visible":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }