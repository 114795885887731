import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        errors: [],
        warnings: [],
    }
}

const state = getDefaultState()

const log = {
    namespaced: true,
    state,
    getters: {
        errors(state) {
            return state.errors
        },
        warnings(state) {
            return state.warnings
        },
    },
    mutations: {
        PUSH_ERROR(state, error) {
            state.errors.push(error)
        },
        PUSH_WARNING(state, warning) {
            state.warnings.push(warning)
        },
        UPDATE_ERROR(state, error) {
            state.errors = state.errors.map((e) => {
                if (e.id === error.id) {
                    return { ...e, ...error }
                }
                return e
            })
        },
    },
    actions: {
        logError(context, { error, errorType = 'error', debugContext = null }) {
            // TODO: make console log optional?
            if (errorType === 'warning') console.warn('[Warning log]', error)
            else console.error('[Error log]', error)
            // Give unique ID to error
            error.id = `${Date.now()}.${Math.random()}`
            error.type = errorType
            if (debugContext) {
                error.debug = error.debug || {}
                error.debug.context = { ...(error.debug.context || {}), ...debugContext }
            }
            context.commit('PUSH_ERROR', error)
        },
        hideError(context, errorId) {
            context.commit('UPDATE_ERROR', { id: errorId, hidden: true })
        },
    },
}

export default log
