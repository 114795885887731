<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="ers-page-content">
        <div class="ers-page-card">
            <img src="@/assets/icons/green-check.svg" alt="" />
            <div class="ers-page-card__text">
                <h2>{{ $t('energy.end_section.got_it') }}</h2>
                <p>{{ $t('energy.end_section.text') }}</p>
            </div>
        </div>

        <div class="column is-12 mt-6">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    tag="router-link"
                    :to="{
                        name: 'ers.solar-panels',
                    }"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        nextPage()
                    "
                />
                <b-button
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextPage"
                >
                    <span>{{ $t('energy.end_section.go_to_consumption') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitButton from '../../elements/SubmitButton.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
    name: 'EndingSection',
    components: {
        SubmitButton,
    },
    computed: {
        ...mapGetters('ersStore', ['getOtherData', 'hasRequestBeenUnsubmitted']),
    },
    methods: {
        nextPage() {
            this.$router.push({
                name: 'ers.energy-consumption',
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
.ers-page-card {
    display: flex;
    padding: 2.5rem;
    margin-block-start: 6.25rem;
    flex-direction: column;
    align-items: center;
    gap: 1.375rem;
    max-width: 30rem;
    border-radius: 0.625rem;
    background: #fff;
    margin-inline: auto;

    &__text {
        h2 {
            color: #001837;
            text-align: center;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            margin-block-start: 1rem;
            color: #001837;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }
    }
}
</style>
