import { ref, computed, watch, unref } from 'vue'
import {
    useMutation,
    useQuery,
    useQueries,
    useQueryClient,
    hashKey,
} from '@tanstack/vue-query'
import deepmerge from 'deepmerge'
import utils from '@/shared/plugins/utils'
import address from '@/apps/Valuation/store/address'
import valuationStore from '@/apps/Valuation/store'
import { useUser } from './user'
// https://stackoverflow.com/a/75085570/16901626
import { useRoute } from 'vue2-helpers/vue-router'
// import { useRoute } from 'vue-router' // Vue 3
import { filterKeys } from './fake-vuex'
import { cloneDeepUnref } from '@/composables/utils/clone'
import { setQueryDataAndPersist } from './query'

const overwriteMerge = (array1, array2, options) => array2

const getDefaultState = () => {
    return {
        ...address.getters.getDefaultState(),
        ...valuationStore.getters.getDefaultState(),
        //isLoading: true,
    }
}
// server_data is a reference to the result of the result of the query
const server_data = ref(getDefaultState())
// In-memory state, modified through mutations from the store
const client_data = ref(getDefaultState())

const isLoading = ref(true)

// Copy getters and rootGetters from valuationStore and its Address submodule
const getters = {}
const rootGetters = {}
const addressKeys = Object.keys(address.getters.getDefaultState())
const valuationStoreKeys = Object.keys(valuationStore.getters.getDefaultState())

for (const [name, getter] of Object.entries(address.getters)) {
    getters[name] = computed(() =>
        getter(filterKeys(client_data.value, addressKeys), getters, null, rootGetters)
    )
    rootGetters[`valuationStore/Address/${name}`] = getters[name]
}
for (const [name, getter] of Object.entries(valuationStore.getters)) {
    getters[name] = computed(() =>
        getter(
            filterKeys(client_data.value, valuationStoreKeys),
            getters,
            null,
            rootGetters
        )
    )
    rootGetters[`valuationStore/${name}`] = getters[name]
}

// Copy mutations from valuationStore and its Address submodule
// and have them apply them to client_data instead
const mutations = {}
for (const [name, mutation] of Object.entries(address.mutations)) {
    mutations[name] = (data) => {
        mutation(client_data.value, data)
    }
}
for (const [name, mutation] of Object.entries(valuationStore.mutations)) {
    mutations[name] = (data) => {
        mutation(client_data.value, data)
    }
}

export function useRequest() {
    const route = useRoute()
    const { hasRole } = useUser()
    rootGetters['auth/hasRole'] = hasRole
    const load_request = (request) => {
        const { valuation } = request
        valuation.date = new Date(
            Array.isArray(valuation.date) ? valuation.date[0] : valuation.date
        )
        if (typeof valuation.date === 'string') {
            valuation.date = utils.parseFUDate(valuation.date)
        }
        // TODO: currently ignoring the override_price logic (dvm only). decide whether it's relevant long term and include it here accordingly
        // TODO: currently ignoring the extra_info_edited logic (request only). decide whether it's relevant long term and include it here accordingly
        // TODO: check if it is needed to default valuation.internal_remarks to ''
        isLoading.value = false
        return {
            ...request,
            valuation,
            request_language: request.last_submission_lang,
        }
    }
    // TODO: deal with unread_messages
    // TODO: fetch documents on request update?
    // TODO: deal with fetchStreetviewInfo
    const getRequestRef = computed(() => route.query.valuation_request_ref)
    const queryClient = useQueryClient()
    const queryKey = ['valuation', 'request', getRequestRef, { details: 'full' }]

    // Whenever server_data is updated, reset the in-memory state
    watch(server_data, (newValue) => {
        client_data.value = cloneDeepUnref(newValue)
    })

    watch(
        getRequestRef,
        (valuation_request_ref) => {
            if (getRequestRef.value !== server_data.value.valuation_request_ref) {
                isLoading.value = true
                server_data.value = getDefaultState()
                queryClient
                    .ensureQueryData({
                        queryKey,
                    })
                    .then((data) => {
                        // We use deepmerge here to ensure all keys from the default state are present, even if the server doesn't have them.
                        server_data.value = deepmerge(
                            getDefaultState(),
                            load_request(data),
                            {
                                arrayMerge: overwriteMerge,
                            }
                        )
                    })
            }
        },
        { immediate: true }
    )

    const { mutateAsync } = useMutation({
        onMutate: async ({ verb, queryKey, data }) => {
            return setQueryDataAndPersist(queryClient, queryKey, (old) => {
                return deepmerge(old, data, { arrayMerge: overwriteMerge })
            })
        },
        onSuccess: (result, { verb, queryKey, data }, context) => {},
        onError: (error, { verb, queryKey, data }, context) => {
            // Remove optimistic update
            // queryClient.setQueryData(queryKey, context.previous)
        },
    })

    const saveOnsiteVal = () => {
        const features = { ...getters.getFeatures.value }
        delete features.f_building_type
        const now = new Date()
        const valuationDate = `${now.getFullYear()}-${now.getMonth() +
            1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
        const base_valuation = {
            date: valuationDate,
            type: getters.getValuationType.value,
            // TODO: add valuer: { data from the valuation request assigned valuer }
        }

        const data = {
            valuation: { ...getters.getValuation.value, ...base_valuation },
            dvm_features: getters.getDVMFeatures,
            ovm_features: getters.getOVMFeatures,
            features,
        }
        return mutateAsync({
            verb: 'PATCH',
            queryKey,
            data,
        })
    }

    const load_valuation_request = async () => {
        server_data.value = load_request(
            await queryClient.fetchQuery({
                queryKey,
            })
        )
    }

    return {
        server_data,
        client_data,
        ...getters,
        getRequestRef,
        isRequestLoaded: ref(true),
        ...mutations,
        saveOnsiteVal,
        load_valuation_request,
        isLoading,
    }
}
