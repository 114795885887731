<template>
    <grid-card :title="$t('valuer_tool.general.reference.title')" no-padding>
        <template slot="header">
            <white-button @click="goToMap">
                <div class="map_buttn-content">
                    <span>{{ $t('valuer_tool.general.reference.map.open') }}</span>
                    <svg-icon icon="map" height="16" scale="1" color="#001837" />
                </div>
            </white-button>
        </template>
        <div class="map-container">
            <ref-map
                v-if="isRequestLoaded && isLoaded"
                :center="[getFeatures.f_lat, getFeatures.f_lng]"
                :region="getFeatures.region"
                :population="reference_listings"
                :assessed="getFeatures"
                :price="getAvmTransactionEstimations.asking_price_q50"
                :prices="prices"
                :price-per-sqm-array="pricePerSqmArray"
                :address="getAddress"
                @select="goToMap"
            />
            <b-skeleton
                height="320px"
                width="100%"
                :rounded="false"
                :active="!isRequestLoaded || !isLoaded"
            />
        </div>
    </grid-card>
</template>
<script>
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import RefMap from '@/apps/Valuation/components/ValuerTool/elements/RefMapComponent.vue'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'

export default {
    name: 'RefPoints',
    components: {
        GridCard,
        RefMap,
        WhiteButton,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'getFeatures',
                'getAvmTransaction',
                'getAvmTransactionEstimations',
                'getAddress',
                'getDVMFeatures',
                'isRequestLoaded',
            ]),
        }
    },
    computed: {
        // TODO: Handle loading state much more gracefully
        isLoaded() {
            // Somehow, even though isRequestLoaded is true, these are still undefined:
            return (
                this.getFeatures.f_lat && this.getAvmTransaction.body.reference_listings
            )
        },
        prices() {
            const array = this.getAvmTransaction?.body?.reference_listings.map(
                ({ prijs }) => prijs
            )
            array.sort((a, b) => a - b)
            return array
        },
        pricePerSqmArray() {
            const array = this.getAvmTransaction?.body?.reference_listings.map(
                ({ prijs, f_living_area }) => Math.round(prijs / f_living_area)
            )
            array.sort((a, b) => a - b)
            return array
        },
        reference_listings() {
            let listings = this.getAvmTransaction?.body?.reference_listings
            if (!listings) return null
            let list = [...listings]
            list.forEach((_, i) => {
                list[i].index = i
            })

            return list
        },
    },
    methods: {
        goToMap(targetListing = {}) {
            let params = {
                lang: this.$route.params.lang,
            }

            if (targetListing.hasOwnProperty('listing')) {
                params.listing = targetListing.listing
            }

            this.$router.push({
                name: 'valuation.valuer-app.ref_map',
                params,
                query: this.$route.query,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.map_buttn-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    span {
        margin-right: 8px;
    }
}
.map-container {
    height: 320px;
    width: 100%;
    border-top: 1px solid #d6e2e9;

    @media screen and (max-width: 999px) {
        padding: 0 !important;
    }
}
</style>
