/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'documents': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M8,15.3H5.9c-1.2,0-1.8,0-2.4-0.3c-0.5-0.3-0.9-0.7-1.2-1.2C2,13.3,2,12.7,2,11.5V4.5c0-1.2,0-1.8,0.3-2.4 C2.5,1.6,3,1.2,3.5,1C4,0.7,4.6,0.7,5.9,0.7h4.3c1.2,0,1.8,0,2.4,0.3c0.5,0.3,0.9,0.7,1.2,1.2C14,2.7,14,3.3,14,4.5V7 c0,0.4-0.3,0.7-0.7,0.7S12.7,7.4,12.7,7V4.5c0-1,0-1.5-0.1-1.8c-0.1-0.2-0.3-0.5-0.6-0.6C11.7,2,11.1,2,10.1,2H5.9 c-1,0-1.5,0-1.8,0.1C3.8,2.3,3.6,2.5,3.5,2.7C3.3,3,3.3,3.6,3.3,4.5v6.9c0,1,0,1.5,0.1,1.8c0.1,0.2,0.3,0.5,0.6,0.6 C4.3,14,4.9,14,5.9,14H8c0.4,0,0.7,0.3,0.7,0.7S8.4,15.3,8,15.3z M12,14.7c-0.4,0-0.7-0.3-0.7-0.7v-1.3H10c-0.4,0-0.7-0.3-0.7-0.7 s0.3-0.7,0.7-0.7h1.3V10c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v1.3H14c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7h-1.3V14 C12.7,14.4,12.4,14.7,12,14.7z M6.7,10.7H5.3c-0.4,0-0.7-0.3-0.7-0.7S5,9.3,5.3,9.3h1.3C7,9.3,7.3,9.6,7.3,10S7,10.7,6.7,10.7z  M9.3,8h-4C5,8,4.7,7.7,4.7,7.3S5,6.7,5.3,6.7h4C9.7,6.7,10,7,10,7.3S9.7,8,9.3,8z M10.7,5.3H5.3C5,5.3,4.7,5,4.7,4.7S5,4,5.3,4 h5.3c0.4,0,0.7,0.3,0.7,0.7S11,5.3,10.7,5.3z"/>'
  }
})
