/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document-file': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M10.1,15.3H5.9c-1.2,0-1.8,0-2.4-0.3c-0.5-0.3-0.9-0.7-1.2-1.2C2,13.3,2,12.7,2,11.5V4.5c0-1.2,0-1.8,0.3-2.4 C2.5,1.6,3,1.2,3.5,1C4,0.7,4.6,0.7,5.9,0.7h3.5c0.2,0,0.3,0.1,0.5,0.2l4,4C13.9,5,14,5.2,14,5.3v6.1c0,1.2,0,1.8-0.3,2.4 c-0.3,0.5-0.7,0.9-1.2,1.2C12,15.3,11.4,15.3,10.1,15.3z M5.9,2c-1,0-1.5,0-1.8,0.1C3.8,2.3,3.6,2.5,3.5,2.7C3.3,3,3.3,3.6,3.3,4.5 v6.9c0,1,0,1.5,0.1,1.8c0.1,0.2,0.3,0.5,0.6,0.6C4.3,14,4.9,14,5.9,14h4.3c1,0,1.5,0,1.8-0.1c0.2-0.1,0.5-0.3,0.6-0.6 c0.1-0.3,0.1-0.8,0.1-1.8V6h-2.3c-0.4,0-0.7,0-1-0.1C9.1,5.7,8.9,5.5,8.8,5.3C8.7,5,8.7,4.7,8.7,4.3V2H5.9z M10,2.9v1.3 c0,0.2,0,0.4,0,0.4c0,0,0.2,0,0.4,0h1.3L10,2.9z M10.7,12H5.3c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h5.3c0.4,0,0.7,0.3,0.7,0.7 S11,12,10.7,12z M10.7,9.3H5.3C5,9.3,4.7,9,4.7,8.7C4.7,8.3,5,8,5.3,8h5.3c0.4,0,0.7,0.3,0.7,0.7C11.3,9,11,9.3,10.7,9.3z M6.7,6.7 H5.3C5,6.7,4.7,6.4,4.7,6S5,5.3,5.3,5.3h1.3C7,5.3,7.3,5.6,7.3,6S7,6.7,6.7,6.7z"/>'
  }
})
