<template>
    <div class="main">
        <div class="borrower-sidemenu">
            <h1 class="borrower-sidemenu__title">
                {{ $t('valuer_tool.borrower.overview') }}
            </h1>
            <!-- ADDRESS -->
            <div v-if="borrowerData.address" class="borrower-sidemenu__step">
                <div class="borrower-sidemenu__icon">
                    <img src="@/assets/icons/ic-marker-pin.svg" alt="" />
                </div>
                <div class="borrower-sidemenu__content">
                    <p>
                        {{ formatAddress.firstLine }}
                    </p>
                    <p>
                        {{ formatAddress.secondLine }}
                    </p>
                </div>
            </div>

            <!-- APPOINTMENT DATE -->
            <div v-if="borrowerData.appointment" class="borrower-sidemenu__step">
                <div class="borrower-sidemenu__icon">
                    <img src="@/assets/icons/ic-calendar.svg" alt="" />
                </div>
                <div class="borrower-sidemenu__content">
                    {{ formatDate(borrowerData.appointment.date) }}
                </div>
            </div>

            <!-- APPOINTMENT TIME -->
            <div v-if="borrowerData.appointment" class="borrower-sidemenu__step">
                <div class="borrower-sidemenu__icon">
                    <img src="@/assets/icons/ic-clock.svg" alt="" />
                </div>
                <div class="borrower-sidemenu__content">
                    {{ formatTime(borrowerData.appointment.start_time) }} -
                    {{ formatTime(borrowerData.appointment.end_time) }}
                </div>
            </div>

            <!-- PAYMENT -->
            <div
                v-if="borrowerData.payment && currentStep?.progress > 2"
                class="borrower-sidemenu__step"
            >
                <div class="borrower-sidemenu__content--payment">
                    <div class="borrower-sidemenu__price-row">
                        <p>{{ $t('monolith.global.subtotal') }}</p>
                        <p>{{ formatPrice(borrowerData.payment.subtotal) }}</p>
                    </div>
                    <div class="borrower-sidemenu__price-row">
                        <p>{{ $t('monolith.global.vat') }}</p>
                        <p>{{ formatPrice(borrowerData.payment.vat) }}</p>
                    </div>
                    <div class="borrower-sidemenu__price-row">
                        <p>{{ $t('monolith.global.total') }}</p>
                        <p>{{ formatPrice(borrowerData.payment.total) }}</p>
                    </div>
                </div>
            </div>

            <!-- WARNING MESSAGE-->
            <div
                v-if="currentStep?.progress <= 2"
                class="borrower-sidemenu__step borrower-sidemenu__step--warning"
            >
                <div class="borrower-sidemenu__icon">
                    <img src="@/assets/icons/ic-warning.svg" alt="" />
                </div>
                <div class="borrower-sidemenu__content">
                    {{ $t('valuer_tool.borrower.access_property_warning') }}
                </div>
            </div>
        </div>
        <!-- CANT FIND SLOT MESSAGE-->
        <div
            v-if="!borrowerData.appointment"
            class="borrower-sidemenu borrower-sidemenu--slot-message "
        >
            <div class="borrower-sidemenu__content">
                <h1 class="borrower-sidemenu__title borrower-sidemenu__title--big">
                    {{ $t('valuer_tool.borrower.calendar.message_title') }}
                </h1>
                <p class="borrower-sidemenu__step">
                    {{ $t('valuer_tool.borrower.calendar.message_content') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'

export default {
    name: 'SideOverview',
    props: {
        steps: {
            type: Object,
            default: () => ({}),
        },
        currentStep: {
            type: Object,
            default: () => ({
                progress: undefined,
            }),
        },
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        formatAddress() {
            return utils.formatAddress(this.borrowerData.address)
        },
    },
    methods: {
        formatDate(aDate) {
            return utils.formatDate(aDate, true, this.$route.params.lang)
        },
        formatTime(time) {
            return utils.formatTime(time)
        },
        formatPrice(val) {
            return utils.int_value_formatter(+val, this.$route.params.lang, 2)
        },
    },
}
</script>

<style scoped lang="scss">
.borrower-sidemenu {
    display: flex;
    min-width: 18.75rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.5rem 0.5rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    max-width: 18rem;

    &--slot-message {
        margin-top: 1.5rem;
    }

    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.52081rem;
        width: 2.5rem;
        height: 2.5rem;
        margin-block: auto;
        border-radius: 4.16669rem;
        background: rgba(0, 150, 255, 0.2);
    }

    &__title {
        color: #001837;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
        border-bottom: 1px solid #d6e2e9;
        min-width: 100%;
        display: flex;
        padding: 1.5rem;
        align-items: flex-start;
        gap: 1.25rem;
        align-self: stretch;
        &--big {
            font-size: 1.5rem;
            text-align: left;
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__content {
        color: #001837;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
        &--payment {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;
            min-width: 100%;
            color: #001837;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__step {
        display: flex;
        padding: 1.5rem;
        align-items: center;
        gap: 1.25rem;
        align-self: stretch;
        &--warning {
            display: flex;
            padding: 1rem;
            margin: 1.5rem;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            flex: 1 0 0;
            border-radius: 0.5rem;
            border: 1px solid #fa0;
            background: rgba(255, 170, 0, 0.2);
            .borrower-sidemenu__content {
                text-align: center;
            }
            .borrower-sidemenu__icon {
                display: flex;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0.52081rem;
                justify-content: center;
                align-items: center;
                gap: 0.52081rem;
                border-radius: 4.16669rem;
                background: rgba(255, 170, 0, 0.2);
            }
        }
    }

    &__price-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        min-width: 100%;
    }

    &__step:not(:last-child) {
        border-bottom: 1px solid #d6e2e9;
    }

    &__loading {
        position: relative;
        min-height: 20rem;
    }
}
</style>

<style lang="scss">
.borrower-sidemenu {
    .loading-overlay {
        border-radius: 0.5rem;
        .loading-background {
            background: white;
        }
    }
}
</style>
