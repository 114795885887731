<template>
    <div class="main">
        <h1>{{ $t('valuer_tool.borrower.valuer_info.title') }}</h1>
        <p>
            {{ $t('valuer_tool.borrower.valuer_info.description') }}
        </p>
        <div class="main__content">
            <div class="cards">
                <div class="card">
                    <div class="card__title">
                        {{ $t('valuer_tool.borrower.valuer_info.profile_card-title') }}
                    </div>
                    <div class="card-content">
                        <div class="card-content__section">
                            <h2>{{ $t('monolith.global.email') }}:</h2>
                            <span>{{ getEmail }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="getAccreditations && getAccreditations.length" class="card">
                    <div class="card__title">
                        {{ $t('valuer_tool.valuer.accreditations') }}
                    </div>
                    <div class="card-content accreditations-grid">
                        <accreditation-card
                            v-for="acc in getAccreditations"
                            v-show="acc.accreditation_number"
                            :key="acc.id"
                            :acc="acc"
                            :editable="canValuerEditOwnAccreds"
                            @save="saveAccreditation"
                            @delete="deleteAccreditation"
                        />
                        <div
                            v-if="canValuerEditOwnAccreds"
                            class="card card--mini new_accr-card"
                        >
                            <b-input
                                v-model="newAccreditation.institution"
                                type="text"
                                size="is-small"
                                :placeholder="
                                    $t('admin_panel.user_mgmt.accreditation_institution')
                                "
                            />
                            <b-input
                                v-model="newAccreditation.accreditation_number"
                                type="text"
                                size="is-small"
                                :placeholder="
                                    $t(
                                        'valuer_tool.borrower.valuer_info.accreditation_card-number'
                                    )
                                "
                            />
                            <b-button
                                :type="newAccreditationDisabled || 'is-primary'"
                                size="is-small"
                                :disabled="newAccreditationDisabled"
                                @click="saveAccreditation(newAccreditation)"
                            >
                                {{ $t('admin_panel.user_mgmt.add_accreditation') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AccreditationCard from './AccreditationCard'

export default {
    name: 'ValuerInfoTab',
    components: {
        AccreditationCard,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        isDeletingAccr: null,
        newAccreditation: {
            institution: null,
            accreditation_number: null,
        },
    }),
    computed: {
        ...mapGetters('auth', ['getEmail', 'getAccreditations']),
        newAccreditationDisabled() {
            return (
                !this.newAccreditation.accreditation_number ||
                utils.isEmptyStr(this.newAccreditation.accreditation_number) ||
                !this.newAccreditation.institution ||
                utils.isEmptyStr(this.newAccreditation.institution)
            )
        },
        canValuerEditOwnAccreds() {
            return this.$config.ALLOW_VALUER_ACCREDITATION_EDITING
        },
    },
    methods: {
        ...mapActions('auth', ['fetchAccreditations']),
        saveAccreditation(acc) {
            let url
            let method

            if (acc.id) {
                url = utils.urlJoin(this.$config.AUTH_API_URL, ['accreditations', acc.id])
                method = 'patch'
            } else {
                url = utils.urlJoin(this.$config.AUTH_API_URL, [
                    'accreditations',
                    'user',
                    this.effectiveUserId,
                ])
                method = 'post'
            }

            this.$axios({
                method,
                url,
                data: {
                    institution: acc.institution,
                    accreditation_number: acc.accreditation_number,
                },
            }).then(() => {
                this.fetchAccreditations()
                if (!acc.id) {
                    this.newAccreditation = {
                        institution: null,
                        accreditation_number: null,
                    }
                }
            })
        },
        deleteAccreditation(id) {
            let url = utils.urlJoin(this.$config.AUTH_API_URL, ['accreditations', id])

            this.$axios.delete(url).then(() => {
                this.fetchAccreditations()
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-block: 2.5rem;
    gap: 3rem;
    flex: 1 0 0;
    h1 {
        color: #001837;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    p {
        color: #798da6;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    &__content {
        display: flex;
        min-width: 100%;
        gap: 1rem;
        flex-wrap: wrap;
    }
}

.cards {
    --cards-grid-gap: 1rem;
    --card-section-gap: 0.25rem;

    display: flex;
    gap: var(--cards-grid-gap);
    align-items: stretch;
    flex-direction: column;
    width: 100%;

    .card,
    ::v-deep .card {
        flex: 1 0 0;
        max-width: calc((100% - var(--cards-grid-gap)) / 2);

        &__title {
            display: flex;
            padding: 1rem 1.5rem;
            align-items: flex-start;
            gap: 1.25rem;
            align-self: stretch;
            border-bottom: 1px solid #d6e2e9;
            background: #fff;
            color: #001837;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 125% */
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }
        &-content {
            --card-content-gap: 1.5rem;
            display: flex;
            padding: 1.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--card-content-gap);
            align-self: stretch;
            &__section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--card-section-gap);

                &-title {
                    color: #001837;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.25rem; /* 100% */
                    margin-bottom: 1rem;
                }
                h2 {
                    color: rgba(0, 24, 55, 0.6);
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem; /* 166.667% */
                }
                span {
                    color: #001837;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem; /* 142.857% */
                }
            }

            &.accreditations-grid {
                --card-content-gap: 1rem;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
        &.card--mini {
            padding: 0.75rem;
            flex: 1 0 40%;
            max-width: calc((100% - var(--card-content-gap)) / 2);

            .card {
                &__section-title {
                    width: 100%;
                    display: flex;
                    border-bottom: 0;
                    padding: 0;
                    align-items: center;
                    justify-content: space-between;
                    gap: var(--card-section-gap);
                    color: #001837;
                    font-style: normal;
                    font-weight: 700;
                    margin-bottom: calc(
                        var(--card-content-gap) - var(--card-section-gap)
                    );

                    &-actions {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }

            &.new_accr-card {
                display: flex;
                flex-direction: column;
                gap: var(--card-section-gap);
            }
        }
    }
}
</style>
