<template>
    <div class="is-w100">
        <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
            Documents
        </h2>
        <div class="documents-grid">
            <div />
            <span class="has-text-centered has-text-black">Validated purpose</span>
            <span class="has-text-centered has-text-black">Remark</span>
            <span class="has-text-centered has-text-black">Validate?</span>
            <div />
            <div />
            <span class="has-text-right has-text-black in-grid-text">
                Validate all
            </span>
            <div>
                <b-button
                    class="action-button is-success"
                    rounded
                    @click="validateAll(true)"
                >
                    <b-icon class="button-icon" icon="check"></b-icon>
                </b-button>
                <b-button
                    class="action-button is-danger"
                    rounded
                    @click="validateAll(false)"
                >
                    <b-icon class="button-icon" icon="close"></b-icon>
                </b-button>
            </div>
            <template v-for="document in getDocuments">
                <div :key="`info_${document.document_ref}`" class="document-info">
                    <a
                        :href="documentURL(document.document_ref)"
                        class="upload-name"
                        target="_blank"
                    >
                        <span class="has-text-primary">
                            {{ document.filename }}
                        </span>
                    </a>
                    <span class="upload-purpose">
                        {{ $tc(`document.${document.purpose}`, 1) }}
                    </span>
                </div>
                <b-select
                    :key="`val_purpose_${document.document_ref}`"
                    v-model="document.validation_purpose"
                >
                    <option disabled :value="null">
                        Validated purpose
                    </option>
                    <option
                        v-for="purpose in purposes"
                        :key="`purp_${purpose.value}`"
                        :value="purpose.value"
                    >
                        {{ purpose.label }}
                    </option>
                </b-select>
                <b-input
                    :key="`remark_${document.document_ref}`"
                    v-model="document.validation_remark"
                    expanded
                    class="document-remark"
                    placeholder="Remark"
                />
                <validation-toggle
                    :key="`valid_${document.document_ref}`"
                    v-model="document.valid"
                />
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import ValidationToggle from './ValidationToggle.vue'

export default {
    name: 'DocumentsMgmt',
    components: { ValidationToggle },
    computed: {
        ...mapGetters('valuationStore', ['getDocuments']),
        purposes() {
            return [
                {
                    value: 'epc_certificate',
                    label: 'EPC certificate',
                },
                {
                    value: 'ers_report',
                    label: 'ERS report',
                },
                {
                    value: 'exterior_picture',
                    label: 'Exterior Picture',
                },
                {
                    value: 'interior_picture',
                    label: 'Interior picture',
                },
                {
                    value: 'sales_agreement',
                    label: 'Sales agreement (compromis)',
                },
                {
                    value: 'deed_of_sale',
                    label: 'Deed of sale (acte)',
                },
                {
                    value: 'listing',
                    label: 'Listing ad',
                },
                {
                    value: 'buyer_data',
                    label: 'Buyer data',
                },
                {
                    value: 'renovation_quotation',
                    label: 'Renovation quotation',
                },
                {
                    value: 'renovation_specifications',
                    label: 'Renovation specifications',
                },
                {
                    value: 'specifications',
                    label: 'Specifications (cahier de charges / lastenboek)',
                },
                {
                    value: 'building_blueprint',
                    label: 'Building blueprint (bouwtekening / plans du bâtiment)',
                },
                {
                    value: 'cadastral_plan',
                    label: 'Cadastral plan',
                },
                {
                    value: 'expected_epc',
                    label: 'Expected EPC',
                },
                {
                    value: 'email',
                    label: 'Email',
                },
                {
                    value: 'external_report',
                    label: 'External report',
                },
                {
                    value: 'avm_report',
                    label: 'Rock.estate AVM Report',
                },
                {
                    value: 'dvm_report',
                    label: 'Rock.estate DVM Report',
                },
                {
                    value: 'other',
                    label: 'Other',
                },
            ]
        },
    },
    methods: {
        documentURL(document_ref) {
            return (
                utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                    this.$config.VALUATION_API_URL,
                    'document',
                    document_ref,
                ]) + '?view=true'
            )
        },
        validateAll(validation) {
            this.getDocuments.forEach((document) => {
                document.valid = validation
            })
        },
    },
}
</script>
<style lang="scss" scoped>
@import '~bulma/sass/utilities/functions';

.documents-grid {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    align-items: center;
    gap: 1rem 1.5rem;

    .document-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .in-grid-text {
        align-self: center;
        height: fit-content;
    }

    .upload-name {
        color: var(--black);
    }

    .upload-purpose {
        font-size: 0.8rem;
        line-height: 0.8rem;
    }

    .document-remark {
        flex-grow: 1;
    }

    .action-button {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        &:not(:first-child) {
            margin-left: 1rem;
        }

        .button-icon {
            height: 37px;
            width: auto;
        }

        & > span {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
