<template>
    <Card class="savings">
        <div class="savings__title">
            {{ $t('energy.result.savings.title') }}
        </div>
        <div class="savings__results results">
            <div class="results__epc epc">
                <div class="epc__label label">
                    {{ $t('energy.result.savings.epc') }}
                </div>
                <div class="epc__value value">
                    {{ parseInt(getRenovationsSummary.epcSaving) }}
                    {{ $t('unit.kwh/m2year') }}
                </div>
            </div>

            <div class="results__carbon carbon">
                <div class="carbon__label label">
                    {{ $t('energy.result.savings.carbon') }}
                </div>
                <div class="carbon__value value">
                    {{ parseInt(getSelectedScenario.co2_savings) }}
                    {{ $t('unit.kgco2/year') }}
                </div>
            </div>

            <div class="results__bill bill">
                <div class="bill__label label">
                    {{ $t('energy.result.savings.bills') }}
                </div>
                <div class="bill__value value">
                    {{ int_value_formatter(getSelectedScenario.yearly_savings / 12) }}
                    {{ $t('unit.month') }}
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Card from './Card.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Savings',
    components: { Card },
    computed: {
        ...mapGetters('ersStore', ['getRenovationsSummary', 'getSelectedScenario']),
    },
    methods: {
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
    },
}
</script>

<style lang="scss" scoped>
.savings {
    max-width: 550px;
    height: fit-content;
    background: white;
    margin-block-start: 20px;
    padding: 20px;
}

.savings__title {
    margin-block-end: 1.5rem;
    color: #001837;
    font-family: Satoshi;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.savings__results {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.results__epc,
.results__carbon,
.results__bill {
    display: flex;
    justify-content: space-between;
}

.label {
    color: rgba(0, 24, 55, 0.5);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.value {
    color: #001837;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media print {
    .savings {
        padding: 0.3cm;
        background: #eefbef;

        &__title {
            line-height: normal;
        }

        &__results {
            gap: 0.2rem;
        }
    }
}
</style>
