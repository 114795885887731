/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile-menu': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path d="M21,19.5H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,19.5,21,19.5z M21,13.5H3c-0.6,0-1-0.4-1-1 c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1C22,13.1,21.6,13.5,21,13.5z M21,7.5H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1 S21.6,7.5,21,7.5z"/>'
  }
})
