<template>
    <button class="main" :class="{ 'main--selected': selected }" @click="$emit('click')">
        <div class="main__content">
            <slot />
        </div>
        <img
            v-if="selected"
            src="@/assets/icons/ic-radio-selected.svg"
            class="main__radio"
            alt=""
        />
        <img v-else src="@/assets/icons/ic-radio.svg" class="main__radio" alt="" />
    </button>
</template>

<script>
export default {
    name: 'PackageButton',
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    min-width: 20rem;
    display: flex;
    padding: 1.5rem;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    position: relative;
    cursor: pointer;
    &__radio {
        position: absolute;
        right: 1.33rem;
        top: 1rem;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        text-align: left;
    }
    &--selected {
        border-radius: 0.3125rem;
        border: 1px solid #009cff;
        background: linear-gradient(
                0deg,
                rgba(0, 156, 255, 0.05) 0%,
                rgba(0, 156, 255, 0.05) 100%
            ),
            #fff;
        box-shadow: 0px 2px 14px 0px rgba(0, 156, 255, 0.2);
    }
}
@media print {
    .main {
        &__radio {
            display: none;
        }
    }
}
</style>
