/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M9,14.7H7c-1.1,0-1.7,0-2.3-0.3c-0.5-0.2-0.9-0.6-1.2-1.1c-0.3-0.5-0.3-1.1-0.4-2.3L2.7,4.7H2 C1.6,4.7,1.3,4.4,1.3,4S1.6,3.3,2,3.3h1.3c0,0,0,0,0,0h9.3c0,0,0,0,0,0H14c0.4,0,0.7,0.3,0.7,0.7S14.4,4.7,14,4.7h-0.7l-0.4,6.4 c-0.1,1.1-0.1,1.7-0.4,2.3c-0.3,0.5-0.7,0.8-1.2,1.1C10.7,14.7,10.1,14.7,9,14.7z M4,4.7L4.5,11c0.1,0.9,0.1,1.4,0.2,1.7 c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.1,0.8,0.1,1.7,0.1h2c0.9,0,1.4,0,1.7-0.1c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.3,0.2-0.8,0.2-1.7L12,4.7 H4z M9.3,11c-0.4,0-0.7-0.3-0.7-0.7V7c0-0.4,0.3-0.7,0.7-0.7S10,6.6,10,7v3.3C10,10.7,9.7,11,9.3,11z M6.7,11C6.3,11,6,10.7,6,10.3 V7c0-0.4,0.3-0.7,0.7-0.7S7.3,6.6,7.3,7v3.3C7.3,10.7,7,11,6.7,11z M10,2.7H6C5.6,2.7,5.3,2.4,5.3,2S5.6,1.3,6,1.3h4 c0.4,0,0.7,0.3,0.7,0.7S10.4,2.7,10,2.7z"/>'
  }
})
