import { onlineManager } from '@tanstack/vue-query'
import { ref } from 'vue'

const isOnline = ref(onlineManager.isOnline())
onlineManager.subscribe((online) => (isOnline.value = online))

const useOnline = () => {
    const setOnline = (online) => {
        onlineManager.setOnline(online)
    }
    return { isOnline, setOnline }
}

export { useOnline }
