/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person': {
    width: 36,
    height: 90,
    viewBox: '0 0 36 90',
    data: '<path pid="0" d="M27 22.5H9c-4.95 0-9 4.05-9 9v26a1 1 0 001 1h8V89a1 1 0 001 1h16a1 1 0 001-1V58.5h8a1 1 0 001-1v-26c0-4.95-4.05-9-9-9zM18 18a9 9 0 100-18 9 9 0 000 18z" _fill="#798DA6" fill-opacity=".4"/>'
  }
})
