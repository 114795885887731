var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"renovations__item item",class:{
        'item--selected': _vm.selected,
        'item--selectable': _vm.selectable,
        'item--disabled': !_vm.selectable && !_vm.selected,
    }},[_c('button',{staticClass:"card-button",on:{"click":function($event){!_vm.isTooltipOpen && _vm.$emit('click')}}}),_c('div',{staticClass:"invisible-layer"},[_c('div',{staticClass:"item__text text"},[_c('div',{staticClass:"text__title"},[(_vm.reportIndex >= 0)?_c('div',{staticClass:"renovation__number"},[_vm._v(" "+_vm._s(_vm.reportIndex)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(`energy.result.renovation.${_vm.getName}`))+" ")]),_c('Tooltip',{staticClass:"text__tooltip",attrs:{"disabled":!_vm.selectable && !_vm.selected,"html":_vm.$t(`energy.result.renovation.${_vm.getName}_tooltip_popup`, {
                        tooltip_title: _vm.$t(`energy.result.renovation.${_vm.getName}`),
                    })},on:{"update-tooltip-state":(value) => _vm.handleTooltipState(value)}})],1),_c('img',{staticClass:"item__icon",attrs:{"src":_vm.icon}}),_c('div',{staticClass:"item__table table"},[_c('div',{staticClass:"table__price price"},[_c('div',{staticClass:"price__label"},[_vm._v(" "+_vm._s(_vm.$t('common.price'))+" ")]),_c('p',{staticClass:"price__value"},[_vm._v(_vm._s(_vm.int_value_formatter(_vm.getPrice)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }