<template>
    <grid-card :title="$t('valuer_tool.general.state.title')">
        <template slot="header">
            <white-button class="docs_buttn" @click="openDocuments">
                <span>{{ $tc('document.epc_certificate', 1) }}</span>
                <svg-icon icon="documents" height="16" scale="1" color="#001837" />
            </white-button>
        </template>
        <template slot="default">
            <div class="grid_card-content_grid">
                <div v-if="f_epc_label" class="content_grid-item content_grid-item--full">
                    <b-field :label="$t('valuer_tool.general.state.epc_label')">
                        <epc-array-flanders
                            v-if="isFlanders"
                            interactive
                            :current-label="f_epc_label"
                            @select-epc="selectEpc"
                        />
                        <epc-array-bru
                            v-else-if="isBrussels"
                            interactive
                            :current-label="f_epc_label"
                            @select-epc="selectEpc"
                        />
                        <epc-array-wal
                            v-else-if="isWallonia"
                            interactive
                            :current-label="f_epc_label"
                            @select-epc="selectEpc"
                        />
                    </b-field>
                </div>
                <div class="content_grid-item">
                    <b-field :label="$t('features.f_epc_numeric')">
                        <b-input
                            v-model.number="f_epc_numeric"
                            type="number"
                            @input="updateEpcLabel"
                        />
                    </b-field>
                </div>
                <div class="content_grid-item">
                    <b-field :label="$t('features.f_construction_year')">
                        <b-input v-model.number="f_construction_year" type="number" />
                    </b-field>
                </div>
            </div>
        </template>
        <template slot="modal">
            <b-modal v-model="documentsShown" width="fit-content">
                <documents-modal purpose="epc_certificate" />
            </b-modal>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'
import DocumentsModal from '@/apps/Valuation/components/ValuerTool/elements/DocMgmt/DocumentsModal.vue'
import EpcArrayFlanders from './EpcArrayFlanders.vue'
import EpcArrayBru from './EpcArrayBru.vue'
import EpcArrayWal from './EpcArrayWal.vue'
import utils from '@/shared/plugins/utils'
import enums from '@/shared/plugins/enums'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import debounce from 'lodash/debounce'

export default {
    name: 'State',
    components: {
        GridCard,
        WhiteButton,
        EpcArrayFlanders,
        EpcArrayBru,
        EpcArrayWal,
        DocumentsModal,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
            ...mapMutations(requestObjects, ['SET_FEATURES']),
        }
    },
    data: () => ({
        f_epc_label: null,
        f_epc_numeric: null,
        f_construction_year: null,
        documentsShown: false,
    }),
    computed: {
        isFlanders() {
            return ['Flanders', 'BE-VLG'].includes(this.getFeatures.region)
        },
        isBrussels() {
            return ['Brussels', 'BE-BRU'].includes(this.getFeatures.region)
        },
        isWallonia() {
            return ['Wallonia', 'BE-WAL'].includes(this.getFeatures.region)
        },
        epc_color() {
            let region = this.getFeatures.region

            return utils.epc_color(region, label)
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_epc_label: debounce(function(val) {
            this.SET_FEATURES({
                f_epc_label: val,
            })
        }, 250),
        f_construction_year: debounce(function(val) {
            this.SET_FEATURES({
                f_construction_year: +val,
            })
        }, 250),
        f_epc_numeric: debounce(function(val) {
            if (val === null || typeof val === 'undefined') {
                this.f_epc_numeric = 0
            }

            this.SET_FEATURES({
                f_epc_numeric: +val,
            })
        }, 250),
    },
    mounted() {
        this.f_epc_label = (() => {
            if (this.getFeatures.f_epc_label) return this.getFeatures.f_epc_label

            let region = this.getFeatures.region
            let epc_value = this.getFeatures.f_epc_numeric

            return utils.epc_label(region, epc_value)
        })()

        this.f_epc_numeric = (() => {
            if (this.getFeatures.f_epc_numeric) return this.getFeatures.f_epc_numeric

            let region = this.getFeatures.region
            let epc_label = this.getFeatures.f_epc_label

            return region && epc_label ? enums.epc_mapping[region][epc_label] : null
        })()

        this.f_construction_year = this.getFeatures.f_construction_year
    },
    methods: {
        // Debounce to not trigger updates on every keystroke
        updateEpcLabel: debounce(function() {
            this.f_epc_label = utils.epc_label(
                this.getFeatures.region,
                this.f_epc_numeric
            )
        }, 250),
        selectEpc(epc) {
            this.f_epc_numeric = enums.epc_mapping[this.getFeatures.region][epc]
            this.f_epc_label = epc

            // this.SET_FEATURES({
            //     f_epc_label: this.f_epc_label,
            //     f_epc_numeric: this.f_epc_numeric,
            // })
        },
        openDocuments() {
            this.documentsShown = true
        },
        closeDocuments() {
            this.documentsShown = false
        },
    },
}
</script>
