<template>
    <b-loading v-if="isLoading" v-model="showLoading" />
    <div v-else class="valuer_tool-default-layout-grid">
        <building-violations />
        <basic-info />
        <state />
        <size />
        <apartment-features v-if="is_apartment" />
        <value />
        <ref-points />
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import ApartmentFeatures from './cards/ApartmentFeatures.vue'
import BasicInfo from './cards/BasicInfo.vue'
import BuildingViolations from './cards/BuildingViolations.vue'
import State from './cards/State'
import Size from './cards/Size.vue'
import Value from './cards/Value'
import RefPoints from './cards/RefPoints'

export default {
    name: 'General',
    components: {
        ApartmentFeatures,
        BuildingViolations,
        BasicInfo,
        State,
        Size,
        Value,
        RefPoints,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'getFeatures',
                'getFullValuationStore',
                'isLoading',
            ]),
        }
    },
    computed: {
        building_type() {
            return this.getFeatures?.f_building_type
        },
        is_apartment() {
            // TODO: fix race condition that makes this.building_type occasionally be null instead of 'apartment'
            return utils.is_apartment(this.building_type)
        },
        showLoading() {
            return !this.getFullValuationStore.Address.isLoaded
        },
    },
}
</script>
<style lang="scss" scoped></style>
