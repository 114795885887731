import { render, staticRenderFns } from "./BuildingTypeSelect.vue?vue&type=template&id=04e2a027"
import script from "./BuildingTypeSelect.vue?vue&type=script&lang=js"
export * from "./BuildingTypeSelect.vue?vue&type=script&lang=js"
import style0 from "./BuildingTypeSelect.vue?vue&type=style&index=0&id=04e2a027&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BuildingTypeSelect.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports