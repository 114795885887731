<!-- eslint-disable vue/no-v-html -->
<i18n>
{
    "fr-BE":{
        "step5": {
            "title": "Télécharger les résultats",
            "subtitle": "Entrez l'adresse email ci-dessous à laquelle nous pouvons envoyer les résultats.",
            "text1": "Le graphique montre comment ce prix cible est lié aux prix de 200 maisons de la région.",
            "text2": "Il existe plusieurs facteurs qui influencent le prix demandé, de sorte que notre prix cible s'écartera également. Ci-dessous, nous vous donnons quelques <b> exemples </b> de ce qui peut influencer le prix.",
            "card1": {
                "title": "Pourquoi le prix demandé pourrait-il être plus bas?",
                "li_1": "La maison est désuète et doit être entièrement rénovée",
                "li_2": "La maison est loin des commerces"
            },
            "card2": {
                "title": "Pourquoi le prix demandé pourrait-il être plus élevé?",
                "li_1": "La maison vient d'être rénovée",
                "li_2": "La maison a un bel emplacement",
                "li_3": "Il y a beaucoup de lumière dans la maison"
            },
            "send": "envoyer"
        }
    },
    "nl-BE":{
        "step5": {
            "title": "Resultaten downloaden",
            "subtitle": "Vul hieronder het email adres in waar we de resultaten naartoe mogen sturen.",
            "text1": "Op de grafiek ziet u hoe deze richtprijs zich verhoudt tot de woning prijzen van de 200 woningen in de buurt.",
            "text2": "Er zijn meerdere factoren die invloed hebben op de vraagprijs waardoor onze richtprijs ook zal afwijken. Hieronder geven we je enkele <b>voorbeelden</b> wat de prijs kan beïnvloeden.",
            "card1": {
                "title": "Waarom zou de vraagrijs lager kunnen zijn?",
                "li_1": "De woning is verouderd en moet volledig gerenoveerd worden",
                "li_2": "De woning ligt ver uit de buurt van winkels"
            },
            "card2": {
                "title": "Waarom zou de vraagrijs hoger kunnen zijn?",
                "li_1": "De woning is net gerenoveerd",
                "li_2": "De woning heeft een prachtige ligging",
                "li_3": "Er is veel lichtinval in de woning"
            },
            "send": "Verzenden"
        }
    },
    "en-BE":{
        "step5": {
            "title": "Download results",
            "subtitle": "Enter the email address below to which we can send the results.",
            "text1": "The graph shows how this target price relates to the house prices of the 200 houses in the area.",
            "text2": "There are several factors that influence the asking price, so that our target price will also deviate. Below we give you some <b> examples </b> what can influence the price.",
            "card1": {
                "title": "Why could the asking price be lower?",
                "li_1": "The house is outdated and needs to be completely renovated",
                "li_2": "The house is far away from shops"
            },
            "card2": {
                "title": "Why could the asking price be higher?",
                "li_1": "The house has just been renovated",
                "li_2": "The house has a beautiful location",
                "li_3": "There is a lot of light in the house"
            },
            "send": "Send"
        }   
    }
}
</i18n>

<template>
    <div id="step5">
        <div class="small-title-box">
            <!-- <transition name="fade-move-opacity-small"> -->
            <transition name="fade-move-opacity-small">
                <div v-show="animation.part1" class="small-title">
                    {{ $t('step5.text1') }}
                </div>
            </transition>
            <transition name="fade-move-opacity-small">
                <div
                    v-show="animation.part3"
                    class="small-title title2"
                    v-html="$t('step5.text2')"
                ></div>
            </transition>
        </div>
        <transition name="fade-opacity">
            <div class="chart1">
                <cost-plot
                    :part="part"
                    :estimation_results_data="addressInfo.estimationResultsData"
                    :input_data="getFeatures"
                />
            </div>
        </transition>
        <transition name="fade-move-opacity-small">
            <div v-show="animation.part2boxes" class="description-boxes">
                <div class="description-box">
                    <div class="title">
                        {{ $t('step5.card1.title') }}
                    </div>
                    <ul>
                        <li>
                            {{ $t('step5.card1.li_1') }}
                        </li>
                        <li>{{ $t('step5.card1.li_2') }}</li>
                    </ul>
                </div>
                <div class="description-box">
                    <div class="title">
                        {{ $t('step5.card2.title') }}
                    </div>
                    <ul>
                        <li>{{ $t('step5.card2.li_1') }}</li>
                        <li>{{ $t('step5.card2.li_2') }}</li>
                        <li>{{ $t('step5.card2.li_3') }}</li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import CostPlot from './CostPlot.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Step6',
    components: { CostPlot },
    props: ['modalActive'],
    data() {
        return {
            email: '',
            animation: {
                part1: false,
                part2: false,
                part3: false,
                part2boxes: false,
            },
            part: 2,
            errors: {},
        }
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures', 'addressInfo']),
    },
    created() {
        this.animation.part2 = true
        this.animation.part3 = true
        this.animation.part2boxes = true
    },
    methods: {
        modalClose() {
            this.$emit('modalClose')
        },
        emailSend() {
            if (!this.errors.has('email_field')) {
                this.modalClose()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
#step5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0 50px;
    .small-title-box {
        height: 75px;
        margin-bottom: 80px;
        margin-top: 100px;
        transition: 0.4s all;
        &.box2 {
            transform: translateY(-50px);
        }
    }
    .small-title {
        width: 430px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #798da6;
        position: relative;
        transition: 0.4s all;
    }

    .chart1 {
        max-width: 940px;
        width: 100%;
        height: 150px;
        margin-bottom: 50px;
        transition: 0.5s all;
        &.chart2 {
            max-width: 820px;
            transition: 0.5s all;
            transform: scale(1.3) translateY(-90px);
            margin-top: 50px;
            margin-bottom: 0px;
        }
    }

    .description-boxes {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s all;
    }
    .description-box {
        width: 380px;
        height: 195px;
        border-radius: 5px;
        box-shadow: 0 3px 7px 0 rgba(0, 47, 80, 0.1);
        border: solid 1px #e5eaed;
        background-color: #ffffff;
        padding: 20px;
        box-sizing: border-box;
        margin-right: 57px;
        &:last-child {
            margin-right: 0;
        }

        .title {
            font-size: 16px;
            font-weight: 900;
            line-height: 1.75;
            color: #001837;
        }

        ul {
            list-style: none;
        }
        li {
            font-size: 14px;
            line-height: 1.36;
            color: #798da6;
            margin-bottom: 14px;
            &::before {
                content: '\2022';
                color: #798da6;
                font-weight: bold;
                display: inline-block;
                width: 1em;
            }
        }
    }
}
</style>
