// adapted from https://stackoverflow.com/a/18650249
const fileToDataURL = function(file) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(file)
    })
}

// adapted from https://stackoverflow.com/a/5100158
const dataURLtoFile = function(dataURL, filename = '') {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString
    if (dataURL.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURL.split(',')[1])
    else byteString = unescape(dataURL.split(',')[1])

    // separate out the mime component
    var mimeString = dataURL
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    return new File([ia], filename, { type: mimeString })
}

export { fileToDataURL, dataURLtoFile }
