<template>
    <a v-if="!isLoading" :href="getCalendarLink">
        {{ $t('valuer_tool.borrower.calendar.button') }}
    </a>
    <b-skeleton v-else width="100%" height="100%" rounded active />
</template>

<script>
import utils from '@/shared/plugins/utils'
import { createEvent } from 'ics'

export default {
    name: 'AddToCalendarButton',
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    data: function() {
        return {
            calendarUrl: null,
            isLoading: false,
        }
    },
    computed: {
        getCalendarLink() {
            return this.calendarUrl
        },
    },
    mounted() {
        this.generateCalendarLink()
    },
    methods: {
        generateCalendarLink() {
            this.isLoading = true
            const {
                streetname,
                streetnumber,
                postalcode,
                municipality,
                boxnumber,
                matched_f_lat,
                matched_f_lng,
            } = this.data.address

            const location = utils.formatAddress({
                streetname,
                streetnumber,
                postalcode,
                municipality,
                boxnumber,
            })

            const { start_time, end_time } = this.data.appointment

            const event = {
                start: [
                    start_time.getFullYear(),
                    start_time.getMonth() + 1,
                    start_time.getDate(),
                    start_time.getHours(),
                    start_time.getMinutes(),
                ],
                end: [
                    end_time.getFullYear(),
                    end_time.getMonth() + 1,
                    end_time.getDate(),
                    end_time.getHours(),
                    end_time.getMinutes(),
                ],
                location: `${location.firstLine} ${location.secondLine}`,
                title: this.title,
                status: 'CONFIRMED',
                busyStatus: 'BUSY',
                description: this.description,
                geo: {
                    lat: matched_f_lat,
                    lon: matched_f_lng,
                },
            }
            const filename = 'CalendarEvent.ics'
            const file = new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                    if (error) {
                        reject(error)
                    }

                    resolve(new File([value], filename, { type: 'text/calendar' }))
                })
            })
            file.then((url) => {
                this.calendarUrl = URL.createObjectURL(url)
                this.isLoading = false
            })
        },
    },
}
</script>

<style scoped lang="scss">
a {
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #fff !important;
    color: rgba(0, 24, 55, 0.7) !important;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
}
</style>
