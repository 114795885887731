<template>
    <form class="overview" @submit.prevent="(htmlElement) => onSubmit(htmlElement)">
        <div class="overview__title">
            <p>{{ $t('valuer_tool.borrower.appointment-overview.title') }}</p>
            <p>{{ $t('valuer_tool.borrower.appointment-overview.please-share') }}</p>
        </div>
        <div class="overview__appointment">
            <!-- ADDRESS -->
            <div v-if="borrowerData.address" class="overview__appointment-item">
                <div class="overview__appointment-icon">
                    <img src="@/assets/icons/ic-marker-pin.svg" alt="" />
                </div>
                <div class="overview__appointment-content">
                    <p>
                        {{ formatAddress.firstLine }}
                    </p>
                    <p>
                        {{ formatAddress.secondLine }}
                    </p>
                </div>
            </div>
            <!-- APPOINTMENT DATE -->
            <div v-if="borrowerData.appointment" class="overview__appointment-item">
                <div class="overview__appointment-icon">
                    <img src="@/assets/icons/ic-calendar.svg" alt="" />
                </div>
                <div class="overview__appointment-content">
                    {{ formatDate(borrowerData.appointment.date) }}
                </div>
            </div>
            <!-- APPOINTMENT TIME -->
            <div v-if="borrowerData.appointment" class="overview__appointment-item">
                <div class="overview__appointment-icon">
                    <img src="@/assets/icons/ic-clock.svg" alt="" />
                </div>
                <div class="overview__appointment-content">
                    {{ formatTime(borrowerData.appointment.start_time) }} -
                    {{ formatTime(borrowerData.appointment.end_time) }}
                </div>
            </div>
        </div>

        <div class="overview__property-access">
            <div class="overview__property-access-question">
                {{
                    $t(
                        'valuer_tool.borrower.appointment-overview.will-be-present_question'
                    )
                }}
            </div>
            <div class="overview__property-access-buttons">
                <yes-no
                    v-model="willBePresent"
                    is-small
                    class="overview__property-access-choice"
                />
            </div>
            <template v-if="!willBePresent">
                <div class="overview__property-access-question">
                    {{
                        $t(
                            'valuer_tool.borrower.appointment-overview.access_representative'
                        )
                    }}
                </div>
                <b-field class="overview__property-access-inputs" grouped>
                    <b-field
                        :message="[
                            $v.representative_role.$dirty &&
                            !$v.representative_role.requiredIfNotPresent
                                ? $t('validation.field_required')
                                : null,
                        ]"
                        :type="{
                            'is-danger':
                                $v.representative_role.$dirty &&
                                $v.representative_role.$invalid,
                        }"
                    >
                        <b-select
                            v-model="representative_role"
                            :placeholder="$t('valuer_tool.borrower.role.place_holder')"
                            expanded
                            class="grid-offset"
                            name="representative_role"
                            @blur="$v.representative_role.$touch()"
                        >
                            <option
                                v-for="(option, i) in roles"
                                :key="i"
                                :value="['notary', 'broker', 'renter', 'other'][i]"
                            >
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field
                        v-if="representative_role === 'other'"
                        :label="
                            $t(
                                'valuer_tool.borrower.appointment-overview.access_representative-label'
                            )
                        "
                        :message="[
                            $v.representative_role_description.$dirty &&
                            !$v.representative_role_description.requiredIfOtherSelected
                                ? $t('validation.field_required')
                                : null,
                        ]"
                        :type="{
                            'is-danger':
                                $v.representative_role_description.$dirty &&
                                $v.representative_role_description.$invalid,
                        }"
                    >
                        <b-input
                            v-model="representative_role_description"
                            name="representative_role_description"
                            class="grid-offset"
                            @input="$v.representative_role_description.$touch()"
                            @blur="$v.representative_role_description.$touch()"
                        />
                    </b-field>
                    <b-field
                        :label="$t('monolith.global.full_name')"
                        :message="[
                            $v.representative_full_name.$dirty &&
                            !$v.representative_full_name.requiredIfNotPresent
                                ? $t('validation.field_required')
                                : null,
                        ]"
                        :type="{
                            'is-danger':
                                $v.representative_full_name.$dirty &&
                                $v.representative_full_name.$invalid,
                        }"
                    >
                        <b-input
                            v-model="representative_full_name"
                            name="representative_full_name"
                            @input="$v.representative_full_name.$touch()"
                            @blur="$v.representative_full_name.$touch()"
                        />
                    </b-field>
                    <b-field
                        :label="$t('monolith.global.phone')"
                        :message="[
                            $v.representative_phone_number.$dirty &&
                            !$v.representative_phone_number.requiredIfNotPresent
                                ? $t('validation.field_required')
                                : null,
                        ]"
                        :type="{
                            'is-danger':
                                $v.representative_phone_number.$dirty &&
                                $v.representative_phone_number.$invalid,
                        }"
                    >
                        <b-input
                            v-model="representative_phone_number"
                            name="representative_phone_number"
                            @input="$v.representative_phone_number.$touch()"
                            @blur="$v.representative_phone_number.$touch()"
                        />
                    </b-field>
                    <b-field
                        :label="$t('monolith.global.email')"
                        :message="[
                            $v.representative_email.$dirty &&
                            !$v.representative_email.requiredIfNotPresent
                                ? $t('validation.field_required')
                                : null,
                        ]"
                        :type="{
                            'is-danger':
                                $v.representative_email.$dirty &&
                                $v.representative_email.$invalid,
                        }"
                    >
                        <b-input
                            v-model="representative_email"
                            name="representative_email"
                            type="email"
                            @input="$v.representative_email.$touch()"
                            @blur="$v.representative_email.$touch()"
                        />
                    </b-field>
                </b-field>
            </template>
            <div class="overview__property-access-question">
                {{ $t('valuer_tool.borrower.appointment-overview.access_description') }}
            </div>
            <textarea
                v-model="access_instructions"
                name="access_instructions"
                cols="30"
                rows="10"
                :placeholder="
                    $t(
                        'valuer_tool.borrower.appointment-overview.access_description-placeholder'
                    )
                "
            ></textarea>
        </div>
        <nav-buttons
            class="overview__buttons"
            :show-next="showNext"
            :next-step="nextStep"
            :prev-step="prevStep"
            :loading="firstPass && loading"
        >
            <b-button
                v-if="!firstPass"
                id="update"
                :disabled="!showNext"
                type="is-primary"
                :icon-right="loading ? 'loading' : 'pencil'"
                native-type="submit"
                :loading="loading"
            >
                {{ $t('monolith.global.update') }}
            </b-button>
        </nav-buttons>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '@/shared/plugins/utils'
import YesNo from '@/apps/ERS/components/elements/YesNo.vue'
import NavButtons from '../NavButtons.vue'
import { required } from 'vuelidate/lib/validators'

const requiredIfOtherSelected = (value, vm) => {
    if (vm.representative_role !== 'other' || vm.willBePresent) {
        return true
    } else {
        return required(value)
    }
}

const requiredIfNotPresent = (value, vm) => {
    if (vm.willBePresent) {
        return true
    } else {
        return required(value)
    }
}

export default {
    name: 'OverviewAppointment',
    components: {
        YesNo,
        NavButtons,
    },
    props: {
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: () => ({
        representative_full_name: null,
        representative_role_description: null,
        representative_role: null,
        representative_phone_number: null,
        representative_email: null,
        willBePresent: null,
        access_instructions: null,
        loading: false,
        firstPass: true,
    }),
    validations: () => ({
        representative_role_description: {
            requiredIfOtherSelected,
        },
        representative_full_name: {
            requiredIfNotPresent,
        },
        representative_phone_number: {
            requiredIfNotPresent,
        },
        representative_role: {
            requiredIfNotPresent,
        },
        representative_email: {
            requiredIfNotPresent,
        },
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getAddress']),
        ...mapGetters('valuationStore', ['borrower', 'getRequestRef', 'ovmInfo']),
        roles() {
            return [
                this.$t('valuer_tool.borrower.role.notary'),
                this.$t('valuer_tool.borrower.role.broker'),
                this.$t('valuer_tool.borrower.role.renter'),
                this.$t('valuer_tool.borrower.role.other'),
            ]
        },
        formatAddress() {
            return utils.formatAddress(this.borrowerData.address)
        },
        showNext() {
            return !this.$v.$invalid
        },
        user_id() {
            return this.borrower.id
        },
        representative() {
            if (!this.willBePresent) {
                return {
                    role: this.representative_role,
                    full_name: this.representative_full_name,
                    role_description: this.representative_role_description,
                    phone: this.representative_phone_number,
                    email: this.representative_email,
                }
            } else {
                return null
            }
        },
    },
    mounted() {
        if (this.borrowerData.ovm_info) {
            const {
                will_be_present,
                representative,
                access_instructions,
            } = this.borrowerData.ovm_info
            const { phone, role, full_name, role_description, email } = representative
            if (!will_be_present || access_instructions) {
                this.firstPass = false
            }
            this.willBePresent = will_be_present
            this.access_instructions = access_instructions
            this.representative_role = role
            this.representative_full_name = full_name
            this.representative_role_description = role_description
            this.representative_phone_number = phone
            this.representative_email = email
        }
    },
    methods: {
        ...mapActions('valuationStore', ['load_valuation_request']),
        formatDate(aDate) {
            return utils.formatDate(aDate, true, this.$route.params.lang)
        },
        formatTime(time) {
            return utils.formatTime(time)
        },
        onSubmit({ submitter }) {
            this.loading = true
            this.$axios
                .patch(utils.val_urls(this.$config).request_ref(this.getRequestRef), {
                    ovm_info: {
                        will_be_present: this.willBePresent,
                        access_instructions: this.access_instructions,
                        representative: this.representative,
                    },
                })
                .then(() => {})
                .finally(() => {
                    this.loading = false
                })

            if (submitter.id === 'update') return

            this.$router.push({
                query: { ...this.$route.query, step: this.nextStep },
            })
            this.load_valuation_request()
        },
    },
}
</script>

<style scoped lang="scss">
.overview {
    display: flex;
    flex-direction: column;
    &__title {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        color: #001837;
        font-family: Satoshi;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
        padding: 1.5rem;
        border-top: 1px solid #d6e2e9;
        border-right: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
        background: #fff;
    }
    &__appointment {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        align-items: stretch;
        border: 1px solid #d6e2e9;
        background: #fff;
        &-item {
            padding: 1.5rem 0.625rem;
            gap: 0.625rem;
            flex: 1 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            &:not(:last-child) {
                border-right: 1px solid #d6e2e9;
            }
        }

        &-icon {
            display: flex;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.52081rem;
            justify-content: center;
            align-items: center;
            gap: 0.52081rem;
            border-radius: 4.16669rem;
            background: rgba(0, 150, 255, 0.2);
        }
        &-content {
            color: #001837;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 142.857% */
        }
    }
    &__property-access {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        border-right: 1px solid #d6e2e9;
        border-bottom: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
        background: #fff;
        &-buttons {
            display: flex;
            gap: 1.5rem;
            flex-wrap: wrap;
        }
        &-question {
            color: #001837;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 142.857% */
        }
        &-inputs {
            & ::v-deep .field-body {
                .is-grouped {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    flex-wrap: wrap;
                    .field {
                        flex: 1 1 0px;
                        flex-grow: 1;
                        display: grid;
                        grid-template-rows: 1fr 1fr 1fr;
                        grid-auto-rows: auto;
                        min-width: 10rem;
                        .control {
                            .select:not(.is-multiple):not(.is-loading)::after {
                                border-color: #798da6 !important;
                            }
                        }
                        p {
                            grid-row: 3/3;
                        }
                    }
                }
            }
        }
        &-choice {
            gap: 1.5rem;
            //flex-shrink: 0;
        }
        &-warning {
            color: #798da6;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        textarea {
            display: flex;
            height: 5rem;
            padding: 0.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;
            border-radius: 0.25rem;
            border: 1px solid #d6e2e9;
            background: #fff;
            resize: none;
        }
    }
    &__buttons {
        display: flex;
        padding: 1rem 1.5rem;
        justify-content: space-between;
        min-width: 100%;
        gap: 1.5rem;
        align-self: stretch;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        border-right: 1px solid #d6e2e9;
        border-bottom: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
        background: #fff;
    }
}

.grid-offset {
    grid-row: 2/3;
}
</style>
