<template>
    <b-loading v-if="showLoading" v-model="showLoading" />
    <router-view v-else />
</template>
<script>
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'

export default {
    name: 'Floorplan',
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
        }
    },
    computed: {
        showLoading() {
            //TODO Use isLoading when the store is fixed because rn isLoading returns null all the time
            return this.getFeatures.f_building_type === null
        },
    },
}
</script>
<style lang="scss">
.floorplan_layout {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 24px;

    .mobile_actions_container {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 16px;
        border-top: 1px solid #d6e2e9;
        background: #fff;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 8px;

        .mobile_action-buttn {
            flex-grow: 1;
        }
    }

    @media screen and (max-width: 999px) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 0;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-actions {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: flex-end;
        }

        &-breadcrumbs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            color: rgba(0, 44, 101, 0.6);
            font-size: 24px;
            font-weight: 700;

            & > *:last-child {
                color: #002c65;
            }

            .chevron {
                opacity: 0.6;
                width: 16px;
                transform-origin: center;
                transform: rotate(-90deg);
            }
        }
    }
}
</style>
