<i18n>
{
    "fr-BE":{
        "sidebar5": {
            "title1": "Notre prix-cible",
            "subtitle": "Compte tenu de l'emplacement et des caractéristiques spécifiés, nous arrivons à un prix cible de:"
        }
    },
    "nl-BE":{
        "sidebar5": {
            "title1": "Onze richtprijs",
            "subtitle": "Gegeven de opgegeven locatie en kenmerken, komen we tot een richtprijs van:"
        }
    },
    "en-BE":{
        "sidebar5": {
            "title1": "Our target price",
            "subtitle": "Given the specified location and characteristics, we arrive at a target price of:"
        }
    }
}
</i18n>

<template>
    <div v-if="getEstimate" id="sidebar5" class="sidebar-inner">
        <h1>{{ $t('sidebar5.title1') }}</h1>
        <div class="subtitle mt-4">
            {{ $t('sidebar5.subtitle') }}
        </div>
        <div class="price">
            {{ rounded_estimation }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Sidebar3',
    computed: {
        ...mapGetters('valuationStore/Address', ['addressInfo', 'getEstimate']),
        rounded_estimation() {
            let number =
                Math.round(
                    this.addressInfo.estimationResultsData.estimation.asking_price_q50 /
                        1000
                ) * 1000
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            }).format(number)
        },
    },
}
</script>

<style lang="scss" scoped>
.price {
    font-size: 28px;
    font-weight: 900;
    color: #ff6f00;
    transition: 0.4s all;
}
</style>
