<template>
    <div class="result-page">
        <div
            v-if="getSimulationStatus === 'finished'"
            class="result-page__content _content"
        >
            <h1 class="result-page__title">
                {{ $t('energy.result.header.title') }}
            </h1>
            <p class="result-page__subtitle">
                {{ $t('energy.result.header.subtitle') }}
            </p>
            <div class="address">
                <div class="address__details">
                    <div class="address__icon icon">
                        <img
                            src="@/assets/icons/map-marker.svg"
                            alt=""
                            class="icon__img"
                        />
                    </div>
                    <div class="address__text">
                        <div class="text__street">
                            {{ formatAddress.firstLine }}
                        </div>
                        <div class="text__city">
                            {{ formatAddress.secondLine }}
                        </div>
                    </div>
                </div>
                <Button v-if="getIsOwner" class="edit-bttn" @btn-click="openSummaryModal">
                    <span>{{ $t('energy.result.button.edit_input') }}</span>
                </Button>
            </div>
            <div class="_content__results results">
                <div class="results__epc">
                    <div class="address">
                        <div class="address__details">
                            <div class="address__icon icon">
                                <img
                                    src="@/assets/icons/map-marker.svg"
                                    alt=""
                                    class="icon__img"
                                />
                            </div>
                            <div class="address__text">
                                <div class="text__street">
                                    {{ formatAddress.firstLine }}
                                </div>
                                <div class="text__city">
                                    {{ formatAddress.secondLine }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <epc-score
                        :region="getRegion"
                        :label="getCurrentEpcLabel"
                        :score="Math.round(getCurrentScenario.epc_ind)"
                        :percent="getEpcPercents"
                    />
                    <consumption />
                </div>
                <div class="page_breaker" />
                <package-selector />
                <div class="results__renovations renovations">
                    <renovation-grid v-if="getScenariosAvailable" />
                    <div
                        class="renovations__summary"
                        :class="{
                            'renovations__summary--no-renovations': !getScenariosAvailable,
                        }"
                    >
                        <Summary v-if="getScenariosAvailable" />
                        <property-value v-if="getScenariosAvailable" />
                        <Savings v-if="getScenariosAvailable" />
                        <div class="_content__buttons">
                            <Button
                                class="download-bttn"
                                :class="{ 'download-bttn--disabled': reportLoading }"
                                :disabled="reportLoading"
                                @btn-click="get_report()"
                            >
                                <svg-icon
                                    icon="download"
                                    width="1.25rem"
                                    height="1.25rem"
                                    color="rgba(0, 24, 55, 0.7)"
                                />
                                {{ $t('energy.result.button.download') }}
                            </Button>

                            <Button
                                v-if="getIsOwner && getScenariosAvailable"
                                class="save-bttn"
                                @btn-click="saveResults"
                            >
                                <img src="@/assets/icons/ic-save.svg" alt="" />
                                {{ $t('common.save') }}
                            </Button>

                            <Button
                                v-if="getIsOwner"
                                class="share-bttn"
                                :href="mailto_link"
                                @btn-click="track_share"
                            >
                                {{ $t('energy.result.button.share') }}
                                <svg-icon
                                    icon="share"
                                    width="1.25rem"
                                    height="1.25rem"
                                    color="rgba(0, 24, 55, 0.7)"
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <p class="result-page__footnote">{{ $t('energy.result.footnote') }}</p>
        </div>

        <b-loading
            v-else
            v-model="isLoading"
            :is-full-page="true"
            :can-cancel="false"
        ></b-loading>
        <summary-modal ref="summaryModal" :unsubmit="true" />
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

import EpcScore from './EpcScore.vue'
import Consumption from './Consumption.vue'
import PackageSelector from './PackageSelector.vue'
import RenovationGrid from './RenovationGrid.vue'
import Summary from './Summary.vue'
import PropertyValue from './PropertyValue.vue'
import Savings from './Savings.vue'
import Button from '@/components/elements/Button.vue'
import SummaryModal from '../../../components/pages/result/SummaryModal'

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'Result',
    components: {
        EpcScore,
        Consumption,
        PackageSelector,
        RenovationGrid,
        Summary,
        PropertyValue,
        Savings,
        Button,
        SummaryModal,
    },
    data() {
        return {
            region: null,
            epcScore: null,
            currentScenario: null,
            epcLabel: null,
            consumptions: {},
            isLoading: true,
            isSummaryModalOpen: false,
            sharing_id: null,
            simulation_ref: null,
            reportLoading: false,
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getScenarios',
            'getScenariosNames',
            'getSimulationStatus',
            'getAddress',
            'getFeatures',
            'getRegion',
            'getCurrentScenario',
            'getCurrentEpcLabel',
            'getEpcPercents',
            'getSelectedPackage',
            'getIsOwner',
            'getScenariosAvailable',
            'getRequestRef',
            'getSimulationRef',
        ]),
        formatAddress() {
            return utils.formatAddress(this.getAddress)
        },
        mailto_link() {
            const url = `${window.location.protocol}//${window.location.host}${this.$config.FRONTEND_URL}${this.$route.fullPath}&sharing_id=${this.sharing_id}`
            return `mailto:?subject=${encodeURIComponent(
                this.$t('email.sharing_subject')
            )}&body=${encodeURIComponent(this.$t('email.sharing_body', { url: url }))}`
        },
        report_url() {
            let query_params = { ...this.$route.query }
            query_params.valuation_request_ref = query_params.ref
            delete query_params.ref
            query_params.lang = this.$route.params.lang
            let query_string = Object.entries(query_params)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join('&')
            return `${utils.urlJoin(
                this.$config.VALUATION_API_URL,
                '/report'
            )}?${query_string}`
        },
    },
    async mounted() {
        await this.load_simulation({
            route: this.$route,
        })
        this.sharing_id = utils.random_string(16)

        this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: {
                ...this.$route.query,
                simulation_ref: this.getSimulationRef,
            },
        })
    },
    methods: {
        ...mapActions('ersStore', ['load_simulation', 'track_event']),
        ...mapMutations('ersStore', ['SET_SELECTED_PACKAGE', 'SET_SIMULATION_REF']),
        openSummaryModal() {
            this.$refs.summaryModal.open()
        },
        get_report() {
            this.reportLoading = true
            return axios
                .get(this.report_url, { responseType: 'arraybuffer' })
                .then((res) => {
                    const content = res.data
                    const file = new Blob([content], {
                        type: 'application/pdf',
                    })

                    const fileURL = URL.createObjectURL(file)
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.style = 'display: none'
                    a.href = fileURL
                    a.download = `ERS-${this.formatAddress.firstLine}-${this.formatAddress.secondLine}.pdf`
                    a.click()
                    window.URL.revokeObjectURL(file)
                    this.reportLoading = false
                })
        },
        track_share() {
            if (this.$config.TRACK) {
                this.track_event({
                    event: 'share_results',
                    route: this.$route,
                    data: { sharing_id: this.sharing_id },
                })
            }
        },
        saveResults() {
            axios
                .patch(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'energy-simulation',
                        this.getSimulationRef,
                    ]),
                    {
                        saved_scenario: {
                            package_name: this.$route.query.package,
                            scenario_name: this.$route.query.scenario,
                            budget: this.$route.query.budget,
                            target_epc: this.$route.query.targetEpc,
                        },
                    }
                )
                .then(() => {
                    this.$buefy.notification.open({
                        message: this.$t('energy.result.scenario_saved_message'),
                        duration: 5000,
                        type: 'is-success',
                        position: 'is-bottom',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
.ers-result {
    .dropdown {
        margin-block: auto;
        margin-inline-start: auto;
    }
}

.result-page {
    &__footnote {
        margin-top: 33px;
        color: #798da6;
        font-size: 10px;
        font-weight: 400;
    }
}
</style>

<style lang="scss" scoped>
.result-page {
    min-width: 100%;
    margin-block-end: auto;
    background: #f8f9fb;
}

.result-page__content {
    max-width: 70vw;
    margin-inline: auto;
    padding-block: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f8f9fb;
}

@media only screen and (max-width: 1025px) {
    .result-page__content {
        max-width: 97vw;
    }
}

.address {
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-block: 30px;
}

.address__icon {
    margin-block: auto;
}

.address__text {
    flex-grow: 1;
    color: #001837;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.address__details {
    display: flex;
    gap: 50px;
}

.icon__img {
    min-width: 56px;
    transform: translateX(25%);
}

._content__results {
}

.results__epc {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 24px;
}

@media (max-width: 1200px) {
    .results__epc {
        grid-template-columns: 1fr;
    }
}

.results__renovations {
    display: grid;
    gap: 30px;
    grid-template-columns: 3fr 1.4fr;
    margin-top: 3rem;
}

@media only screen and (max-width: 1400px) {
    .results__renovations {
        grid-template-columns: 1fr;
    }
}

.renovations__summary {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-inline: auto;
    max-width: fit-content;
    &--no-renovations {
        margin-inline: unset;
        margin-left: auto;
    }
}

._content__buttons {
    display: flex;
    min-width: 100%;
    margin-block-start: 30px;
    gap: 10px;
}

.btn-download,
.btn-save {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    display: flex;
}

.btn-share {
    background: var(--color-primary) !important;
    color: white !important;
}

.result-page__title {
    color: #001837;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.result-page__subtitle {
    color: #798da6;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>
<style lang="scss" scoped>
@media screen {
    .results__epc {
        // grid-template-columns: 1fr;
        justify-items: stretch;

        .address {
            display: none;
        }
    }

    .page_breaker {
        display: none;
    }
}
@media print {
    .address {
        display: none;
    }

    .results__renovations {
        grid-template-columns: 1fr;
    }

    ._content__results {
        .results__epc {
            grid-template-columns: 1fr 1fr;
            justify-items: stretch;

            .address {
                display: grid;
                align-items: center;
            }
        }
    }

    ._card.consumption {
        grid-row: 1;
        grid-column: 2;
        max-width: none;
    }

    ._card.epc {
        grid-row: 2;
        grid-column: 1 / 3;
    }

    .page_breaker {
        page-break-after: always;
    }

    .renovations__summary {
        ._card.summary {
            margin-top: 10mm;
        }
    }

    .result-page__footnote {
        width: 100%;
        margin-top: 0;
    }
}

.download-bttn,
.share-bttn,
.save-bttn,
.edit-bttn {
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    color: rgba(0, 24, 55, 0.7);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-grow: 1;
}
.share-bttn {
    background: #001837;
    color: white;
}

.edit-bttn {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    border-radius: 0.3125rem;
    border: 1px solid var(--color-primary);
    background: #fff;
    color: var(--color-primary);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    width: 10rem;
    max-height: 2.75rem;
    margin-left: auto;
    align-self: center;
}

.save-bttn,
.download-bttn {
    display: flex;
    padding: 0.75rem 1.5rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;

    /* Shadow */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    img {
        width: 1.25rem;
        height: 1.25rem;
    }

    &--disabled {
        background: #d2d7de;
        color: #919caa;
    }
}
</style>
