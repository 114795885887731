<template>
    <grid-card
        :title="$t(`${configLocation}.${feature}`)"
        :is-full="wide"
        :aria-label="feature"
    >
        <checkboxes
            :value="checked"
            :options="values"
            :wide="wide"
            :feature="feature"
            @select="selectValue"
        />
        <!-- <div class="checkboxes" :class="{ 'checkboxes--wide': wide }">
            <b-checkbox
                v-for="(option, i) in values"
                :key="`${feature}-answer_${i}`"
                v-model="checked"
                :native-value="option.value"
            >
                {{ option.name }}
            </b-checkbox>
        </div> -->
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import utils from '@/shared/plugins/utils'
import GridCard from '..'
import Checkboxes from '../../Checkboxes'

export default {
    name: 'CheckboxesCard',
    components: {
        GridCard,
        Checkboxes,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
        configLocation: {
            type: String,
            default: 'dvm_features',
        },
        wide: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFullBuildingData']),
            ...mapMutations(requestObjects, ['SET_UNIVERSAL']),
        }
    },
    computed: {
        values() {
            return utils
                .generateValues(this.feature, this.value)
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
        checked: {
            get: function() {
                return this.getFullBuildingData[this.configLocation]?.[this.feature] ?? []
            },
            set: function(val) {
                this.SET_UNIVERSAL({
                    key: this.configLocation,
                    values: { [this.feature]: val },
                })
            },
        },
    },
    methods: {
        selectValue(value) {
            this.checked = value
        },
    },
}
</script>
<style lang="scss" scoped>
.checkboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    justify-items: stretch;
    align-items: center;

    &--wide {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
