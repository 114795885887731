var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ers-content section results-page is-fluid pt-0 pb-0 mb-0 mt-0",class:[_vm.$vssWidth < 768 && 'pt-0']},[_c('div',{staticClass:"sticky-column"},[_c('div',{staticClass:"column is-12 mt-0"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]),((_vm.status == 'pending' || _vm.status === 'created') && _vm.wait_time)?_c('h1',{staticClass:"pending-title title",domProps:{"innerHTML":_vm._s(
                    _vm.$t(`processing_page.title_pending_${_vm.pending_range}`, {
                        waiting: _vm.wait_time,
                    })
                )}}):_vm._e()]),_c('div',{staticClass:"buttons-wrapper"},[(_vm.status === 'success')?_c('b-button',{attrs:{"tag":"router-link","to":{
                    name: 'ers.result',
                    params: { lang: _vm.$route.params.lang },
                    query: {
                        ..._vm.$route.query,
                    },
                },"type":"is-primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('processing_page.check_results')))])]):_vm._e(),(_vm.status === 'failed')?[_c('b-button',{attrs:{"tag":"router-link","to":{ name: 'ers' },"type":"is-primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.new_request.title')))])]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.$emit('open-summary-modal', {
                            unsubmit: true,
                        })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('energy.result.button.edit_input')))])])]:_vm._e()],2),(_vm.status === 'pending')?_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_scanning.gif")}}):(_vm.status === 'success')?_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_basic.gif")}}):(_vm.status === 'failed')?_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_reconstruction.gif")}}):_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_basic.gif")}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }