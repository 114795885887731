<template>
    <form class="main" @submit.prevent="(htmlElement) => onSubmit(htmlElement)">
        <div class="main__form">
            <h1 class="main__title">
                {{ $t('valuer_tool.borrower.borrower-details.title') }}
            </h1>
            <div class="main__inputs">
                <b-field grouped>
                    <b-field :label="$t('monolith.global.first_name')" expanded>
                        <b-input
                            v-model="first_name"
                            required
                            name="first_name"
                            @blur="$v.first_name.$touch()"
                        />
                    </b-field>
                    <b-field :label="$t('monolith.global.last_name')" expanded>
                        <b-input v-model="last_name" required name="last_name" />
                    </b-field>
                </b-field>
                <b-field :label="$t('monolith.global.phone')">
                    <b-input v-model="phone" required name="phone" />
                </b-field>
                <b-field :label="$t('monolith.global.email')">
                    <b-input v-model="email" disabled name="email" />
                </b-field>
            </div>
        </div>
        <div class="main__buttons">
            <nav-buttons
                :show-next="showNext"
                :next-step="nextStep"
                :prev-step="prevStep"
                :loading="firstPass && loading"
            >
                <b-button
                    v-if="!firstPass"
                    id="update"
                    type="is-primary"
                    :icon-right="loading ? 'loading' : 'pencil'"
                    native-type="submit"
                    :loading="loading"
                >
                    {{ $t('monolith.global.update') }}
                </b-button>
            </nav-buttons>
        </div>
    </form>
</template>

<script>
import utils from '@/shared/plugins/utils'
import { mapGetters, mapActions } from 'vuex'
import NavButtons from '../NavButtons.vue'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'BorrowerDetails',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        firstPass: true,
        loading: false,
    }),
    validations: {
        first_name: {
            required,
        },
        last_name: {
            required,
        },
        phone: {
            required,
        },
        email: {
            required,
        },
    },
    computed: {
        ...mapGetters('valuationStore', ['borrower', 'getRequestRef', 'ovmInfo']),
        user_id() {
            return this.borrower.id
        },
        showNext() {
            return !this.$v.$invalid
        },
    },
    mounted() {
        if (this.borrowerData.userData) {
            const { email, first_name, last_name, phone } = this.borrowerData.userData
            const isNull = (val) => val === null
            this.firstPass = [email, first_name, last_name, phone].some(isNull)
            this.first_name = first_name
            this.last_name = last_name
            this.email = email
            this.phone = phone
        }
    },
    methods: {
        ...mapActions('valuationStore', ['load_valuation_request']),
        async onSubmit({ submitter }) {
            this.loading = true
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'user',
                this.user_id,
            ])
            let res = await this.$axios.patch(url, {
                first_name: this.first_name,
                last_name: this.last_name,
                phone: this.phone,
            })
            if (res.status !== 200) {
                this.loading = false
                return
            }

            this.loading = false

            if (submitter.id === 'update') return

            this.$router.push({
                query: { ...this.$route.query, step: this.nextStep },
            })
            this.load_valuation_request()
        },
    },
}
</script>

<style scoped lang="scss">
.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    border: 1px solid #d6e2e9;
    background: #fff;

    &__title {
        color: #001837;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
        align-self: stretch;
        margin-inline: 1.5rem;
        margin-top: 1.5rem;
    }

    &__buttons {
        border-top: 1px solid #d6e2e9;
        min-width: 100%;
        padding-block: 1rem;
        padding-inline: 1.5rem;
    }
}
</style>
