/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkered-flag': {
    width: 14,
    height: 17,
    viewBox: '0 0 14 17',
    data: '<path d="M6 2.125H4V0.125H6V2.125ZM10 0.125H8V2.125H10V0.125ZM4 10.125H6V8.125H4V10.125ZM14 6.125V4.125H12V6.125H14ZM14 10.125V8.125H12V10.125H14ZM8 10.125H10V8.125H8V10.125ZM14 0.125H12V2.125H14V0.125ZM8 4.125V2.125H6V4.125H8ZM2 6.125V4.125H4V2.125H2V1.125C2 0.575 1.55 0.125 1 0.125C0.45 0.125 0 0.575 0 1.125V15.125C0 15.675 0.45 16.125 1 16.125C1.55 16.125 2 15.675 2 15.125V8.125H4V6.125H2ZM10 8.125H12V6.125H10V8.125ZM6 6.125V8.125H8V6.125H6ZM4 4.125V6.125H6V4.125H4ZM8 6.125H10V4.125H8V6.125ZM10 2.125V4.125H12V2.125H10Z"/>'
  }
})
