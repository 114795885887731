var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.epc_score.question'))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('energy.epc_score.tooltip')}})],1),_c('p',[_vm._v(_vm._s(_vm.$t('energy.epc_score.subtitle')))])]),_c('multiple-choice',{attrs:{"answers":_vm.epc_types},model:{value:(_vm.epc_type),callback:function ($$v) {_vm.epc_type=$$v},expression:"epc_type"}}),(_vm.is_epc_number)?_c('div',{staticClass:"epc-section"},[_c('p',[_vm._v(_vm._s(_vm.$t('energy.epc_score.subtitle_2')))]),_c('b-field',{staticClass:"ers-input-field",attrs:{"type":{
                'is-danger': !_vm.$v.f_epc_numeric.numeric,
            },"message":[
                !_vm.$v.f_epc_numeric.numeric ? _vm.$t('validation.only_numbers') : null,
            ]}},[_c('b-input',{attrs:{"size":"is-medium"},model:{value:(_vm.f_epc_numeric),callback:function ($$v) {_vm.f_epc_numeric=$$v},expression:"f_epc_numeric"}}),_c('p',{staticClass:"unit",class:{ 'unit--error': !_vm.$v.f_epc_numeric.numeric }},[_vm._v(" "+_vm._s(_vm.$t('unit.kwh/m2year'))+" ")])],1)],1):_vm._e(),(_vm.is_epc_label)?_c('div',{staticClass:"epc-section"},[_c('p',[_vm._v(_vm._s(_vm.$t('features_question.enter_epc_label')))]),_c('multiple-choice',{attrs:{"answers":_vm.epc_labels,"small":"","center":""},model:{value:(_vm.f_epc_label),callback:function ($$v) {_vm.f_epc_label=$$v},expression:"f_epc_label"}})],1):_vm._e(),_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":function($event){return _vm.changePage(false)}}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                    unsubmit: false,
                })
                _vm.changePage(true)}}}),(_vm.showNextButton)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":function($event){return _vm.changePage(true)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1),_c('b-modal',{attrs:{"can-cancel":['escape']},model:{value:(_vm.warningModal),callback:function ($$v) {_vm.warningModal=$$v},expression:"warningModal"}},[_c('div',{staticClass:"ers-page-modal-warning"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-tooltip-warning.svg"),"alt":"","srcset":""}}),_c('div',{staticClass:"ers-page-modal-warning-text"},[_c('h1',{staticClass:"ers-page-modal-warning-text__main"},[_vm._v(" "+_vm._s(_vm.$t('energy.epc_score.warning_tooltip-main'))+" ")]),_c('p',{staticClass:"ers-page-modal-warning-text__description"},[_vm._v(" "+_vm._s(_vm.$t('energy.epc_score.warning_tooltip-description'))+" ")])]),_c('b-button',{staticClass:"ers-page-modal-warning-bttn",attrs:{"size":"is-medium","type":"is-primary"},on:{"click":function($event){_vm.warningModal = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t('energy.end_section.got_it')))])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }