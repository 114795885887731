<template>
    <grid-card :title="$t('valuer_tool.commons.costs.title')" no-padding>
        <div v-if="costs.length" class="costs-grid">
            <template v-for="(cost, i) in costs">
                <h6 :key="`cost_${i}_name`">{{ cost.name }}</h6>
                <h6 :key="`cost_${i}_amount`" class="price">€ {{ cost.amount }}</h6>
                <icon-button
                    :key="`cost_${i}_delete`"
                    icon="delete"
                    @click="deleteCost(i)"
                />
                <icon-button :key="`cost_${i}_edit`" icon="edit" @click="editCost(i)" />
            </template>
        </div>
        <div class="new_cost">
            <b-field :label="$t('monolith.global.description')">
                <b-input v-model="newCost.name" type="text" expanded />
            </b-field>
            <b-field class="cost_input" :label="$t('valuer_tool.commons.costs.amount')">
                <b-input v-model.number="newCost.amount" type="number" />
                <span>€</span>
            </b-field>
            <b-button class="new_cost-buttn" type="is-primary" @click="addCost">
                +
            </b-button>
        </div>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import IconButton from '@/components/elements/IconButton.vue'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import { mapGetters as vuexGetters } from 'vuex'

export default {
    name: 'CommonGarden',
    components: {
        GridCard,
        IconButton,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getOVMFeatures']),
            ...mapMutations(requestObjects, ['SET_OVM_FEATURES']),
        }
    },
    data: () => ({
        costs: [],
        newCost: {
            name: '',
            amount: 0,
        },
    }),
    computed: {
        ...vuexGetters(['isMobile']),
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        costs(val) {
            this.SET_OVM_FEATURES({
                common_costs: val,
            })
        },
    },
    mounted() {
        this.costs = this.getOVMFeatures?.common_costs || []
    },
    methods: {
        addCost() {
            this.costs.push(this.newCost)
            this.newCost = {
                name: '',
                amount: 0,
            }
        },
        deleteCost(index) {
            this.costs.splice(index, 1)
        },
        editCost(index) {
            this.newCost = { ...this.costs[index] }
            this.costs.splice(index, 1)
        },
    },
}
</script>
<style lang="scss" scoped>
.costs-grid {
    display: grid;
    padding: 24px;
    border-top: 1px solid #d6e2e9;
    grid-template-columns: auto repeat(3, max-content);
    align-items: center;
    gap: 8px;

    h6 {
        margin-right: 8px;
        color: #001837;
        font-size: 14px;
        font-weight: 500;

        &.price {
            font-weight: 700;
        }
    }

    @media screen and (max-width: 999px) {
        padding: 16px;
        gap: 16px 8px;

        h6 {
            margin-right: 8px;
        }
    }
}

.new_cost {
    border-top: 1px solid #d6e2e9;
    padding: 24px;
    display: grid;
    flex-direction: row;
    gap: 16px;
    grid-template-rows: 1fr;
    grid-template-columns: 65% auto auto;

    align-items: end;

    & > div {
        margin-bottom: 0;
    }

    & ::v-deep .cost_input {
        .field-body .field {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    @media screen and (max-width: 999px) {
        grid-template-rows: auto auto;
        grid-template-columns: 75% auto;
        gap: 8px;
        max-width: 100%;
        padding: 16px;

        .new_cost-buttn {
            grid-column: 1 / 3;
        }
    }
}
</style>
