<template>
    <button class="clear-button" :disabled="disabled" @click="$emit('click')">
        <slot />
    </button>
</template>
<script>
export default {
    name: 'ClearButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss" scoped>
.clear-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }

    &:disabled {
        cursor: not-allowed;
        color: grey;
    }
}
</style>
