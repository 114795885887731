<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="main" :class="{ 'package_selector-on_report': onReport }">
        <div v-if="getScenariosAvailable" class="main__title">
            {{ $t('energy.result.package.title') }}
        </div>
        <div
            v-if="getScenariosAvailable && getScenarios.length > 2"
            class="main__buttons"
        >
            <package-button
                :selected="getSelectedPackage === 'target'"
                @click="selectedPackage = 'target'"
            >
                <h1>{{ $t('energy.package.epc.title') }}</h1>
                <p>
                    {{ $t('energy.package.epc.description') }}
                </p>
            </package-button>

            <package-button
                :selected="getSelectedPackage === 'budget'"
                @click="selectedPackage = 'budget'"
            >
                <h1>{{ $t('energy.package.budget.title') }}</h1>
                <p>
                    {{ $t('energy.package.budget.description') }}
                </p>
            </package-button>

            <package-button
                :selected="getSelectedPackage === 'custom'"
                @click="selectedPackage = 'custom'"
            >
                <h1>{{ $t('energy.package.custom.title') }}</h1>
                <p>
                    {{ $t('energy.package.custom.description') }}
                </p>
            </package-button>
        </div>

        <div
            v-if="
                getScenariosAvailable &&
                    getSelectedPackage !== 'custom' &&
                    getScenarios.length > 2
            "
            class="main__sliders"
        >
            <div v-show="getSelectedPackage === 'budget'" class="budget-input-slider">
                <div class="slider-field">
                    <span>{{ int_value_formatter(getMinMaxBudget.min) }}</span>
                    <b-slider
                        v-model="budget"
                        size="is-medium"
                        :disabled="getSelectedPackage === 'custom'"
                        :type="getSelectedPackage === 'custom' ? 'is-dark' : 'is-primary'"
                        class="package-slider"
                        :min="getMinMaxBudget.min"
                        :max="getMinMaxBudget.max"
                        :custom-formatter="(val) => int_value_formatter(val)"
                        rounded
                    ></b-slider>
                    <span>{{ int_value_formatter(getMinMaxBudget.max) }}</span>
                </div>
                <b-field class="ers-input-field">
                    <b-input
                        v-model="budget"
                        :disabled="getSelectedPackage === 'custom'"
                        size="is-medium"
                        class="budget-input-slider-input"
                        lazy
                    />
                    <p class="unit-eur">
                        €
                    </p>
                </b-field>
            </div>

            <div v-show="getSelectedPackage === 'target'" class="budget-input-slider">
                <div class="slider-field">
                    <span>{{ getMinMaxEpcValues.max }}</span>
                    <!-- Here slider min/max values are negative and inverted to have a decreasing slider -->
                    <b-slider
                        v-model="targetEpc"
                        size="is-medium"
                        :disabled="getSelectedPackage === 'custom'"
                        :type="getSelectedPackage === 'custom' ? 'is-dark' : 'is-primary'"
                        class="package-slider"
                        :min="-getMinMaxEpcValues.max"
                        :max="-getMinMaxEpcValues.min"
                        :custom-formatter="
                            (val) => val * -1 + ' ' + $t('unit.kwh/m2year')
                        "
                        rounded
                    ></b-slider>
                    <span>{{ getMinMaxEpcValues.min }}</span>
                </div>
                <b-field class="ers-input-field">
                    <b-input
                        v-model="getPositiveTargetEpc"
                        :disabled="getSelectedPackage === 'custom'"
                        size="is-medium"
                        class="budget-input-slider-input"
                        lazy
                        :custom-formatter="(val) => val * -1 + ' kwh'"
                    />
                    <p class="unit-eur">
                        {{ $t('unit.kwh/m2year') }}
                    </p>
                </b-field>
            </div>
        </div>

        <div
            v-if="!getScenariosAvailable"
            class="main__no-renovation-text"
            v-html="$t('energy.result.no_renovation')"
        />
        <div v-if="getScenarios.length === 2" class="main__only-one-renovation-text">
            {{ $t('energy.result.one_renovation_available') }}
        </div>

        <div
            class="main__histogram"
            :class="{
                'main__histogram--no-renovation': !getScenariosAvailable,
                'main__histogram--custom-package': getSelectedPackage === 'custom',
            }"
        >
            <div class="selector">
                <b-tooltip
                    always
                    class="epc-tooltip current-epc"
                    position="is-bottom"
                    :style="{
                        left: `${computePositionFromEpc(getCurrentScenario.epc_ind)}%`,
                    }"
                >
                    <template #content>
                        <p class="bubble__title">
                            {{ $t('ers_report.current_epc') }}
                        </p>
                        <p class="bubble__subtitle">
                            {{ Math.round(getCurrentScenario.epc_ind) }}
                            {{ $t('unit.kwh/m2year') }}
                        </p>
                    </template>
                </b-tooltip>
            </div>

            <div v-if="getScenariosAvailable" class="selector">
                <b-tooltip
                    always
                    class="epc-tooltip budget-epc epc-tooltip--active"
                    position="is-top"
                    :style="{
                        left: `${computePositionFromEpc(
                            getRenovationsSummary.futureEpcScore
                        )}%`,
                    }"
                >
                    <template #content>
                        <p class="bubble__title">
                            {{ $t('ers_report.future_epc') }}
                        </p>
                        <p class="bubble__subtitle">
                            {{ Math.round(getRenovationsSummary.futureEpcScore) }}
                            {{ $t('unit.kwh/m2year') }}
                        </p>
                    </template>
                </b-tooltip>
            </div>

            <epc-array-brussels
                v-if="getRegion === 'Brussels'"
                :interactive="true"
                :current-labels="getCurrentLabels"
            />
            <epc-array-flanders
                v-else-if="getRegion === 'Flanders'"
                :interactive="true"
                :current-labels="getCurrentLabels"
            />
            <epc-array-wallonia
                v-else
                :interactive="true"
                :current-labels="getCurrentLabels"
            />
        </div>

        <info-bubble v-if="currentScenarioWarnings.warning_messages.length && !onReport">
            <div class="package__warnings">
                <p>
                    {{ $t('energy.result.renovation.poorly_insulated') }}
                    <span
                        v-for="type in currentScenarioWarnings.poorly_insulated"
                        :key="type"
                        class="renovation-type"
                    >
                        {{ $t(`energy.result.renovation.${type}`) }}
                    </span>
                </p>

                <ul class="warning-list">
                    <li
                        v-for="(warning, i) in currentScenarioWarnings.warning_messages"
                        :key="i"
                    >
                        {{ $t(`energy.result.warning.${warning}`) }}
                    </li>
                </ul>
            </div>
        </info-bubble>
    </div>
</template>

<script>
import EpcArrayBrussels from './EpcArrayBrussels'
import EpcArrayFlanders from './EpcArrayFlanders'
import EpcArrayWallonia from './EpcArrayWallonia'
import PackageButton from './PackageButton'
import utils from '@/shared/plugins/utils'
import enums from '@/shared/plugins/enums'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import InfoBubble from '@/components/elements/InfoBubble.vue'

export default {
    name: 'PackageSelector',
    components: {
        EpcArrayWallonia,
        EpcArrayBrussels,
        EpcArrayFlanders,
        PackageButton,
        InfoBubble,
    },
    props: {
        onReport: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            region: 'flanders',
            budgetTooltip: false,
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getTargetEpcScenario',
            'getBestEpcScenario',
            'getSelectedScenario',
            'getRegion',
            'getSelectedPackage',
            'getRenovationsSummary',
            'getCurrentScenario',
            'getBudgetScenario',
            'getScenarios',
            'getScenariosNames',
            'getMinMaxBudget',
            'getScenariosAvailable',
            'getRenovationOptions',
            'getSelectedRenovationList',
        ]),
        getTargetEpcLabel() {
            const epcs = enums.epc_mapping[this.getRegion]
            return epcs
        },
        getCurrentLabels() {
            return [
                utils.computeLabelFromScore(
                    this.getCurrentScenario.epc_ind,
                    this.getRegion
                ).label,
                utils.computeLabelFromScore(
                    this.getSelectedScenario.epc_ind,
                    this.getRegion
                ).label,
            ]
        },
        getMinMaxEpcValues() {
            if (!this.getScenariosAvailable) {
                return {
                    min: this.getCurrentScenario.epc_ind,
                    max: this.getCurrentScenario.epc_ind,
                }
            }
            let scenarios = this.getScenarios
            const compareEpc = (a, b) => {
                if (a.epc_ind < b.epc_ind) {
                    return -1
                }
                if (a.epc_ind > b.epc_ind) {
                    return 1
                }
                return 0
            }

            scenarios = scenarios.filter((sceno) => sceno.name !== 'current')

            const currentEpc = this.getCurrentScenario.epc_ind

            let max = Math.round(this.getTargetEpcScenario(currentEpc * -1).epc_ind) // currentEpc is inverted because we're working with a decreased slider
            let min = Math.round(scenarios.sort(compareEpc)[0].epc_ind)

            return {
                min,
                max,
            }
        },
        getPositiveTargetEpc: {
            get() {
                return this.$store.state.ersStore.target_epc * -1 // -1 because we're working with a decreased slider
            },
            set(value) {
                this.SET_TARGET_EPC(+value * -1) // -1 because we're working with a decreased slider
                if (this.getSelectedPackage === 'target') {
                    this.select_package({
                        packageName: 'target',
                        scenario: this.getTargetEpcScenario(+value),
                    })
                }
            },
        },
        selectedPackage: {
            get() {
                return this.$store.state.ersStore.results.selectedPackage
            },
            set(value) {
                if (value === 'custom') {
                    this.select_package({
                        packageName: 'custom',
                        scenario: this.getSelectedScenario,
                    })
                } else if (value === 'budget') {
                    this.select_package({
                        packageName: 'budget',
                        scenario: this.getBudgetScenario(+this.budget),
                    })
                } else {
                    this.select_package({
                        packageName: 'target',
                        scenario: this.getTargetEpcScenario(+this.targetEpc),
                    })
                }
                this.$router.push({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        ...this.$route.query,
                        package: this.getSelectedPackage,
                        scenario: this.getSelectedScenario.name,
                    },
                })
            },
        },
        budget: {
            get() {
                return this.$store.state.ersStore.budget
            },
            set(value) {
                this.SET_BUDGET(+value)
                if (this.getSelectedPackage === 'budget') {
                    this.select_package({
                        packageName: 'budget',
                        scenario: this.getBudgetScenario(+value),
                    })
                }
            },
        },
        targetEpc: {
            get() {
                return this.$store.state.ersStore.target_epc
            },
            set(value) {
                this.SET_TARGET_EPC(+value)
                if (this.getSelectedPackage === 'target') {
                    this.select_package({
                        packageName: 'target',
                        scenario: this.getTargetEpcScenario(+value),
                    })
                }
            },
        },
        currentScenarioWarnings() {
            const available_types = utils.renovation_types(
                Object.values(this.getRenovationOptions).flat()
            )
            const selected_renovation_types = utils.renovation_types(
                this.getSelectedScenario.name.split('_')
            )

            const warning_messages = []
            const poorly_insulated = []

            enums.renovationWarningRules.forEach((rule) => {
                const { x, y } = rule

                if (selected_renovation_types.includes(x)) {
                    y.forEach((type) => {
                        if (
                            available_types.includes(type) &&
                            !selected_renovation_types.includes(type)
                        ) {
                            if (!warning_messages.includes(x)) warning_messages.push(x)
                            if (!poorly_insulated.includes(type))
                                poorly_insulated.push(type)
                        }
                    })
                }
            })

            return {
                warning_messages,
                poorly_insulated,
            }
        },
    },
    mounted() {
        if (this.getSelectedPackage) {
            this.set_route()
        }
    },
    methods: {
        ...mapMutations('ersStore', [
            'SET_SELECTED_PACKAGE',
            'SET_SELECTED_SCENARIO',
            'SET_BUDGET',
            'SET_TARGET_EPC',
        ]),
        ...mapActions('ersStore', ['load_simulation', 'track_event']),
        computePositionFromEpc(epc) {
            if (!this.getRegion) return
            //Get a list of EPCs for the region
            let epcs = utils.epc_percent_mapping(this.getRegion).epcs

            //This var will represent the base percentage we have to move.
            //EPC svgs have been modified to coincide with the number of breakpoints we have for each label
            //If the lower EPC label (G or F depending on region) has X breakpoints, it has to be the size of X
            //smaller items of the SVG + their spacing (!!!!)
            let basePerc

            //if EPC bigger than the high threshold, return 0 (position is very beginning)
            if (epc > epcs[0]) {
                return 0
            }
            //if EPC smaller than the low threshold, return 100 (position is very end)
            if (epc < epcs[epcs.length - 1]) {
                return 100
            }

            for (let i = 0; i < epcs.length; i++) {
                //get which "segment" we're in and define base percentage based on that
                //this is why it's specified above that SVGs need a specific sizing for the longer part of the arrow
                //this is also the fallback if the below condition is never reached for some reason
                basePerc = (100 / epcs.length) * i
                if (epc > epcs[i]) {
                    //Above condition reached when we found the correct range
                    const minRange = epcs[i - 1]
                    const maxRange = epcs[i]

                    //get how much percents of the range is needed to be added to the basePercentage
                    //(input - min) * 100 / (max - min) = percentage of input between a range of [min, max] representing [0, 100]
                    //divide by epcs.length to get that percentage on a segment
                    const additional =
                        ((epc - minRange) * 100) / (maxRange - minRange) / epcs.length

                    return basePerc + additional
                }
            }
            //Fallback:
            // if the above loop never returned anything, return the basePercentage + half of a segment's length
            // to center the tooltips to its relative epc mlabel
            return basePerc + 100 / epcs.length / 2
        },
        select_package(package_info) {
            const oldScenarioName = this.getSelectedScenario.name
            this.SET_SELECTED_PACKAGE(package_info)

            const newScenarioName = this.getSelectedScenario.name

            if (
                // limit the speed rate at which parameters will be inserted into the url
                (this.getSelectedPackage === 'budget' ||
                    this.getSelectedPackage === 'target') &&
                oldScenarioName === newScenarioName
            ) {
                return
            }

            this.set_route()
        },
        set_route() {
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                    ...this.$route.query,
                    package: this.getSelectedPackage,
                    scenario: this.getSelectedScenario.name,
                    budget: this.budget,
                    targetEpc: this.targetEpc,
                },
            })
        },
        updateBudget(val) {
            if (this.getSelectedPackage === 'budget') {
                this.budget = +val
                this.select_package({
                    packageName: 'budget',
                    scenario: this.getBudgetScenario(+this.budget),
                })
            }
        },
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    &__title {
        align-self: stretch;
        color: #001837;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 3rem;
    }
    &__buttons {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        max-width: 100%;
        flex-wrap: wrap;
        h1 {
            color: #001837;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            color: rgba(0, 24, 55, 0.6);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    &__sliders {
        margin-bottom: 3rem;
    }
    &__histogram {
        min-width: 100%;
        position: relative;
        margin-bottom: 3rem;
        &--no-renovation {
            margin-block: 3rem;
        }
        &--custom-package {
            margin-block: 3rem;
        }
    }
}
</style>

<style lang="scss">
.package .ers-input-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.budget-input-slider {
    display: flex;
    justify-content: space-between;
    &-label {
        color: #000;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .slider-field {
        display: flex;
        gap: 1rem;
        min-width: 21rem;
        color: #798da6;
        text-align: center;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: auto;
        span {
            margin: auto;
        }
    }
    &-input {
        display: flex;
        justify-content: flex-end;
        margin-block-start: auto;
        input {
            color: #001837 !important;
            font-size: 0.875rem !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 1.25rem !important; /* 142.857% */
            max-width: 10rem !important;
            margin-inline-start: 1.5rem;
        }
    }
}
.unit-eur {
    display: grid;
    align-items: end;
    transform: translateX(-150%) translateY(-20%);
    z-index: 4;
}

.main__no-renovation-text {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ul {
        padding-left: 1.5rem;
        color: #001837;
        li {
            list-style-type: disc;
            color: #001837;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    p {
        color: #001837;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:first-child {
            color: #001837;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .text-primary {
        color: rgba(0, 156, 255, 1);
    }
}

.main__only-one-renovation-text {
    margin: 1.5rem 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #001837;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>

<style lang="scss">
.b-tooltip.epc-tooltip .tooltip-content {
    background: #162d49 !important;
    &:before {
        border-top-color: #162d49 !important;
    }
}

.b-tooltip.current-epc .tooltip-content {
    background: #162d49 !important;
    &:before {
        border-bottom-color: #162d49 !important;
    }
}

.b-tooltip.budget-epc .tooltip-content {
    background: #162d49 !important;
    &:before {
        border-top-color: #162d49 !important;
    }
}

.b-tooltip.epc-tooltip--active .tooltip-content {
    background: #16a3fd !important;
    &:before {
        border-top-color: #16a3fd !important;
    }
}

.b-tooltip.epc-tooltip--active-bottom .tooltip-content {
    background: #16a3fd !important;
    &:before {
        border-bottom-color: #16a3fd !important;
    }
}
</style>

<style lang="scss" scoped>
.warning-list {
    padding-top: 1rem;
    li:not(:only-child) {
        list-style-type: disc;
        margin-left: 1rem;
    }
}
.package {
    &__warnings {
        display: flex;
        flex-direction: column;
        &-list {
            padding-bottom: 1rem;
            margin-left: 1rem;
        }
    }
    &__notice {
        display: flex;
        padding: 1rem;
        align-items: center;
        gap: 1rem;
        border-radius: 0.3125rem;
        background: rgba(0, 156, 255, 0.1);
        max-width: fit-content;
        color: #6583a8;
    }
    &__icon {
        max-width: 20px;
    }
}

.renovation-type {
    max-width: fit-content;
    display: inline-flex;
    &:not(:last-child):after {
        content: ',\00a0';
    }
    &:last-child:after {
        content: '.';
    }
}

.epc {
    margin-block-end: 3rem;
}

.package_selector-on_report {
    .epc-tooltip {
        &:not(.epc-tooltip--active):not(.current-epc) {
            display: none;
        }
    }
    .main__title {
        margin-top: 0;
    }
    .main__sliders {
        display: none;
    }
    .main__histogram {
        margin-block: 3rem;
    }
    .package__notice {
        display: none;
    }
}

.chart__step {
    display: flex;
    flex-direction: column;
}

.step__bubbles {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.step__img {
    margin-block-start: auto;
    height: 41px;
}

.bubble__subtitle {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
}

.target-epc {
    position: absolute;
    top: -5px;
    z-index: 0;
}

.best-epc,
.target-epc,
.custom-epc,
.current-epc,
.budget-epc {
    position: absolute;
    top: -5px;
    z-index: 0;
}

.current-epc {
    bottom: -5px;
    pointer-events: none;
}

.selector {
    cursor: pointer;
}

.text__title {
    color: #001837;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text__subtitle {
    color: #798da6;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 1.5rem;
}

.budget-input-slider-label-disabled {
    opacity: 0.5;
}

@media print {
    .bubble {
        &__title,
        &__subtitle {
            font-size: 0.9rem;
            color: white;
        }
        &__subtitle {
            font-weight: 400;
        }
        &__title {
            font-weight: bold;
        }
    }
}
</style>
