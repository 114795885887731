<i18n lang="json">
{
    "en-BE": {
        "customer": "Customer",
        "actions": "Actions",
        "address": "Address",
        "type": "Type",
        "automatic_valuation": "Automatic valuation",
        "cancel": "Cancel",
        "customer_ref": "Customer ref",
        "edit": "Edit",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Number of facades",
            "f_construction_year": "Construction year",
            "f_living_area": "Living area",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "More information",
        "last_modified": "Last modified",
        "created_at": "Created",
        "missing_features": "The following features are missing, preventing an automatic valuation:",
        "ok": "OK",
        "per_page": "per page",
        "request_ref": "Request ref",
        "search": "Search",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submitted": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "valued": "Valued",
            "failed": "Failed"
        },
        "badges": {
            "resubmitted": "Resubmitted",
            "needs_on_site": "OVM Needed",
            "needs_borrower_email": "Borrower email needed",
            "needs_appointment_confirmation": "Appointment confirmation needed",
            "needs_borrower_action": "Borrower action needed",
            "appointment_confirmed": "Appointment confirmed"
        },
        "tooltip_text": "Follow here the status of your valuation requests",
        "review_tooltip": "This request has been analyzed by a valuation specialist and some answers need to be reviewed",
        "view_request": "View request",
        "dvm": "DVM",
        "ovm": "OVM",
        "appointment_summary": "Appointment",
        "lang": "Language",
        "valuer": "Valuer",
        "unassigned": "Unassigned",
        "create_report": "Generate report",
        "refresh_report": "Generate new report",
        "download_report": "Save report",
        "report_loading": "Report loading",
        "owner": "Owner",
        "take_ownership": "Take ownership"
    },
    "fr-BE": {
        "customer": "Client",
        "actions": "Actions",
        "address": "Adresse",
        "type": "Type",
        "automatic_valuation": "Valorisation automatique",
        "cancel": "Annuler",
        "customer_ref": "Référence interne",
        "edit": "Modifier",
        "last_modified": "Dernière modification",
        "created_at": "Créé",
        "request_ref": "Référence de demande",
        "status": {
            "awaiting-onsite": "Expertise sur place nécessaire",
            "under-dvm-valuation": "Envoyé",
            "draft": "À compléter",
            "submitted": "Envoyé",
            "exotic": "Expertise sur place nécessaire",
            "needs-review": "Plus d'information nécessaire",
            "cancelled": "Annulé",
            "valued": "Evalué",
            "failed": "Echec"
        },
        "tooltip_text": "Suivez ici l'évolution de vos demandes",
        "review_tooltip": "Cette demande a été analysée par un expert immobilier et certaines réponses doivent être révisées",
        "view_request": "Voir la demande",
        "dvm": "DVM",
        "lang": "Language",
        "valuer": "Expert immobilier",
        "unassigned": "Non assignée",
        "create_report": "Générer un rapport",
        "refresh_report": "Générer un rapport de nouveau",
        "download_report": "Télécharger un rapport",
        "report_loading": "Chargement du rapport",
        "owner": "Demandeur",
        "take_ownership": "Prendre en charge"
    },
    "nl-BE": {
        "customer": "Klant",
        "actions": "Acties",
        "address": "Adres",
        "type": "Type",
        "automatic_valuation": "Automatische waardering",
        "cancel": "Annuleren",
        "customer_ref": "Interne referentie",
        "edit": "Bewerken",
        "last_modified": "Laatst gewijzigd",
        "created_at": "Gecreëerd",
        "request_ref": "Aanvraag referentie",
        "status": {
            "awaiting-onsite": "On-site expertise vereist",
            "under-dvm-valuation": "Ingediend",
            "draft": "Ontwerp",
            "submitted": "Ingediend",
            "exotic": "On-site expertise vereist",
            "needs-review": "Meer informatie nodig",
            "cancelled": "Geannuleerd",
            "valued": "Gewaardeerd",
            "failed": "Gefaald"
        },
        "tooltip_text": "Volg hier de status van uw aanvraagen",
        "review_tooltip": "Deze aanvraag is door een vastgoeddeskundige geanalyseerd en sommige antwoorden moeten worden herzien",
        "view_request": "Aanvraag bekijken",
        "dvm": "DVM",
        "lang": "Taal",
        "valuer": "Vastgoeddeskundige",
        "unassigned": "Niet toegewezen",
        "create_report": "Genereer een verslag",
        "refresh_report": "Genereer een nieuw verslag",
        "download_report": "Verslag opslaan",
        "report_loading": "Verslag is aan het laden",
        "owner": "Aanvrager",
        "take_ownership": "Aanvraag overnemen"
    }
}
</i18n>

<template>
    <div class="mobile-table">
        <b-table
            :data="tableData"
            hoverable
            card-layout
            paginated
            scrollable
            pagination-size="is-small"
            default-sort="modified_at"
            default-sort-direction="desc"
            :loading="loading"
            :per-page="10"
            :current-page.sync="currentPage"
            :row-class="() => 'mobile-row'"
        >
            <template v-for="(column, i) in columns">
                <b-table-column :key="column.valuation_request_ref" v-bind="column">
                    <template #default="props" v-if="i === 1">
                        <b-skeleton
                            v-if="props.row.valuation_request_ref === 'skeleton'"
                            height="32px"
                            width="100%"
                            :rounded="true"
                            :active="true"
                        />
                        <div class="mobile-row-grid" v-else>
                            <div class="grid_item">
                                <div class="building_type-icon">
                                    <svg-icon
                                        :icon="bt_icon(props.row.building_type)"
                                        :height="20"
                                        color="var(--color-primary)"
                                    />
                                </div>
                            </div>
                            <div class="grid_item address-container">
                                <span class="address-text">
                                    {{ props.row.address }}
                                </span>
                            </div>
                            <div class="grid_item">
                                <div
                                    class="status-badge"
                                    :class="statusBadgeClass(props.row.status)"
                                >
                                    <span>{{ props.row.displayStatus }}</span>
                                </div>
                            </div>
                            <div class="grid_item grid_item--full info-container">
                                <div class="info-line">
                                    <span class="info-line-item">
                                        <span>{{ $t('customer_ref') }}:&nbsp;</span>
                                        <b>{{ props.row.customer_ref }}</b>
                                    </span>
                                    <span class="info-line-separator" />
                                    <span class="info-line-item">
                                        <span>{{ $t('request_ref') }}:&nbsp;</span>
                                        <b>{{ props.row.valuation_request_ref }}</b>
                                    </span>
                                </div>
                                <div class="info-line">
                                    <span class="info-line-item">
                                        <span>{{ $t('created_at') }}:&nbsp;</span>
                                        <b>{{ date_format(props.row.created_at) }}</b>
                                    </span>
                                    <span class="info-line-separator" />
                                    <span class="info-line-item">
                                        <span>{{ $t('last_modified') }}:&nbsp;</span>
                                        <b>{{ date_format(props.row.modified_at) }}</b>
                                    </span>
                                </div>
                                <div class="info-line" v-if="showOwner">
                                    <span class="info-line-item">
                                        <span>{{ $t('owner') }}:&nbsp;</span>
                                        <b>{{ props.row.displayOwner }}</b>
                                    </span>
                                </div>
                            </div>
                            <div
                                class="grid_item grid_item--full tags-container"
                                v-if="hasBadges(props.row)"
                            >
                                <b-tag
                                    v-if="
                                        needsBorrowerEmail(props.row) &&
                                            (hasRole('dispatcher', 'ovm') ||
                                                props.row.owner.is_self)
                                    "
                                    type="is-warning"
                                >
                                    {{ $t('badges.needs_borrower_email') }}
                                </b-tag>
                                <b-tag
                                    v-if="
                                        needsAppointmentConfirmation(props.row) &&
                                            isTypeDispatcher(props.row)
                                    "
                                    type="is-warning"
                                >
                                    {{ $t('badges.needs_appointment_confirmation') }}
                                </b-tag>
                                <b-tag
                                    v-if="
                                        needsBorrowerAction(props.row) &&
                                            hasRole('dispatcher', 'ovm')
                                    "
                                    type="is-warning"
                                >
                                    {{ $t('badges.needs_borrower_action') }}
                                </b-tag>
                                <b-tag
                                    v-if="
                                        isAppointmentConfirmed(props.row) &&
                                            (hasRole('dispatcher', 'ovm') ||
                                                props.row.owner.is_self ||
                                                props.row.valuer?.is_self)
                                    "
                                    type="is-success is-light"
                                >
                                    {{ $t('badges.appointment_confirmed') }}
                                </b-tag>
                            </div>
                            <div class="grid_item grid_item--full grid_item-actions">
                                <b-field
                                    :type="valuerSelectType(props.row) || ''"
                                    class="mb-0"
                                    v-if="
                                        ['dvm', 'ovm'].includes(
                                            props.row.valuation_type
                                        ) && isTypeDispatcher(props.row)
                                    "
                                >
                                    <b-select
                                        v-model="
                                            valuerMutable[props.row.valuation_request_ref]
                                        "
                                        @input="
                                            $emit('valuerChange', [$event, props.row])
                                        "
                                        expanded
                                    >
                                        <option value="N/A">
                                            {{ $t('unassigned') }}
                                        </option>
                                        <option
                                            v-for="val in valuers[
                                                props.row.valuation_type
                                            ]"
                                            :key="val.email"
                                            :value="val.username"
                                        >
                                            {{ val.email }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <action-buttons
                                    :row="props.row"
                                    @update="
                                        $emit('updateRow', [
                                            props.row.valuation_request_ref,
                                            $event,
                                        ])
                                    "
                                    @ownership="$emit('openSubModal', props.row)"
                                />
                            </div>
                        </div>
                    </template>
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import ActionButtons from './ActionButtons.vue'

export default {
    name: 'MobileTable',
    components: { ActionButtons },
    props: {
        tableData: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        showOwner: {
            type: Boolean,
            default: false,
        },
        valuers: {
            type: Object,
            default: () => ({}),
        },
        valuerSelects: {
            type: Object,
            default: () => ({}),
        },
        showValuer: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        currentPage: 1,
    }),
    computed: {
        ...mapGetters('auth', ['hasRole', 'accessibleModules', 'roles']),
        isValuer() {
            return this.hasRole('valuer')
        },
        valuerMutable: {
            get: function() {
                return this.valuerSelects
            },
            set: function(val) {
                this.$emit('updateValuerSelects', val)
            },
        },
    },
    methods: {
        bt_icon(bt) {
            switch (bt) {
                case 'apartment':
                    return 'apartment'
                case 'new_house':
                case 'new_apartment':
                    return 'new-construction'
                case 'house':
                default:
                    return 'home'
            }
        },
        statusBadgeClass(status) {
            switch (status) {
                case 'awaiting-onsite':
                case 'draft':
                    return 'status-badge--is_warning'
                case 'cancelled':
                    return 'status-badge--is_danger'
                default:
                    return 'status-badge--is_success'
            }
        },
        date_format: utils.formatDate,
        valuerSelectType(row) {
            if (!this.isValuer || row.status !== 'submitted') return false
            if (!row.valuer.assigned) return 'is-success'
            if (row.valuer.is_self) return 'is-primary'
            return 'is-danger'
        },
        isTypeDispatcher(row) {
            return this.hasRole('dispatcher', row.valuation_type)
        },
        needsBorrowerEmail(row) {
            return !!(
                row.status === 'submitted' &&
                row.ovm_status === 'borrower-registration-pending'
            )
        },
        needsBorrowerAction(row) {
            return !!(
                row.status === 'submitted' &&
                ['appointment-pending', 'payment-pending', 'borrower-details'].includes(
                    row.ovm_status
                )
            )
        },
        needsAppointmentConfirmation(row) {
            return !!(
                row.status === 'submitted' && row.ovm_status === 'confirmation-pending'
            )
        },
        isAppointmentConfirmed(row) {
            return !!(
                row.status === 'submitted' && row.ovm_status === 'valuation-pending'
            )
        },
        hasBadges(row) {
            return (
                this.needsBorrowerEmail(row) ||
                this.needsBorrowerAction(row) ||
                this.needsAppointmentConfirmation(row) ||
                this.isAppointmentConfirmed(row)
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table {
    & ::v-deep .mobile-row {
        border-radius: 8px;
        border: 1px solid #d6e2e9;
        background: #fff;
        box-shadow: 0px 4px 12px 0px rgba(0, 24, 55, 0.1);
        margin-bottom: 16px;
        padding: 16px;

        &-grid {
            display: grid;
            grid-template-columns: auto 1fr auto;
            gap: 16px;
            width: 100%;

            .grid_item {
                &--full {
                    grid-column: 1 / 4;

                    &:empty {
                        display: none;
                    }
                }

                &.tags-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    gap: 4px;
                }

                .building_type-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background: rgba(0, 150, 255, 0.2);
                }

                &.address-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .address-text {
                        color: #001837;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 20px;
                        text-align: left;
                    }
                }

                .status-badge {
                    padding: 4px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 70px;
                    font-size: 12px;
                    font-weight: 700;

                    &--is_danger {
                        background: #feecf0;
                        color: #cc0f35;
                    }

                    &--is_warning {
                        background: #ffe08a;
                        color: rgba(0, 0, 0, 0.7);
                    }

                    &--is_success {
                        background: #11cc0026;
                        color: #11cc00;
                    }
                }

                &.info-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;

                    .info-line {
                        color: rgba(0, 24, 55, 0.5);
                        font-size: 12px;
                        font-weight: 500;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        gap: 0 8px;

                        &-item {
                            text-align: left;
                            white-space: nowrap;
                        }

                        b {
                            color: #001837;
                            font-weight: 500;
                        }

                        .info-line-separator {
                            &:before {
                                content: '•';
                            }
                            // height: 3px;
                            // width: 3px;
                            // display: inline-block;
                            // background: rgba(0, 24, 55, 0.5);
                            // border-radius: 50%;
                        }
                    }
                }

                &-actions {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
            }
        }

        td {
            padding: 0;
            border-bottom: 0 !important;

            &:before {
                display: none !important;
            }
        }
    }
}
</style>
