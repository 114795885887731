<template>
    <Card class="epc">
        <div class="epc__info info">
            <div class="info__text text">
                <div class="text__title">
                    {{
                        $t('energy.result.epc_score.title', {
                            current_epc_score: score,
                        })
                    }}
                </div>
                <div class="text__description">
                    {{
                        $t('energy.result.epc_score.subtitle', {
                            x: percent,
                            region: getCurrentRegion,
                        })
                    }}
                </div>
            </div>
            <div
                :style="{ background: getLabel.color }"
                class="info__score _title _title--big"
            >
                {{ getLabel.label }}
            </div>
        </div>
        <img
            v-if="region === 'Brussels'"
            src="./epc/epc-histograms/brussels-histogram.svg"
            alt="epc chart"
            class="epc__chart"
        />
        <img
            v-else-if="region === 'Wallonia'"
            src="./epc/epc-histograms/wallonia-histogram.svg"
            alt="epc chart"
            class="epc__chart"
        />
        <img
            v-else
            src="./epc/epc-histograms/flanders-histogram.svg"
            alt="epc chart"
            class="epc__chart"
        />
    </Card>
</template>

<script>
import Card from './Card.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'EpcScore',
    components: { Card },
    props: {
        label: {
            type: Object,
            default: () => ({}),
        },
        score: {
            type: Number,
            default: 0,
        },
        region: {
            type: String,
            default: '',
        },
        percent: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        getLabel() {
            return utils.computeLabelFromScore(this.score, this.region)
        },
        getCurrentRegion() {
            return this.$t(`global.shared.${this.region.toLowerCase()}`)
        },
    },
}
</script>

<style scoped>
.epc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.epc__chart {
    margin-block-start: 20px;
    width: 100%;
}

.epc__info {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.text__title {
    display: flex;
    color: #001837;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text__description {
    color: #798da6;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.info__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 3.19rem;
}

.info__score {
    display: grid;
    place-items: center;
    background: #d62015;
    min-width: 56px;
    min-height: 56px;
    border-radius: 28px;
    flex-shrink: 0;
    color: black;
    max-height: 56px;
}

.tooltip {
    max-width: 20px;
    max-height: 20px;
    margin-inline-start: 10px;
    font-size: 13px;
}

.title__tooltip {
    margin-block: auto;
}

@media print {
    .info__score {
        min-height: 0;
        min-width: 0;
        height: 0.8cm;
        width: 0.8cm;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
</style>
