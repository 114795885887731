<template>
    <div class="analytics_item">
        <h5 class="analytics_item-title">{{ title }}</h5>
        <kernel-chart
            :data-array="population"
            :target-value="target"
            :width="kernelWidth"
            :height="isMobile ? null : 180"
            :margin-right="10"
            :margin-left="10"
            :margin-bottom="20"
            :margin-top="0"
        />
        <div class="analytics_item-details">
            <span class="analytics_item-details-line">
                {{ $t('valuer_tool.general.value.analytics.assessed') }}
            </span>
            <span class="analytics_item-details-line">
                {{ displayedTarget }}
            </span>
            <span class="analytics_item-details-line">
                {{ $t('valuer_tool.general.value.analytics.median') }}
            </span>
            <span class="analytics_item-details-line">
                {{ median }}
            </span>
            <span class="analytics_item-details-line">
                {{ $t('valuer_tool.general.value.analytics.average') }}
            </span>
            <span class="analytics_item-details-line">
                {{ mean }}
            </span>
            <span class="analytics_item-details-line">
                {{ $t('valuer_tool.general.value.analytics.deviance') }}
            </span>
            <span class="analytics_item-details-line">
                {{ deviance }}
            </span>
            <span class="analytics_item-details-percentile">% = {{ percentile }}</span>
        </div>
    </div>
</template>
<script>
import KernelChart from '@/apps/Valuation/components/ValuerTool/elements/KernelChart.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'AnalyticsItem',
    components: {
        KernelChart,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        population: {
            type: Array,
            required: true,
        },
        target: {
            type: Number,
            required: true,
        },
        assessed: {
            type: String,
            default: null,
        },
        median: {
            type: String,
            required: true,
        },
        mean: {
            type: String,
            required: true,
        },
        deviance: {
            type: String,
            required: true,
        },
        percentile: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['isMobile']),
        displayedTarget() {
            if (this.assessed === null) return this.target
            return this.assessed
        },
        kernelWidth() {
            if (this.isMobile) return window.innerWidth - 32
            return undefined
        },
    },
}
</script>
<style lang="scss" scoped>
.analytics_item {
    padding: 24px;
    background: white;

    @media screen and (max-width: 999px) {
        border-bottom: 1px solid #d6e2e9;
        padding: 16px;
    }

    &-title {
        color: #001837;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    &-details {
        margin-top: 16px;
        display: grid;
        width: 100%;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 8px 0;
        justify-items: start;

        &-line {
            color: #798da6;
            font-size: 14px;
            font-weight: 500;

            &:nth-child(2n) {
                color: #001837;
                justify-self: end;
            }
        }

        &-percentile {
            grid-column: 1 / -1;
            justify-self: end;
            color: #001837;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
        }
    }
}
</style>
