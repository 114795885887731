<template>
    <div
        class="renovations__item item"
        :class="{
            'item--selected': selected,
            'item--selectable': selectable,
            'item--disabled': !selectable && !selected,
        }"
    >
        <button class="card-button" @click="!isTooltipOpen && $emit('click')" />
        <div class="invisible-layer">
            <div class="item__text text">
                <div class="text__title">
                    <div v-if="reportIndex >= 0" class="renovation__number">
                        {{ reportIndex }}
                    </div>
                    {{ $t(`energy.result.renovation.${getName}`) }}
                </div>
                <Tooltip
                    class="text__tooltip"
                    :disabled="!selectable && !selected"
                    :html="
                        $t(`energy.result.renovation.${getName}_tooltip_popup`, {
                            tooltip_title: $t(`energy.result.renovation.${getName}`),
                        })
                    "
                    @update-tooltip-state="(value) => handleTooltipState(value)"
                />
            </div>
            <img class="item__icon" :src="icon" />
            <div class="item__table table">
                <div class="table__price price">
                    <div class="price__label">
                        {{ $t('common.price') }}
                    </div>
                    <p class="price__value">{{ int_value_formatter(getPrice) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Tooltip from '../../elements/Tooltip.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'

export default {
    name: 'RenovationItem',
    components: {
        Tooltip,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        reportIndex: {
            type: Number,
            default: -1,
        },
        tooltip: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            required: true,
        },
        price: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isTooltipOpen: false,
        }
    },
    computed: {
        ...mapGetters('ersStore', ['getFeatures', 'getScenarios', 'getSelectedScenario']),
        getName() {
            if (this.name === 'roofIn') {
                const { f_percent_of_roof_flat } = this.getFeatures
                if (f_percent_of_roof_flat === 0) {
                    return 'roofIn'
                } else if (f_percent_of_roof_flat === 1) {
                    return 'ceiling'
                } else {
                    return 'mixed'
                }
            } else {
                return this.name
            }
        },
        getPrice() {
            const exceptions = ['heatPump', 'condGas']

            if (this.selected || !exceptions.includes(this.name)) return this.price

            let selectedRenovations = this.getSelectedScenario.name.split('_')
            selectedRenovations = selectedRenovations.filter(
                (renovation) => !exceptions.includes(renovation)
            )

            return this.getScenarios.find((scenario) => {
                const renovations = scenario.name.split('_')
                return [...selectedRenovations, this.name].every((renov) =>
                    renovations.includes(renov)
                )
            }).heating_investment_cost
        },
    },
    methods: {
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
        handleTooltipState(value) {
            this.isTooltipOpen = value
        },
    },
}
</script>
<style lang="scss" scoped>
.renovations__item {
    background: #f4f6f9;
    border-radius: 4px;
    border: 1px solid rgba(214, 226, 233, 1);
}

.item {
    transition: all 0.1s;
    min-width: 4rem;
    min-height: 16rem;
    position: relative;
    &--selected {
        border: 1px solid var(--color-primary);
        box-shadow: 4px 8px 12px 0px rgba(0, 24, 55, 0.1);
    }
    &--disabled {
        .item__text {
            .text__title {
                opacity: 0.4;
            }
        }
        .item__icon,
        .item__table {
            opacity: 0.4;
        }
    }
    &--selectable {
        cursor: pointer;
    }
}

.card-button {
    all: unset;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
}

.invisible-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
}

.item__text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.item__icon {
    max-width: 9.40881rem;
    max-height: 4.375rem;
    margin: auto;
}

.text__tooltip {
    min-width: 100%;
    display: flex;
}

.renovation__number {
    display: none;
}

.text__title {
    max-width: 180px;
    color: #001837;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.title__tooltip {
    margin-block: auto;
}

.price__label,
.subsidy__label {
    color: #00183780;
}

.price__value,
.subsidy__value {
    color: #001837;
    text-align: right;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.price,
.subsidy {
    display: flex;
    width: 100;
    justify-content: space-between;

    &__label {
        color: rgba(0, 24, 55, 0.5);
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media print {
    .renovation__number {
        display: inline-flex;
        border-radius: 50%;
        background: rgba(0, 24, 55, 0.2);
        font-size: 0.5rem;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        position: relative;
        bottom: 0.2rem;
        line-height: 0.5rem;
    }

    .item {
        padding: 1rem;
        gap: 1rem;

        &--selected {
            outline: none;
            box-shadow: none;
        }

        &__icon {
            width: 100%;
            height: auto;
            margin: 0;
            max-width: none;
            max-height: 1.5cm;
        }
    }
}
</style>
<style lang="scss">
.text__tooltip {
    .tooltip-trigger {
        max-height: 20px;
    }
}
</style>
