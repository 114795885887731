<template>
    <div class="card-content__section card card--mini">
        <div class="card__section-title">
            <template v-if="!isEditing">
                {{ acc.institution }}
            </template>
            <b-input v-else v-model="newInput.institution" type="text" />
            <div v-if="editable" class="card__section-title-actions">
                <icon-button
                    icon-only
                    :icon="isEditing ? 'save' : 'edit'"
                    @click="onEditBttnClick"
                />
                <b-tooltip
                    :triggers="['click']"
                    :auto-close="['inside', 'outside']"
                    type="is-dark"
                >
                    <template slot="content">
                        <div class="delete-tooltip">
                            <b-button
                                type="is-danger"
                                size="is-small"
                                @click="$emit('delete', acc.id)"
                            >
                                {{ $t('monolith.global.delete') }}
                            </b-button>
                            <b-button size="is-small">
                                {{ $t('common.cancel') }}
                            </b-button>
                        </div>
                    </template>
                    <icon-button icon-only :icon="'delete'" @click="onDeleteBttnClick" />
                </b-tooltip>
            </div>
        </div>
        <h2>
            {{ $t('valuer_tool.borrower.valuer_info.accreditation_card-number') }}
        </h2>
        <span v-if="!isEditing">{{ acc.accreditation_number }}</span>
        <b-input
            v-else
            v-model="newInput.accreditation_number"
            type="text"
            size="is-small"
        />
    </div>
</template>
<script>
import IconButton from '@/components/elements/IconButton.vue'

export default {
    name: 'AccreditationCard',
    components: {
        IconButton,
    },
    props: {
        acc: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isEditing: false,
        newInput: {},
    }),
    watch: {
        acc: {
            immediate: true,
            deep: true,
            handler(val) {
                this.newInput = { ...val }
            },
        },
    },
    methods: {
        onEditBttnClick() {
            if (this.isEditing) {
                this.$emit('save', {
                    ...this.newInput,
                })
            }

            this.isEditing = !this.isEditing
        },
    },
}
</script>
<style lang="scss" scoped>
.delete-tooltip {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    & ::v-deep .is-danger span {
        color: white !important;
    }
}
</style>
