<template>
    <form class="overview" @submit.prevent="onSubmit">
        <div class="overview__title">
            <p>{{ $t('valuer_tool.borrower.payment-overview.title') }}</p>
        </div>
        <div class="overview__container">
            <div class="overview__appointment">
                <!-- ADDRESS -->
                <div v-if="borrowerData.address" class="overview__appointment-item">
                    <div class="overview__appointment-icon">
                        <img src="@/assets/icons/ic-marker-pin.svg" alt="" />
                    </div>
                    <div class="overview__appointment-content">
                        <p>
                            {{ formatAddress.firstLine }}
                        </p>
                        <p>
                            {{ formatAddress.secondLine }}
                        </p>
                    </div>
                </div>
                <!-- APPOINTMENT DATE -->
                <div v-if="borrowerData.appointment" class="overview__appointment-item">
                    <div class="overview__appointment-icon">
                        <img src="@/assets/icons/ic-calendar.svg" alt="" />
                    </div>
                    <div class="overview__appointment-content">
                        {{ formatDate(borrowerData.appointment.date) }}
                    </div>
                </div>
                <!-- APPOINTMENT TIME -->
                <div v-if="borrowerData.appointment" class="overview__appointment-item">
                    <div class="overview__appointment-icon">
                        <img src="@/assets/icons/ic-clock.svg" alt="" />
                    </div>
                    <div class="overview__appointment-content">
                        {{ formatTime(borrowerData.appointment.start_time) }} -
                        {{ formatTime(borrowerData.appointment.end_time) }}
                    </div>
                </div>
                <!-- PAYMENT -->
                <div v-if="borrowerData.payment" class="overview__appointment-item">
                    <div class="overview__appointment-icon">
                        <img src="@/assets/icons/ic-euro-blue.svg" alt="" />
                    </div>
                    <div class="overview__appointment-content">
                        <p>{{ formatPrice(borrowerData.payment.total) }}</p>
                        <span>{{ $t('monolith.global.total') }}</span>
                    </div>
                </div>
            </div>

            <div class="overview__checkboxes">
                <b-checkbox v-model="agreeWithConditions" name="agreeWithConditions">
                    {{ $t('valuer_tool.borrower.payment-overview.agree-with-terms') }}
                </b-checkbox>
                <b-checkbox v-model="willBePresent" name="willBePresent">
                    {{
                        $t(
                            'valuer_tool.borrower.payment-overview.confirm-someone-present'
                        )
                    }}
                </b-checkbox>
            </div>

            <div class="overview__cancel-policy">
                <h1 class="overview__cancel-policy-title">
                    {{ $t('valuer_tool.borrower.payment-overview.cancel_title') }}
                </h1>
                <p class="overview__cancel-policy-description">
                    {{ $t('valuer_tool.borrower.payment-overview.cancel_description') }}
                </p>
            </div>
        </div>

        <nav-buttons
            class="overview__buttons"
            :show-next="showNext"
            :next-step="nextStep"
            :prev-step="prevStep"
        ></nav-buttons>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'OverviewAppointment',
    components: {
        NavButtons,
    },
    props: {
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: () => ({
        willBePresent: false,
        agreeWithConditions: false,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getAddress']),
        ...mapGetters('valuationStore', ['borrower', 'getRequestRef', 'ovmInfo']),
        showNext() {
            return this.willBePresent && this.agreeWithConditions
        },
        user_id() {
            return this.borrower.id
        },

        formatPrice() {
            return (val) => {
                return utils.int_value_formatter(+val, this.$route.params.lang)
            }
        },
        formatAddress() {
            return utils.formatAddress(this.borrowerData.address)
        },
    },
    mounted() {},
    methods: {
        async onSubmit() {
            this.$router.push({
                query: { ...this.$route.query, step: 'payment-pending' },
            })
        },
        formatDate(aDate) {
            return utils.formatDate(aDate, true, this.$route.params.lang)
        },
        formatTime(time) {
            return utils.formatTime(time)
        },
    },
}
</script>

<style scoped lang="scss">
.overview {
    display: flex;
    flex-direction: column;
    &__container {
        border-right: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
    }
    &__checkboxes {
        margin-top: 1.5rem;
        margin-inline: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        color: #001837;
        // text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
    }
    &__cancel-policy {
        display: flex;
        padding: 1.5rem;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        margin-inline: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        border-radius: 0.5rem;
        background: rgba(214, 226, 233, 0.5);
        &-title {
            color: #001837;
            text-align: center;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 111.111% */
        }
        &-description {
            color: rgba(0, 24, 55, 0.6);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 142.857% */
        }
    }
    &__title {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        color: #001837;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
        padding: 1.5rem;
        border-top: 1px solid #d6e2e9;
        border-right: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
        background: #fff;
    }
    &__appointment {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        border: 1px solid #d6e2e9;
        margin-inline: 1.5rem;
        border-radius: 0.5rem;
        background: #fff;
        &-item {
            display: flex;
            padding: 1.5rem;
            align-items: flex-start;
            gap: 0.625rem;
            flex: 1 0 0;
            gap: 1rem;
            &:nth-child(odd) {
                border-right: 1px solid #d6e2e9;
            }
            &:nth-child(-n + 2) {
                border-bottom: 1px solid #d6e2e9;
            }
        }

        &-icon {
            display: flex;
            width: 2.5rem;
            height: 2.5rem;
            justify-content: center;
            align-items: center;
            gap: 0.52081rem;
            border-radius: 4.16669rem;
            background: rgba(0, 150, 255, 0.2);
        }
        &-content {
            color: #001837;
            text-align: left;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 142.857% */
            margin-block: auto;
            span {
                color: rgba(0, 24, 55, 0.5);
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
    &__buttons {
        display: flex;
        padding: 1rem 1.5rem;
        justify-content: space-between;
        min-width: 100%;
        gap: 1.5rem;
        align-self: stretch;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        border-right: 1px solid #d6e2e9;
        border-bottom: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
        background: #fff;
    }
}
</style>
