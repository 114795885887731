/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M8.5,15.3H3.5c-0.8,0-1.3,0-1.7-0.2c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.2-0.9-0.2-1.7V7.5 c0-0.8,0-1.3,0.2-1.7c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.2,1.7-0.2h5.1c0.8,0,1.3,0,1.7,0.2c0.4,0.2,0.7,0.5,0.9,0.9 c0.2,0.4,0.2,0.9,0.2,1.7v5.1c0,0.8,0,1.3-0.2,1.7c-0.2,0.4-0.5,0.7-0.9,0.9C9.8,15.3,9.4,15.3,8.5,15.3z M3.5,6 c-0.6,0-1,0-1.1,0.1C2.2,6.1,2.1,6.2,2.1,6.4C2,6.5,2,6.9,2,7.5v5.1c0,0.6,0,1,0.1,1.1c0.1,0.1,0.2,0.2,0.3,0.3 C2.5,14,2.9,14,3.5,14h5.1c0.6,0,1,0,1.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.5,0.1-1.1V7.5c0-0.6,0-1-0.1-1.1 C9.9,6.2,9.8,6.1,9.6,6.1C9.5,6,9.1,6,8.5,6H3.5z M13,11.3c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.3,0,0.5,0,0.6-0.1 c0.1-0.1,0.2-0.2,0.3-0.3C14,9.5,14,9.3,14,9c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0c0.4,0,0.7,0.3,0.7,0.7c0,0.5,0,0.9-0.2,1.2 c-0.2,0.4-0.5,0.7-0.9,0.9C13.9,11.3,13.5,11.3,13,11.3C13,11.3,13,11.3,13,11.3z M14.7,7.3C14.3,7.3,14,7,14,6.7V5.3 c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v1.3C15.3,7,15,7.3,14.7,7.3z M14.7,3.7C14.3,3.7,14,3.4,14,3c0-0.3,0-0.5-0.1-0.6 c-0.1-0.1-0.2-0.2-0.3-0.3C13.5,2,13.3,2,13,2c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0c0.5,0,0.9,0,1.2,0.2 c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.3,0.2,0.7,0.2,1.2C15.3,3.4,15,3.7,14.7,3.7C14.7,3.7,14.7,3.7,14.7,3.7z M5.3,3.7 C5.3,3.7,5.3,3.7,5.3,3.7C5,3.7,4.7,3.4,4.7,3c0-0.5,0-0.9,0.2-1.2c0.2-0.4,0.5-0.7,0.9-0.9C6.1,0.7,6.5,0.7,7,0.7 c0.4,0,0.7,0.3,0.7,0.7C7.7,1.7,7.4,2,7,2C6.7,2,6.5,2,6.4,2.1C6.2,2.1,6.1,2.2,6.1,2.4C6,2.5,6,2.7,6,3C6,3.4,5.7,3.7,5.3,3.7z M10.7,2H9.3C9,2,8.7,1.7,8.7,1.3S9,0.7,9.3,0.7h1.3c0.4,0,0.7,0.3,0.7,0.7S11,2,10.7,2z"/>'
  }
})
