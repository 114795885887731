<template>
    <grid-card is-full :title="$t('dvm_features.solar_panel')" no-mobile-toggle>
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t('valuer_tool.exterior.solar_panel.question') }}</h5>
                <b-checkbox v-model="solar_panel" />
            </label>
        </template>
        <template v-if="solar_panel" slot="default">
            <div class="grid_card-content_grid three-cols">
                <div class="content_grid-item">
                    <b-field :label="$t('dvm_features.solar_panel_area')">
                        <b-input v-model.number="solar_panel_area" type="number" />
                    </b-field>
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
export default {
    name: 'CommonSolarPanels',
    components: {
        GridCard,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getDVMFeatures']),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES']),
        }
    },
    data: () => ({
        solar_panel: false,
        solar_panel_area: 0,
    }),
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        solar_panel(val) {
            this.SET_DVM_FEATURES({
                common_solar_panel: val,
            })
        },
        solar_panel_area(val) {
            this.SET_DVM_FEATURES({
                common_solar_panel_area: val,
            })
        },
    },
    mounted() {
        this.solar_panel = this.getDVMFeatures?.common_solar_panel || false
        this.solar_panel_area = this.getDVMFeatures?.common_solar_panel_area || 0
    },
}
</script>
