var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'sidebar-section': true,
        'is-toggle': _vm.isToggle,
        interactive: _vm.hasClickListener,
        active: _vm.active,
    },on:{"click":function($event){return _vm.$emit('click')}}},[_c('h2',{staticClass:"sidebar-section-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isMobile)?_c('div',{class:{
            'chevron-badge': true,
            'chevron-toggle': _vm.isToggle,
        }},[_c('img',{class:{ toggled: _vm.toggled, chevron: true },attrs:{"src":_vm.chevron}})]):(_vm.isToggle)?_c('img',{class:{ toggled: _vm.toggled, chevron: true },attrs:{"src":_vm.chevron}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }