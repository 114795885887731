/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'processing': {
    width: 16,
    height: 16,
    viewBox: '0 0 297 297',
    data: '<path pid="0" d="M251.01 277.015h-17.683l-.002-31.559c0-31.639-17.358-60.726-48.876-81.901-3.988-2.682-6.466-8.45-6.466-15.055s2.478-12.373 6.464-15.053c31.52-21.178 48.878-50.264 48.88-81.904V19.985h17.683c5.518 0 9.992-4.475 9.992-9.993 0-5.518-4.475-9.992-9.992-9.992H45.99c-5.518 0-9.992 4.475-9.992 9.992 0 5.519 4.475 9.993 9.992 9.993h17.683v31.558c0 31.642 17.357 60.729 48.875 81.903 3.989 2.681 6.467 8.448 6.467 15.054 0 6.605-2.478 12.373-6.466 15.053-31.519 21.176-48.876 50.263-48.876 81.903v31.559H45.99c-5.518 0-9.992 4.475-9.992 9.993 0 5.519 4.475 9.992 9.992 9.992h205.02c5.518 0 9.992-4.474 9.992-9.992 0-5.519-4.475-9.993-9.992-9.993zM138.508 110.362c0-5.518 4.474-9.993 9.992-9.993s9.992 4.475 9.992 9.993v17.664c0 5.519-4.474 9.992-9.992 9.992s-9.992-4.474-9.992-9.992v-17.664zm2.925 63.594a10.072 10.072 0 017.064-2.927c2.628 0 5.206 1.069 7.064 2.927 1.868 1.859 2.928 4.438 2.928 7.065s-1.06 5.206-2.928 7.064a10.068 10.068 0 01-7.064 2.928 10.064 10.064 0 01-7.064-2.928c-1.859-1.858-2.928-4.437-2.928-7.064s1.068-5.205 2.928-7.065zM86.94 277.112c8.152-30.906 50.161-64.536 55.405-68.635a9.993 9.993 0 0112.309 0c5.244 4.1 47.252 37.729 55.404 68.635H86.94z"/>'
  }
})
