/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'submit': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path class="st0" d="M11.8,18.3H6.5c-1.5,0-2.3,0-3-0.4c-0.6-0.3-1.1-0.8-1.5-1.5c-0.4-0.7-0.4-1.5-0.4-3V8.2c0-1.5,0-2.3,0.4-3 C2.4,4.5,2.9,4,3.5,3.7c0.7-0.4,1.5-0.4,3-0.4h1.8c0.5,0,0.8,0.4,0.8,0.8S8.8,5,8.3,5H6.5C5.3,5,4.6,5,4.2,5.2 C3.9,5.3,3.7,5.6,3.5,5.9c-0.2,0.4-0.2,1-0.2,2.3v5.3c0,1.2,0,1.9,0.2,2.3c0.2,0.3,0.4,0.6,0.7,0.7c0.4,0.2,1,0.2,2.3,0.2h5.3 c1.2,0,1.9,0,2.3-0.2c0.3-0.2,0.6-0.4,0.7-0.7c0.2-0.4,0.2-1,0.2-2.3v-1.8c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.8 c0,1.5,0,2.3-0.4,3c-0.3,0.6-0.8,1.1-1.5,1.5C14.1,18.3,13.4,18.3,11.8,18.3z M10.8,10c-0.2,0-0.4-0.1-0.6-0.2 c-0.3-0.3-0.3-0.9,0-1.2l5.2-5.2h-3c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h5c0,0,0,0,0,0h0c0,0,0,0,0,0h0h0c0.1,0,0.2,0,0.3,0.1 c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3v5c0,0.5-0.4,0.8-0.8,0.8S16.7,8,16.7,7.5v-3l-5.2,5.2 C11.3,9.9,11,10,10.8,10z"/>'
  }
})
