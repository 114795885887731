/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dvm': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<rect pid="0" width="16" height="16" rx="8" _fill="#009CFF"/><path pid="1" d="M8.96 12.12c-1.76 0-3.08-.92-3.53-2.5h-.75v-.91h.6c-.01-.11-.01-.23-.01-.35 0-.12 0-.24.01-.35h-.6V7.1h.76c.45-1.6 1.8-2.54 3.63-2.54.37 0 .78.05 1.11.15v1.2a4.36 4.36 0 00-1.05-.15c-1.19 0-2.01.45-2.38 1.34h2.51v.91H6.54c-.01.11-.01.22-.01.33 0 .13.01.25.02.37h2.71v.91h-2.5c.37.87 1.16 1.31 2.27 1.31.4 0 .82-.07 1.22-.19v1.23c-.46.1-.86.15-1.29.15z" _fill="#fff"/>'
  }
})
