<template>
    <div class="main">
        <div class="main__wrapper">
            <div v-if="choice === false" class="main__unhappy">
                <div class="main__unhappy-icon">
                    <img src="@/assets/icons/ic-question-mark-orange.svg" alt="" />
                </div>
                <h1 class="main__unhappy-title">
                    {{ $t('valuer_tool.borrower.confirm-address_unhappy-title') }}
                </h1>
                <p class="main__unhappy-description">
                    {{ $t('valuer_tool.borrower.confirm-address_unhappy-description') }}
                </p>
                <div class="main__buttons">
                    <clear-button
                        class="main__unhappy-button"
                        aria-label="Go back"
                        @click="choice = null"
                    >
                        <img class="chevron" :src="chevron" />
                        <span>{{ $t('common.back') }}</span>
                    </clear-button>
                </div>
            </div>
            <div v-else class="main__content">
                <div class="main__text">
                    <h1 class="main__title">{{ $t('monolith.apps.welcome') }}</h1>
                    <p class="main__description">
                        {{ $t('valuer_tool.borrower.confirm-address_question') }}
                    </p>
                </div>
                <div class="main__map">
                    <re-map
                        class="address-map"
                        :building-id="getAddress.building_id"
                        :parcel-ids="get_map_scene2d.parcel_ids"
                        level="2"
                        :center="{
                            lat: get_map_scene2d.center?.lat,
                            lng: get_map_scene2d.center?.lng,
                        }"
                        :map-scene2d="get_map_scene2d"
                        :flood-visible="false"
                        :focus-layer="'building'"
                        read-only
                        marker
                        size="small"
                        :layers-control-visible="false"
                        :options="{
                            zoomControl: false,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            doubleClickZoom: false,
                            boxZoom: false,
                            dragging: false,
                        }"
                    />
                    <div class="address-text">
                        <div class="address-text__icon">
                            <img src="@/assets/icons/ic-marker-pin.svg" alt="" />
                        </div>
                        <div class="address-text__content">
                            <p>
                                {{ formatAddress.firstLine }}
                            </p>
                            <p>
                                {{ formatAddress.secondLine }}
                            </p>
                        </div>
                    </div>
                </div>
                <yes-no v-model="choice" class="main__buttons" is-small />
            </div>
        </div>
    </div>
</template>

<script>
import ReMap from '@/components/common/Map.vue'
import { LMarker } from 'vue2-leaflet'
import { mapGetters } from 'vuex'
import YesNo from '@/apps/ERS/components/elements/YesNo.vue'
import utils from '@/shared/plugins/utils'
import ClearButton from '@/components/elements/ClearButton.vue'
import chevron from '@/shared/assets/icons/chevron.svg'

export default {
    name: 'OVMSubmitter',
    components: {
        ReMap,
        YesNo,
        ClearButton,
    },
    data: () => ({
        choice: null,
        chevron: chevron,
    }),
    computed: {
        ...mapGetters('valuationStore', ['borrower', 'getRequestRef']),
        ...mapGetters('valuationStore/Address', [
            'getAddress',
            'getFeatures',
            'get_map_scene2d',
        ]),
        formatAddress() {
            return utils.formatAddress(this.getAddress)
        },
    },
    watch: {
        choice(newVal) {
            if (newVal) {
                this.$emit('on-hide')
            }
        },
    },
}
</script>

<style scoped lang="scss">
.main {
    background: #f4f6f9;
    max-width: 100vw;
    flex-grow: 1;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    &__wrapper {
        max-width: 40rem;
        margin-inline: auto;
    }
    &__content {
        display: flex;
        padding-top: 5rem;
        align-items: flex-start;
        flex-direction: column;
        gap: 3rem;
        flex: 1 0 0;
        min-width: 100%;
        margin-inline: auto;
        min-height: 100%;
    }
    &__map {
        border-radius: 0.5rem;
        border: 1px solid #d6e2e9;
        background: #fff;
        display: flex;
        min-width: 100%;
        flex-grow: 1;
        max-height: 9rem;
        overflow: hidden;
    }
    &__title {
        color: #001837;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    &__buttons {
        min-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__description {
        color: #001837;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__unhappy {
        display: flex;
        padding: 7.5rem 0rem 5rem 0rem;
        flex-direction: column;
        min-width: 100%;
        margin-inline: auto;
        gap: 3rem;
        flex: 1 0 0;
        align-self: stretch;
        &-icon {
            display: flex;
            width: 5rem;
            height: 5rem;
            padding: 1.04169rem;
            justify-content: center;
            align-items: center;
            gap: 1.04169rem;
            border-radius: 8.33331rem;
            background: rgba(255, 170, 0, 0.2);
            margin-inline: auto;
        }
        &-title {
            color: #001837;
            text-align: center;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.375rem; /* 118.75% */
        }
        &-description {
            color: #798da6;
            text-align: center;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &-button {
            display: flex;
            padding: 0.75rem 1.25rem;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.3125rem;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background-color: rgb(223, 228, 235);
            color: rgb(121, 141, 166);
            .chevron {
                transform: rotate(90deg);
                height: 8px;
                margin-right: 12px;
            }
        }
    }
}

.address-map {
    min-height: 10rem;
}

.address-map,
.address-text {
    flex: 1 1 0;
}

.address-text {
    display: flex;
    padding-block: 1.5rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.52081rem;
        width: 2.5rem;
        height: 2.5rem;
        margin-block: auto;
        border-radius: 4.16669rem;
        background: rgba(0, 150, 255, 0.2);
    }
    &__content {
        color: #001837;
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 142.857% */
    }
}
</style>
