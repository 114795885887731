<template>
    <div class="main">
        <div class="main__content">
            <img src="@/assets/icons/ic-checkmark.svg" class="main__icon" />
            <h1 class="main__title">
                {{ $t('valuer_tool.borrower.booking-complete.title') }}
            </h1>
            <p class="main__text">
                {{ $t('valuer_tool.borrower.booking-complete.description') }}
            </p>

            <div class="main__buttons">
                <b-button
                    v-if="receiptUrl"
                    tag="a"
                    target="_blank"
                    class="borrower-button-dark"
                    :href="receiptUrl"
                >
                    {{ $t('valuer_tool.borrower.receipt.button') }}
                </b-button>
                <b-button
                    v-if="status == 'valued'"
                    tag="a"
                    class="borrower-button-dark"
                    :href="reportUrl"
                >
                    {{ $t('valuer_tool.borrower.report.button') }}
                </b-button>
                <add-to-calendar-button
                    v-if="status == 'submitted'"
                    :title="$t('valuer_tool.borrower.calendar.title')"
                    :data="borrowerData"
                />
            </div>
            <b-button class="contact-form-bttn" @click="showContactModal">
                {{ $t('valuer_tool.borrower.billing-address-button') }}
            </b-button>
        </div>
        <b-modal
            v-model="isContactModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="!isFeedbackSent" class="contact-form">
                <div class="contact-form__title">
                    <h2 class="title is-size-4 has-text-weight-bold">
                        {{ $t('valuer_tool.borrower.billing-address-button') }}
                    </h2>
                    <button class="closeModalBttn" @click="isContactModalActive = false">
                        <img src="@/assets/icons/bttn-close-black.svg" alt="" />
                    </button>
                </div>
                <form>
                    <div class="contact-form__streetname">
                        <b-field
                            :label="$t('global.shared.streetname')"
                            :type="{
                                'is-danger': $v.invoice.streetname.$error,
                            }"
                            :message="[
                                !$v.invoice.streetname.required &&
                                $v.invoice.streetname.$error
                                    ? $t('validation.field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="invoice.streetname"
                                :placeholder="$t('global.shared.placeholder.streetname')"
                                name="representative_role_description"
                                class="grid-offset"
                                @input="$v.invoice.streetname.$touch()"
                            />
                        </b-field>
                    </div>
                    <div class="contact-form__address">
                        <b-field
                            :label="$t('global.shared.streetnumber')"
                            :type="{
                                'is-danger': $v.invoice.streetnumber.$error,
                            }"
                            :message="[
                                !$v.invoice.streetnumber.required &&
                                $v.invoice.streetnumber.$error
                                    ? $t('validation.field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="invoice.streetnumber"
                                :placeholder="
                                    $t('global.shared.placeholder.streetnumber')
                                "
                                name="representative_role_description"
                                class="grid-offset"
                            />
                        </b-field>
                        <b-field :label="$t('global.shared.boxnumber')">
                            <b-input
                                v-model="invoice.boxnumber"
                                placeholder="0"
                                name="representative_role_description"
                                class="grid-offset"
                            />
                        </b-field>
                        <b-field
                            :label="$t('global.shared.postalcode')"
                            :type="{
                                'is-danger': $v.invoice.postalcode.$error,
                            }"
                            :message="[
                                !$v.invoice.postalcode.required &&
                                $v.invoice.postalcode.$error
                                    ? $t('validation.field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="invoice.postalcode"
                                :placeholder="$t('global.shared.placeholder.postalcode')"
                                name="representative_role_description"
                                class="grid-offset"
                            />
                        </b-field>
                        <b-field
                            :label="$t('global.shared.municipality')"
                            :type="{
                                'is-danger': $v.invoice.municipality.$error,
                            }"
                            :message="[
                                !$v.invoice.municipality.required &&
                                $v.invoice.municipality.$error
                                    ? $t('validation.field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="invoice.municipality"
                                name="representative_role_description"
                                :placeholder="
                                    $t('global.shared.placeholder.municipality')
                                "
                                class="grid-offset"
                            />
                        </b-field>
                    </div>
                    <div class="contact-form__company">
                        <h3 class="is-size-6 has-text-weight-bold">
                            {{ $t('global.shared.company') }}
                        </h3>
                        <b-field :label="$t('global.shared.vat')">
                            <b-input
                                v-model="invoice.vat"
                                name="representative_role_description"
                                :placeholder="$t('global.shared.placeholder.vat')"
                                class="grid-offset"
                            />
                        </b-field>
                        <b-field :label="$t('global.shared.name')">
                            <b-input
                                v-model="invoice.contact_name"
                                :placeholder="
                                    $t('global.shared.placeholder.company-name')
                                "
                                name="representative_role_description"
                                class="grid-offset"
                            />
                        </b-field>
                        <b-field :label="$t('global.shared.email')">
                            <b-input
                                v-model="invoice.email"
                                :placeholder="
                                    $t('global.shared.placeholder.contact-email')
                                "
                                name="representative_role_description"
                                class="grid-offset"
                            />
                        </b-field>
                    </div>
                    <b-button
                        type="is-primary"
                        class="has-text-weight-bold submit-btn"
                        @click="sendFeedback"
                    >
                        {{ $t('global.helpcenter.contact_form.submit') }}
                    </b-button>
                </form>
            </div>
            <div v-if="isFeedbackSent" class="contact-form">
                <div class="contact-form__title">
                    <h2
                        class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                    >
                        {{ $t('global.helpcenter.contact_form.feedback_title') }}
                    </h2>
                    <button class="closeModalBttn" @click="isContactModalActive = false">
                        <img src="@/assets/icons/bttn-close-black.svg" alt="" />
                    </button>
                </div>
                <p>
                    {{ $t('global.helpcenter.contact_form.feedback_content') }}
                </p>
                <b-button
                    type="is-primary"
                    class="has-text-weight-bold mt-5"
                    @click="isContactModalActive = false"
                >
                    {{ $t('global.helpcenter.contact_form.close') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import AddToCalendarButton from './AddToCalendarButton.vue'
import { required } from 'vuelidate/lib/validators'
import axios from '@/shared/plugins/axios'

export default {
    name: 'BookingComplete',
    components: {
        AddToCalendarButton,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: function() {
        return {
            isContactModalActive: false,
            isFeedbackSent: false,
            invoice: {
                streetname: '',
                boxnumber: '',
                streetnumber: '',
                municipality: '',
                postalcode: '',
                vat: '',
                contact_name: '',
                email: '',
            },
        }
    },
    validations() {
        return {
            invoice: {
                streetname: {
                    required,
                },
                streetnumber: {
                    required,
                },
                postalcode: {
                    required,
                },
                municipality: {
                    required,
                },
            },
        }
    },
    computed: {
        ...mapGetters('valuationStore', ['status', 'getRequestRef']),
        receiptUrl() {
            return this.borrowerData?.payment?.receipt_url
        },
        reportUrl() {
            const lang = this.$route.params.lang
            return (
                `${utils.urlJoin(this.$config.VALUATION_API_URL, '/report')}?` +
                `valuation_request_ref=${this.getRequestRef}` +
                `&lang=${lang}`
            )
        },
    },
    methods: {
        sendFeedback() {
            this.$v.$touch()

            if (
                !this.$v.invoice.streetname.required ||
                !this.$v.invoice.streetnumber.required ||
                !this.$v.invoice.postalcode.required ||
                !this.$v.invoice.municipality.required
            )
                return

            const {
                streetname,
                streetnumber,
                boxnumber,
                postalcode,
                municipality,
                vat,
                contact_name,
                email,
            } = this.invoice

            let formData = new FormData()
            formData.append(
                'subject',
                this.$t(`global.helpcenter.contact_form.subject_req-invoice`)
            )
            formData.append('module', 'ovm')
            formData.append('valuation_request_ref', this.getRequestRef)
            formData.append(
                'url',
                JSON.stringify({
                    params: this.$route.params,
                    fullPath: this.$route.fullPath,
                    query: this.$route.query,
                    hash: this.$route.hash,
                    name: this.$route.name,
                })
            )

            formData.append(
                'content',
                `Address: ${streetname} ${streetnumber} ${boxnumber} ${postalcode} ${municipality} \n VAT: ${vat} \n Contact name: ${contact_name} \n email: ${email}`
            )

            axios
                .post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, 'contact'),
                    formData,
                    { headers: { 'content-type': 'multipart/form-data' } }
                )
                .then((res) => {})
                .finally(() => {
                    this.isFeedbackSent = true
                })
        },
        hideContactModal() {
            this.isContactModalActive = false
            this.isFeedbackSent = false
        },

        showContactModal() {
            this.isContactModalActive = true
            this.isFeedbackSent = false
        },
    },
}
</script>

<style lang="scss">
.borrower-button-dark {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #001837 !important;
    color: #fff !important;
    border-radius: 0.3125rem;
    border-width: 0;
}
</style>

<style scoped lang="scss">
.contact-form-bttn {
    max-width: fit-content;
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: #fff !important;
    color: rgba(0, 24, 55, 0.7) !important;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
}
.contact-form {
    min-width: 490px;
    max-width: 800px;
    width: fit-content;
    padding: 30px;
    position: relative;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
    display: flex;
    flex-direction: column;

    &__title {
        display: flex;
        min-width: 100%;
        justify-content: space-between;
        max-height: 2rem;
        padding-bottom: 3rem;
    }
    &__address {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        padding-top: 3rem;
    }

    .closeModalBttn {
        all: unset;
        cursor: pointer;
    }

    form {
        button {
            margin-top: 1.5rem;
            margin-left: auto;
            display: flex;
        }
    }

    @media screen and (max-width: 999px) {
        min-width: auto;
        max-width: 100vw;
        width: auto;

        .contact-form__address {
            display: block;
            padding-top: 0;
        }

        .contact-form__company {
            margin-top: 1rem;
        }

        form {
            button {
                margin-left: 0;
            }
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border: 1px solid #d6e2e9;
    background: #fff;
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        align-items: center;
        padding: 1.5rem;
        max-width: 70%;
    }
    &__icon {
        width: 3rem;
        height: 3rem;
    }
    &__title {
        color: #001837;
        text-align: center;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__text {
        color: #798da6;
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &__buttons {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 1rem;
        min-width: 100%;
        & > a {
            flex: 1 1 0;
        }
    }
    &__nav {
        border-top: 1px solid #d6e2e9;
        padding: 1.5rem;
        width: 100%;
    }
}
</style>
