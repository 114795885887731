<template>
    <div id="valuer_tool">
        <sidebar v-if="showSidebar" />
        <div
            v-if="!isLoading"
            id="valuer_tool-content"
            :class="{ 'full-width': !showSidebar }"
        >
            <div class="valuer_tool-default-layout">
                <div
                    v-if="showMap && getFeatures.building_id"
                    class="valuer_tool-default-layout-map_container"
                >
                    <re-map
                        :default-zoom-modifier="isMobile ? -2 : 0"
                        :building-id="getFeatures.building_id"
                        :parcel-ids="getFeatures.parcel_ids"
                        :level="getFeatures.level"
                        :region="getFeatures.region"
                        :center="{
                            lat: getFeatures.f_lat,
                            lng: getFeatures.f_lng,
                        }"
                        :map-scene2d="get_map_scene2d"
                        :read-only="true"
                        :focus-layer="'building'"
                        :layers-control-visible="false"
                        :options="{
                            zoomControl: true,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            doubleClickZoom: true,
                            boxZoom: true,
                            dragging: true,
                        }"
                        :aerial-tile-visible="false"
                        :cadastral-tile-visible="false"
                        class="has-ratio"
                    ></re-map>
                </div>
                <router-view />
            </div>
        </div>
        <b-loading v-else />
    </div>
</template>
<script>
import { mapGetters as vuexGetters } from 'vuex'
import ReMap from '@/components/common/Map.vue'
import { mapGetters, mapActions } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import Sidebar from './elements/Sidebar'

export default {
    name: 'ValuerTool',
    components: {
        ReMap,
        Sidebar,
    },
    beforeRouteUpdate(to, from, next) {
        this.saveOnsiteVal()
        next()
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'get_map_scene2d',
                'getFeatures',
                'getValuation',
                'getDVMFeatures',
                'getOVMFeatures',
                'isLoading',
            ]),
            ...mapActions(requestObjects, ['saveOnsiteVal']),
        }
    },
    computed: {
        ...vuexGetters(['isMobile']),
        isRouteFullWidth() {
            return this.$route.name === 'valuation.valuer-app.ref_map'
        },
        showSidebar() {
            return !this.isMobile && !this.isRouteFullWidth && !this.isLoading
        },
        showMap() {
            return (
                !this.isMobile &&
                !['valuation.valuer-app.ref_map'].includes(this.$route.name) &&
                !this.$route.name.startsWith('valuation.valuer-app.interior.floorplan')
            )
        },
    },
}
</script>
<style lang="scss">
#valuer_tool {
    display: grid;
    grid-template: auto / 300px auto;
    min-height: 100%;

    & > *:not(#sidebar) {
        min-height: 100%;
    }

    .valuer_tool-mobile_tabs {
        margin-bottom: 0;
        background: #fff;

        .tabs {
            border-bottom: 1px solid #d6e2e9;

            ul {
                border-bottom: 0;
            }
        }

        .tab-content {
            display: none;
        }

        &-tab {
            a {
                border-bottom-width: 3px;
                border-bottom-color: white;
            }

            span {
                color: rgba(0, 24, 55, 0.6);
                font-size: 14px;
                font-weight: 500;
            }

            &.is-active {
                a {
                    border-bottom-color: var(--color-primary);
                }

                span {
                    color: #001837;
                    font-weight: 700;
                }
            }
        }
    }

    #valuer_tool-content {
        overflow: scroll;
        background: #f4f6f9;

        &.full-width {
            grid-column: 1 / -1;
            align-self: stretch;
        }
    }

    .valuer_tool-title {
        color: #001837;
        font-size: 24px;
        font-weight: 700;
        grid-column: 1 / -1;

        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    .valuer_tool-default-layout {
        height: 100%;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;

        &-grid {
            display: grid;
            grid-template: auto / 1fr 1fr;
            padding: 64px;
            gap: 24px;
            width: 100%;
            height: auto;
            align-items: stretch;

            @media screen and (max-width: 999px) {
                display: flex;
                padding: 8px 0;
                gap: 8px;
                flex-direction: column;
                align-items: stretch;

                &.valuer_tool-default-layout-grid--has_tabs {
                    padding-top: 0;
                }
            }
        }

        &-map_container {
            flex-shrink: 0;
            height: 246px;
        }
    }

    .single_line_card {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        h5 {
            color: #798da6;
            font-size: 12px;
            font-weight: 500;
        }

        .is-expanded {
            flex-grow: 2;
        }
    }
}
</style>
