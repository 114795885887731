<template>
    <b-field
        custom-class="is-small-tooltip"
        :type="type"
        :message="message"
        :expanded="expanded"
    >
        <template v-if="!isEmptyStr(label)" slot="label">
            {{ label }}
            <b-tooltip
                v-if="!isEmptyStr(tooltip)"
                type="is-primary"
                class="ml-2 mb-1"
                :label="tooltip"
                multilined
            >
                <div
                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                >
                    ?
                </div>
            </b-tooltip>
        </template>
        <b-input
            :value="value"
            size="is-medium"
            :disabled="disabled"
            :aria-label="ariaLabel"
            :type="inputType"
            :autocorrect="autocorrect"
            :spellcheck="spellcheck"
            :required="required"
            @input="$emit('input', $event)"
            @focus="$emit('focus', $event)"
        ></b-input>
    </b-field>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        message: {
            type: Array,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        autocorrect: {
            type: String,
            default: 'off',
        },
        spellcheck: {
            type: String,
            default: 'false',
        },
        required: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            required: false,
            default: null,
        },
        type: {
            type: Object,
            required: false,
            default: null,
        },
        ariaLabel: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: {
        isEmptyStr: (str) => utils.isEmptyStr(str),
    },
}
</script>
