<template>
    <grid-card
        is-full
        :title="$t(`valuer_tool.exterior.${garageType}.name`)"
        no-mobile-toggle
    >
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t(`valuer_tool.exterior.${garageType}.question`) }}</h5>
                <b-checkbox v-model="f_garage_present" />
            </label>
        </template>
        <template v-if="f_garage_present" slot="default">
            <div class="grid_card-content_grid">
                <div
                    v-for="(garage, i) in garages"
                    :key="`garage_${i}`"
                    class="content_grid-item--full garage_row"
                >
                    <h4>
                        {{ $t(`valuer_tool.exterior.${garageType}.name`) }} {{ i + 1 }}
                    </h4>
                    <b-field
                        horizontal
                        :label="$t(`valuer_tool.exterior.${garageType}.area`)"
                        class="full_width"
                    >
                        <b-input v-model.number="garages[i].area" type="number" />
                    </b-field>
                    <div class="toggles full_width">
                        <b-radio-button
                            v-model="garages[i].is_interior"
                            :native-value="true"
                            type="is-primary"
                        >
                            <span>{{ $t('value.interior') }}</span>
                        </b-radio-button>
                        <b-radio-button
                            v-model="garages[i].is_interior"
                            :native-value="false"
                            type="is-primary"
                        >
                            <span>{{ $t('value.exterior') }}</span>
                        </b-radio-button>
                    </div>
                    <icon-button
                        icon="delete"
                        class="delete_garage_button"
                        @click="removeGarage(i)"
                    />
                </div>
                <clear-button
                    class="content_grid-item content_grid-item--full add-garage"
                    @click="addGarage"
                >
                    {{ $t(`valuer_tool.exterior.${garageType}.add`) }}
                </clear-button>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters as vuexGetters } from 'vuex'
import ClearButton from '@/components/elements/ClearButton.vue'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import IconButton from '@/components/elements/IconButton.vue'

export default {
    name: 'GarageParking',
    components: {
        GridCard,
        IconButton,
    },
    props: {
        garageType: {
            type: String,
            default: 'garage',
        },
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getOVMFeatures', 'getFeatures']),
            ...mapMutations(requestObjects, ['SET_OVM_FEATURES', 'SET_FEATURES']),
        }
    },
    data: () => ({
        f_garage_present: false,
        garages: [],
    }),
    computed: {
        ...vuexGetters(['isMobile']),
        ovmKey() {
            return this.garageType === 'garage' ? 'garages' : 'parkings'
        },
        featureKey() {
            return this.garageType === 'garage' ? 'f_n_closed_garage' : 'f_n_parking_spot'
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_garage_present(val) {
            if (val) {
                if (Array.isArray(this.garages) && this.garages.length === 0) {
                    this.addGarage()
                } else if (!Array.isArray(this.garages)) {
                    this.garages = []
                    this.addGarage()
                }
            } else {
                this.garages = []
            }
        },
        garages: {
            deep: true,
            handler(val) {
                this.SET_OVM_FEATURES({
                    [this.ovmKey]: val,
                })
                this.SET_FEATURES({
                    [this.featureKey]: val.length,
                })
                this.f_garage_present = this.garages.length > 0
            },
        },
    },
    mounted() {
        if (this.getOVMFeatures?.[this.ovmKey].length) {
            // If ovm garages have been saved before, populate everything from that
            this.garages = this.getOVMFeatures[this.ovmKey]
        } else {
            // Else populate everything in terms either f_n_closed_garage or f_n_parking_spot
            const generatedGarages = []

            if (this.getFeatures?.[this.featureKey]) {
                for (let i = 0; i < this.getFeatures[this.featureKey]; i++) {
                    generatedGarages.push({
                        area: 0,
                        is_interior: this.garageType === 'garage', // default garage to interior and parking spots to exterior
                    })
                }
            }
            this.garages = generatedGarages
        }
    },
    methods: {
        addGarage() {
            this.garages.push({
                area: 0,
                is_interior: this.garageType === 'garage',
            })
        },
        removeGarage(i) {
            this.garages.splice(i, 1)
        },
    },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 999px) {
    .grid_card-content_grid {
        margin-top: 16px !important;
        gap: 24px !important;
    }
}

.garage_row {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    @media screen and (max-width: 999px) {
        display: grid;
        grid-template-columns: 1fr 32px;
        width: 100%;

        h4 {
            grid-row: 1;
            grid-column: 1;
        }

        .delete_garage_button {
            grid-row: 1;
            grid-column: 2;
        }

        .full_width {
            grid-column: 1 / 3;
        }

        .toggles {
            & ::v-deep .control {
                width: 50%;
            }
        }
    }

    h4 {
        flex-grow: 2;
        color: #001837;
        font-size: 14px;
        font-weight: 500;
    }

    & ::v-deep .toggles {
        display: flex;

        .control {
            label.b-radio.button.radio {
                border-radius: 0 !important;
            }

            &:first-child {
                label.b-radio.button.radio {
                    border-radius: 4px 0 0 4px !important;
                }
            }

            &:last-child {
                label.b-radio.button.radio {
                    border-radius: 0 4px 4px 0 !important;
                }
            }
        }
    }

    & ::v-deep .field.is-horizontal {
        align-items: center;
        margin-bottom: 0;
        gap: 12px;

        .field-label {
            flex-grow: 2;
            padding-top: 0;
            margin-right: 0;

            label {
                white-space: nowrap;
                text-align: right;

                @media screen and (max-width: 999px) {
                    text-align: left;
                }
            }
        }
    }
}

.add-garage {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 700;
    align-items: flex-start;
    padding: 0;
    cursor: pointer;

    @media screen and (max-width: 999px) {
        width: 100%;
        background: #001837;
        border-radius: 4px;
        padding: 8px 12px;
        color: #fff;
        text-align: center;
    }
}
</style>
