<template>
    <b-field class="mobile-toggle-field">
        <b-radio-button v-model="showMap" size="is-small" :native-value="1">
            <svg-icon
                icon="map"
                height="16"
                :color="showMap == 1 ? '#ffffff' : '#767F8E'"
            />
        </b-radio-button>
        <b-radio-button v-model="showMap" size="is-small" :native-value="0">
            <svg-icon
                icon="list"
                height="16"
                :color="showMap == 0 ? '#ffffff' : '#767F8E'"
            />
        </b-radio-button>
    </b-field>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'MobileToggle',
    computed: {
        ...mapGetters(['getRefMapShown']),
        showMap: {
            get: function() {
                return this.getRefMapShown
            },
            set: function(val) {
                this.TOGGLE_REF_MAP_MOBILE(val)
            },
        },
    },
    methods: {
        ...mapMutations(['TOGGLE_REF_MAP_MOBILE']),
    },
}
</script>
<style lang="scss" scoped>
.mobile-toggle-field {
    margin-bottom: 0 !important;
}
</style>
