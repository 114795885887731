<i18n>
{
  "nl-BE": {
    "title": "Wat is het adres van het te financieren pand?",
    "tooltip_text": "Selecteer het adres van het pand dat wordt gefinancierd. Gelieve dit niet te verwarren met het adres van eventuele andere panden die gebruikt worden als extra zekerheid.",
    "control_address": "Vervolledig het adres indien nodig",
    "street": "Straat",
    "number": "Nummer",
    "box": "Bus",
    "postalcode": "Postcode",
    "municipality": "Stad",
    "previous": "Vorige",
    "next": "Volgende",
    "field_required": "Dit veld is verplicht",
    "only_numbers": "Gelieve enkel cijfers in te vullen",
    "min_4_characters": "Dit veld vereist min. 4 karakters"
  },
  "en-BE": {
    "title": "What is the address of the property being financed?",
    "tooltip_text": "Select the address of the property that is being financed. Please do not confuse this with the address of possible other properties that are being used as additional collateral." ,
    "control_address": "Complete the address if needed",
    "street": "Street",
    "number": "Number",
    "box": "Box number",
    "postalcode": "Postal code",
    "municipality": "City",
    "previous": "Previous",
    "next": "Next",
    "field_required": "This field is required",
    "only_numbers": "Please enter only numeric values",
    "min_4_characters": "Input should contain min. 4 characters"
  },
  "fr-BE": {
    "title": "Quelle est l'adresse du bien financé ?",
    "tooltip_text": "Sélectionnez l'adresse du bien financé. Merci de ne pas confondre ceci avec l'adresse d'autres bien éventuels qui peuvent servir comme sûreté.",
    "control_address": "Complétez l'adresse si nécessaire",
    "street": "Rue",
    "number": "Numéro",
    "box": "Boîte",
    "postalcode": "Code postal",
    "municipality": "Commune",
    "previous": "Précédent",
    "next": "Suivant",
    "field_required": "Ce champ est obligatoire",
    "only_numbers": "Veuillez entrer uniquement des chiffres",
    "min_4_characters": "Ce champ requiert 4 caractères au minimum"
  }
}
</i18n>

<template>
    <div class="ers-content building-view-container">
        <img src="@/assets/img/ers/green-check.svg" />
        <h1
            class="page-title has-text-black has-text-centered"
            :class="[$vssWidth < 768 && 'has-text-centered']"
        >
            {{ $t('common.thank_you_so_far') }}
        </h1>
        <div class="building-view-content">
            <div class="left-side">
                <p>
                    {{ $t('map_page.3d_model') }}
                </p>

                <div class="building-address-box">
                    <div class="building-address-box_icon">
                        <svg-icon
                            icon="location"
                            width="21"
                            height="21"
                            color="#001837"
                            :fill="true"
                        />
                    </div>
                    <h3
                        class="building-address-box_address has-text-black has-text-weight-medium is-size-7"
                    >
                        {{ getAddress.streetname }} {{ getAddress.streetnumber }}
                        <br />
                        {{ getAddress.postalcode }} {{ getAddress.municipality }}
                    </h3>
                </div>
            </div>
            <renderer
                :building-id="getMapScene3d.building_id"
                :solar-potential="false"
                :map-scene3d="getMapScene3d"
                :show-attributions="false"
                @update:mapScene3d="SET_MAP_SCENE3D($event)"
            />
        </div>

        <div class="button-row">
            <b-button
                size="is-medium"
                type="is-dark"
                icon-left="arrow-left"
                @click="$router.go(-1)"
            >
                {{ $t('common.previous') }}
            </b-button>
            <b-button
                size="is-medium"
                type="is-primary"
                icon-right="arrow-right"
                @click="nextPage"
            >
                <span>{{ $t('common.next') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'

export default {
    name: 'BuildingView',
    components: {
        Renderer,
    },
    computed: {
        ...mapGetters('ersStore', ['getAddress', 'getMapScene3d', 'getRequestRef']),
    },
    methods: {
        nextPage() {
            this.$router.push({
                name: 'ers.epc',
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.building-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .page-title {
        margin-top: 30px;
        font-weight: bold;
        font-size: 26px;
    }

    .building-view-content {
        width: 80%;
        max-width: 850px;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 1101px) {
            flex-direction: row;
        }
        align-items: center;
        justify-content: space-around;
        margin-top: 50px;

        .left-side {
            padding: 30px;
            background: #fff;
            border-radius: 20px;

            .building-address-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-top: 40px;

                &_icon {
                    padding: 10px;
                    background: rgba(0, 24, 55, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    margin-right: 15px;
                }
            }
        }
    }

    .button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (min-width: 768px) {
            margin-top: 50px;
        }
        max-width: 850px;
        width: 80%;
    }
}
</style>
