<template>
    <svg
        width="100%"
        viewBox="0 0 446 33"
        :class="{ interactive: interactive }"
        class="epc_array"
    >
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'G' }"
            aria-label="G"
            @click="$emit('select-epc', 'G')"
        >
            <path
                d="M0 0H105.265L112.532 16.4155L105.265 32.831H0L7.26693 16.4155L0 0Z"
                fill="#d62015"
                class="epc_arrow-bg"
            />
            <path
                d="M56.7289 14.3047C54.9483 14.3047 53.9291 15.5941 53.9291 17.5343C53.9291 19.536 55.0834 20.6289 56.7412 20.6289C58.3007 20.6289 59.2463 19.7693 59.2463 18.3325V18.2957H56.5324V16.9081H60.7076V21.9551H59.3813L59.2831 20.9236C58.7919 21.6112 57.7727 22.0902 56.557 22.0902C54.0396 22.0902 52.2836 20.2605 52.2836 17.4975C52.2836 14.7714 54.0642 12.8312 56.7657 12.8312C58.8165 12.8312 60.4006 14.0223 60.6584 15.852H59.0007C58.7182 14.7959 57.8095 14.3047 56.7289 14.3047Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'F' }"
            aria-label="F"
            @click="$emit('select-epc', 'F')"
        >
            <path
                d="M108.515 0H146.949L154.216 16.4155L146.949 32.831H108.515L115.782 16.4155L108.515 0Z"
                fill="#fa9101"
                class="epc_arrow-bg"
            />
            <path
                d="M134.016 18.2834H130.676V21.9551H129.104V12.9785H134.654V14.4275H130.676V16.8712H134.016V18.2834Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'E' }"
            aria-label="E"
            @click="$emit('select-epc', 'E')"
        >
            <path
                d="M150.199 0H188.632L195.899 16.4155L188.632 32.831H150.199L157.466 16.4155L150.199 0Z"
                fill="#fdd400"
                class="epc_arrow-bg"
            />
            <path
                d="M176.774 21.9551H171.199V12.9785H176.774V14.4275H172.771V16.7362H176.357V18.1238H172.771V20.5061H176.774V21.9551Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'D' }"
            aria-label="D"
            @click="$emit('select-epc', 'D')"
        >
            <path
                d="M191.882 0H230.316L237.583 16.4155L230.316 32.831H191.882L199.149 16.4155L191.882 0Z"
                fill="#fdf300"
                class="epc_arrow-bg"
            />
            <path
                d="M215.461 21.9551H212.231V12.9785H215.375C218.028 12.9785 219.857 14.8082 219.857 17.4852C219.857 20.1254 218.064 21.9551 215.461 21.9551ZM215.228 14.4275H213.803V20.5061H215.314C217.107 20.5061 218.199 19.3518 218.199 17.4852C218.199 15.5818 217.082 14.4275 215.228 14.4275Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'C' }"
            aria-label="C"
            @click="$emit('select-epc', 'C')"
        >
            <path
                d="M233.565 0H271.999L279.266 16.4155L271.999 32.831H233.565L240.832 16.4155L233.565 0Z"
                fill="#d8db1a"
                class="epc_arrow-bg"
            />
            <path
                d="M256.672 22.1024C254.057 22.1024 252.362 20.285 252.362 17.4729C252.362 14.6854 254.118 12.8312 256.746 12.8312C258.87 12.8312 260.454 14.0714 260.761 15.9994H259.104C258.797 14.9556 257.9 14.3416 256.709 14.3416C255.051 14.3416 254.008 15.545 254.008 17.4607C254.008 19.364 255.064 20.592 256.709 20.592C257.925 20.592 258.858 19.9535 259.153 18.9588H260.786C260.442 20.8499 258.797 22.1024 256.672 22.1024Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'B' }"
            aria-label="B"
            @click="$emit('select-epc', 'B')"
        >
            <path
                d="M275.249 0H313.682L320.949 16.4155L313.682 32.831H275.249L282.516 16.4155L275.249 0Z"
                fill="#9ade27"
                class="epc_arrow-bg"
            />
            <path
                d="M294.308 21.9551V12.9785H297.882C299.65 12.9785 300.731 13.8995 300.731 15.3976C300.731 16.3923 300.289 17.1045 299.441 17.4607C300.387 17.7677 300.866 18.4799 300.866 19.5237C300.866 21.0587 299.797 21.9551 297.943 21.9551H294.308ZM297.747 14.3661H295.88V16.8098H297.784C298.643 16.8098 299.122 16.3555 299.122 15.5573C299.122 14.7837 298.631 14.3661 297.747 14.3661ZM297.882 18.1361H295.88V20.5675H297.882C298.766 20.5675 299.257 20.1377 299.257 19.3272C299.257 18.5658 298.754 18.1361 297.882 18.1361Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'A' }"
            aria-label="A"
            @click="$emit('select-epc', 'A')"
        >
            <path
                d="M316.933 0H355.366L362.633 16.4155L355.366 32.831H316.933L324.2 16.4155L316.933 0Z"
                fill="#00bd00"
                class="epc_arrow-bg"
            />
            <path
                d="M337.246 21.9551H335.613L338.855 12.9785H340.464L343.705 21.9551H342.048L341.323 19.8798H337.971L337.246 21.9551ZM339.457 15.6555L338.437 18.5536H340.869L339.837 15.6555C339.764 15.4222 339.678 15.1521 339.653 14.9679C339.616 15.1398 339.543 15.4099 339.457 15.6555Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'A+' }"
            aria-label="A+"
            @click="$emit('select-epc', 'A+')"
        >
            <path
                d="M358.616 0H397.05L404.317 16.4155L397.05 32.831H358.616L365.883 16.4155L358.616 0Z"
                fill="#00bd00"
                class="epc_arrow-bg"
            />
            <path
                d="M376.512 21.9551H374.879L378.121 12.9785H379.729L382.971 21.9551H381.313L380.589 19.8798H377.236L376.512 21.9551ZM378.722 15.6555L377.703 18.5536H380.134L379.103 15.6555C379.029 15.4222 378.943 15.1521 378.919 14.9679C378.882 15.1398 378.808 15.4099 378.722 15.6555ZM384.084 17.1905H386.528V14.7468H387.781V17.1905H390.224V18.443H387.781V20.899H386.528V18.443H384.084V17.1905Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'A++' }"
            aria-label="A++"
            @click="$emit('select-epc', 'A++')"
        >
            <path
                d="M400.3 0H438.733L446 16.4155L438.733 32.831H400.3L407.567 16.4155L400.3 0Z"
                fill="#00bd00"
                class="epc_arrow-bg"
            />
            <path
                d="M415.422 21.9551H413.789L417.031 12.9785H418.64L421.882 21.9551H420.224L419.499 19.8798H416.147L415.422 21.9551ZM417.633 15.6555L416.614 18.5536H419.045L418.013 15.6555C417.94 15.4222 417.854 15.1521 417.829 14.9679C417.792 15.1398 417.719 15.4099 417.633 15.6555ZM422.995 17.1905H425.439V14.7468H426.691V17.1905H429.135V18.443H426.691V20.899H425.439V18.443H422.995V17.1905ZM431.102 17.1905H433.545V14.7468H434.798V17.1905H437.241V18.443H434.798V20.899H433.545V18.443H431.102V17.1905Z"
                class="epc_arrow-label"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'EpcArrayBru',
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        currentLabel: {
            type: String,
            default: null,
        },
    },
}
</script>
<style lang="scss" scoped>
@import './epcArrayStyle.scss';
</style>
