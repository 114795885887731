<template>
    <form class="main" @submit.prevent="pay">
        <div class="main__payment">
            <div class="main__stripe">
                <b-loading
                    :is-full-page="true"
                    :active="!paymentSummary && paymentProcessing"
                ></b-loading>
                <template v-if="paymentSummary && paymentSummary.is_payment_needed">
                    <h1 class="main__stripe-title">
                        {{ $t('valuer_tool.borrower.payment.method') }}
                    </h1>

                    <b-message v-if="!STRIPE_PUBLISHABLE_KEY" type="is-warning">
                        {{ $t('valuer_tool.borrower.payment.missing_stripe_config') }}
                    </b-message>

                    <b-message
                        v-if="stripeTestMode"
                        title="Testing mode"
                        type="is-warning"
                        aria-close-label="Close message"
                    >
                        This deployment is using the Stripe test mode. No real payment
                        will be processed.
                        <br />
                        You can find the different test cards available
                        <a href="https://stripe.com/docs/testing" target="_blank">
                            here.
                        </a>
                    </b-message>

                    <template v-if="stripeClientSecret && renderPaymentComponent">
                        <StripeElementPayment
                            ref="paymentRef"
                            class="is-fullwidth my-2"
                            :pk="STRIPE_PUBLISHABLE_KEY"
                            :elements-options="{
                                appearance: {},
                                clientSecret: stripeClientSecret,
                            }"
                            :locale="lang"
                            redirect="if_required"
                            :confirm-params="{ return_url: returnUrl }"
                            @confirmed="handleSuccess"
                            @error="handleError"
                        />
                    </template>
                </template>
            </div>
        </div>
        <nav-buttons
            class="main__buttons"
            :is-submit="paymentSummary?.is_payment_needed"
            :next-step="paymentSummary ? nextStep : null"
            :prev-step="prevStep"
        />
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import utils from '@/shared/plugins/utils'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'BorrowerPayment',
    components: {
        StripeElementPayment,
        // PulseLoader,
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: function() {
        return {
            paymentSummary: null,
            amountDueEuro: null,
            stripeClientSecret: null,
            paymentProcessing: false,
            isLoadingPayment: false,
            STRIPE_PUBLISHABLE_KEY: this.$config.STRIPE_PUBLISHABLE_KEY,
            renderPaymentComponent: true,
        }
    },
    computed: {
        ...mapGetters('valuationStore', ['ovm_status', 'getRequestRef']),
        valRef() {
            return this.$route.params.request || this.getRequestRef
        },
        returnUrl() {
            return window.location.href
        },
        stripeTestMode() {
            return this.STRIPE_PUBLISHABLE_KEY.startsWith('pk_test')
        },
        lang() {
            return this.$route.params.lang
        },
        isSendBtnDisabled() {
            return (
                utils.isEmptyStr(this.invoice.streetname) &&
                utils.isEmptyStr(this.invoice.streetnumber) &&
                utils.isEmptyStr(this.invoice.postalcode) &&
                utils.isEmptyStr(this.invoice.municipality)
            )
        },
    },
    watch: {
        paymentSummary: function() {
            this.paymentProcessing = false

            if (this.paymentSummary) {
                if (this.paymentSummary.is_payment_needed) {
                    if (
                        this.$route.query.redirect_status &&
                        this.paymentSummary.status == 'started'
                    ) {
                        // we are redirected after a Stripe payment:
                        // probably need to wait until the payment is processed in the backend
                        console.log('Waiting for backend to update payment status…')

                        this.paymentProcessing = true
                        this.paymentSummary.status = 'pending'
                        this.paymentSummary.is_payment_needed = false
                        setTimeout(this.loadPaymentSummary, 5000)
                    } else {
                        this.getPaymentIntent()
                    }
                } else {
                    this.$router.push({
                        query: { ...this.$route.query, step: this.nextStep },
                    })
                }
            }
            if (this.paymentSummary && this.paymentSummary.status === 'succeeded') {
                this.load_valuation_request()
            }
        },
        lang() {
            this.forceRender()
        },
    },
    mounted() {
        this.loadPaymentSummary()
    },
    methods: {
        ...mapActions('valuationStore', ['load_valuation_request']),
        handleSuccess() {
            // This is called when the payment is completed (without a redirect)
            this.loadPaymentSummary()
        },
        handleError() {
            // This is called when the payment fails (without a redirect)
            this.loadPaymentSummary()
        },
        async forceRender() {
            // Remove MyComponent from the DOM
            this.renderPaymentComponent = false

            // Then, wait for the change to get flushed to the DOM
            await this.$nextTick()

            // Add MyComponent back in
            this.renderPaymentComponent = true
        },
        loadPaymentSummary() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.valRef,
                'summary',
            ])

            this.$axios.get(url).then((response) => {
                this.paymentSummary = response.data
            })
        },
        getPaymentIntent() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.valRef,
                'intent',
            ])

            this.$axios.post(url, {}).then((res) => {
                this.stripeClientSecret = res.data.client_secret
                this.amountDueEuro = res.data.amount_euro
            })
        },
        pay() {
            this.isLoadingPayment = true
            this.$refs.paymentRef.submit()
        },
        statusToClass(status) {
            if (status == 'succeeded') return 'is-success'
            if (status == 'cancelled') return 'is-error'
            if (status == 'refunded') return 'is-warning'
            return 'is-info'
        },
    },
}
</script>

<style lang="scss" scoped>
.is-fullwidth {
    width: 100%;
}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    &__payment {
        min-width: 100%;
        border-bottom: 1px solid #d6e2e9;
        display: flex;
        gap: 1.5rem;
    }
    &__title,
    &__stripe-title {
        color: #001837;
        text-align: left;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
        margin-right: auto;
    }
    &__stripe-title {
        padding-top: 1.5rem;
    }

    &__stripe {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-self: center;
        margin: auto;
        padding: 1.5rem;
        gap: 1rem;

        .message {
            margin-bottom: 0;
        }
    }
    &__billing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        border-right: 1px solid #d6e2e9;
        flex-shrink: 1;
        padding: 1.5rem;
    }

    &__buttons {
        display: flex;
        padding-inline: 1.5rem;
        padding-bottom: 1.5rem;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;
        background: #fff;
    }
    &__invoice {
        display: flex;
        padding: 1rem;
        flex-direction: column;
        gap: 1rem;
        border-radius: 0.3125rem;
        background: rgba(0, 156, 255, 0.1);
        max-width: -moz-fit-content;
        max-width: fit-content;
        color: #6583a8;
    }

    & ::v-deep #stripe-payment-element-errors {
        margin-top: 0.5rem;
        color: var(--danger);
        font-weight: 500;
    }
}
.submit-btn {
    display: flex;
    margin-left: auto;
    margin-top: 3rem;
}
</style>
