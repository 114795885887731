<template>
    <div class="borrower-main">
        <ul class="borrower-main__header">
            <li
                v-for="step in steps"
                :key="step.name"
                :class="{
                    'borrower-main__header--success': currentStep.progress >= step.index,
                }"
            >
                <div
                    v-if="step.index < currentStep.progress"
                    class="borrower-main__header-icon"
                >
                    <img src="@/assets/icons/ic-checkmark-blue.svg" alt="" srcset="" />
                </div>
                <div v-else class="borrower-main__header-number">
                    {{ step.index + 1 }}
                </div>
                <p class="borrower-main__header-label">{{ step.name }}</p>
            </li>
        </ul>
        <div class="borrower-main__content">
            <transition mode="out-in" name="fade">
                <component
                    :is="currentStep.component"
                    v-if="currentStep"
                    :next-step="nextStep"
                    :prev-step="prevStep"
                    :borrower-data="borrowerData"
                    @update:appointment="$emit('update:appointment')"
                    @update:payment="$emit('update:payment')"
                />
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainLayout',
    props: {
        currentStep: {
            type: Object,
            default: () => ({}),
        },
        borrowerData: {
            type: Object,
            default: () => ({}),
        },
        nextStep: {
            type: String,
            default: '',
        },
        prevStep: {
            type: String,
            default: '',
        },
    },
    computed: {
        steps() {
            return [
                {
                    name: this.$t('valuer_tool.borrower.step.appointment'),
                    index: 0,
                },
                {
                    name: this.$t('valuer_tool.borrower.step.contact-details'),
                    index: 1,
                },
                {
                    name: this.$t('valuer_tool.borrower.step.confirmation'),
                    index: 2,
                },
                {
                    name: this.$t('valuer_tool.borrower.step.payment'),
                    index: 3,
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.borrower-main {
    max-width: 50rem;
    margin-inline: auto;
    flex-grow: 1;
    &__loading {
        position: relative;
        min-height: 20rem;
    }
    &__content {
        background: white;
    }
    li {
        display: flex;
        align-items: center;
        gap: 0.75rem;
    }
    &__header {
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
        align-self: stretch;
        border-radius: 0.5rem 0.5rem 0rem 0rem;
        border-top: 1px solid #d6e2e9;
        border-right: 1px solid #d6e2e9;
        border-left: 1px solid #d6e2e9;
        background: #fff;
        &-label {
            color: #798da6;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 142.857% */
        }
        &-number {
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
            padding: 0.52081rem;
            justify-content: center;
            align-items: center;
            gap: 0.52081rem;
            text-align: center;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 166.667% */
            border-radius: 0.5rem;
            background: rgba(121, 141, 166, 0.2);
        }
        &--success {
            color: var(--color-primary);
            .borrower-main__header-label {
                color: var(--color-primary);
            }
            .borrower-main__header-number {
                background: rgba(0, 150, 255, 0.2);
            }
        }
        &-icon {
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
            justify-content: center;
            align-items: center;
            gap: 0.52081rem;
            border-radius: 0.5rem;
            background: rgba(0, 150, 255, 0.2);
        }
    }
}
</style>

<style lang="scss">
.borrower-main {
    .loading-overlay {
        border-radius: 0.5rem;
        .loading-background {
            background: white;
            border-radius: 0.5rem;
            border: 1px solid #d6e2e9;
        }
    }
}
</style>
