var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"valuer_tool"}},[(_vm.showSidebar)?_c('sidebar'):_vm._e(),(!_vm.isLoading)?_c('div',{class:{ 'full-width': !_vm.showSidebar },attrs:{"id":"valuer_tool-content"}},[_c('div',{staticClass:"valuer_tool-default-layout"},[(_vm.showMap && _vm.getFeatures.building_id)?_c('div',{staticClass:"valuer_tool-default-layout-map_container"},[_c('re-map',{staticClass:"has-ratio",attrs:{"default-zoom-modifier":_vm.isMobile ? -2 : 0,"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"center":{
                        lat: _vm.getFeatures.f_lat,
                        lng: _vm.getFeatures.f_lng,
                    },"map-scene2d":_vm.get_map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":false,"options":{
                        zoomControl: true,
                        attributionControl: false,
                        scrollWheelZoom: false,
                        attributionControl: false,
                        scrollWheelZoom: false,
                        doubleClickZoom: true,
                        boxZoom: true,
                        dragging: true,
                    },"aerial-tile-visible":false,"cadastral-tile-visible":false}})],1):_vm._e(),_c('router-view')],1)]):_c('b-loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }