/* eslint-disable */
require('./apartment')
require('./apps')
require('./avm')
require('./chart')
require('./check')
require('./checkered-flag')
require('./click-choose')
require('./copy')
require('./delete')
require('./document-file')
require('./documents')
require('./download')
require('./duplicates')
require('./dvm')
require('./edit')
require('./energy')
require('./ers')
require('./floors')
require('./heating-fuel-coal')
require('./heating-fuel-district-heating')
require('./heating-fuel-electric')
require('./heating-fuel-gas')
require('./heating-fuel-oil')
require('./heating-fuel-pellets')
require('./heating-fuel-wood')
require('./help')
require('./home')
require('./house')
require('./idk')
require('./insulation')
require('./location')
require('./map')
require('./marker')
require('./message')
require('./monitor')
require('./new-apartment')
require('./new-construction')
require('./new-house')
require('./no')
require('./ovm')
require('./person')
require('./plot')
require('./processing')
require('./rooms')
require('./ruler')
require('./save')
require('./share')
require('./submit')
require('./user-admin')
require('./yes')
require('./list')
require('./mobile-menu')
require('./mobile-menu-close')
