<template>
    <div>
        <div :id="elId"></div>
    </div>
</template>

<script>
import { loadScript } from 'vue-plugin-load-script'

export default {
    name: 'CronofyAvailability',
    props: {
        elementToken: {
            default: null,
            type: String,
        },
        ruleId: {
            required: true,
            type: String,
        },
    },
    data: () => ({
        availabilityRules: null,
    }),
    computed: {
        elId() {
            return 'cronofy-availability-' + this.ruleId
        },
        locale() {
            return this.$i18n.locale.split('-')[0]
        },
    },
    watch: {
        locale(locale) {
            if (this.availabilityRules) {
                this.availabilityRules.update({
                    locale,
                })
            }
        },
    },
    mounted() {
        loadScript('https://elements.cronofy.com/js/CronofyElements.v1.56.5.js').then(
            () => {
                // See documentation:
                // https://docs.cronofy.com/developers/ui-elements/availability-rules/
                // eslint-disable-next-line no-undef
                this.availabilityRules = CronofyElements.AvailabilityRules({
                    element_token: this.elementToken,
                    target_id: this.elId,
                    availability_rule_id: this.ruleId,
                    data_center: 'de',
                    config: {
                        start_time: '08:00',
                        end_time: '18:00',
                        duration: 30,
                        week_start_day: 'monday',
                        tz_list: ['Europe/Brussels'],
                        auto_create_rules: true,
                        default_weekly_periods: [
                            {
                                day: 'monday',
                                start_time: '09:00',
                                end_time: '17:00',
                            },
                            {
                                day: 'tuesday',
                                start_time: '09:00',
                                end_time: '17:00',
                            },
                            {
                                day: 'wednesday',
                                start_time: '09:00',
                                end_time: '17:00',
                            },
                            {
                                day: 'thursday',
                                start_time: '09:00',
                                end_time: '17:00',
                            },
                            {
                                day: 'friday',
                                start_time: '09:00',
                                end_time: '17:00',
                            },
                        ],
                    },
                    styles: {
                        prefix: 'RECronofyAvailability',
                        colors: {
                            // NOTE: can't use css vars here since this gets parsed by Cronofy:
                            primary: '#009CFF',
                            available: '#6ece1a',
                            // unavailable: '#ff1b1e',
                        },
                    },
                    tzid: 'Europe/Brussels',
                    callback: this.cronofyCallback,
                    locale: this.locale,
                })
            }
        )
    },
    methods: {
        cronofyCallback(ev) {
            switch (ev.notification.type) {
                case 'availability_rule_edited':
                    this.$emit('input')
                    break
                case 'availability_rule_saved':
                    // TODO: figure out a way to click on the 'save' button automatically
                    this.$emit('save')
                    break
            }
        },
    },
}
</script>

<style>
.RECronofyAvailability__footer {
    flex-wrap: wrap;
}
.RECronofyAvailability__submit {
    width: 100%;
}
.RECronofyAvailability__timezone {
    display: none !important;
}
/* TODO: decide whether we want to show this (maybe as an option): */
.RECronofyAvailability__calendars {
    display: none !important;
}
</style>
