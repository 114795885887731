/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insulation': {
    width: 16,
    height: 16,
    viewBox: '0 0 33 30',
    data: '<path pid="0" d="M32.3 21.2l-3.6-1.6 3.5-1.6c.3-.1.5-.4.5-.7v-4.6c0-.3-.2-.6-.5-.8l-3.5-1.6 3.5-1.6c.4-.2.6-.7.4-1.1-.1-.2-.2-.3-.4-.4L16.8.3c-.2-.1-.5-.1-.7 0L.7 7.3c-.6.2-.7 1.2 0 1.5l3.5 1.6L.7 12c-.3.1-.5.4-.5.7v4.6c0 .3.2.6.5.7l3.5 1.6-3.6 1.6c-.4.2-.6.7-.4 1.1.1.2.2.3.4.4l15.5 6.8c.2.1.5.1.7 0l15.5-6.8c.7-.3.7-1.3 0-1.5zm-27-2.9l-3.5-1.6v-2.9l3.5 1.6v2.9zm4.9 2.2L6.9 19v-2.9l3.2 1.4v3zm5.5 2.4l-3.9-1.7v-2.9l3.9 1.7v2.9zm.8-4.3L3 12.7l3.2-1.4 10 4.4c.2.1.5.1.7 0l10-4.4 3.2 1.4-13.6 5.9zm4.7 2.6l-3.9 1.7V20l3.9-1.7v2.9zm5.6-2.5l-.2.1-3.7 1.6v-2.9l3.9-1.7v2.9zm1.6-3.6l2.8-1.3v2.9L28.4 18v-2.9z" _fill="#798da6"/>'
  }
})
