var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{staticClass:"summary"},[_c('div',{staticClass:"summary__title title is-4"},[_vm._v(" "+_vm._s(_vm.$t('energy.result.summary.title'))+" ")]),_c('div',{staticClass:"summary__notice"},[_c('img',{staticClass:"summary__icon",attrs:{"src":require("@/assets/icons/warning-icon.svg"),"alt":""}}),_c('div',{staticClass:"summary__text"},[_vm._v(" "+_vm._s(_vm.$t('energy.result.summary.subtitle'))+" "),(!_vm.onReport)?_c('router-link',{attrs:{"to":{
                    name: 'support',
                    params: {
                        lang: _vm.$route.params.lang,
                    },
                    query: {
                        question: 'how-total-investment-cost-calculated',
                    },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('energy.shared.tooltip_more_info'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"summary__results results"},[_c('div',{staticClass:"results__line"},[_c('div',{staticClass:"line__header"},[_vm._v(_vm._s(_vm.$t('energy.result.summary.item')))]),_c('div',{staticClass:"line__header line__header--end"},[_vm._v(" "+_vm._s(_vm.$t('energy.result.summary.price_incl_vat'))+" ")]),_c('div',{staticClass:"line__header line__header--end"},[_vm._v(" "+_vm._s(_vm.$t('energy.result.summary.price_excl_vat'))+" ")])]),_vm._l((_vm.getRenovationsSummary.renovations),function(renovation,i){return _c('div',{key:i,staticClass:"results__line line"},[_c('div',{staticClass:"line__title"},[_vm._v(" "+_vm._s(_vm.$t(`energy.result.renovation.${renovation.name}`))+" ")]),_c('div',{staticClass:"line__price"},[_vm._v(" "+_vm._s(_vm.int_value_formatter(renovation.price))+" ")]),_c('div',{staticClass:"line__subsidy"},[_vm._v(" "+_vm._s(_vm.int_value_formatter( _vm.getRenovationVatExcl(renovation.name, renovation.price) ))+" ")])])}),_c('div',{staticClass:"results__line line line--total"},[_c('div',{staticClass:"line__title"},[_vm._v(" "+_vm._s(_vm.$t('monolith.global.total'))+" ")]),_c('div',{staticClass:"line__price"},[_vm._v(" "+_vm._s(_vm.int_value_formatter(_vm.getRenovationsSummary.total.price))+" ")]),_c('div',{staticClass:"line__subsidy"},[_vm._v(" "+_vm._s(_vm.int_value_formatter(_vm.totalExclVat))+" ")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }