<template>
    <div class="top-step">
        <span>
            <svg-icon
                :icon="steps[currentStep].icon"
                width="32"
                height="32"
                color="#fff"
                :fill="true"
                class="step-icon"
            />
            <span class="step-name">
                {{ $t(`steps.${steps[currentStep].name}`) }}
            </span>
        </span>
    </div>
</template>

<script>
import { localeMixin } from '@/components/mixins/locale.js'

// logic could be change if route structure
export default {
    name: 'TopStep',
    mixins: [localeMixin],
    data() {
        return {
            prevRoute: '/',
            progressTransition: 'none',
        }
    },
    computed: {
        currentStep() {
            if (typeof this.$route.meta === 'function') {
                return this.$route.meta(this.$route).step
            } else {
                return this.$route.meta.step
            }
        },
        steps() {
            return [
                {
                    name: 'building_type',
                    icon: 'house',
                },
                {
                    name: 'address',
                    icon: 'location',
                },
                {
                    name: 'house_info',
                    icon: 'ruler',
                },
                {
                    name: 'insulation',
                    icon: 'insulation',
                },
                {
                    name: 'consumption',
                    icon: 'energy',
                },
                {
                    name: 'processing',
                    icon: 'processing',
                },
            ]
        },
    },
}
</script>

<style lang="scss" scoped>
.top-step {
    width: 100%;
    margin-top: 52px;
    background: white;
    padding: 15px;
    border-bottom: 1px solid #d6e2e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .step-name {
        color: var(--primary);
        font-size: 14px;
        line-height: 14px;
    }

    .step-icon {
        background: var(--primary);
        border-radius: 50%;
        padding: 7px;
        margin-right: 15px;
    }
}
</style>
