<template>
    <router-link class="app_card" :to="appRoute">
        <img :src="appImg" />
        <div class="app_card-text">
            <h2>{{ $t(`monolith.apps.${appId}.name`) }}</h2>
            <p v-if="$te(`monolith.apps.${appId}.description`)">
                {{ $t(`monolith.apps.${appId}.description`) }}
            </p>
        </div>
    </router-link>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'AppCard',
    props: {
        appId: {
            type: String,
            required: true,
        },
        appUrl: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapGetters('auth', ['submitableValuations']),
        appImg() {
            // necessary for dynamic asset import because Vue adds a hash to asset names
            let imgImport = require.context('@/assets/img/apps', false, /\.jpg$/)
            return imgImport(`./${this.appId}.jpg`)
        },
        appRoute() {
            if (this.appId === 'ers') {
                return {
                    name: 'ers.welcome',
                    query: { ...this.$route.query },
                    params: { lang: this.$route.params.lang },
                }
            } else {
                return {
                    name: 'valuation.request',
                    query: {
                        ...this.$route.query,
                        valuation_type: this.submitableValuations[0],
                    },
                    params: { lang: this.$route.params.lang, action: 'start' },
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.app_card {
    background: white;
    border-radius: 5px;
    border: 1px solid;
    padding: 0;
    overflow: hidden;
    width: 30%;
    transition: all 0.2s ease-out;
    border-color: #d6e2e9;
    box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0);

    @media screen and (max-width: 999px) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
        border-color: var(--color-primary);
        box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0.1);
    }

    &-text {
        padding: 24px;

        h2 {
            color: #001837;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            margin-top: 8px;
            color: #798da6;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}
</style>
