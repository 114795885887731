<template>
    <Card class="property-value">
        <div class="property-value__title _title">
            {{ $t('energy.result.property_value') }}
        </div>
        <div class="property-value__value">
            <img class="property-value__icon" src="@/assets/icons/positive.svg" alt="" />
            {{ priceFormat(getPropertyValue) }}
        </div>
    </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from './Card.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'PropertyValue',
    components: { Card },
    computed: {
        ...mapGetters('ersStore', ['getPropertyValue']),
    },
    methods: {
        priceFormat(x) {
            return utils.priceFormat(x, false)
        },
    },
}
</script>

<style lang="scss" scoped>
.property-value {
    max-width: 550px;
    height: fit-content;
    display: flex;
    margin-block-start: 20px;
    padding: 20px;
    justify-content: space-between;
}

.property-value__title {
    margin-block: auto;
}

.property-value__icon,
.property-value__value {
    margin-block: auto;
    color: black;
    font-weight: 700;
}

@media print {
    .property-value {
        padding: 0.3cm;

        &__title {
            line-height: normal;
        }
    }
}
</style>
