<template>
    <div>
        <b-field label="Name" horizontal>
            <b-input
                v-model="name"
                aria-placeholder="Slot name"
                @input="debouncedSaveRule"
            />
        </b-field>
        <!-- TODO: use better system to avoid Regions closing after checking first box: -->
        <details class="region-field" :open="regions.length == 0">
            <summary>
                <span class="title is-size-5 mt-5">
                    {{ $t('valuer_tool.valuer_settings.availability_districts') }}
                </span>
                <template v-if="regions.length">
                    ({{ regions.map((r) => nuts3ToName(r)).join(', ') }})
                </template>
            </summary>
            <div v-for="reg in nuts3" :key="reg.prov_en">
                <h3 class="has-text-weight-semibold">{{ reg['prov' + lang] }}</h3>
                <div class="columns is-multiline is-gapless">
                    <div
                        v-for="n in reg.nuts3"
                        :key="n.NUTS_ID"
                        class="column is-one-third"
                    >
                        <b-field>
                            <b-checkbox
                                v-model="regions"
                                :native-value="n.NUTS_ID"
                                class="mt-2"
                                @input="debouncedSaveRule"
                            >
                                {{ n['arr' + lang] }}
                            </b-checkbox>
                        </b-field>
                    </div>
                </div>
            </div>
        </details>
        <div>
            <h3 class="title is-size-5 mt-5">
                {{ $t('valuer_tool.valuer.availability') }}
            </h3>
            <template v-if="unsavedChanges">
                <b-notification
                    type="is-warning"
                    has-icon
                    aria-close-label="Close notification"
                    aria-role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    aria-describedby="unsaved-changes"
                >
                    <span id="unsaved-changes">
                        You have unsaved changes. Please save them before switching tabs.
                    </span>
                </b-notification>
            </template>
            <CronofyAvailability
                :rule-id="rule.id"
                :element-token="elementToken"
                @save="debouncedSaveRule"
                @input="unsavedChanges = true"
            />
        </div>
    </div>
</template>
<script>
import CronofyAvailability from './cronofyElements/CronofyAvailability'
import debounce from 'lodash/debounce'

export default {
    name: 'AvailabilityRule',
    components: {
        CronofyAvailability,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
        nuts3: {
            type: Array,
            required: true,
        },
        elementToken: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        name: '',
        regions: [],
        unsavedChanges: false,
    }),
    computed: {
        lang() {
            if (this.$i18n.locale === 'fr-BE') return '_fr'
            if (this.$i18n.locale === 'nl-BE') return '_nl'
            return '_en'
        },
        form() {
            return {
                name: this.name,
                regions: this.regions,
            }
        },
        flatNuts3() {
            return this.nuts3.map((n) => n.nuts3).flat()
        },
    },
    mounted() {
        this.reset()
    },
    methods: {
        reset() {
            this.name = this.rule.name
            this.regions = this.rule.regions
        },
        nuts3ToName(nutsId) {
            const item = this.flatNuts3.find((n) => n.NUTS_ID === nutsId)
            if (item === undefined) return ''
            return item['arr' + this.lang]
        },
        debouncedSaveRule: debounce(function() {
            this.$emit('save', this.form)
            this.unsavedChanges = false
        }, 1000),
    },
}
</script>

<style lang="scss">
.region-field {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    summary {
        cursor: pointer;
    }
}
</style>
