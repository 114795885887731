import L from 'leaflet'

const scale = 1

const map_pin = () => {
    return L.divIcon({
        html: `<svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9185 38.0922C17.2818 38.7236 16.6409 39.3591 16 40C15.3591 39.3591 14.7182 38.7236 14.0815 38.0922C6.76959 30.8415 0 24.1287 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.1287 25.2304 30.8415 17.9185 38.0922ZM22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16Z" fill="#009CFF"/>
        </svg>
        `,
        iconSize: [32 * scale, 40 * scale],
        iconAnchor: [16 * scale, 40 * scale],
    })
}

export default map_pin
