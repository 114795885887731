<template>
    <b-navbar
        id="navbar"
        type="is-white"
        :class="{ 'is-fixed-top': fixed }"
        :mobile-burger="false"
    >
        <!-- this can be link to main page -->
        <template slot="brand">
            <div v-if="isMobile" class="navbar_mobile">
                <clear-button v-if="prevRoute" class="prev-button" @click="goToPrevRoute">
                    <img :src="chevron" />
                </clear-button>
                <h5 v-if="mobileTitle">{{ mobileTitle }}</h5>
                <template v-else>
                    <b-navbar-item
                        tag="router-link"
                        :to="
                            user.email
                                ? {
                                      path: '/',
                                  }
                                : { name: 'login', params: { lang: $i18n.locale } }
                        "
                    >
                        <img
                            src="@/assets/icons/rockestate-logo-horizontal-dark.svg"
                            class="logo"
                        />
                    </b-navbar-item>
                    <span class="navbar-spacer" />
                </template>
                <component :is="mobileRouteComp" />
                <clear-button @click="toggleMobileMenu">
                    <svg-icon
                        :icon="`mobile-menu${isMobileMenuOpen ? '-close' : ''}`"
                        height="24"
                        scale="1"
                        color="#001837"
                    />
                </clear-button>
            </div>
            <b-navbar-item
                v-else
                tag="router-link"
                :to="
                    user.email
                        ? {
                              path: '/',
                          }
                        : { name: 'login', params: { lang: $i18n.locale } }
                "
            >
                <img
                    src="@/assets/icons/rockestate-logo-horizontal-dark.svg"
                    class="logo"
                    width="148"
                />
            </b-navbar-item>

            <div v-if="isMobileMenuOpen" class="mobile-menu">
                <template v-if="user.email != null">
                    <div class="profile-item">
                        <h5>
                            {{ user.email }}
                        </h5>
                        <clear-button :href="logout_url" class="logout-button">
                            {{ $t('monolith.global.logout') }}
                        </clear-button>
                    </div>

                    <sidemenu />
                </template>

                <div class="mobile-langs">
                    <h4>{{ $t('monolith.global.language') }}</h4>
                    <div class="langs buttons has-addons my-0">
                        <clear-button
                            class="lang-btn"
                            :class="{ active: $i18n.locale === 'nl-BE' }"
                            :type="{
                                'is-primary': $i18n.locale === 'nl-BE',
                            }"
                            @click="changeLang('nl-BE')"
                        >
                            <span>NL</span>
                        </clear-button>
                        <clear-button
                            class="lang-btn"
                            :class="{ active: $i18n.locale === 'fr-BE' }"
                            :type="{
                                'is-primary': $i18n.locale === 'fr-BE',
                            }"
                            @click="changeLang('fr-BE')"
                        >
                            <span>FR</span>
                        </clear-button>
                        <clear-button
                            class="lang-btn"
                            :class="{ active: $i18n.locale === 'en-BE' }"
                            :type="{
                                'is-primary': $i18n.locale === 'en-BE',
                            }"
                            @click="changeLang('en-BE')"
                        >
                            <span>EN</span>
                        </clear-button>
                        <clear-button
                            v-if="hasRole('developer')"
                            class="lang-btn"
                            :class="{ active: $i18n.locale === 'technical' }"
                            :type="{
                                'is-primary': $i18n.locale === 'technical',
                            }"
                            @click="changeLang('technical')"
                        >
                            <span>Technical</span>
                        </clear-button>
                    </div>
                </div>

                <new-request-button class="mobile-new_request">
                    <clear-button class="mobile-new_request-button">
                        <span>{{ $t('dashboard.new_request.title') }}</span>
                        <b-icon type="is-white" icon="plus" size="is-small" />
                    </clear-button>
                </new-request-button>
            </div>
        </template>
        <!-- <template slot="burger" slot-scope="props">
            <b-button @click="props.toggleActive">
                {{ props.isOpened ? 'A' : 'B' }}
            </b-button>
        </template> -->

        <template v-if="showMenu" slot="start">
            <div v-if="isValuer && isDispatchDashboard" class="deployment-select mr-2">
                <b-select expanded :disabled="isLoading">
                    <option disabled>Deployment</option>
                </b-select>
            </div>
            <div
                v-if="isValuer"
                ref="reference_field"
                class="ref-search"
                :class="{
                    'apply-shake': shake,
                }"
            >
                <div class="ref-form">
                    <b-input
                        v-model="dvm_ref"
                        placeholder="Go to DVM (enter ref)"
                        :disabled="isLoading"
                    />
                    <b-button
                        class="ml-2"
                        type="is-primary"
                        :disabled="isDvmRefEmpty || isLoading"
                        @click="goToDvm(false)"
                    >
                        Go
                    </b-button>
                    <b-button
                        class="ml-2"
                        type="is-primary"
                        :disabled="isDvmRefEmpty || isLoading"
                        @click="goToDvm(true)"
                    >
                        Go (new tab)
                    </b-button>
                </div>
            </div>
        </template>

        <template slot="end">
            <b-navbar-item tag="div">
                <b-field>
                    <b-button
                        v-if="hasRole('developer')"
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': isOnline,
                            'is-danger': !isOnline,
                        }"
                        @click="setOnline(!isOnline)"
                    >
                        <span>{{ isOnline ? 'online' : 'offline' }}</span>
                    </b-button>
                </b-field>
            </b-navbar-item>
            <b-navbar-item tag="div">
                <new-request-button>
                    <b-button
                        :label="$t('dashboard.new_request.title')"
                        icon-left="plus"
                        size="is-small"
                        type="is-primary"
                        class="my-0"
                    />
                </new-request-button>
            </b-navbar-item>
            <b-dropdown
                v-if="hasRole('admin')"
                aria-role="list"
                :close-on-click="false"
                right
                paddingless
            >
                <template #trigger>
                    <b-navbar-item>
                        <b-icon icon="shield-account" />
                    </b-navbar-item>
                </template>

                <b-dropdown-item
                    aria-role="listitem"
                    paddingless
                    class="roles-dropdown"
                    :focusable="false"
                    custom
                >
                    <admin-roles
                        :roles="get_roles"
                        :editable="true"
                        :unremovable="['-:admin', '*:admin']"
                        class="role-widget"
                        @input="patchUser({ roles: listToRoles($event) })"
                    ></admin-roles>
                </b-dropdown-item>
            </b-dropdown>

            <b-navbar-item tag="div">
                <div class="langs buttons has-addons my-0">
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': $i18n.locale === 'nl-BE',
                        }"
                        @click.native="changeLang('nl-BE')"
                    >
                        <span>NL</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': $i18n.locale === 'fr-BE',
                        }"
                        @click.native="changeLang('fr-BE')"
                    >
                        <span>FR</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': $i18n.locale === 'en-BE',
                        }"
                        @click.native="changeLang('en-BE')"
                    >
                        <span>EN</span>
                    </b-button>
                    <b-button
                        v-if="hasRole('developer')"
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': $i18n.locale === 'technical',
                        }"
                        @click.native="changeLang('technical')"
                    >
                        <span>Technical</span>
                    </b-button>
                </div>
                <div
                    class="mr-4 px-1 is-size-7 user"
                    :class="[user.email ? 'ml-6' : 'ml-4']"
                >
                    {{ user.email }}
                </div>
                <div class="buttons">
                    <a v-if="user.email != null" :href="logout_url">
                        <b-button size="is-small" type="is-primary" outlined>
                            <span>{{ $t('monolith.global.logout') }}</span>
                        </b-button>
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import chevron from '@/shared/assets/icons/chevron.svg'
import AdminRoles from '@/shared/components/AdminRoles.vue'
import NewRequestButton from './NewRequestButton.vue'
import Sidemenu from './Sidemenu'
import ClearButton from '@/components/elements/ClearButton.vue'

import { useUser } from '@/composables/user'
import { useOnline } from '@/composables/utils/online'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Navbar',
    components: {
        AdminRoles,
        NewRequestButton,
        Sidemenu,
        ClearButton,
    },
    props: {
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { user, hasRole, patchUser } = useUser()
        const { isOnline, setOnline } = useOnline()
        return { user, hasRole, patchUser, isOnline, setOnline }
    },
    data: () => ({
        shake: false,
        dvm_ref: null,
        isLoading: false,
        isMobileMenuOpen: false,
        chevron,
    }),
    computed: {
        ...mapGetters(['isMobile']),
        logout_url: function() {
            return utils.urlJoin(this.$config.AUTH_API_URL, 'logout')
        },
        showMenu() {
            return !this.$config.CHECK_LOGIN || this.user.authenticated
        },
        get_roles() {
            return utils.rolesToList(this.user.roles)
        },
        isValuer() {
            return this.hasRole('valuer', 'dvm')
        },
        isDispatchDashboard() {
            return !!this.$config.DISPATCHED_DEPLOYMENTS?.length
        },
        isDvmRefEmpty() {
            return !this.dvm_ref || utils.isEmptyStr(this.dvm_ref)
        },
        newDVMEnabled() {
            return this.$config.ENABLE_NEW_DVM
        },
        appMapping() {
            return {
                ers: 'ers',
                avm: 'valuation.request',
                dvm: 'valuation.request', // TODO: find a way for this to work when already on the valuation route
                ovm: 'valuation.request',
            }
        },
        mobileTitle() {
            if (
                !this.isMobile ||
                !this.$route.meta.mobile ||
                !this.$route.meta.mobile.title
            )
                return false
            let i18nParams = {}
            if (this.$route.meta.mobile.title_param) {
                i18nParams[this.$route.meta.mobile.title_param] = this.$route.query[
                    this.$route.meta.mobile.title_param
                ]
            }
            return this.$te(this.$route.meta.mobile.title)
                ? this.$t(this.$route.meta.mobile.title, { ...i18nParams })
                : false
        },
        mobileRouteComp() {
            if (!this.isMobile) return null
            return this.$route.meta?.mobile?.navbarComponent
        },
        prevRoute() {
            if (!this.isMobile) return null
            return this.$route.meta?.mobile?.prevRoute
        },
    },
    watch: {
        fixed() {
            document.body.classList.toggle('has-navbar-fixed-top', this.fixed)
        },
        '$route.name'() {
            this.isMobileMenuOpen = false
        },
    },
    mounted() {
        try {
            this.$nextTick(() => {
                if (this.$refs.reference_field) {
                    this.$refs.reference_field.addEventListener('animationend', () => {
                        this.shake = false
                    })
                }
            })
        } catch (e) {
            console.log({ e })
        }
    },
    methods: {
        ...mapActions('auth', ['update_current_user']),
        changeLang(lang) {
            let params = this.$route.params
            params.lang = lang
            this.$router
                .push({
                    name: this.$route.name,
                    query: this.$route.query,
                    params: params,
                })
                .catch((error) => {
                    if (error.name !== 'NavigationDuplicated') {
                        throw error
                    }
                })
        },
        listToRoles(roles) {
            return utils.listToRoles(roles)
        },
        /*update_roles(roles) {
            this.patchUser({ roles: utils.listToRoles(roles) })
        },*/
        shakeAnimation() {
            this.shake = true
        },
        goToPrevRoute() {
            this.$router.push({
                name: this.prevRoute,
                query: this.$route.query,
                params: this.$route.params,
            })
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen
        },
        goToDvm(newTab = false) {
            if (
                this.$route.name === 'valuation.dvm' &&
                this.dvm_ref === this.$route.query.valuation_request_ref &&
                !newTab
            ) {
                this.shakeAnimation()
                return
            }

            let name = this.newDVMEnabled ? 'valuation.valuer-app' : 'valuation.dvm'

            this.isLoading = true
            this.$axios
                .get(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'request',
                        this.dvm_ref,
                    ])
                )
                .then(() => {
                    if (newTab) {
                        const routeData = this.$router.resolve({
                            name,
                            query: {
                                valuation_request_ref: this.dvm_ref,
                            },
                        })
                        window.open(routeData.href, '_blank')
                        this.isLoading = false
                        return
                    }
                    this.$router.push({
                        name,
                        query: {
                            valuation_request_ref: this.dvm_ref,
                        },
                    })
                    this.isLoading = false
                })
                .catch((e) => {
                    this.isLoading = false
                    console.log({ e })
                    this.shakeAnimation()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.new_request_navbar {
    color: #001837;
    height: 100%;

    &:hover {
        background: #f2f2f2;
        color: #001837;
    }
}

// no need for separate file less load with scope
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

#navbar {
    padding: 0 15px;
    border-bottom: 1px solid #d6e2e9;

    .navbar_mobile {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 8px;
        width: 100vw;
        padding: 0 8px;

        .navbar-spacer {
            flex-grow: 2;
        }

        h5 {
            color: #001837;
            font-size: 16px;
            font-weight: 700;
            flex-grow: 1;
        }

        .prev-button {
            img {
                width: 12px;
                transform-origin: center;
                transform: rotate(90deg);
            }
        }
    }

    @media screen and (max-width: 999px) {
        padding: 0;
        z-index: 9999;
    }
}

.roles-dropdown {
    height: 100%;
    padding: 10px !important;
    min-width: 250px;
}

.ref-search {
    z-index: 99998;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ref-form {
        display: flex;
        flex-direction: row;
    }
}

.deployment-select {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile-menu {
    position: absolute;
    top: 100%;
    height: calc(100vh - 100%);
    background: white;
    width: 100vw;
    border-top: 1px solid #d6e2e9;
    display: flex;
    flex-direction: column;

    .profile-item {
        padding: 16px;
        border-bottom: 1px solid #d6e2e9;
        background: rgba(0, 24, 55, 0.06);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h5 {
            color: #001837;
            font-size: 14px;
            font-weight: 400;
        }

        .logout-button {
            color: var(--color-primary);
            font-size: 12px;
            font-weight: 700;
            padding: 8px 16px;
            border-radius: 3px;
            border: 1px solid var(--color-primary);
        }
    }

    .mobile-langs {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        align-self: stretch;
        flex-grow: 1;

        h4 {
            color: #001837;
            font-family: Satoshi;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .langs {
            align-self: stretch;
        }

        .lang-btn {
            padding: 12px 8px;
            border: 1px solid rgba(0, 50, 84, 0.3);
            flex-grow: 1;

            &:not(:last-child) {
                border-right: 0;
            }

            &:first-child {
                border-radius: 8px 0 0 8px;
            }

            &:last-child {
                border-radius: 0 8px 8px 0;
            }

            span {
                color: rgba(0, 50, 84, 0.3);
                font-size: 14px;
                font-weight: 900;
            }

            &.active {
                border-color: var(--color-primary);
                background: var(--color-primary);

                span {
                    color: white;
                }
            }
        }
    }

    .mobile-new_request {
        margin: 16px;
        margin-top: 0;
        display: flex;
        align-items: stretch;
        flex-direction: column;

        .mobile-new_request-button {
            border-radius: 6px;
            background: var(--color-primary);
            width: 100%;
            padding: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            span {
                color: #fff;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}
</style>
