<template>
    <div
        class="grid_card"
        :class="{
            'grid_card--full': isFull,
            'grid_card--no_padding': noPadding,
            'grid_card--stretch': !hasContent && !noStretch,
            'grid_card--low_profile': isMobile && !showContent,
            'grid_card--expanded': showContent,
            'grid_card--mobile_margin': mobileMargin,
        }"
    >
        <div
            v-if="title || hasComment || hasHeaderSlot"
            class="grid_card-header"
            :class="{
                'mb-0': !hasContent && noStretch,
                'grid_card-header--wrapped': wrapHeader,
            }"
        >
            <h5 v-if="title" class="grid_card-title">
                {{ title }}
            </h5>
            <slot name="header" />
            <comment-button v-if="hasComment" />
            <clear-button
                v-if="isMobile && !noMobileToggle"
                class="toggle-button"
                @click="toggleContent"
            >
                <img :src="chevron" :class="{ toggled: showContent }" />
            </clear-button>
        </div>
        <slot v-if="showContent" />
        <slot name="modal" />
    </div>
</template>
<script>
import chevron from '@/shared/assets/icons/chevron.svg'
import CommentButton from '../CommentButton.vue'
import ClearButton from '@/components/elements/ClearButton.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'GridCard',
    components: {
        CommentButton,
        ClearButton,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        isFull: {
            type: Boolean,
            default: false,
        },
        hasComment: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        noMobileToggle: {
            type: Boolean,
            default: false,
        },
        mobileMargin: {
            type: Boolean,
            default: false,
        },
        noStretch: {
            type: Boolean,
            default: false,
        },
        wrapHeader: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showContent: true,
        chevron,
    }),
    computed: {
        ...mapGetters(['isMobile']),
        hasHeaderSlot() {
            return (
                (this.isMobile && !this.noMobileToggle) ||
                this.$slots.hasOwnProperty('header')
            )
        },
        hasContent() {
            return this.$slots.hasOwnProperty('default')
        },
    },
    watch: {
        isMobile(val) {
            if (!val) {
                this.showContent = true
            }
        },
    },
    mounted() {
        if (this.isMobile && !this.noMobileToggle) {
            this.showContent = false
        }
    },
    methods: {
        toggleContent() {
            if (!this.isMobile) return
            this.showContent = !this.showContent
        },
    },
}
</script>
<style lang="scss" scoped>
.grid_card {
    padding: 24px;
    min-height: fit-content;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;
    align-self: start;

    & ::v-deep .field {
        margin-bottom: 0;

        .label {
            color: #798da6;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 8px;
        }
    }

    & ::v-deep label {
        h5 {
            color: #798da6;
            font-size: 14px;
            font-weight: 500;
        }
    }

    @media screen and (max-width: 999px) {
        border-radius: 0;
        border-left: none;
        border-right: none;
        align-self: stretch;
        padding: 16px;

        &.grid_card--expanded:not(.grid_card--no_padding) {
            .grid_card-header {
                margin-bottom: 16px;
                padding: 0 !important;
            }
        }
    }

    &--stretch {
        align-self: stretch;
    }

    &--low_profile {
        padding-top: 16px !important;
        padding-bottom: 16px !important;

        .grid_card-header {
            margin-bottom: 0;
        }
    }

    &--mobile_margin {
        @media screen and (max-width: 999px) {
            border-radius: 8px;
            border: 1px solid #d6e2e9;
            margin: auto 16px;
        }
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        height: auto;
        margin-bottom: 24px;

        .grid_card-title {
            color: #001837;
            font-size: 18px;
            font-weight: 700;
            flex-grow: 2;
        }

        .toggle-button {
            background: rgba(121, 141, 166, 0.15);
            border: none;
            outline: none;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                width: 10px;
                &.toggled {
                    transform: scaleY(-1);
                }
            }
        }

        &--wrapped {
            flex-wrap: wrap;
            gap: 24px 8px;
        }
    }

    &--expanded {
        @media screen and (max-width: 999px) {
            .grid_card-header {
                margin-bottom: 0;
            }
        }
    }

    &--full {
        grid-column: 1 / -1;
    }

    &--no_padding {
        padding: 0;
        padding-top: 24px;

        .grid_card-header {
            padding: 0 24px;
        }

        @media screen and (max-width: 999px) {
            padding-top: 16px;

            &.grid_card--expanded {
                .grid_card-header {
                    padding-bottom: 16px;
                }
            }

            & > * {
                padding-left: 16px !important;
                padding-right: 16px !important;
            }
        }
    }

    & > *:only-child {
        height: 100%;
    }

    & ::v-deep .docs_buttn {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        width: fit-content;

        span {
            color: #001837;
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
        }

        &-badge {
            background: #009cff33;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            h5 {
                color: var(--color-primary);
                font-size: 8px;
                font-weight: 700;
            }
        }
    }

    & ::v-deep .b-checkbox {
        margin-right: 0 !important;

        .control-label {
            color: #001837;
            font-size: 15px;
            font-weight: 500;
        }
    }

    & ::v-deep .flex-label {
        .b-checkbox {
            margin-right: 0 !important;

            .control-label {
                display: none;
            }
        }
    }

    & ::v-deep .grid_card-content_grid {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        @media screen and (max-width: 999px) {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        &.three-cols {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .content_grid-item {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 8px;

            label {
                color: #798da6;
                font-size: 14px;
                font-weight: 500;
            }

            &--full {
                grid-column: 1 / -1;
            }
        }
    }

    & ::v-deep .grid_card-content_flex {
        display: flex;
        width: 100%;
        gap: 24px;
        flex-wrap: wrap;

        .content_flex-item {
            flex-grow: 1;
            &--full {
                flex-grow: 3;
            }
        }
    }

    & ::v-deep .flex-label {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 8px;
    }
}
</style>
