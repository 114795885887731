<template>
    <div
        class="valuer_tool-default-layout-grid valuer_tool-default-layout-grid--has_tabs"
    >
        <b-tabs v-if="isMobile" v-model="activeTab" class="valuer_tool-mobile_tabs">
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('valuer_tool.exterior.title')"
                value="0"
            />
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('valuer_tool.exterior.parcel')"
                value="1"
            />
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('valuer_tool.exterior.annexes')"
                value="2"
            />
            <b-tab-item
                header-class="valuer_tool-mobile_tabs-tab"
                :label="$t('monolith.global.documents')"
                value="3"
            />
        </b-tabs>
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.exterior.title') }}
        </h1>
        <template v-if="!isMobile || activeTab === '0'">
            <mobile-exterior-card v-if="isMobile" />
            <template v-else>
                <touching-sides />
                <historical />
            </template>
            <multi-choice-card full feature="style_of_house" />
            <multi-choice-card full feature="exterior_state" />
            <multi-choice-card full multiple feature="facade_material" />
            <multi-choice-card full multiple feature="roof_material" />
            <roof-information />
            <solar-panels v-if="is_house" />
            <multi-choice-card full feature="window_type" config-location="features" />
        </template>
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.exterior.parcel') }}
        </h1>
        <template v-if="!isMobile || activeTab === '1'">
            <garden />
            <swimming-pool v-if="is_house" />

            <grid-card
                v-if="is_apartment"
                is-full
                :title="$t('features.f_balcony_present')"
                no-mobile-toggle
            >
                <template slot="header">
                    <label class="flex-label">
                        <h5>
                            {{ $t('valuer_tool.exterior.f_balcony_present.question') }}
                        </h5>
                        <b-checkbox v-model="f_balcony_present" />
                    </label>
                </template>
            </grid-card>
            <grid-card
                v-if="is_apartment"
                is-full
                :title="$t('features.f_cellar_attic')"
                no-mobile-toggle
            >
                <template slot="header">
                    <label class="flex-label">
                        <h5>
                            {{
                                $t('valuer_tool.exterior.f_cellar_attic_present.question')
                            }}
                        </h5>
                        <b-checkbox v-model="f_cellar_attic" />
                    </label>
                </template>
            </grid-card>
        </template>
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.exterior.annexes') }}
        </h1>
        <template v-if="!isMobile || activeTab === '2'">
            <garage-parking garage-type="garage" />
            <garage-parking garage-type="parking" />
            <annex v-if="is_house" feature="pool_house" />
            <annex v-if="is_house" feature="stables" />
            <annex v-if="is_house" feature="barn" />
        </template>
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('monolith.global.documents') }}
        </h1>
        <template v-if="!isMobile || activeTab === '3'">
            <upload-card
                :title="$tc('document.exterior_picture', 2)"
                purpose="exterior_picture"
                thumbnails
            />
            <upload-card
                :title="$tc('document.cadastral_plan', 2)"
                purpose="cadastral_plan"
            />
        </template>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import { mapGetters as vuexGetters } from 'vuex'
import TouchingSides from './cards/Exterior/TouchingSides.vue'
import Historical from './cards/Exterior/Historical.vue'
import UploadCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/UploadCard.vue'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import RoofInformation from './cards/Exterior/RoofInformation.vue'
import SolarPanels from './cards/Exterior/SolarPanels.vue'
import Garden from './cards/Parcel/Garden.vue'
import SwimmingPool from './cards/Parcel/SwimmingPool.vue'
import GarageParking from './cards/Annexes/GarageParking.vue'
import Annex from './cards/Annexes/Annex.vue'
import MobileExteriorCard from './cards/Exterior/MobileExteriorCard.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Exterior',
    components: {
        TouchingSides,
        Historical,
        UploadCard,
        MultiChoiceCard,
        GridCard,
        RoofInformation,
        SolarPanels,
        Garden,
        SwimmingPool,
        GarageParking,
        Annex,
        MobileExteriorCard,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFullValuationStore', 'getFeatures']),
            ...mapMutations(requestObjects, ['SET_FEATURES']),
        }
    },
    data: () => ({
        activeTab: '0',
    }),
    computed: {
        ...vuexGetters(['isMobile']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        f_cellar_attic: {
            get: function() {
                return this.getFeatures.f_cellar_attic
            },
            set: function(f_cellar_attic) {
                this.SET_FEATURES({
                    f_cellar_attic,
                })
            },
        },
        f_balcony_present: {
            get: function() {
                return this.getFeatures.f_balcony_present
            },
            set: function(f_balcony_present) {
                this.SET_FEATURES({
                    f_balcony_present,
                })
            },
        },
    },
}
</script>
