<template>
    <div class="interior_tab">
        <h1 v-if="!isMobile" class="interior_tab-title">
            {{ $t('valuer_tool.interior.title') }}
        </h1>
        <checkboxes-card
            v-if="isHouse"
            feature="comfort"
            config-location="ovm_features"
        />
        <multi-choice-card
            feature="interior_state"
            config-location="ovm_features"
            :small="isHouse"
            :full="!isHouse"
        />
        <other-impacting-card
            type="positive"
            feature="interior_positive"
            config-location="ovm_features"
        />
        <other-impacting-card
            type="negative"
            feature="interior_negative"
            config-location="ovm_features"
        />
    </div>
</template>
<script>
import { mapGetters } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import CheckboxesCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/CheckboxesCard.vue'
import OtherImpactingCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/OtherImpactingCard.vue'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import { mapGetters as vuexGetters } from 'vuex'
import utils from '@/shared/plugins/utils'

export default {
    name: 'InteriorTab',
    components: {
        OtherImpactingCard,
        MultiChoiceCard,
        CheckboxesCard,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
        }
    },
    computed: {
        ...vuexGetters(['isMobile']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        isHouse() {
            return utils.is_house(this.building_type)
        },
    },
}
</script>
<style lang="scss" scoped>
.interior_tab {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    align-items: start;

    &-title {
        grid-column: 1 / 3;
        color: #001837;
        font-size: 24px;
        font-weight: 700;
    }

    @media screen and (max-width: 999px) {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
</style>
