<template>
    <div>
        <div v-if="!isFeedbackSent" class="exit-modal">
            <button class="closeModalBttn" @click="$emit('hide-contact-modal')">
                <img src="@/assets/icons/bttn-close-black.svg" alt="" />
            </button>
            <h2
                class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
            >
                {{ $t('global.helpcenter.contact_form.title') }}
            </h2>
            <p class="mb-5">
                {{ $t('global.helpcenter.contact_form.content') }}
            </p>
            <form class="contact-form">
                <b-field :label="$t('common.module')">
                    <b-select v-model="contactForm.module">
                        <option
                            v-for="m in accessibleModules.concat(['-'])"
                            :key="`module_${m}`"
                            :value="m"
                        >
                            {{ $t(`value.${m}`) }}
                        </option>
                    </b-select>
                </b-field>
                <b-field :label="$t('global.helpcenter.contact_form.subject_label')">
                    <b-select v-model="contactForm.subject">
                        <option
                            v-for="(subject, index) in subjects"
                            :key="`subject_option_${index}`"
                            :value="subject"
                        >
                            {{ $t(`global.helpcenter.contact_form.subject_${subject}`) }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    v-if="contactForm.subject === 'other'"
                    :label="$t('global.helpcenter.contact_form.subject_other')"
                >
                    <b-input v-model="contactForm.otherSubject" />
                </b-field>
                <b-field :label="t('global.helpcenter.contact_form.customer_ref')">
                    <b-select v-model="contactForm.valuation_request_ref" required>
                        <option
                            v-for="request in userRequests"
                            :key="request.valuation_request_ref"
                            :value="request.valuation_request_ref"
                        >
                            {{ request.customer_ref }} -
                            {{ display_address(request) }}
                        </option>
                        <option disabled>-------</option>
                        <option value="N/A">
                            {{ $t('global.helpcenter.contact_form.not_specific_req') }}
                        </option>
                    </b-select>
                </b-field>
                <b-field :label="$t('global.helpcenter.contact_form.message')">
                    <b-input v-model="contactForm.content" type="textarea" />
                </b-field>
                <div
                    v-for="(file, index) in contactForm.screenshots"
                    :key="`screenshot_${index}`"
                    class="screenshot-item"
                >
                    <img src="@/assets/icons/ic-file.svg" alt="" class="file-icon" />
                    <span class="screenshot-name">
                        {{ file.name }}
                    </span>
                    <b-button
                        class="delete-button is-light"
                        rounded
                        @click="removeScreenshot(index)"
                    >
                        <b-icon class="file-icon" icon="delete"></b-icon>
                    </b-button>
                </div>
                <b-field class="file is-primary" multiple>
                    <b-upload
                        v-model="contactForm.screenshots"
                        class="file-upload"
                        rounded
                        multiple
                        outlined
                        accept=".jpg,.jpeg,.png,image/png,image/jpeg"
                    >
                        <span class="file-cta mt-1">
                            <span class="file-label has-text-weight-bold">
                                {{
                                    $t('global.helpcenter.contact_form.screenshot_label')
                                }}
                            </span>
                            <b-icon class="file-icon" icon="image"></b-icon>
                        </span>
                        <h5
                            :class="{
                                'is-size-7': true,
                                'has-text-centered': true,
                                'mt-2': true,
                                'has-text-danger': isWrongFormat,
                            }"
                        >
                            {{ t('global.helpcenter.contact_form.screenshot_info') }}
                        </h5>
                    </b-upload>
                </b-field>
            </form>

            <span class="separator" />

            <b-button
                type="is-primary"
                class="has-text-weight-bold submit-btn"
                :disabled="isFormSubmitDisabled"
                @click="sendFeedback"
            >
                {{ $t('global.helpcenter.contact_form.submit') }}
            </b-button>
        </div>
        <div v-if="isFeedbackSent" class="exit-modal">
            <button class="closeModalBttn" @click="$emit('hide-contact-modal')">
                <img src="@/assets/icons/bttn-close-black.svg" alt="" />
            </button>
            <h2
                class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
            >
                {{ $t('global.helpcenter.contact_form.feedback_title') }}
            </h2>
            <p>
                {{ $t('global.helpcenter.contact_form.feedback_content') }}
            </p>
            <b-button
                type="is-primary"
                class="has-text-weight-bold mt-5"
                @click="$emit('hide-contact-modal')"
            >
                {{ $t('global.helpcenter.contact_form.close') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'
import { localeMixin } from '@/components/mixins/locale.js'

export default {
    name: 'ContactForm',
    mixins: [localeMixin],
    data() {
        return {
            searchField: '',
            isFeedbackSent: false,

            contactForm: {
                subject: 0,
                module: '-',
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            },

            userRequests: [],
        }
    },
    mounted() {
        this.getRequests()
        if (this.$route.query.from_module) {
            this.contactForm.module = this.$route.query.from_module
        }
    },
    computed: {
        ...mapGetters('auth', ['accessibleModules']),
        isFormSubmitDisabled() {
            return (
                this.isWrongFormat ||
                utils.isEmptyStr(this.contactForm.valuation_request_ref)
            )
        },
        isWrongFormat() {
            return (
                this.contactForm.screenshots.length > 0 &&
                !this.contactForm.screenshots.every((x) =>
                    ['image/png', 'image/jpeg'].includes(x.type)
                )
            )
        },
        subjects() {
            const selectedModule = this.contactForm.module
            if (selectedModule === 'ers') {
                return [
                    'req-failing',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            } else if (selectedModule === 'dvm') {
                return [
                    'req-not-eval-yet',
                    'req-back-to-draft',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            } else if (selectedModule === '-') {
                return [
                    'req-not-eval-yet',
                    'req-back-to-draft',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            }
            return []
        },
    },
    methods: {
        getRequests() {
            this.$axios
                .get(utils.urlJoin(this.$config.VALUATION_API_URL, ['user', 'requests']))
                .then((res) => {
                    this.userRequests = res.data
                })
        },
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },

        removeScreenshot(index) {
            this.contactForm.screenshots.splice(index, 1)
        },
        resetContactForm() {
            this.contactForm = {
                subject: 0,
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            }
        },
        sendFeedback() {
            const {
                valuation_request_ref,
                module,
                otherSubject,
                content,
                screenshots,
            } = this.contactForm

            const subject = this.contactForm.subject

            // TODO : Replace below commented API call to the proper endpoint and
            // remove the lines above this comment block and below the commented
            // API call

            let formData = new FormData()
            formData.append('subject_tech', subject)
            if (subject === 'other') {
                formData.append('subject', otherSubject)
            } else {
                formData.append(
                    'subject',
                    this.$t(`global.helpcenter.contact_form.subject_${subject}`)
                )
            }
            formData.append('module', module)
            formData.append('valuation_request_ref', valuation_request_ref)
            formData.append(
                'url',
                JSON.stringify({
                    params: this.$route.params,
                    fullPath: this.$route.fullPath,
                    query: this.$route.query,
                    hash: this.$route.hash,
                    name: this.$route.name,
                })
            )
            formData.append('content', content)
            for (let i = 0; i < screenshots.length; i++) {
                formData.append(`screenshot_${i}`, screenshots[i])
            }

            axios
                .post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, 'contact'),
                    formData,
                    { headers: { 'content-type': 'multipart/form-data' } }
                )
                .then((res) => {})
                .finally(() => {
                    this.isFeedbackSent = true
                    this.resetContactForm()
                })
        },
    },
}
</script>

<style scoped lang="scss">
.exit-modal {
    min-width: 490px;
    max-width: 800px;
    width: fit-content;
    padding: 30px;
    position: relative;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;

    .closeModalBttn {
        all: unset;
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: stretch;
    }

    .screenshot-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        .file-icon {
            height: 37px;
            width: auto;
        }

        .screenshot-name {
            flex-grow: 1;
            color: var(--black);
        }

        .delete-button {
            height: 2.5rem;
            width: 2.5rem;
            background: #99a7cc33;
            color: #99a7cc99;
            padding: 0;

            &:hover {
                background: #99a7cc66;
                color: #99a7ccff;
            }
        }
    }

    .file-upload {
        display: flex;
        flex-direction: column;
        width: 100%;

        .file-cta {
            color: var(--primary) !important;
            border-color: var(--primary) !important;
            background: white !important;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                color: white !important;
                background: var(--primary) !important;
            }

            .icon {
                margin-left: 10px;
            }
        }
    }
}
</style>
