<template>
    <div ref="chart" />
</template>
<script>
import * as d3 from 'd3'

export default {
    name: 'KernelChart',
    props: {
        dataArray: {
            type: Array,
            required: true,
        },
        targetValue: {
            type: Number,
            required: true,
        },
        width: {
            type: Number,
            default: 700,
        },
        marginRight: {
            type: Number,
            default: 50,
        },
        marginLeft: {
            type: Number,
            default: 50,
        },
        marginBottom: {
            type: Number,
            default: 50,
        },
        marginTop: {
            type: Number,
            default: 50,
        },
        thresholds: {
            type: Number,
            default: 60,
        },
        isSides: {
            type: Boolean,
            default: false,
        },
        isYear: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        margin() {
            return {
                left: this.marginLeft,
                right: this.marginRight,
                top: this.marginTop,
                bottom: this.marginBottom,
            }
        },
    },
    mounted() {
        const width = this.width
        const height = this.width / 2.5
        const bandwidth = d3.deviation(this.dataArray)

        const tenTile = d3.quantileSorted(this.dataArray, 0.1)
        const ninetyTile = d3.quantileSorted(this.dataArray, 0.9)

        const svg = d3
            .select(this.$refs.chart)
            .append('svg')
            .attr('width', width)
            .attr('length', height)
            .attr('viewBox', [0, 0, width, height])

        var xLower = this.isSides
            ? 0
            : d3.max([d3.min(this.dataArray) - d3.deviation(this.dataArray), 0])

        var x = d3
            .scaleLinear()
            .domain([xLower, d3.max(this.dataArray) + d3.deviation(this.dataArray)])
            .range([this.margin.left, width - this.margin.right])

        const thresholds = x.ticks(this.thresholds, '~s')
        const xAxisTicks = x
            .ticks(10, '~s')
            .filter((tick) => (this.isSides ? Number.isInteger(tick) : true))

        const xAxisTickFormat = (val) => {
            if (val >= 1000 && val % 100 === 0 && !this.isYear) {
                return (val / 1000).toString() + 'K'
            }
            return val
        }

        svg.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + (height - this.margin.bottom) + ')')
            .call(
                d3
                    .axisBottom(x)
                    .tickValues(xAxisTicks)
                    .tickFormat(xAxisTickFormat)
            )
            .append('text')
            .attr('x', width - this.margin.right)
            .attr('y', -(height - this.margin.bottom - this.margin.top))
            .attr('fill', '#000')
            .attr('text-anchor', 'end')
            .attr('font-weight', 'bold')
            .style('font-size', '1rem')
            .text(this.title)

        d3.selectAll('.tick text').classed('is-size-7', true)

        const bin = d3
            .bin()
            .domain(x.domain())
            .thresholds(thresholds)

        const bins = bin(this.dataArray)

        const binlengths = bins.map((b) => b.length)

        var y = d3
            .scaleLinear()
            .domain([0, d3.max(binlengths)])
            .range([height - this.margin.bottom, this.margin.top])

        const density = kernelDensityEstimator(
            kernelEpanechnikov(bandwidth),
            thresholds
        )(this.dataArray)

        const maxDensity = d3.max(density.map((d) => d[1]))

        density.forEach((d) => {
            d[1] = ((d[1] * d3.max(binlengths)) / maxDensity) * 0.75
        })

        // density = (density * d3.max(binlengths)) / d3.max(density)

        svg.insert('g', '*')
            .attr('fill', 'var(--color-primary)')
            .append('rect')
            .attr('x', x(this.targetValue))
            .attr('y', this.margin.top)
            .attr('width', 3)
            .attr('height', height - this.margin.top - this.margin.bottom)

        svg.insert('g', '*')
            .attr('fill', 'green')
            .append('rect')
            .attr('x', x(tenTile))
            .attr('y', this.margin.top)
            .attr('width', 3)
            .attr('height', height - this.margin.top - this.margin.bottom)

        svg.insert('g', '*')
            .attr('fill', 'green')
            .append('rect')
            .attr('x', x(ninetyTile))
            .attr('y', this.margin.top)
            .attr('width', 3)
            .attr('height', height - this.margin.top - this.margin.bottom)

        svg.insert('g', '*')
            .attr('fill', '#ccc')
            .selectAll('rect')
            .data(bins)
            .enter()
            .append('rect')
            .attr('x', function(d) {
                return x(d.x0) + 1
            })
            .attr('y', function(d) {
                return y(d.length)
            })
            .attr('width', function(d) {
                return x(d.x1) - x(d.x0) - 1
            })
            .attr('height', function(d) {
                return y(0) - y(d.length)
            })

        svg.append('path')
            .datum(density)
            .attr('fill', 'none')
            .attr('stroke', '#000')
            .attr('stroke-width', 1.5)
            .attr('stroke-linejoin', 'round')
            .attr(
                'd',
                d3
                    .line()
                    .curve(d3.curveBasis)
                    .x(function(d) {
                        return x(d[0])
                    })
                    .y(function(d) {
                        return y(d[1])
                    })
            )

        function kernelDensityEstimator(kernel, X) {
            return function(V) {
                return X.map(function(x) {
                    return [
                        x,
                        d3.mean(V, function(v) {
                            return kernel(x - v)
                        }),
                    ]
                })
            }
        }

        function kernelEpanechnikov(k) {
            return function(v) {
                return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0
            }
        }
    },
}
</script>
