var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid_card",class:{
        'grid_card--full': _vm.isFull,
        'grid_card--no_padding': _vm.noPadding,
        'grid_card--stretch': !_vm.hasContent && !_vm.noStretch,
        'grid_card--low_profile': _vm.isMobile && !_vm.showContent,
        'grid_card--expanded': _vm.showContent,
        'grid_card--mobile_margin': _vm.mobileMargin,
    }},[(_vm.title || _vm.hasComment || _vm.hasHeaderSlot)?_c('div',{staticClass:"grid_card-header",class:{
            'mb-0': !_vm.hasContent && _vm.noStretch,
            'grid_card-header--wrapped': _vm.wrapHeader,
        }},[(_vm.title)?_c('h5',{staticClass:"grid_card-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._t("header"),(_vm.hasComment)?_c('comment-button'):_vm._e(),(_vm.isMobile && !_vm.noMobileToggle)?_c('clear-button',{staticClass:"toggle-button",on:{"click":_vm.toggleContent}},[_c('img',{class:{ toggled: _vm.showContent },attrs:{"src":_vm.chevron}})]):_vm._e()],2):_vm._e(),(_vm.showContent)?_vm._t("default"):_vm._e(),_vm._t("modal")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }