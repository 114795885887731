import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import i18n from './i18n'
import { createRouter } from './router'
import store from './store/index'

Vue.use(VueClipboard)

import TitleWithTooltip from '@/shared/components/TitleWithTooltip.vue'

Vue.component('TitleWithTooltip', TitleWithTooltip)

import { VueQueryPlugin, onlineManager } from '@tanstack/vue-query'
import { vueQueryPluginOptions } from '@/composables/query'

Vue.use(VueQueryPlugin, vueQueryPluginOptions)

// Leaflet stuff
import { LMap, LTileLayer, LMarker, LGeoJson } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

Vue.component('LMap', LMap)
Vue.component('LTileLayer', LTileLayer)
Vue.component('LMarker', LMarker)
Vue.component('LGeoJson', LGeoJson)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
Vue.config.productionTip = false

Vue.config.warnHandler = function(msg, vm, trace) {
    // Do not handle if not in env mode:
    if (process.env.NODE_ENV !== 'production') {
        return
    }
    try {
        const warning = {
            error_code: 'vue_warning',
            debug: {
                message: `Warning in ${trace} : ${msg}`,
                traceback: trace,
            },
        }
        store.dispatch('log/logError', { error: warning, errorType: 'warning' })
    } catch (e) {
        // Prevent infinite loop:
        console.log('[Fallback from global warning handler. Exception]', e)
    }
}

Vue.config.errorHandler = function(err, vm, info) {
    try {
        const error = {
            error_code: 'vue_error',
            debug: {
                message: `Error in ${info} : ${err.message}`,
                traceback: err.stack,
                context: {
                    info: info,
                    // TODO: extract more info from vm.$options (avoid recursion)
                    // vue_vm_options: vm.$options
                    vm_options_name: vm.$options ? vm.$options.name : null,
                    // vm_options_propsData: vm.$options
                    //     ? JSON.stringify(vm.$options.propsData)
                    //     : null,
                },
            },
        }
        store.dispatch('log/logError', { error, errorType: 'error' })
    } catch (e) {
        // Prevent infinite loop:
        console.log('[Fallback from global error handler. Exception]', e)
    }
}

window.addEventListener('unhandledrejection', function(event) {
    // NOTE: this will only work if devServer.client.overlay is also set to false:
    event.preventDefault()

    if (event.reason?.handled_by_axios) return

    try {
        const error = {
            error_code: 'unhandled_promise_rejection',
            debug: {
                message: `Unhandled promise rejection: ${event.reason.message}`,
                traceback: event.reason.stack,
            },
        }
        store.dispatch('log/logError', { error, errorType: 'error' })
    } catch (e) {
        // Prevent infinite loop:
        console.log('[Fallback from unhandledrejection handler. Exception]', e)
    }
})

import '@mdi/font/css/materialdesignicons.css' // <-- Just here
import Buefy from 'buefy'
Vue.use(Buefy)

import * as VueGoogleMaps from 'vue2-google-maps'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import Axios from '@/shared/plugins/axios'
Vue.prototype.$axios = Axios
//Vue.prototype.$store = store
//window.store = store

// 'svgicon'
import SvgIcon from 'vue-svgicon'
import '@/shared/assets/svg-icon'

Vue.use(SvgIcon, {
    tagName: 'svg-icon',
    defaultWidth: '1em',
    defaultHeight: '1em',
    isOriginalDefault: true,
})

import VueScreenSize from 'vue-screen-size'
Vue.use(VueScreenSize)

// set Sentry key in .env VUE_APP_SENTRY_DSN = your key
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

Vue.config.productionTip = false

import { fetchConfig } from '@/shared/plugins/config'
import './registerServiceWorker'

fetchConfig().then((conf) => {
    if (conf.GOOGLE_MAP_KEY !== 'disabled') {
        if (conf.GOOGLE_MAP_KEY !== '') {
            Vue.use(VueGoogleMaps, {
                load: {
                    key: conf.GOOGLE_MAP_KEY,
                    libraries: 'places',
                },
            })
        } else {
            console.warn('Missing Google map key from server settings.')
        }
    }

    if (typeof conf.SENTRY_DSN != 'undefined') {
        Sentry.init({
            dsn: conf.SENTRY_DSN,
            integrations: [new VueIntegration({ Vue, attachProps: true })],
            environment: conf.ENVIRONMENT,
            release: process.env.VUE_APP_VERSION_FULL,
        })
    }

    // Always start with an online state that is coherent with the source of the config
    // This is useful because, when our PWA is loaded from cache, the browser reports being online, even though it isn't, and tanstack/query also believes that.
    onlineManager.setOnline(conf.source === 'online')

    const router = createRouter(conf)

    // TODO: test that this works:
    router.onError((err) => {
        console.log('[Router error]', err)
        const error = {
            error_code: 'router_error',
            debug: {
                message: `Vue Router error: ${err.message}`,
                traceback: err.stack,
            },
        }
        store.dispatch('log/logError', { error, errorType: 'error' })
    })

    // update customer_ref in store before router update (not for start/enter)
    router.beforeEach((to, from, next) => {
        if (to.params.lang) {
            i18n.locale = to.params.lang
        }
        if (to.params.action === 'start') {
            // update localestorage lang on landing
            if (to.redirectedFrom) {
                const lang = to.redirectedFrom.split('/')[1]
                localStorage.setItem('lang', lang)
            }
            next()
        } else if (typeof to.query.valuation_request_ref !== 'undefined') {
            if (
                store.state.valuationStore.valuation_request_ref !=
                to.query.valuation_request_ref
            ) {
                store.commit(
                    'valuationStore/SET_VALUATION_REQUEST_REF',
                    to.query.valuation_request_ref
                )
                store.dispatch('valuationStore/load_valuation_request').finally(() => {
                    next()
                })
            } else {
                next()
            }
        } else {
            next()
        }
    })

    const app = new Vue({
        router,
        i18n,
        store,
        render: (h) => h(App),
    })

    // get access to store in testing
    if (window.Cypress) {
        // only available during E2E tests
        window.app = app
    }

    app.$mount('#app')
})
