<i18n lang="json">
{
    "fr-BE": {
        "beginning": "This is the beginning of your chat with",
        "bank_agent": "the bank agent",
        "vs": "the Rock.estate Valuation Specialist",

        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submit": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "submit-dvm-valuation": "Valued"
        },
        "onsite_reasons": {
            "onsite_streetview": "Streetview data not available or outdated",
            "onsite_incoherent_features": "Wrong input filled",
            "onsite_exceptional_property": "Exceptional property for the area",
            "onsite_commercial_building": "Commercial building",
            "onsite_volume_missing": "Building volume missing",
            "onsite_flood_zone": "Flood zone risk",
            "onsite_few_comparison": "Not enough comparison data points",
            "onsite_extensive_renovation": "Ratio renovation/current value",
            "onsite_others": "Other"
        }
    },
    "nl-BE": {
        "beginning": "This is the beginning of your chat with",
        "bank_agent": "the bank agent",
        "vs": "the Rock.estate Valuation Specialist",

        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submit": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "submit-dvm-valuation": "Valued"
        },
        "onsite_reasons": {
            "onsite_streetview": "Streetview data not available or outdated",
            "onsite_incoherent_features": "Wrong input filled",
            "onsite_exceptional_property": "Exceptional property for the area",
            "onsite_commercial_building": "Commercial building",
            "onsite_volume_missing": "Building volume missing",
            "onsite_flood_zone": "Flood zone risk",
            "onsite_few_comparison": "Not enough comparison data points",
            "onsite_extensive_renovation": "Ratio renovation/current value",
            "onsite_others": "Other"
        }
    },
    "en-BE": {
        "beginning": "This is the beginning of your chat with",
        "bank_agent": "the bank agent",
        "vs": "the Rock.estate Valuation Specialist",

        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submit": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "valued": "Valued"
        },
        "onsite_reasons": {
            "onsite_streetview": "Streetview data not available or outdated",
            "onsite_incoherent_features": "Wrong input filled",
            "onsite_exceptional_property": "Exceptional property for the area",
            "onsite_commercial_building": "Commercial building",
            "onsite_volume_missing": "Building volume missing",
            "onsite_flood_zone": "Flood zone risk",
            "onsite_few_comparison": "Not enough comparison data points",
            "onsite_extensive_renovation": "Ratio renovation/current value",
            "onsite_others": "Other"
        }
    }
}
</i18n>
<template>
    <div class="chat">
        <h5 class="is-size-8 mb-5">
            {{ $t('beginning') }}
            {{ isInDVM ? $t('bank_agent') : $t('vs') }}
        </h5>
        <template v-for="(message, key) in completeHistory">
            <system-bubble
                v-if="message.isSystem"
                :key="`message_${key}`"
                :content="message.content"
                :time="new Date(message.posted_at)"
                :username="message.user"
            />
            <chat-bubble
                v-else
                :key="`message_${key}`"
                :is-own="message.user.is_self"
                :content="message.content"
                :time="new Date(message.posted_at)"
                :surname="message.user.first_name"
                :name="message.user.last_name"
                :is-agent="message.posted_as === 'owner'"
            />
        </template>
        <form id="input-form" @submit="sendChat">
            <textarea
                id="chat-input"
                v-model="outboundChat"
                class="py-2 px-3"
                alt="Enter your message here"
                @keyup="resizeChatInput"
                @keypress="submitOnEnter"
            />
            <button
                type="submit"
                class="clear-button submit-button"
                :disabled="isSubmitDisabled"
            >
                <b-icon icon="send" size="is-small" class="m-0" />
            </button>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ChatBubble from './ChatBubble.vue'
import SystemBubble from './SystemBubble.vue'
import utils from '@/shared/plugins/utils'

const minInMil = 60 * 1000

export default {
    name: 'Chat',
    components: { ChatBubble, SystemBubble },
    data() {
        return {
            outboundChat: '',
            history: [],
            messages: [],
            messagesLoading: true,
            historyLoading: true,
        }
    },
    computed: {
        ...mapGetters('valuationStore', ['getRequestRef']),
        ...mapGetters('auth', ['hasRole']),
        isValuer() {
            return this.hasRole('valuer')
        },
        now() {
            return new Date()
        },
        isSubmitDisabled() {
            return utils.isEmptyStr(this.outboundChat)
        },
        isInDVM() {
            return this.$route.name === 'valuation.dvm'
        },
        isChatAllowed() {
            return this.$config.ENABLE_CHAT
        },
        completeHistory() {
            let fullArray = this.messages.map((message) => {
                let returnObj = { ...message }
                returnObj.isSystem = false
                return returnObj
            })

            this.history.forEach((event) => {
                let returnObj = {}
                returnObj.posted_at = event.datetime
                returnObj.user = event.user.username

                switch (event.event_type) {
                    case 'create_request':
                        returnObj.content = 'Request created'
                        break
                    case 'change_request_status':
                        switch (event.status) {
                            case 'submit':
                                returnObj.content = 'Request was (re)submitted.'
                                break
                            case 'submit-valuation':
                            case 'submit-dvm-valuation':
                                returnObj.content = 'Request was valued'
                                break
                            default:
                                return null
                        }
                        break
                    case 'patch_request':
                        returnObj.content = this.generatePatchContent(event.params)
                        break
                    default:
                        console.log({ event })
                        return null
                }
                if (returnObj.content == null || utils.isEmptyStr(returnObj.content)) {
                    console.log({ event })
                    return null
                }

                returnObj.isSystem = true
                fullArray.push(returnObj)
            })

            fullArray.sort((a, b) => {
                if (a.posted_at > b.posted_at) {
                    return 1
                }
                if (a.posted_at < b.posted_at) {
                    return -1
                }
                return 0
            })

            return fullArray
        },
    },
    mounted() {
        if (this.isChatAllowed) {
            this.fetch_messages()
        }

        this.fetch_history()

        try {
            this.$nextTick(() => {
                this.resizeChatInput()
                document.getElementById('chat-input').keyDown(function(e) {})
            })
        } catch (e) {
            console.log({ e })
        }
    },
    methods: {
        ...mapMutations(['SET_UNREAD_MESSAGES']),
        secondsAgo(x) {
            let delta = x * 1000
            return new Date(this.now.getTime() - delta)
        },
        minsAgo(x) {
            let delta = x * minInMil
            return new Date(this.now.getTime() - delta)
        },
        hoursAgo(x) {
            let delta = x * 60 * minInMil
            return new Date(this.now.getTime() - delta)
        },
        daysAgo(x) {
            let delta = x * 24 * 60 * minInMil
            return new Date(this.now.getTime() - delta)
        },
        generatePatchContent(params) {
            if (params.hasOwnProperty('dvm_rejections')) {
                let returnStr = ''
                if (params.dvm_rejections.hasOwnProperty('incoherent_features')) {
                    returnStr += 'Request was sent for review because of '
                    Object.keys(params.dvm_rejections.incoherent_features).forEach(
                        (key, i) => {
                            if (i) returnStr += ', '
                            returnStr += '"'
                            returnStr += this.$t(`features.${key}`)
                            returnStr += '"'
                        }
                    )
                } else if (params.dvm_rejections.hasOwnProperty('onsite_reasons')) {
                    returnStr += `Request was sent on-site because of "${this.$t(
                        `onsite_reasons.${params.dvm_rejections.onsite_reasons}`
                    )}"`
                }

                if (
                    params.dvm_rejections.hasOwnProperty('rejection_comments') &&
                    !utils.isEmptyStr(params.dvm_rejections.rejection_comments)
                ) {
                    returnStr += ' with the comment "'
                    returnStr += params.dvm_rejections.rejection_comments
                    returnStr += '"'
                }
                returnStr += '.'

                return returnStr
            }

            return null
        },
        fetch_messages() {
            this.$axios
                .get(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'request',
                        this.getRequestRef,
                        'messages',
                    ])
                )
                .then((res) => {
                    this.messages = res.data.messages
                    this.SET_UNREAD_MESSAGES(false)
                    this.messagesLoading = false
                })
                .catch((e) => {
                    console.log({ e })
                })
        },
        fetch_history() {
            this.$axios
                .get(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'request',
                        this.getRequestRef,
                        'history',
                    ])
                )
                .then((res) => {
                    this.history = res.data.events
                    this.historyLoading = false
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        resizeChatInput() {
            const chatInput = document.getElementById('chat-input')
            chatInput.style.height = '1px'
            chatInput.style.height = chatInput.scrollHeight + 'px'
        },
        submitOnEnter(e) {
            if (e.which === 13 && !e.shiftKey) {
                this.sendChat(e)
            }
        },
        sendChat(e) {
            e.preventDefault()

            this.$axios
                .post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'request',
                        this.getRequestRef,
                        'message',
                    ]),
                    { content: this.outboundChat }
                )
                .then((res) => {
                    this.fetch_messages()
                    this.outboundChat = ''
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#input-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    align-items: center;

    #chat-input {
        resize: none;
        overflow: hidden;
        flex-grow: 1;
        border-radius: var(--bubble-radius);

        &:focus {
            border: none !important;
            outline: 1px solid var(--primary) !important;
        }
    }

    .submit-button {
        color: var(--primary);
        border-radius: 100%;
        height: 1.5rem;
        width: 1.5rem;

        &:hover {
            background: whitesmoke;
        }

        &:disabled {
            color: #e4e4e4;
            cursor: not-allowed;
        }
    }
}
</style>
