<template>
    <div class="document_block" :class="{ document_thumbnail: thumbnail }">
        <template v-if="!thumbnail">
            <div class="document_block-icon">
                <svg-icon :icon="documentIcon" height="16" scale="1" color="#009CFF" />
            </div>
            <a :href="documentURL" target="_blank" class="document_block-name">
                {{ fileName }}
            </a>
        </template>
        <template v-else-if="getImageDataURL(documentRef) === 'document-image'">
            <div class="document_block-icon">
                <svg-icon icon="document-image" height="16" scale="1" color="#009CFF" />
            </div>
            <a :href="documentURL" target="_blank" class="document_block-name">
                {{ fileName }}
            </a>
        </template>
        <img
            v-else-if="fileType.startsWith('image/')"
            class="thumbnail_pic"
            :src="getImageDataURL(documentRef)"
        />
        <svg-icon
            v-else
            :icon="documentIcon"
            height="45"
            scale="1.8"
            color="#009CFF"
            class="thumbnail_replacement"
        />
        <clear-button
            class="document_block-delete_buttn"
            @click="$emit('delete', documentRef)"
        >
            <svg-icon
                icon="delete"
                height="22"
                scale="1"
                :color="thumbnail ? '#FFFFFF' : '#798DA6'"
            />
        </clear-button>
    </div>
</template>
<script>
import ClearButton from '@/components/elements/ClearButton.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters } from '@/composables/fake-vuex'
import { useDocuments } from '@/composables/documents'

export default {
    name: 'Document',
    components: {
        ClearButton,
    },
    props: {
        fileName: {
            type: String,
            required: true,
        },
        documentRef: {
            type: String,
            required: true,
        },
        fileType: {
            type: String,
            default: 'image/jpeg',
            //required: true,
        },
        thumbnail: {
            type: Boolean,
            default: false,
        },
    },
    setup: () => {
        const documentObjects = useDocuments()
        return {
            ...mapGetters(documentObjects, ['getImageDataURL']),
        }
    },
    computed: {
        documentIcon() {
            return 'document-file'
            return this.fileType.startsWith('image/') ? 'document-image' : 'document-file'
        },
        documentURL() {
            return (
                utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                    this.$config.VALUATION_API_URL,
                    'document',
                    this.documentRef,
                ]) + '?view=true'
            )
        },
    },
}
</script>
<style lang="scss" scoped>
.document_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 416px;
    padding: 8px;
    background: white;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid #d6e2e9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    width: 100%;

    @media screen and (max-width: 999px) {
        min-width: 0;
    }

    &-icon {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background: rgba(0, 156, 255, 0.2);
        border-radius: 50%;
    }

    &-name {
        flex-grow: 2;
        color: #001837;
        font-size: 16px;
        font-weight: 500;
    }

    &-delete_buttn {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background: rgba(121, 141, 166, 0.15);
        border-radius: 50%;
    }

    &.document_thumbnail {
        width: 56px;
        height: 56px;
        min-width: 0;
        align-items: center;
        justify-content: center;
        padding: 12px;
        position: relative;
        border-radius: 8px;
        box-shadow: none;
        overflow: hidden;

        .thumbnail_replacement {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .thumbnail_pic {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 3;
        }

        .document_block-delete_buttn {
            opacity: 0%;
            z-index: 4;
            background: rgba(0, 24, 55, 0.8);
        }

        &:hover {
            .document_block-delete_buttn {
                opacity: 1;
            }
        }
    }
}
</style>
