/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path d="M16,30.7C7.9,30.7,1.3,24.1,1.3,16S7.9,1.3,16,1.3S30.7,7.9,30.7,16S24.1,30.7,16,30.7z M16,4 C9.4,4,4,9.4,4,16s5.4,12,12,12s12-5.4,12-12S22.6,4,16,4z M16,24c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h0 c0.7,0,1.3,0.6,1.3,1.3S16.8,24,16,24z M15.9,18.7c-0.6,0-1.1-0.4-1.3-0.9c-0.2-0.7,0.1-1.5,0.8-1.7c0.8-0.3,3.1-1.4,3.1-2.7 c0-0.6-0.2-1.2-0.6-1.7c-0.4-0.5-1-0.8-1.6-0.9c-0.6-0.1-1.3,0-1.8,0.3c-0.5,0.3-1,0.8-1.2,1.4c-0.2,0.7-1,1.1-1.7,0.8 c-0.7-0.2-1.1-1-0.8-1.7c0.4-1.2,1.2-2.2,2.3-2.8c1.1-0.6,2.4-0.9,3.6-0.7C18,8.3,19.2,8.9,20,9.9c0.8,1,1.3,2.2,1.3,3.4 c0,3.5-4.4,5.1-4.9,5.3C16.2,18.6,16,18.7,15.9,18.7z"/>'
  }
})
