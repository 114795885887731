/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plot': {
    width: 37,
    height: 37,
    viewBox: '0 0 37 37',
    data: '<path pid="0" _stroke="#798DA6" stroke-width="2" stroke-dasharray="3 3" d="M4 4h29v29H4z"/><path pid="1" _fill="#F2F4F6" _stroke="#798DA6" stroke-width="2" d="M1 1h6v6H1zM30 1h6v6h-6zM1 30h6v6H1zM30 30h6v6h-6z"/>'
  }
})
