<template>
    <div
        v-if="!!page.name"
        :id="`page-${page.name}`"
        class="page is-flex is-flex-direction-column"
    >
        <div class="section content pt-5 px-5 pb-2 has-text-left">
            <slot name="header"><report-header /></slot>
            <div v-if="hasSidebar" class="columns is-multiline">
                <div class="column is-4">
                    <slot name="sidebar"></slot>
                </div>
                <div class="column is-8">
                    <slot name="preSectionsContent"></slot>
                    <sections-content :sections="sections"></sections-content>
                    <slot name="postSectionsContent"></slot>
                </div>
            </div>
            <div v-else>
                <slot name="preSectionsContent"></slot>
                <sections-content :sections="sections"></sections-content>
                <slot name="postSectionsContent"></slot>
            </div>
        </div>
        <slot name="footer"><report-footer :page-number="pageNumber" /></slot>
    </div>
</template>

<script>
import ReportHeader from './Header.vue'
import ReportFooter from './Footer.vue'
import SectionsContent from './SectionsContent.vue'

import { mapMutations, mapGetters } from 'vuex'

export default {
    name: 'ReportPage',
    components: {
        ReportHeader,
        ReportFooter,
        SectionsContent,
    },
    props: {
        page: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    computed: {
        sections() {
            return (this.page ?? {}).sections
        },
        pageNumber() {
            return (this.page ?? {}).index
        },
        hasSidebar() {
            // Unclear if this works with Vue 3, TBC... https://stackoverflow.com/a/44077358/16901626
            return !!this.$slots.sidebar
        },
    },
    methods: {},
}
</script>
