<template>
    <div>
        <h2 class="title is-size-4 has-text-weight-bold mgt-medium pb-4">
            {{ $t('valuer_tool.valuer_settings.availability_title') }}
        </h2>
        <template v-if="rules === null">
            <b-progress></b-progress>
        </template>
        <b-tabs
            v-else
            v-model="activeTab"
            type="is-boxed"
            aria-label="availability slots"
            @input="
                (val) => {
                    if (val === 'add') createEmptyRule()
                }
            "
        >
            <b-tab-item
                v-for="r in rules"
                :key="r.id"
                :label="r.name"
                :value="r.id"
                :disabled="rulesBeingDeleted.includes(r.id)"
            >
                <template #header>
                    <span>{{ r.name }}</span>
                    <button
                        class="delete-button"
                        :disabled="rulesBeingDeleted.includes(r.id)"
                        @click.stop="deleteRule(r.id)"
                    >
                        <b-icon icon="trash-can" />
                    </button>
                </template>
                <AvailabilityRule
                    :rule="r"
                    :nuts3="nuts3"
                    :element-token="cronofyUser.cronofy_element_token"
                    @save="updateRule(r.id, $event)"
                />
            </b-tab-item>
            <b-tab-item
                key="add"
                icon="plus"
                :label="$t('valuer_tool.valuer_settings.availability_add-button')"
                value="add"
                :disabled="isCreatingRule"
            >
                <b-progress></b-progress>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import AvailabilityRule from './AvailabilityRule'

export default {
    name: 'AvailabilityRules',
    components: {
        AvailabilityRule,
    },
    props: {
        cronofyUser: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        rules: null,
        activeTab: null,
        nuts3: null,
        isCreatingRule: false,
        rulesBeingDeleted: [],
    }),
    computed: {
        rulesUrl() {
            return utils.urlJoin(this.$config.VALUATION_API_URL, [
                'availability',
                'user',
                this.cronofyUser.user_id,
                'rules',
            ])
        },
    },
    async mounted() {
        this.loadNuts3()
        this.getRules().then(this.resetRuleTab)
    },
    methods: {
        ruleUrl(id) {
            return utils.urlJoin(this.rulesUrl, [id])
        },
        resetRuleTab() {
            if (this.rules.length) {
                this.activeTab = this.rules[0].id
            }
        },
        getRules() {
            return this.$axios.get(this.rulesUrl).then((res) => {
                this.rules = res.data
                this.$emit('on-rules-change', res.data)
            })
        },
        createEmptyRule() {
            var newRuleName = 'Availability slot'
            if (this.rules.length > 0) {
                newRuleName += ' ' + (this.rules.length + 1)
            }
            this.isCreatingRule = true
            this.$axios
                .post(this.rulesUrl, {
                    regions: [],
                    name: newRuleName,
                })
                .then(({ data }) => {
                    this.getRules().then(() => {
                        this.activeTab = data.id
                    })
                })
                .finally(() => {
                    this.isCreatingRule = false
                })
        },
        deleteRule(id) {
            if (confirm(this.$t('valuer_tool.valuer_settings.availability_delete_ays'))) {
                const url = this.ruleUrl(id)
                this.rulesBeingDeleted.push(id)
                this.$axios
                    .delete(url)
                    .then(() => {
                        this.getRules().then(() => {
                            this.rulesBeingDeleted = this.rulesBeingDeleted.filter(
                                (r) => r !== id
                            )
                            this.resetRuleTab()
                        })
                    })
                    .catch(() => {
                        this.rulesBeingDeleted = this.rulesBeingDeleted.filter(
                            (r) => r !== id
                        )
                    })
            }
        },
        loadNuts3() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'availability',
                'nuts3',
            ])

            this.$axios.get(url).then((res) => {
                this.nuts3 = this.processNuts3(res.data)
            })
        },
        processNuts3(nuts3) {
            const provinceAwareNuts3 = {}

            for (const n of nuts3) {
                if (!(n.prov_en in provinceAwareNuts3)) {
                    provinceAwareNuts3[n.prov_en] = {
                        prov_en: n.prov_en,
                        prov_fr: n.prov_fr,
                        prov_nl: n.prov_nl,
                        nuts3: [],
                    }
                }
                provinceAwareNuts3[n.prov_en].nuts3.push(n)
            }

            // Manual fill for Brussels
            provinceAwareNuts3['null'].prov_en = 'Brussels Capital Region'
            provinceAwareNuts3['null'].prov_fr = 'Région de Bruxelles-Capitale'
            provinceAwareNuts3['null'].prov_nl = 'Brussels Hoofdstedelijk Gewest'

            return Object.values(provinceAwareNuts3)
        },
        updateRule(id, data) {
            const url = this.ruleUrl(id)
            this.$axios.put(url, data).then(() => {
                this.getRules()
            })
        },
    },
}
</script>

<style scoped lang="scss">
.delete-button {
    border: unset;
    background-color: unset;
    color: red;
    padding: 0;
    margin-left: 0.8rem;
    cursor: pointer;
    span.icon {
        margin-left: 0;
        margin-right: 0;
    }

    &:hover {
        color: black;
    }
}
</style>
