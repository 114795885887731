/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy': {
    width: 16,
    height: 16,
    viewBox: '0 0 19 34',
    data: '<path pid="0" d="M7 33.9c-.6 0-1.1-.6-1.1-1.2l1.7-11.9H1.2c-1.1 0-1.1-.6-.7-1.2.4-.6.1-.2.1-.2C3 15.1 6.4 8.9 11 .6c.2-.3.5-.6.9-.6.6 0 1.1.6 1.1 1.2l-1.7 11.9h6.5c.9 0 1 .6.9 1l-.1.3C12.5 25.3 9 31.6 8 33.4c-.2.3-.6.5-1 .5z" _fill="#798da6"/>'
  }
})
