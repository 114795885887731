<template>
    <grid-card :title="$t('valuer_tool.general.value.title')" no-padding>
        <template slot="header">
            <white-button
                class="refresh_buttn"
                :disabled="isRefreshing || !isOnline"
                @click="refreshAvm"
            >
                <span>{{ $t('common.refresh') }}</span>
            </white-button>
            <white-button class="analytics_buttn" @click="openAnalytics">
                <span>{{ $t('valuer_tool.general.value.analytics.title') }}</span>
                <svg-icon icon="chart" height="16" scale="1" color="#001837" />
            </white-button>
        </template>
        <template slot="default">
            <div class="cadastral-container">
                <b-field :label="$t('valuation.cadastral_income')">
                    <b-input v-model.number="cadastral_income" type="number" />
                    <p class="control">
                        <span class="button is-static">
                            {{ $t('unit.euro') }}
                        </span>
                    </p>
                </b-field>
            </div>
            <div class="prices_grid">
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.market_value') }}</h4>
                    <p
                        v-if="get_avm_values"
                        class="prices_grid-item-price--blue"
                        :class="{
                            'not-available': !get_avm_values.market_value,
                        }"
                    >
                        {{
                            get_avm_values.market_value ||
                                $t('monolith.global.not_available')
                        }}
                    </p>
                    <b-skeleton v-else height="32px" width="100%" rounded active />
                </div>
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.forced_sale_value') }}</h4>
                    <p
                        v-if="get_avm_values"
                        :class="{ 'not-available': !get_avm_values.forced_sale_value }"
                    >
                        {{
                            get_avm_values.forced_sale_value ||
                                $t('monolith.global.not_available')
                        }}
                    </p>
                    <b-skeleton v-else height="32px" width="100%" rounded active />
                </div>
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.rental_value') }}</h4>
                    <p
                        v-if="get_avm_values?.rental_value"
                        :class="{ 'not-available': !get_avm_values.rental_value }"
                    >
                        {{
                            get_avm_values.rental_value ||
                                $t('monolith.global.not_available')
                        }}
                    </p>
                    <b-skeleton v-else height="32px" width="100%" rounded active />
                </div>
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.reconstruction_value') }}</h4>
                    <p
                        v-if="get_avm_values"
                        :class="{ 'not-available': !get_avm_values.reconstruction_value }"
                    >
                        {{
                            get_avm_values.reconstruction_value ||
                                $t('monolith.global.not_available')
                        }}
                    </p>
                </div>
            </div>
        </template>
        <template slot="modal">
            <b-modal v-model="analyticsShown" width="fit-content">
                <analytics />
            </b-modal>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'
import Analytics from './Analytics.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters as vuexGetters } from 'vuex'
import { mapGetters, mapMutations, mapActions } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import { useOnline } from '@/composables/utils/online'

export default {
    name: 'Value',
    components: {
        GridCard,
        WhiteButton,
        Analytics,
    },
    setup() {
        const { isOnline } = useOnline()
        const requestObjects = useRequest()
        return {
            isOnline,
            ...mapGetters(requestObjects, [
                'getAvmTransactionEstimations',
                'getAvmRentalEstimations',
                'getFeatures',
                'getValuation',
                'getRequestRef',
            ]),
            ...mapMutations(requestObjects, ['SET_VALUATION', 'SET_LOADING']),
            ...mapActions(requestObjects, ['saveOnsiteVal', 'load_valuation_request']),
        }
    },
    data: () => ({
        cadastral_income: null,
        analyticsShown: false,
        isRefreshing: false,
    }),
    computed: {
        ...vuexGetters(['isMobile']),
        forced_sale_value() {
            return this.getAvmTransactionEstimations
                ? utils.forced_sale_value(
                      this.getAvmTransactionEstimations?.asking_price_q50
                  )
                : null
        },
        get_avm_values() {
            if (!this.getAvmRentalEstimations || !this.getAvmTransactionEstimations)
                return

            const { asking_price_q50: rental_value } = this.getAvmRentalEstimations
            const { asking_price_q50: market_value } = this.getAvmTransactionEstimations

            return {
                rental_value: rental_value && this.priceFormat(rental_value, false),
                reconstruction_value:
                    this.getValuation.reconstruction_value &&
                    this.priceFormat(this.getValuation.reconstruction_value),
                forced_sale_value:
                    this.forced_sale_value && this.priceFormat(this.forced_sale_value),
                market_value: market_value && this.priceFormat(market_value),
            }
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        cadastral_income(val) {
            this.SET_VALUATION({
                cadastral_income: +val,
            })
        },
        'getValuation.cadastral_income'(val) {
            this.cadastral_income = val
        },
    },
    mounted() {
        this.cadastral_income = this.getValuation.cadastral_income
    },
    methods: {
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
        refreshAvm() {
            this.isRefreshing = true
            this.saveOnsiteVal()
                .then(() => {
                    return this.$axios.put(
                        utils.urlJoin(this.$config.VALUATION_API_URL, [
                            'request',
                            this.getRequestRef,
                            'status',
                        ]),
                        null,
                        { params: { action: 'submit' } }
                    )
                })
                .then(() => {
                    return this.load_valuation_request()
                })
                .finally(() => {
                    this.isRefreshing = false
                })
        },
        openAnalytics() {
            if (this.isMobile) {
                this.$router.push({
                    name: 'valuation.valuer-app.general.analytics',
                    query: this.$route.query,
                })
            } else {
                this.analyticsShown = true
            }
        },
        closeAnalytics() {
            this.analyticsShown = false
        },
    },
}
</script>
<style lang="scss" scoped>
.analytics_buttn {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: fit-content;

    span {
        color: #001837;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
    }
}
.cadastral-container {
    padding: 24px;
    padding-top: 0;
}
.prices_grid {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    gap: 1px;
    background: #d6e2e9;
    padding-top: 1px;

    @media screen and (max-width: 999px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &-item {
        padding: 24px;
        display: flex;
        flex-direction: column;
        background: white;
        min-height: 6rem;

        h4 {
            color: #798da6;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
        }

        p {
            font-size: 24px;
            font-weight: 700;
        }

        .not-available {
            color: #798da6;
            display: block;
            font-size: 1rem;
            font-weight: 400;
            min-height: 24px;
            display: flex;
            margin-block: auto;
            font-style: italic;
        }

        &-price--blue {
            color: var(--color-primary);
        }
    }
}
</style>
