/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicates': {
    width: 38,
    height: 37,
    viewBox: '0 0 38 37',
    data: `<g>
        <path d="m8.34,11.84h2.12v3.95h-2.12c-.4,0-.72-.32-.72-.72v-2.5c0-.4.32-.72.72-.72Z" />
        <path
            d="m38,12.56v2.5c0,.4-.32.72-.72.72h-11.12v11.12c0,.4-.32.72-.72.72h-2.5c-.4,0-.72-.32-.72-.72v-11.12h-5.05v-3.95h5.05V.72c0-.4.32-.72.72-.72h2.5c.4,0,.72.32.72.72v11.12h11.12c.4,0,.72.32.72.72Z" />
        <path
            d="m20.83,20.83v3.95h-5.05v11.12c0,.4-.32.72-.72.72h-2.5c-.4,0-.72-.32-.72-.72v-11.12H.72c-.4,0-.72-.32-.72-.72v-2.5c0-.4.32-.72.72-.72h11.12v-11.12c0-.4.32-.72.72-.72h2.5c.4,0,.72.32.72.72v11.12h5.05Z" />
        <path d="m30.38,21.56v2.5c0,.4-.32.72-.72.72h-2.12v-3.95h2.12c.4,0,.72.32.72.72Z" />
    </g>`
  }
})
