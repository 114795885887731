/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ruler': {
    width: 16,
    height: 16,
    viewBox: '0 0 19 20',
    data: '<path pid="0" d="M18.3 4.3l-3.1-3.1c-.5-.5-1.5-.5-2 0l-.8.8 1.3 1.2L15 4.5c.2.2.2.6 0 .9-.1.1-.3.2-.4.2-.1 0-.3-.1-.4-.2l-.9-.9-.8-.9-.8-.8-.9.9 1.1 1.1.2.2.3.3c.2.2.2.6 0 .9-.1.1-.3.2-.4.2-.2 0-.3-.1-.4-.2l-.3-.3-.6-.6-.8-.8-.9.9 1.3 1.3.2.2L11.6 8c.2.2.2.6 0 .8-.2.1-.3.2-.5.2s-.3-.1-.4-.2l-.8-.9L9 7l-.9-.8-.8.9.8.8.5.5.4.4c.2.2.2.6 0 .8-.2.1-.3.2-.5.2s-.3-.1-.4-.2l-.3-.3-.5-.5-.9-.9-.9.9.8.8.9.9.9.8c.2.2.2.6 0 .8-.1.1-.3.2-.4.2-.2 0-.3-.1-.4-.2l-1-1-.7-.7-.9-.7-.9.9 1.3 1.3.4.4c.2.2.2.6 0 .9-.1.1-.3.2-.4.2-.1 0-.3-.1-.4-.2l-.5-.4L3 11.4l-.9.9 1.5 1.4 1.1 1.1c.2.2.2.6 0 .8-.1.1-.3.2-.4.2-.1 0-.3-.1-.4-.2l-1-1-.1-.1L1.3 13l-.7.7c-.3.3-.4.6-.4 1s.1.7.4 1l3 3.3c.2.3.6.4 1 .4s.8-.2 1-.4L18.3 6.3c.6-.5.6-1.4 0-2z" _fill="#798da6"/>'
  }
})
