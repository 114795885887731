import Vue from 'vue'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

const isDev = process.env.NODE_ENV.toLowerCase() === 'development'

const defaultRoles = () => ({
    '*': [],
    AVM: [],
    DVM: [],
    ERS: [],
    OVM: [],
})

const auth = {
    namespaced: true,
    state: {
        authenticated: null,
        id: null,
        email: null,
        accreditations: null,
        username: null,
        organization: null,
        roles: defaultRoles(),
        jwtInterval: null,
        showTimeoutRibbon: false,
    },
    getters: {
        getUsername(state) {
            return state.username
        },
        getEmail(state) {
            return state.email
        },
        getAccreditations(state) {
            return state.accreditations
        },
        userId(state) {
            return state.id
        },
        hasRole(state) {
            // Can take a list of roles, or a list of [role, module] pairs
            return (role, module) => utils.containsRole(state.roles, role, module)
        },
        accessibleModules(state) {
            const modules = Object.entries(state.roles)
                .filter(([k, v]) => v.length && !['-', '*'].includes(k))
                .map(([k, v]) => k)

            // dmv:submitter can view and edit borrower info on ovm requests:
            if (!modules.includes('ovm') && state.roles['dvm']?.includes('submitter')) {
                modules.push('ovm')
            }
            return modules
        },
        submitableModules(state) {
            return Object.entries(state.roles)
                .filter(([k, v]) => v.length && !['-', '*'].includes(k))
                .filter(([k, v]) => v.includes('submitter'))
                .map(([k, v]) => k)
        },
        submitableValuations(state, getters) {
            return getters.submitableModules.filter((m) =>
                ['avm', 'dvm', 'ovm'].includes(m)
            )
        },
        roles(state) {
            return state.roles
        },
    },
    mutations: {
        SET_USER(state, data) {
            state.id = data.id || null
            state.email = data.email || null
            state.username = data.username || null
            state.authenticated = data.authenticated || null
            state.organization = data.organization || null
            state.roles = data.roles || defaultRoles()
        },
        SET_ACCREDITATIONS(state, data) {
            state.accreditations = data
        },
        SET_INTERVAL(state, data) {
            const cookie_polling_interval = parseFloat(
                Vue.prototype.$config.COOKIE_POLLING_INTERVAL
            )
            if (data.cb) {
                if (state.jwtInterval) {
                    clearInterval(state.jwtInterval)
                }
                state.jwtInterval = setInterval(data.cb, cookie_polling_interval * 1000)
            } else {
                clearInterval(state.jwtInterval)
                state.jwtInterval = null
            }
        },
        SET_TIMEOUTRIBBON(state, data) {
            state.showTimeoutRibbon = data.showTimeoutRibbon
        },
        TOGGLE_ROLE(state, role) {
            if (isDev) {
                utils.arrayToggle(state.roles['*'], role)
            }
        },
    },
    actions: {
        check_authentication(context) {
            return axios
                .get(utils.urlJoin(Vue.prototype.$config.AUTH_API_URL, 'user'))
                .then((response) => {
                    context.commit('SET_USER', response.data)

                    if (context.getters.hasRole('valuer', 'ovm')) {
                        context.dispatch('fetchAccreditations')
                    }
                })
        },
        fetchAccreditations(context) {
            const url = utils.urlJoin(Vue.prototype.$config.AUTH_API_URL, [
                'accreditations',
                'user',
                context.getters.userId,
            ])
            return axios.get(url).then((response) => {
                context.commit('SET_ACCREDITATIONS', response.data)
            })
        },
        start_interval(context) {
            context.commit('SET_INTERVAL', {
                cb: () => {
                    const cookie_polling_interval = parseFloat(
                        Vue.prototype.$config.COOKIE_POLLING_INTERVAL
                    )
                    const jwt_ = utils.getCookie('re-jwt')
                    if (jwt_ && jwt_ !== '') {
                        const parsed_ = utils.parseJwt(jwt_)
                        const { exp } = parsed_

                        if (exp * 1000 - Date.now() > 60000) return

                        if (exp * 1000 - Date.now() <= cookie_polling_interval * 1000) {
                            utils.deleteCookie('re-jwt')
                            context.commit('SET_USER', {})
                            context.commit('SET_TIMEOUTRIBBON', {
                                showTimeoutRibbon: false,
                            })
                            context.commit('SET_INTERVAL', { cb: null })
                            if (Vue.prototype.$route.name !== 'login') {
                                Vue.prototype.$router.push(
                                    '/default/login?msg=session-expired'
                                )
                            }
                            return
                        }

                        if (exp * 1000 - Date.now() <= 60000) {
                            context.commit('SET_TIMEOUTRIBBON', {
                                showTimeoutRibbon: true,
                            })
                        }
                    } else {
                        context.commit('SET_USER', {})
                        context.commit('SET_TIMEOUTRIBBON', { showTimeoutRibbon: false })
                        context.commit('SET_INTERVAL', { cb: null })
                        if (Vue.prototype.$route.name !== 'login') {
                            Vue.prototype.$router.push(
                                '/default/login?msg=session-expired'
                            )
                        }
                    }
                },
            })
        },
        end_interval(context) {
            context.commit('SET_INTERVAL', { cb: null })
        },
        refresh_token(context) {
            axios
                .get(
                    utils.urlJoin(Vue.prototype.$config.VALUATION_API_URL, [
                        'user',
                        'roles',
                    ])
                )
                .then(() => {
                    context.commit('SET_TIMEOUTRIBBON', { showTimeoutRibbon: false })
                })
        },
        update_current_user(context, user_data) {
            return axios
                .patch(`/user/${context.state.username}`, user_data)
                .then((response) => {
                    context.commit('SET_USER', { ...response.data, authenticated: true })
                })
        },
    },
}
export default auth
