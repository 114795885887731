<template>
    <div class="level">
        <div class="level-left has-text-weight-bold has-text-black">
            {{ $t(header) }}
        </div>
        <div class="level-right has-text-weight-bold has-text-black">
            {{
                value
                    ? value.toLocaleString('fr-BE', {
                          maximumSignificantDigits: 3,
                          currency: 'EUR',
                          style: 'currency',
                      })
                    : ''
            }}
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        header: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['getValuation']),
    },
}
</script>
