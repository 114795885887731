import { ref, computed, watch, unref, set as vueSet, del as vueDel } from 'vue'
import {
    useMutation,
    useIsMutating,
    useQuery,
    useQueries,
    useQueryClient,
    hashKey,
} from '@tanstack/vue-query'
import { PERSISTER_KEY_PREFIX } from '@tanstack/query-persist-client-core'
import utils from '@/shared/plugins/utils'
import { get, set, del, keys } from 'idb-keyval'
// https://stackoverflow.com/a/75085570/16901626
import { useRoute } from 'vue2-helpers/vue-router'
// import { useRoute } from 'vue-router' // Vue 3
import { cloneDeepUnref } from './utils/clone'
import { useOnline } from './utils/online'
import { defaultMutationFn, persistQuery } from './query'
import { fileToDataURL, dataURLtoFile } from './utils/blob'
import { setQueryDataAndPersist } from './query'

// Documents still to be uploaded to the server
const clientDocuments = ref({})

const loadClientDocumentsFromStorage = async (valuation_request_ref = '') => {
    const clientKeys = await keys()
    for (const queryHash of clientKeys.filter((queryHash) =>
        queryHash.includes(`"${valuation_request_ref}","document","client-`)
    )) {
        const query = await get(queryHash)
        const doc = JSON.parse(query).state.data
        vueSet(clientDocuments.value, doc.document_ref, doc)
    }
}

export function useDocuments(loadDocuments = false) {
    const route = useRoute()
    const { isOnline } = useOnline()
    const isMutating = useIsMutating()
    const getRequestRef = computed(() => route.query.valuation_request_ref)
    if (loadDocuments) {
        loadClientDocumentsFromStorage(getRequestRef.value)
    }
    const queryClient = useQueryClient()
    const { data: serverDocuments } = useQuery({
        queryKey: ['valuation', 'request', getRequestRef, 'documents'],
    })

    const getDocuments = computed(() => [
        ...Object.values(clientDocuments.value),
        ...(serverDocuments.value ?? []),
    ])

    const imageQueryFn = async ({ queryKey }) => {
        const path_segments = queryKey.filter(
            (key) => typeof key !== 'object' && key !== 'valuation' && key !== 'auth'
        )
        const params = queryKey.find((key) => typeof key === 'object') ?? {}
        const { data, headers } = await axios.get(
            utils.urlJoin(baseUrl(queryKey), path_segments),
            {
                params,
                responseType: 'blob',
            }
        )
        return fileToDataURL(data)
    }

    const imageFilter = (doc) => {
        const { content_type, purpose } = doc
        return (
            content_type.startsWith('image') &&
            ['exterior_picture', 'interior_picture'].includes(purpose)
        )
    }

    const serverThumbnails = useQueries({
        queries: computed(() =>
            (serverDocuments.value ?? []).filter(imageFilter).map(({ document_ref }) => {
                return {
                    queryKey: [
                        'valuation',
                        'document',
                        document_ref,
                        { variant: 'thumbnail' },
                    ],
                    queryFn: imageQueryFn,
                    staleTime: Infinity,
                }
            })
        ),
    })

    const getImageDataURL = (document_ref) => {
        if (document_ref.startsWith('client-')) {
            return clientDocuments.value[document_ref].dataURL
        } else {
            const document = queryClient.getQueryData({
                queryKey: [
                    'valuation',
                    'document',
                    document_ref,
                    { variant: 'thumbnail' },
                ],
            })
            if (document !== undefined) {
                return document.dataURL
            } else {
                return 'document-image'
            }
        }
    }

    const add_document = async function(file, purpose, room_id = null) {
        const dataURL = await fileToDataURL(file)
        const document = {
            purpose,
            filename: file.name,
            content_type: file.type,
            dataURL,
            document_ref: `client-${utils.uuidv4()}`,
        }
        if (room_id) {
            document.room_id = room_id
        }
        vueSet(clientDocuments.value, document.document_ref, document)
        return upload_document(document)
    }
    const { mutateAsync: upload_document } = useMutation({
        // This mutation assumes the document is in clientDocuments before firing.
        mutationFn: async (document) => {
            let data = new FormData()
            data.append('attachment', dataURLtoFile(document.dataURL, document.filename))
            const params = { purpose: document.purpose }
            if (document.room_id) {
                params.room_id = document.room_id
            }
            const verb = 'POST'
            const queryKey = cloneDeepUnref([
                'valuation',
                'request',
                getRequestRef,
                'documents',
                params,
            ])
            return defaultMutationFn({ verb, queryKey, data })
        },
        onMutate: async (document) => {
            const queryKey = [
                'valuation',
                'request',
                getRequestRef,
                'document',
                document.document_ref,
            ]
            const current_cache = queryClient.getQueryData(queryKey)
            if (current_cache === undefined) {
                // Add document to persistent storage if not present

                // This round-trip setQueryData->getQueryState is a poor man's way
                // to wrap the data (document) into a proper vue-query state,
                // so that it can be persisted manually using the expected structure
                queryClient.setQueryData({ queryKey: ['dummy'] }, document)
                const state = queryClient.getQueryState({ queryKey: ['dummy'] })
                persistQuery({
                    state,
                    queryKey: cloneDeepUnref(queryKey),
                    queryHash: hashKey(cloneDeepUnref(queryKey)),
                    buster: '',
                })
            }
        },
        onSuccess: (data, document, context) => {
            // move document from client to server:
            remove_client_document(document.document_ref)
            setQueryDataAndPersist(
                queryClient,
                ['valuation', 'request', getRequestRef, 'documents'],
                (old) => [...old, ...data]
            )
        },
        onError: (error, document, context) => {},
    })

    const remove_document = function(document_ref) {
        if (document_ref.startsWith('client-')) {
            remove_client_document(document_ref)
        } else {
            remove_server_document(document_ref)
        }
    }
    const remove_client_document = function(document_ref) {
        vueDel(clientDocuments.value, document_ref)
        // clean up document from indexeddb
        const queryKey = ['valuation', 'request', getRequestRef, 'document', document_ref]
        const idbKey = `${PERSISTER_KEY_PREFIX}-${hashKey(cloneDeepUnref(queryKey))}`
        del(idbKey)
    }
    const { mutateAsync: remove_server_document } = useMutation({
        mutationFn: async (document_ref) => {
            const verb = 'DELETE'
            const queryKey = ['valuation', 'document', unref(document_ref)]
            return defaultMutationFn({ verb, queryKey, data: null })
        },
        onSuccess: (result, document_ref, context) => {
            // Update the list of documents (server + client)
            setQueryDataAndPersist(
                queryClient,
                ['valuation', 'request', getRequestRef, 'documents'],
                (old) => old.filter((document) => document.document_ref !== document_ref)
            )
        },
        onError: (error, document_ref, context) => {},
    })

    const readyToUpload = computed(() => isOnline.value && !isMutating)
    watch(readyToUpload, async () => {
        if (readyToUpload.value) {
            for (const document of Object.values(clientDocuments.value)) {
                await upload_document(document)
            }
        }
    })

    return {
        getDocuments,
        getRequestRef,
        getImageDataURL,
        remove_document,
        add_document,
        serverDocuments,
        clientDocuments,
    }
}
