/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 18',
    data: '<path pid="0" d="M21.4 8.7l-2.7-2.2V1.2c0-.3-.3-.6-.6-.6h-3.5c-.3 0-.6.3-.6.6v1.5L11.3.5c-.2-.2-.5-.2-.8 0L.5 8.7c-.1.3-.2.6 0 .9.2.2.6.3.8.1l1.9-1.6v8.5c0 .3.3.6.6.6H18c.3 0 .6-.3.6-.6V8.1l1.9 1.6c.1.1.2.1.4.1s.3-.1.5-.2c.3-.3.2-.6 0-.9zM12.3 16H9.7v-4.4h2.5V16z" _fill="#798da6"/>'
  }
})
