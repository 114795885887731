import { isRef, unref } from 'vue'

// Adapted from https://github.com/TanStack/query/blob/main/packages/vue-query/src/utils.ts

const cloneDeep = function(value, customizer) {
    if (customizer) {
        const result = customizer(value)
        // If it's a ref of undefined, return undefined
        if (result === undefined && isRef(value)) {
            return result
        }
        if (result !== undefined) {
            return result
        }
    }

    if (Array.isArray(value)) {
        return value.map((val) => cloneDeep(val, customizer))
    }

    if (typeof value === 'object' && isPlainObject(value)) {
        const entries = Object.entries(value).map(([key, val]) => [
            key,
            cloneDeep(val, customizer),
        ])
        return Object.fromEntries(entries)
    }
    return value
}

const cloneDeepUnref = function(obj) {
    return cloneDeep(obj, (val) => {
        if (isRef(val)) {
            return cloneDeepUnref(unref(val))
        }

        return undefined
    })
}

const isPlainObject = function(value) {
    if (Object.prototype.toString.call(value) !== '[object Object]') {
        return false
    }

    const prototype = Object.getPrototypeOf(value)
    return prototype === null || prototype === Object.prototype
}

export { cloneDeep, cloneDeepUnref, isPlainObject }
